import cn from '@/i18n/components/Topbar/MaApiState.cn.json';
import en from '@/i18n/components/Topbar/MaApiState.en.json';
import ja from '@/i18n/components/Topbar/MaApiState.ja.json';
import ko from '@/i18n/components/Topbar/MaApiState.ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
