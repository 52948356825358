<template>
    <div v-if="banners.length" class="ma-banners text-md">
        <div
            class="flex justify-between items-center h-10 px-24"
            :class="colors.bg"
        >
            <div class="flex items-center">
                <div
                    v-if="banners.length > 1"
                    class="flex items-center"
                >
                    <ma-link-button
                        class="ma-index-button"
                        color="dark"
                        :disabled="activeIndex === 0"
                        @click="decrement"
                    >
                        <ma-icon name="chevron-left"/>
                    </ma-link-button>
                    <span class="mx-2 select-none">{{ currentIndexInfo }}</span>
                    <ma-link-button
                        class="ma-index-button"
                        color="dark"
                        :disabled="activeIndex === banners.length-1"
                        @click="increment"
                    >
                        <ma-icon name="chevron-right"/>
                    </ma-link-button>
                </div>
                <div class="flex items-center ml-16">
                    <ma-icon
                        name="info-circle"
                        size="md"
                        class="mx-4"
                    />
                    <div
                        class="select-none"
                        :class="{ 'cursor-pointer': !!bannerMessageClick }"
                        @click="bannerMessageClick"
                    >
                        <span v-html="bannerMessage"/>
                    </div>
                </div>
            </div>
            <div class="flex items-center space-x-2">
                <ma-button
                    v-if="bannerAction"
                    color="blue"
                    variant="lighter"
                    size="x-small"
                    class="hover:bg-gray-200 bg-transparent"
                    :loading="bannerAction.loading"
                    @click="bannerAction.click"
                >
                    {{ bannerAction.label }}
                </ma-button>
                <ma-button
                    v-if="bannerClosable"
                    color="blue"
                    variant="ghost"
                    size="x-small"
                    class="hover:bg-gray-200 bg-transparent"
                    @click="dismissBanner"
                >
                    {{ t('buttons.dismiss') }}
                </ma-button>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    import { useRoute } from 'vue-router';
    import { computed, ref, defineOptions, watch } from 'vue';
    import { useGlobal, useMaStorage } from '@/composables';
    import { MaIcon, MaButton, MaLinkButton } from '@mobileaction/action-kit';
    import useEmailVerifyBanner from '@/components/banner/useEmailVerifyBanner';
    import useTrialStatusBanner from '@/components/banner/useTrialStatusBanner';
    import bannerEvents from '@/plugins/mixpanel/definitions/bannerEvents';
    import cn from '@/i18n/components/banner/MaBanners.cn.json';
    import en from '@/i18n/components/banner/MaBanners.en.json';
    import ja from '@/i18n/components/banner/MaBanners.ja.json';
    import ko from '@/i18n/components/banner/MaBanners.ko.json';
    import useExpiredIntegrationsBanner from '@/components/banner/useExpiredIntegrationsBanner';

    defineOptions({
        name: 'ma-banners',
        i18n: {
            messages: {
                cn,
                en,
                ja,
                ko,
            },
        },
    });

    const {
        showBanner: showEmailBanner,
        resendVerificationEmail,
        submitting,
    } = useEmailVerifyBanner();
    const {
        showBanner: showTrialBanner,
        showIntercom,
        hasSearchAdsTrial,
        trialRemainingDays,
    } = useTrialStatusBanner();
    const {
        showBanner: showIntegrationBanner,
        goToIntegrations,
    } = useExpiredIntegrationsBanner();

    const activeIndex = ref(0);
    const bannersHidden = ref(useMaStorage('bannersHidden', {}, sessionStorage));
    const { t } = useI18n();
    const app = useGlobal();
    const route = useRoute();
    watch(
        () => route.name,
        (toPage , fromPage) => {
            if (toPage === 'automations' && toPage !== fromPage) {
                activeIndex.value = 2;
            }
        },
        { immediate: true }
    );

    const BANNER_TYPE = {
        INFO: 'info',
        WARNING: 'warning',
        DANGER: 'danger',
    };
    const BANNERS = {
        VERIFY_EMAIL: 'verifyEmail',
        TRIAL_STATUS: 'trialStatus',
        QUICK_AUTOMATIONS: 'quickAutomations',
        EXPIRED_INTEGRATIONS: 'expiredIntegrations',
    };
    const banners = computed(() =>
        [
            {
                id: BANNERS.VERIFY_EMAIL,
                showBanner: showEmailBanner,
                type: BANNER_TYPE.INFO,
                message: t('verifyEmail'),
                action: {
                    label: t('resend'),
                    loading: submitting.value,
                    click: resendVerificationEmail,
                },
            },
            {
                id: BANNERS.EXPIRED_INTEGRATIONS,
                showBanner: showIntegrationBanner,
                type: BANNER_TYPE.INFO,
                message: t('expiredIntegrations'),
                action: {
                    label: t('connect'),
                    click: goToIntegrations,
                },
            },
            {
                id: BANNERS.TRIAL_STATUS,
                showBanner: showTrialBanner,
                type: BANNER_TYPE.WARNING,
                message: t('trialMessage', {
                    days: trialRemainingDays.value,
                    planType: hasSearchAdsTrial.value
                        ? t('trial')
                        : t('subscription'),
                }),
                action: {
                    label: t('buttons.contactSales'),
                    click: showIntercom,
                },
            },
        ].filter(d => d.showBanner.value && !bannersHidden.value?.[d.id]) // filter dismissed banners
    );
    // current banner props
    const bannerId = computed(() => banners.value[activeIndex.value]?.id);
    const bannerType = computed(() => banners.value[activeIndex.value]?.type);
    const bannerAction = computed(() => banners.value[activeIndex.value]?.action);
    const bannerMessage = computed(() => banners.value[activeIndex.value]?.message);
    const bannerMessageClick = computed(() => banners.value[activeIndex.value]?.messageClick);
    const bannerClosable = computed(() => !banners.value[activeIndex.value]?.dismissHidden);
    const currentIndexInfo = computed(() => `${ activeIndex.value + 1 }/${ banners.value.length }`);

    const increment = () => {
        if (activeIndex.value + 1 >= banners.value.length) {
            return;
        }
        activeIndex.value++;
    };
    const decrement = () => {
        if (activeIndex.value - 1 < 0) {
            return;
        }
        activeIndex.value--;
    };

    const bgColors = {
        info: 'bg-blue-500',
        warning: 'bg-yellow-500',
        danger: 'bg-red-500',
    };
    const colors = computed(() => ({
        bg: bgColors[bannerType.value],
    }));

    const dismissBanner = () => {
        const event = {
            [BANNERS.VERIFY_EMAIL]: bannerEvents(route).verifyEmailBannerClose,
            [BANNERS.TRIAL_STATUS]: bannerEvents(route).trialStatusBannerClose,
            [BANNERS.EXPIRED_INTEGRATIONS]: bannerEvents(route).expiredIntegrationsBannerClose,
        }[bannerId.value];
        app.$mixpanel.track(...event.toMixpanel());

        bannersHidden.value = {
            ...bannersHidden.value,
            [bannerId.value]: true,
        };
        // reset activeIndex if dismissed one not on the banners list
        if (activeIndex.value > banners.value.length - 1) {
            activeIndex.value = 0;
        }
    };
</script>

<style scoped>
    .ma-banners {
        @apply text-white;

        .ma-index-button {
            @apply flex justify-center items-center w-7 h-7 rounded-full cursor-pointer p-0;
        }

        .ak-button--info {
            &:hover {
                @apply bg-white/20;
            }

            &:not(:hover) {
                @apply bg-transparent;
            }

            &.ak-button--disabled {
                @apply hover:bg-transparent;
            }
        }
    }
</style>
