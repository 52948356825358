import { defineStore } from 'pinia';
import { ref } from 'vue';
import { DASHBOARD_TABS } from '@/common/FilteringUtils';

export const REPORT_LEVEL = {
    ACCOUNT: 'ACCOUNT',
    APP: 'APP',
    CAMPAIGN: 'CAMPAIGN',
    AD_GROUP: 'AD_GROUP',
    KEYWORD: 'KEYWORD',
    NEGATIVE: 'NEGATIVE',
    SEARCH_TERM: 'SEARCH_TERM',
    AD: 'AD',
    CPP: 'CPP',
};

//We can remove this mapping once we update Dashboard/index to use REPORT_LEVEL directly
const ACTIVE_NAME_REPORT_LEVEL_MAPPING = {
    [DASHBOARD_TABS.ACCOUNTS]: REPORT_LEVEL.ACCOUNT,
    [DASHBOARD_TABS.APPS]: REPORT_LEVEL.APP,
    [DASHBOARD_TABS.CAMPAIGNS]: REPORT_LEVEL.CAMPAIGN,
    [DASHBOARD_TABS.AD_GROUPS]: REPORT_LEVEL.AD_GROUP,
    [DASHBOARD_TABS.ADS]: REPORT_LEVEL.AD,
    [DASHBOARD_TABS.KEYWORDS]: REPORT_LEVEL.KEYWORD,
    [DASHBOARD_TABS.NEGATIVE_KEYWORDS]: REPORT_LEVEL.NEGATIVE,
    [DASHBOARD_TABS.SEARCH_TERMS]: REPORT_LEVEL.SEARCH_TERM,
    [DASHBOARD_TABS.CPP]: REPORT_LEVEL.CPP,
};
/**
 * We'll move ads manager related vairables under this store
 */
export const useAdsManagerStore = defineStore('ads-manager', () => {
    const reportLevel = ref(REPORT_LEVEL.ACCOUNT);
    const showChartAsCumulative = ref(false);

    const setReportLevel = (level) => {
        reportLevel.value = ACTIVE_NAME_REPORT_LEVEL_MAPPING[level];
    };
    const toggleCumulative = () => {
        showChartAsCumulative.value = !showChartAsCumulative.value;
    };
    return {
        reportLevel,
        showChartAsCumulative,
        setReportLevel,
        toggleCumulative,
    };
});
