import { mapGetters } from 'vuex';
import { DASHBOARD_TABLES } from '@/common/FilteringUtils';
import { useColumnPresetStore } from '@/pinia/ColumnPreset';
import { mapState } from 'pinia';
import { useReportController } from '@/controllers/ReportController';

export const sortingEquals = ({ prop: p1, order: o1 }, { prop: p2, order: o2 }) => p1 === p2 && o1 === o2;

export const onDataGridSortChanged = (event, dataGridInstance, sorting) => {
    if (!event?.fullName.endsWith('sortOrder')) { // ignore events if not related with sorting
        return;
    }

    if (!event.value) { // sorting cleared
        return;
    }
    // get dataGrid state & extract columns
    const { columns } = dataGridInstance.state();
    //get columnIndex from event name. Event name example: columns[12].sortOrder
    const columnIndex = event.fullName.substring(event.fullName.indexOf('[') + 1, event.fullName.indexOf(']'));
    const _sorting = {
        prop: columns[columnIndex].dataField,
        order: event.value === 'asc' ? 'ascending' : 'descending',
    };
    // check if any columns have sorting and if it's changed
    if (!_sorting || sortingEquals(sorting, _sorting)) {
        return;
    }
    return { ..._sorting };
};

export const COLUMN_KEY_CONVERTER = {
    // sometimes headerKey and prop name doesn't match
    // todo make these two the same one by one, and delete this converter logic in time
    campaignName: 'campaign',
    country: 'storefront',
    cr: 'conversionRate',
    cpa: 'averageCPA',
    cpt: 'averageCPT',
    defaultCPTAmount: 'defaultMaxCPTBid',
    ageRange: 'age',
};

export default {
    data() {
        return {
            tableType: '', // set it on its own component
            customColumnIndexStarter: 1000,
            converter: COLUMN_KEY_CONVERTER,
            firstAttributionField: null,
            strategyData: [],
            mx_loadingStrategyData: false,
            filtersController: useReportController(),
        };
    },
    computed: {
        ...mapState(useColumnPresetStore, ['selectedColumns', 'customColumns']),
        ...mapGetters('dashboard', ['attributedTime']),
        mx_entityIdProp() {
            switch (this.tableType) {
                case DASHBOARD_TABLES.ACCOUNTS:
                    return 'orgId';
                case DASHBOARD_TABLES.APPS:
                    return 'appId';
                case DASHBOARD_TABLES.CAMPAIGNS:
                    return 'campaignId';
                case DASHBOARD_TABLES.AD_GROUPS:
                    return 'adGroupId';
                case DASHBOARD_TABLES.ADS:
                    return 'adId';
                case DASHBOARD_TABLES.KEYWORDS || DASHBOARD_TABLES.NEGATIVE_KEYWORDS:
                    return 'keywordId';
                case DASHBOARD_TABLES.SEARCH_TERMS:
                    return 'searchTermKeywordId';
                default:
                    return null;
            }
        },
        mx_entityIdList() {
            const idSet = new Set();
            if (!this.tableData?.length) {
                return idSet;
            }
            this.tableData.forEach((row) => {
                if (row[this.mx_entityIdProp] && row[this.mx_entityIdProp] !== -1) {
                    idSet.add(row[this.mx_entityIdProp]);
                }
            });
            return idSet;
        },
    },
    methods: {
        constructStrategyRequest() {
            const propertyMap = {
                [DASHBOARD_TABLES.APPS]: ['appId'],
                [DASHBOARD_TABLES.CAMPAIGNS]: ['appId', 'campaignId'],
                [DASHBOARD_TABLES.AD_GROUPS]: ['appId', 'campaignId', 'adGroupId'],
                [DASHBOARD_TABLES.SEARCH_TERMS]: ['appId', 'campaignId', 'adGroupId', 'searchTermKeywordId'],
                [DASHBOARD_TABLES.KEYWORDS]: ['appId', 'campaignId', 'adGroupId', 'keywordId'],
            };
            const data = {
                metadataLevel: this.requestLevel,
                data: this.tableData.map((row) => {
                    const entity = {
                        orgId: row.orgId,
                    };
                    const propertiesToAssign = propertyMap[this.tableType] || [];
                    propertiesToAssign.forEach((prop) => {
                        if (prop === 'searchTermKeywordId') {
                            entity['keywordId'] = row[prop];
                        } else {
                            entity[prop] = row[prop];
                        }
                    });
                    return entity;
                }),
            };
            return data;
        },
        async mx_fetchStrategies() {
            this.mx_loadingStrategyData = true;
            try {
                this.strategyData = await this.filtersController.getStrategyData(this.constructStrategyRequest());
            } catch (e) {
                console.error('Failed to fetch metadata affected from automations', e);
            } finally {
                this.mx_loadingStrategyData = false;
            }
        },
    },
    watch: {
        mx_entityIdList: {
            deep: true,
            handler() {
                if (![DASHBOARD_TABLES.ADS, DASHBOARD_TABLES.NEGATIVE_KEYWORDS].includes(this.tableType)) {
                    this.mx_fetchStrategies();
                }
            },
        },
    },
};
