import { watch, computed, ref, onMounted } from 'vue';
import { useMaStorage, useGlobal } from '@/composables';
import { useIntervalFn } from '@vueuse/core';
import { parseError } from '@/common/MaUtils.mjs';
import { createCampaignForecastingMetrics } from '@/common/MaRequests/CampaignForecasting';
import { campaignForecastingEvent } from '@/plugins/mixpanel/eventDefinitions';

const UTILIZATION_MAPPING = {
    LOW: 'LOW',
    MEDIUM: 'MEDIUM',
    HIGH: 'HIGH',
};

export const FORECAST_STATUS = {
    PENDING: 'PENDING',
    FAILED: 'FAILED',
    READY: 'READY',
};

const ERROR_CODES = {
    INSUFFICIENT_DATA: 'INSUFFICIENT_DATA',
    UNRELIABLE_DATA: 'UNRELIABLE_DATA',
};

const MAX_RETRY = 10;

export default function useMetricForecast({ campaign, dailyBudget, countryCode }) {

    let metricResponse = ref({});

    const campaignForecastingStorage = useMaStorage(
        'campaignForecastingRequest',
        { status: null }
    );
    const isStarted = ref(false);

    const retryCount = ref(0);

    const utilization = ref(null);

    const isLowConfidence = ref(false);

    watch([campaign, dailyBudget, countryCode], ([newCampaign, newDailyBudget, newCountryCode], [oldCampaign, oldDailyBudget, oldCountryCode]) => {
        if (
            campaignForecastingStorage.value.status !== FORECAST_STATUS.PENDING &&
            (
                newCampaign.campaignId !== oldCampaign.campaignId
                || newDailyBudget !== oldDailyBudget
                || newCountryCode !== oldCountryCode
            )
        ) {
            metricResponse.value = {};
            isStarted.value = false;
            isPending.value = false;
            retryCount.value = 0;
            utilization.value = null;
            isLowConfidence.value = false;
            interval?.pause();
        }
    });

    const fillUtilization = (response) => {
        utilization.value = {
            campaignUtilization: UTILIZATION_MAPPING[response.forecastMetrics.campaignUtilization],
            confidence: UTILIZATION_MAPPING[response.forecastMetrics.confidence],
            budgetUtilization: UTILIZATION_MAPPING[response.forecastMetrics.budgetUtilization],
        };
    };

    let interval = null;

    const estimate = () => {
        isStarted.value = true;

        let requestDto = {
            campaignId: campaign.value.campaignId,
            dailyBudget: dailyBudget.value,
            countryCode: countryCode.value,
            accountId: campaign.value.orgId,
        };
        if (campaignForecastingStorage.value.status === FORECAST_STATUS.PENDING) {
            requestDto = {
                campaignId: campaignForecastingStorage.value.campaignId,
                dailyBudget: campaignForecastingStorage.value.dailyBudget,
                countryCode: campaignForecastingStorage.value.countryCode,
            };
        }

        interval = useIntervalFn(async () => {
            try {
                retryCount.value += 1;
                if (retryCount.value < MAX_RETRY) {
                    metricResponse.value = await createCampaignForecastingMetrics(requestDto);
                    campaignForecastingStorage.value = {
                        ...requestDto,
                        status: metricResponse.value.status.state,
                    };
                }
            } catch (e) {
                errorCode.value = parseError(e);
            }
        }, 15000, { immediateCallback: true });
    };

    watch(retryCount, (val) => {
        if (val >= MAX_RETRY) {
            campaignForecastingStorage.value = {
                ...campaignForecastingStorage.value,
                status: FORECAST_STATUS.FAILED,
            };
            interval.pause();
            isPending.value = false;
            isStarted.value = false;
        }
    });

    const errorCode = computed(() => metricResponse.value?.status?.detail);

    const app = useGlobal();

    watch([metricResponse, errorCode], ([resp, err]) => {
        if (resp.forecastMetrics) {
            if (resp.status.state !== FORECAST_STATUS.PENDING) {
                interval.pause();
                isStarted.value = false;
            }
            const isDataInsufficient = [ERROR_CODES.INSUFFICIENT_DATA, ERROR_CODES.UNRELIABLE_DATA].includes(err);
            if (isDataInsufficient || resp.forecastMetrics.confidence === UTILIZATION_MAPPING.LOW) {
                isLowConfidence.value = true;
            }
            if (isDataInsufficient) {
                app.$mixpanel.track(...campaignForecastingEvent.notEnoughData.toMixpanel());
            }
            if (resp.forecastMetrics.confidence === UTILIZATION_MAPPING.LOW) {
                app.$mixpanel.track(...campaignForecastingEvent.lowConfidence.toMixpanel());
            }
            if ((!resp || ![FORECAST_STATUS.PENDING, null].includes(resp?.status?.state))) {
                if (!isLowConfidence.value) {
                    app.$mixpanel.track(...campaignForecastingEvent.estimationGiven.toMixpanel());
                }
                fillUtilization(resp);
            }
        }
    });

    const isReady = computed(() => campaignForecastingStorage.value.status === FORECAST_STATUS.READY);
    const isFailed = computed(() => campaignForecastingStorage.value.status === FORECAST_STATUS.FAILED);
    const isPending = computed(() => {
        return isStarted.value || campaignForecastingStorage.value.status === FORECAST_STATUS.PENDING;
    });

    watch(campaignForecastingStorage, (newVal, oldValue) => {
        if (newVal === FORECAST_STATUS.READY && oldValue === FORECAST_STATUS.PENDING) {
            utilization.value = null;
            isLowConfidence.value = false;
            isStarted.value = false;
            metricResponse.value = {};
        }
    });

    const activeDays = computed(() => metricResponse.value?.currentMetrics?.activeDays || 0);
    const keywordCount = computed(() => {
        return metricResponse.value?.currentMetrics?.keywordCount || 0;
    });

    onMounted(() => {
        if (campaignForecastingStorage.value.status === FORECAST_STATUS.PENDING) {
            estimate();
        } else {
            campaignForecastingStorage.value = { status: null };
        }
    });

    return {
        metricResponse,
        utilization,
        isStarted,
        isReady,
        isPending,
        isFailed,
        errorCode,
        activeDays,
        isLowConfidence,
        keywordCount,
        estimate,
    };
}
