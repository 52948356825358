{
    "messages": {
        "errors": {
            "keywordBidUpdated": "更新关键词出价时发生意外错误。",
            "adGroupBidUpdated": "更新最高CPT出价时发生意外错误。",
            "checkPermission": "请检查你的权限！",
            "dailyBudgetUpdated": "更新日预算上限时发生意外错误。 {msg}",
            "errorType": {
                "adGroupCampaign": "广告系列的广告组",
                "campaign": "广告系列的广告组",
                "creativeSet": "Creative Set",
                "negagativeKeywordsCampaign": "广告系列的否定关键词",
                "negativeKeywords": "否定关键词",
                "targetedKeyword": "精准关键词"
            },
            "failedCampaign": "广告系列状态更改失败：{msg}",
            "failedChangeState": "{level}的状态更改失败：{msg}",
            "failedDuplicate": "复制失败：{msg}，如果问题仍未解决请联系我们",
            "failedDuplicatedError": "复制失败：{0}，错误{1}",
            "failedToFetchChart": "尝试获取图表数据超时。请点击下方按钮重试。",
            "failedToFetchSummary": "尝试获取摘要数据超时。请点击下方按钮重试。",
            "failedToFetchTable": "尝试获取表格数据超时。请点击下方按钮重试。",
            "keywordBidUpdateFailed": "更新关键词出价时发生意外错误。",
            "noReportData": "抱歉！未找到可以显示的数据。"
        },
        "info": {
            "addGoalTabInfo": "如果选择此标签，则可以为你的移动衡量服务提供商描述新目标。",
            "clickToFilter": "点击以根据该字段过滤",
            "dataIsFetching": "正在获取最新数据。请稍等…",
            "deleteAdGroup": "你确定要删除所选广告组吗？",
            "deleteCampaign": "你确定要删除所选广告系列吗？",
            "deleteCreativeSet": "你确定要删除所选Creative Set吗？",
            "deleteNegativeKeyword": "你确定要删除所选的否定关键词吗？",
            "enterNewBidAmount": "输入新的出价金额",
            "goalTabInfo": "如果选择此标签，你将看到预设自定义转换下的移动衡量服务提供商的报告数据。",
            "invalidKeywordBid": "无效的关键词出价金额",
            "noGoalTabInfo": "还没有移动衡量服务提供商数据、或者还未设定自定义转换。",
            "noTodayDataYet": "还未获取你的今日数据。要获取今日数据，可以点击顶部的“刷新”按钮。",
            "oneAdGroup": "（1次1个广告组）",
            "oneCampaign": "（1次1个广告系列）",
            "oneCreativeSet": "（1次1个Creative Set）",
            "refreshDataRestriction": "Apple Search Ads没有可以读取的数据。",
            "updateKeywordBids": "更新所选广告系列的关键词出价"
        },
        "copyMain": {
            "keyword": "关键词",
            "negative": "否定关键词",
            "term": "搜索词"
        },
        "copySuccess": {
            "keyword": "关键词已被成功复制到新的广告组。",
            "negative": "否定关键词已被成功复制到新的广告组。",
            "term": "搜索词已被成功复制到新的广告组。"
        },
        "duplicatedKeyword": {
            "keyword": "有关键词已被添加到广告系列和广告组中",
            "negative": "有部分否定关键词已被添加到广告系列和广告组中",
            "term": "有部分搜索词已被添加到广告系列和广告组中"
        },
        "success": {
            "adGroupDuplicated": "已成功复制广告组({msg})",
            "campaignDuplicated": "已成功复制广告系列({msg})",
            "completedChangeState": "更改状态操作成功完成。",
            "completedChangeStateLater": "我们已收到你的更改状态请求。这需要一些时间，完成后会立刻通知你。",
            "completedDeleteItem": "删除操作成功完成。",
            "completedDeleteItemLater": "我们已收到你的删除请求。这需要一些时间，完成后会立刻通知你。",
            "deleteItem": "广告系列未被删除 | 删除了{n}个广告系列 | 删除了{n}个广告系列",
            "keywordBidUpdated": "关键词出价已成功更新",
            "keywordBids": "所选广告系列的关键词出价已成功更新",
            "latestDataCalled": "已收到刷新数据请求。可能需要超过5分钟的时间…"
        },
        "warning": {
            "defaultCreativeSetNotDuplicate": "无法复制默认的Creative Sets",
            "defaultCreativeSetNotEditable": "无法编辑默认的Creative Sets",
            "defaultCreativeSetsIgnored": "无法复制默认的Creative Sets，所以它们会被自动忽略。",
            "fetchingCurrentData": "正在获取最新数据",
            "fetchingCurrentDataInfo": "正在获取最新数据。完成后我们会通知你。",
            "onlyOneSingleAdGroup": "你只能编辑1个广告组。请选择1个广告组",
            "onlyOneSingleCampaign": "你只能编辑1个广告系列。请选择1个广告系列",
            "onlyOneSingleCreativeSet": "你只能编辑1个Creative Set。请选择1个Creative Set",
            "onlySameAppsAtBulkDuplicate": "你可以在批量复制时从同一应用程序中复制Creative Sets",
            "thereIsAlreadyACampaignName": "已经有名为 ({name})的广告系列，请修改名称以继续"
        }
    },
    "ALL_USERS": "所有用户",
    "FEMALE": "女性",
    "IPAD": "仅iPad",
    "IPHONE": "仅iPhone",
    "IPHONE_IPAD": "iPad和iPhone",
    "MALE": "男性",
    "MALE_FEMALE": "全部",
    "NEW_USERS": "新用户",
    "ONLY_RE_ATTR_TYPE": "仅再归因",
    "PAUSED_BY_USER": "由于用户暂停",
    "RETURNING_USERS": "回访用户",
    "USERS_OF_MY_OTHER_APPS": "我的其他应用程序的用户",
    "WITHOUT_RE_ATTR_TYPE": "仅新用户下载",
    "WITH_RE_ATTR_TYPE": "新用户下载+再归因",
    "actions": "操作",
    "active": "有效",
    "adGroupStatus": "广告组状态",
    "addLabels": "添加标签",
    "allCampaigns": "所有广告系列",
    "appIcon": "应用图标",
    "appStoreSearchResults": "Apple Store搜索结果",
    "appStoreSearchTab": "App Store搜索标签",
    "automationRule": "自动化规则",
    "buttons": {
        "automationRule": "自动化规则",
        "cancel": "取消",
        "createNewCampaign": "创建新的广告系列",
        "update": "更新"
    },
    "callLatestData": "更新最新数据",
    "campaignStatus": "广告系列状况",
    "categoryLabel": "{category} ({n} 项目)",
    "changeGoal": "改变目标",
    "clear": "清除",
    "collapse": "收起",
    "compareSelectedItems": "比较所选项目",
    "compareSelectedItemsInfo": "使用图表比较所选项目",
    "compareSelectedMetadata": "比较: {n} {type}",
    "copyAllKeywords": "复制关键词",
    "copyAllKeywordsToOther": "复制广告组的所有关键词到另一个广告组",
    "copyAllNegativeKeywords": "复制所有否定关键词",
    "copyAllNegativeKeywordsToOther": "复制广告组的所有否定关键词到另一个广告组",
    "copyKeywordsToOther": "复制所选关键词到其他广告组",
    "copyNegativeKeywordsToOther": "复制所选否定关键词到其他广告组",
    "copySelectedCount": "无数据 | 复制所选搜索词复制所选搜索词 | 复制所选搜索词",
    "copySelectedKeywords": "复制所选关键词到",
    "copySelectedNegativeKeywords": "复制所选否定关键词",
    "copySelectedSearchTerms": "将所选搜索词作为关键词复制到其他广告组",
    "createAutomationRule": "创建自动化规则",
    "creativeSetStatus": "Creative Set状态",
    "creativeTypes": {
        "CREATIVE_SET": "Creative Set"
    },
    "dailyBudget": "日预算",
    "dashboard": "信息中心",
    "dashboardCurrency": "信息中心货币：你的数据将以所选货币提供。",
    "downloadDailyData": "下载每日数据",
    "downloadData": "下载数据",
    "downloadKeywords": "下载关键词",
    "duplicateAdGroup": "复制广告组",
    "duplicateCampaign": "复制广告系列",
    "duplicateCreativeSet": "复制Creative Set",
    "editSelectedAdGroup": "编辑所选广告组",
    "editSelectedCampaign": "编辑所选广告系列",
    "editSelectedCreativeSet": "编辑所选Creative Sets",
    "expand": "扩大",
    "funnel": "漏斗",
    "goOnWithOld": "使用旧版本",
    "goalInfo": {
        "eventName": "事件",
        "goalId": "目标ID",
        "goalName": "目标名称",
        "trackId": "应用程序"
    },
    "goalSelection": "目标选择",
    "groupBy": "分组",
    "keywordMatchTypeUpdated": "成功更新关键词匹配类型",
    "labels": "追加标签",
    "lastUpdateTime": "上次更新时间",
    "maximizeWindow": "窗口最大化",
    "minimizeWindow": "窗口最小化",
    "multiple": "多个",
    "noAttribution": "没有移动衡量服务提供商",
    "noAttributionInfo": "首先，你应该将移动衡量服务提供商串联到Search Ads的信息中心以继续使用目标。",
    "noDataFoundForTimeGranularity": "未找到每日粒度数据",
    "noGoal": "没有目标",
    "noGoalInfo": "首先，你应该创建目标来查看移动衡量服务提供商的数据。",
    "notEventTakenLately": "最近30天未发生事件。",
    "notSelected": "未选择",
    "pendingIntegration": "我们正在等待移动衡量服务提供商事件回传，可能需要24-48小时。",
    "pendingIntegrationWarned": "在过去的48小时里，此应用程序在整合后仍未发生任何安装或应用内事件。",
    "pleaseChooseSingleGoal": "请选择1个目标以继续。",
    "reAttributionInfo": "<b>再归因<\/b><br>计算与再归因相关的指标有【新用户】【再归因】【新用户+再归因】3个选项。",
    "result": {
        "fromNAdGroups": "{n}个广告组的结果",
        "fromNApps": "{n}个应用程序的结果",
        "fromNCampaigns": "{n}个广告系列的结果",
        "fromNCreativeSets": "{n}个Creative Sets的结果",
        "fromNKeywords": "{n}个关键词的结果",
        "fromNSearchTerms": "{n}个搜索词的结果"
    },
    "searchCampaign": "选择广告系列",
    "selectGoalInfo": "点击此处以查看移动衡量服务提供商的数据",
    "selectedN": "已选择{n}个",
    "servingReasons": {
        "ADGROUP_END_DATE_REACHED": "结束日期已到",
        "AD_GROUP_MISSING": "未找到广告组",
        "AD_GROUP_PAUSED_BY_USER": "由于用户暂停",
        "APP_NOT_ELIGIBLE": "应用程序不符合条件",
        "APP_NOT_ELIGIBLE_SEARCHADS": "应用程序不符合Search Ads条件",
        "APP_NOT_LINKED_TO_CAMPAIGN_GROUP": "应用程序与广告系列组未连接",
        "APP_NOT_PUBLISHED_YET": "应用程序尚未发布",
        "APP_NOT_SUPPORT": "不支持此应用程序",
        "AUDIENCE_BELOW_THRESHOLD": "受众低于阈值",
        "BO_END_DATE_REACHED": "预算订单结束日期已到",
        "BO_EXHAUSTED": "预算已用完",
        "BO_START_DATE_IN_FUTURE": "未达到预算订单日期",
        "CAMPAIGN_END_DATE_REACHED": "结束日期已到",
        "CAMPAIGN_NOT_RUNNING": "广告系列未运行",
        "CAMPAIGN_START_DATE_IN_FUTURE": "未到开始日期",
        "CREDIT_CARD_DECLINED": "信用卡无效",
        "DELETED_BY_USER": "被用户删除",
        "LOC_EXHAUSTED": "Loc exhausted",
        "MISSING_BO_OR_INVOICING_FIELDS": "结算单信息不足",
        "NO_ELIGIBLE_COUNTRIES": "没有符合条件的国家",
        "NO_PAYMENT_METHOD_ON_FILE": "未设定付款方式",
        "ORG_CHARGE_BACK_DISPUTED": "Org charge back disputed",
        "ORG_PAYMENT_TYPE_CHANGED": "付款方式已更改",
        "ORG_SUSPENDED_FRAUD": "Org suspended for fraud",
        "ORG_SUSPENDED_POLICY_VIOLATION": "Org suspended for policy violation",
        "PAUSED_BY_SYSTEM": "由于系统暂停",
        "PAUSED_BY_USER": "由于用户暂停",
        "PENDING_AUDIENCE_VERIFICATION": "等待受众认证",
        "SAPIN_LAW_AGENT_UNKNOWN": "Unknown Law agent",
        "SAPIN_LAW_FRENCH_BIZ": "Law French BIZ",
        "SAPIN_LAW_FRENCH_BIZ_UNKNOWN": "Unknown Law French BIZ",
        "START_DATE_IN_THE_FUTURE": "未到开始日期",
        "TAX_VERIFICATION_PENDING": "等待税务确认"
    },
    "showFunnel": "显示漏斗",
    "status": {
        "onHold": "保留",
        "paused": "停止",
        "startDateNotReached": "未到开始日期"
    },
    "tableColumn": {
        "NA": "N\/A",
        "appName": "应用名",
        "campaign": "广告系列",
        "campaignId": "广告系列ID",
        "editColumn": "编辑列",
        "enabled": "已激活"
    },
    "totalOfAllAdGroups": "所有广告组",
    "totalOfAllApp": "所有应用程序",
    "totalOfAllCampaign": "所有广告系列",
    "totalValueCopied": "已复制所有值",
    "updateKeywordsBids": "更新关键词出价",
    "updateKeywordsBidsSelected": "更新所选广告系列的关键词出价",
    "uploadKeywords": "上传关键词",
    "uploadNegativeKeywords": "上传否定关键词"
}
