import mitt from 'mitt';

const _mitt = mitt();

export const bus = {
    $on: (...args) => _mitt.on(...args),
    $off: (...args) => _mitt.off(...args),
    $emit: (...args) => _mitt.emit(...args),
};

export const c = {
    USER_ACCOUNT_UPDATED: 'user-account-updated',
    RELOAD_PAGE: 'reload-page',
    MULTI_LOGIN_DETECTED: 'multi-login-detected',
    INTEGRATIONS_EXPIRED: 'integrations-expired',
    REORDER_COLUMN: 'reorder-column',
};

export const reloadCause = {
    CHUNK_LOAD_ERROR: 'chunk-load-error',
};

export default { bus, c, reloadCause };
