<script setup>
    import { MaEmpty, MaButton } from '@mobileaction/action-kit';
    import { useI18n } from 'vue-i18n';
    import { useGlobal } from '@/composables';

    const { t } = useI18n();
    const { $maIntercom } = useGlobal();
    const openIntercom = () => {
        $maIntercom.show();
    };
</script>

<template>
    <div class="flex flex-col h-full">
        <div class="flex px-[62px] h-[72px]">
            <img
                src="@/assets/sa-logo-dark.svg"
                class="w-[150px]"
                alt="Search Ads Logo"
            >
        </div>
        <div class="h-full flex justify-center pt-10">
            <ma-empty>
                <template #icon>
                    <img src="/img/astronout.svg" alt="astronout">
                </template>
                <template #title>
                    <div class="text-gray-900 text-[28px] font-[500] mt-1">
                        {{ t('common.wellBeBackSoon') }}
                    </div>
                </template>
                <template #description>
                    <div class="text-center font-normal text-gray-500 max-w-[400px] mt-1">
                        {{ t('common.sorryForInconvenience1') }}
                        <ma-button color="blue" variant="ghost" @click="openIntercom">
                            {{ t('common.contactUsTerm1')?.toLowerCase() }}
                        </ma-button>
                        {{ t('common.sorryForInconvenience2') }}
                    </div>
                </template>
            </ma-empty>
        </div>
    </div>
</template>

<style scoped lang="less">

</style>
