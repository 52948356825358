{
    "addedKeywords": {
        "keyword": "追加されたキーワード",
        "negative-keyword": "ネガティブ・キーワードの追加"
    },
    "bidAmountMustBeNumber": "入札金額は数字でなければならない。小数点以下はドット(00.0)で区切ってください。",
    "bidAmountRequired": "入札金額は必須で、ゼロ(0)より大きくなければなりません。",
    "buttons": {
        "changeAll": "すべてを変更する",
        "changeSelected": "選択を変更する",
        "removeAll": "すべて削除",
        "removeSelected": "選択されたものを削除する"
    },
    "createKeywordGroup": "キーワードグループの作成はこちら",
    "createLabel": "ラベルを追加",
    "enterKeywords": "{type} キーワードを入力または貼り付けます。キーワードはカンマで区切って入力することも、1行に1つずつ入力することもできます。",
    "goToKeywordTracking": "キーワードトラッキングへ",
    "keywordBid": "キーワード入札",
    "keywordGroup": "キーワードグループ",
    "limitForAdGroup": "広告グループのキーワード数は5000件が上限です。上限を超えたキーワードは追加されません。",
    "noNegativeKeywords": "除外キーワードはまだ追加されていません",
    "none": "値なし",
    "pleaseInputCorrectBidAmount": "正しい入札額を入力してください",
    "selectAll": "全て選択",
    "specialCharacters": "特殊文字 ({chars}) は受け付けられません。削除の上でリトライしてください。",
    "youCanEitherAddKeywords": "キーワードを手動でこちらに入力するか、左のリストから選択することができます。",
    "addLabelsToSelected": "選択したラベルを適用する",
    "hideSelection": "選択したものを隠す",
    "editKeywords": "編集{count} キーワード",
    "select": "選択する"
}
