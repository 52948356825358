{
    "ad-intelligence": {
        "1": "Go beyond the discovery campaigns",
        "2": "Explore the paid keywords of any app",
        "3": "Type a keyword and see who’s running ads on",
        "heading": "See the top Search Ads advertisers & track your competitors' ASA strategy"
    },
    "automation-rules": {
        "1": "Automatically take actions",
        "2": "Optimize your bid amount",
        "3": "Receive customize updates",
        "4": "Freeze campaigns or add negative keywords",
        "heading": "Set up automation rules to make sure your campaigns are always optimized"
    },
    "competitors": {
        "1": "Find out where you stand against your competitors",
        "2": "Benefit from Keyword Advisor",
        "3": "Easily set up campaigns with QCS",
        "heading": "Add your competitors just in few clicks"
    },
    "conversions": {
        "1": "Create funnels by using your goals",
        "2": "View the details of goal categories",
        "3": "Analyze your in-app event performance",
        "4": "Track all the goals that match your KPIs",
        "heading": "Choose any in-app event as your goal and analyze the effect of your campaign efforts"
    },
    "dashboard": {
        "1": "Create campaigns & scale faster",
        "2": "Track your performance",
        "3": "Add automations rules",
        "4": "Optimize your budget",
        "heading": "Analyze performance across campaigns, all in one place"
    },
    "dashboard-lite": {
        "1": "View your ASA performance",
        "2": "Track your campaign metrics",
        "3": "Optimize your bids",
        "4": "Customize your essentials",
        "heading": "Easily track your campaign metrics"
    },
    "audit": {
        "1": "Get a detailed analysis of your ASA account",
        "2": "Discover defects that are wasting your budget & time",
        "3": "See the improvement areas for your campaign performance",
        "heading": "Find out your real Apple Search Ads performance"
    },
    "keyword-advisor": {
        "1": "Receive customized keyword lists",
        "2": "Scale up your campaigns",
        "3": "Boost your downloads",
        "4": "Strengthen your position in the market",
        "heading": "Automatically find high-intent keywords"
    },
    "keyword-auction-insights": {
        "1": "Go beyond the discovery campaigns",
        "2": "Explore the paid keywords of any app",
        "3": "Type a keyword and see who’s running ads on",
        "heading": "See the top Search Ads advertisers & track your competitors' ASA strategy"
    },
    "keyword-hunt": {
        "1": "See the organic keywords that your competitors are ranking for",
        "2": "Choose the best possible keywords by relying on App Store metrics",
        "3": "Instantly start running ads on those keywords.",
        "heading": "Uncover the organic performance of your competitors"
    },
    "overview": {
        "1": "Take insights about your competitors' position",
        "2": "See your top 10 Keywords",
        "3": "Analyze your country-based ASA performance",
        "heading": "Get a quick overview of your app's Apple Search Ads performance"
    },
    "campaign-wizard": {
        "1": "Don’t waste your time and money to boost your mobile growth!",
        "2": "Get a quick start to Apple Search Ads",
        "3": "Boost your mobile growth!",
        "heading": "Build Apple Search Ads Campaigns in 3 clicks!"
    },
    "recommendations": {
        "1": "Increase/decrease or pause bids for recommended keywords",
        "2": "Take the action just in 1 click!",
        "3": "Act faster with recommendations",
        "heading": "See the recommended actions for your bids, keywords, daily caps, and budget."
    },
    "report": {
        "1": "Create funnels by using your goals",
        "2": "View the details of goal categories",
        "3": "Analyze your in-app event performance",
        "4": "Track all the goals that match your KPIs",
        "heading": "See exactly your ad spend and return on a single dashboard"
    },
    "sa-benchmarks": {
        "1": "Learn the industry benchmarks for different storefronts",
        "2": "Fine-tune your marketing channel strategy",
        "3": "See your position against market trends",
        "heading": "See the average TTR, CR, CPT, and CPA for your category to see how you stack up against competitors"
    },
    "smart-bidding": {
        "1": "Eliminate wasted ad spend by bidding the right amount for the right keywords",
        "2": "Focus more on your messaging and creative strategy",
        "3": "Improve your UA and cost forecast accuracy",
        "4": "Validate keywords and iterate faster",
        "heading": "Save time from monitoring and adjusting bids manually"
    },
}
