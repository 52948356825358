{
    "add": "추가",
    "addNewNotifier": "새 알리미 추가",
    "addNewChannel": "새 Slack 채널 추가",
    "addLabel": "레이블 추가",
    "selectedLabels": "선택한 레이블",
    "addFirstLabel": "여기에 첫 번째 레이블을 추가하세요.",
    "addNewLabel": "새 레이블 추가",
    "cancel": "아니요, 취소",
    "clickToRemove": "제거하려면 클릭",
    "confirmActivateTitle": "선택한 자동화를 활성화하시겠습니까?",
    "confirmActivateButton": "예, 활성화",
    "confirmDeleteTitle": "선택한 자동화를 삭제하시겠습니까?",
    "confirmDeleteButton": "예, 삭제",
    "confirmDuplicateTitle": "선택한 자동화를 복제하시겠습니까?",
    "confirmDuplicateButton": "예, 중복",
    "confirmPauseTitle": "선택한 자동화를 일시 중지하시겠습니까?",
    "confirmPauseButton": "예, 일시 중지",
    "createLabel": "라벨 만들기",
    "days": "일수",
    "hours": "시간",
    "editRuleName": "규칙 이름 편집",
    "editLabel": "레이블 편집",
    "allLabels": "모든 레이블",
    "allUsers": "모든 사용자",
    "compareOptions": {
        "GREATER_THAN": "보다 큰",
        "LESS_THAN": "미만",
        "IN_RANGE": "범위 내",
        "NOT_IN_RANGE": "범위 내에 없음",
        "EQUALS": "같음",
        "NOT_EQUALS": "같지 않음",
        "CONTAINS": "포함 사항",
        "NOT_CONTAINS": "다음을 포함하지 않습니다.",
        "EMPTY": "비어 있음",
        "NOT_EMPTY": "비어 있지 않음",
        "IN": "in",
        "IS": "는",
        "IS_NOT": "는"
    },
    "createCustomTimeframe": "사용자 지정 기간 만들기",
    "customTimeframes": "사용자 지정 기간",
    "dayRangeText": "출처: {start} - {end} 며칠 전",
    "defaultTimeframes": "기본 기간",
    "defaultDateRanges": {
        "FROM_START": "캠페인 시작부터",
        "LAST_HOUR": "지난 시간",
        "LAST_DAY": "지난 1일(오늘부터 적용)",
        "LAST_3_DAY": "지난 3일(오늘부터)",
        "LAST_WEEK": "지난 7일(오늘부터)",
        "DURING_LAST_WEEK": "지난주(일~월)",
        "LAST_14_DAY": "지난 14일(오늘부터)",
        "THIS_MONTH": "이번 달",
        "LAST_MONTH": "지난 30일(오늘 포함)",
        "LAST_2_MONTH": "지난 60일(오늘 포함)",
        "LAST_3_MONTH": "지난 90일(오늘 포함)",
        "CUSTOM_HOUR": "사용자 지정 시간"
    },
    "hourRangeText": "출처: {start} - {end} 몇 시간 전",
    "integrateSlack": "Slack 통합",
    "label": "라벨",
    "labelNameMustBeUnique": "레이블 이름은 고유해야 합니다.",
    "lastNDays": "지난 {n} 일(오늘 포함)",
    "lastNHours": "지난 {n} 시간(현재 시간 포함)",
    "noEmails": "이메일을 찾을 수 없습니다.",
    "pause": "일시 중지",
    "selected": "선택됨",
    "selectNotifiers": "알리미 선택",
    "selectTimeframe": "기간 선택",
    "selectedNotifiers": "선택된 알리미",
    "untitledTimeframe": "제목 없는 기간",
    "today": "오늘",
    "youDontHaveSlack": "Slack 연동이 없습니다."
}