{
    "addedKeywords": "Added Keywords",
    "addToKeywords": "Add to keywords",
    "addToNegativeKeywords": "Add to negative keywords",
    "dataIsNotReady": "We are still fetching your current data. When we finish it, you can add them to your list.",
    "limitForAdGroupKeywords": "Limit for Ad Group keywords is 5000, you cannot add anymore",
    "limitForAdGroupKeywords2": "Limit for Ad Group keywords is 5000, keywords exceeding the limit are not added to the list",
    "noKeywordAddedList": "No keyword added to list!",
    "popularity": "Popularity",
    "runAds": "Run Ads",
    "total": "Total:"
}
