{
    "addToKeywords": "Keyword에 추가",
    "addToNegativeKeywords": "Negative Keyword에 추가",
    "limitForAdGroupKeywords": "Ad Group의 Keyword 제한은 5000개로, 더 이상 추가할 수 없어요.",
    "limitForAdGroupKeywords2": "Ad Group Keyword의 제한은 5000개로, 초과된 Keyword는 목록에 추가되지 않아요",
    "noKeywordAddedList": "목록에 키워드가 추가되지 않았어요.",
    "popularity": "Popularity",
    "runAds": "캠페인에 적용하기",
    "total": "Total:"
}
