import {
    AD_FILTER_COLUMNS,
    AD_GROUP_FILTER_COLUMNS,
    APP_FILTER_COLUMNS,
    CAMPAIGN_FILTER_COLUMNS,
    CPP_FILTER_COLUMNS,
    DASHBOARD_TABS,
    KEYWORD_FILTER_COLUMNS,
    NEGATIVE_KEYWORD_FILTER_COLUMNS,
    SEARCH_TERM_FILTER_COLUMNS,
} from '@/common/FilteringUtils';
import { FILTER_LEVELS } from '../common/FilteringUtils';
import { ATTRIBUTED_TIME } from '../common/DashboardUtils';


export const types = {
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_SELECTED_DATA: 'UPDATE_SELECTED_DATA',
    UPDATE_TAB: 'UPDATE_TAB',
    UPDATE_ATTRIBUTED_TIME: 'UPDATE_ATTRIBUTED_TIME',
    CHANGE_FIRST_TIME: 'CHANGE_FIRST_TIME',
};

Object.keys(types).forEach(k => types[k] = k);

const state = {
    tabs: [
        {
            page: DASHBOARD_TABS.ACCOUNTS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'ACCOUNT',
            automationQueryType: 'ACCOUNT',
            requestLevel: FILTER_LEVELS.ACCOUNT,
            refreshSigner: true,
            refresh: false,
            filterableColumns: [],
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.APPS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'ACCOUNT',
            automationQueryType: 'APP',
            requestLevel: FILTER_LEVELS.APP,
            refreshSigner: true,
            refresh: false,
            filterableColumns: APP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.CAMPAIGNS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'APP',
            automationQueryType: 'CAMPAIGN',
            requestLevel: FILTER_LEVELS.CAMPAIGN,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: CAMPAIGN_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.AD_GROUPS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'APP',
            automationQueryType: 'ADGROUP',
            requestLevel: FILTER_LEVELS.AD_GROUP,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: AD_GROUP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.KEYWORDS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'CAMPAIGN',
            automationQueryType: 'KEYWORD',
            requestLevel: FILTER_LEVELS.KEYWORD,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            filterableColumns: KEYWORD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.SEARCH_TERMS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'KEYWORD',
            automationQueryType: 'SEARCH_TERM',
            requestLevel: FILTER_LEVELS.SEARCH_TERM,
            refreshSigner: true,
            refresh: false,
            filterableColumns: SEARCH_TERM_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.ADS,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            requestLevel: FILTER_LEVELS.ADS,
            filterableColumns: AD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.CPP,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            requestLevel: FILTER_LEVELS.CPP,
            filterableColumns: CPP_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
        {
            page: DASHBOARD_TABS.NEGATIVE_KEYWORDS,
            detailItem: null,
            loading: { table: false, badge: false, chart: false },
            error: { table: false, badge: false, chart: false },
            tryAgain: { table: false, badge: false, chart: false },
            badgeOptions: null,
            selectedData: [],
            automationType: 'CAMPAIGN',
            automationQueryType: 'CAMPAIGN',
            requestLevel: FILTER_LEVELS.NEGATIVE,
            refreshSigner: true,
            refresh: false,
            filterParam: null,
            defaultSort: { prop: 'negativeKeyword', order: 'ASC' },
            filterableColumns: NEGATIVE_KEYWORD_FILTER_COLUMNS,
            removedFilterObject: { item: {}, remainingItems: [] },
        },
    ],
    tabFilters: [],
    preSelectedData: [],
    attributedTime: ATTRIBUTED_TIME.INSTALL_TIME,
    isLoadedFirstTime: true,
};

const getters = {
    tabs: state => state.tabs,
    tabFilters: state => state.tabFilters,
    preSelectedData: state => state.preSelectedData,
    attributedTime: state => state.attributedTime,
    isEventTime: state => state.attributedTime === ATTRIBUTED_TIME.EVENT_TIME,
};

const actions = {
    updatePreSelectedData({ commit }, data) {
        commit(types.UPDATE_SELECTED_DATA, data);
    },
    updateFilterList({ commit }, filters) {
        commit(types.UPDATE_FILTERS, filters);
    },
    updateTab({ commit }, { tabIndex, tabInfo }) {
        commit(types.UPDATE_TAB, { tabIndex, tabInfo });
    },
    updateAttributedTime({ commit }, value) {
        commit(types.UPDATE_ATTRIBUTED_TIME, value);
    },
    changeFirstTime({ commit }) {
        commit(types.CHANGE_FIRST_TIME);
    },
};

const mutations = {
    [types.UPDATE_TAB](s, { tabIndex, tabInfo }) {
        Object.assign(s.tabs[tabIndex], tabInfo);
    },
    [types.UPDATE_FILTERS](s, filters) {
        s.tabFilters = filters;
    },
    [types.UPDATE_SELECTED_DATA](s, data) {
        s.preSelectedData = data;
    },
    [types.UPDATE_ATTRIBUTED_TIME](s, value) {
        s.attributedTime = value;
    },
    [types.CHANGE_FIRST_TIME](s) {
        s.isLoadedFirstTime = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

