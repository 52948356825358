<template>
    <ma-popover-checkbox-group
        :value="selected"
        :options="options"
        label-selector
        :select-all-checkbox-label="t('allLabels')"
        show-search
        @update:value="v => emit('update:selected', v)"
    >
        <template #label="{ color, name, value }">
            <div class="ma-checkbox-list-item">
                <div class="flex items-center gap-2">
                    <ma-icon
                        name="dot"
                        size="xs"
                        :color="color"
                        class="h-[8px] w-[8px]"
                    />
                    {{ name }}
                </div>
                <div v-if="showActions" class="flex items-center gap-0.5">
                    <div
                        class="ma-list-action-icon"
                        @click.prevent="emit('edit-label', { color, name, id: value })"
                    >
                        <ma-icon name="pen-square" size="xs" class="text-gray-600"/>
                    </div>
                    <ma-delete-label-action
                        :label-id="value"
                    />
                </div>
            </div>
        </template>
    </ma-popover-checkbox-group>
</template>

<script setup>
    import { computed } from 'vue';
    import i18n from '@/pages/Automations/Components/AutomationComponents.i18n';
    import { useI18n } from 'vue-i18n';
    import MaPopoverCheckboxGroup from '@/pages/Automations/Components/common/MaPopoverCheckboxGroup.vue';
    import { MaIcon } from '@mobileaction/action-kit';
    import MaDeleteLabelAction from '@/pages/Automations/Components/common/MaDeleteLabelAction.vue';

    defineOptions({ i18n });
    const { t } = useI18n();

    const props = defineProps({
        selected: { type: Array, required: true },
        labels: { type: Array, required: true }, // must have id, name, and color properties.
        showActions: { type: Boolean },
    });
    const emit = defineEmits(['update:selected', 'edit-label']);

    const options = computed(() => {
        return props.labels.map(l => ({ name: l.name, value: l.id, color: l.color }));
    });
</script>

<style lang="less">
    .ma-list-action-icon {
        @apply p-1 ~'rounded-[6px]' invisible cursor-default;
        .ma-icon {
            @apply text-gray-500;
            path {
                @apply stroke-2;
            }
        }
        &:hover:not(.ma-list-action-icon--loading) {
            @apply bg-indigo-100;
            .ma-icon {
                @apply text-gray-600;
            }
        }
        &:not(.ma-list-action-icon--loading) {
            @apply cursor-pointer;
        }
    }
    .antd-checkbox-wrapper:hover {
        .ma-list-action-icon {
            @apply visible;
        }
    }
    .ma-checkbox-list-item {
        @apply flex items-center justify-between;
    }
</style>
