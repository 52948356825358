<template>
    <ma-select
        :value="value"
        size="small"
        :dropdown-match-select-width="false"
        dropdown-class-name="ma-keyword-group-dropdown"
        prefix-icon="sort"
        placeholder="Filter"
        allow-clear
        :loading="loading"
        class="!w-[200px]"
        @update:value="v => emit('update:value', v)"
    >
        <template #notFoundContent>
            <ma-empty
                size="small"
                title="There are no keyword groups"
            >
                <template #description>
                    <div class="text-center">
                        {{ t('createKeywordGroup') }}
                    </div>
                </template>
                <ma-button
                    color="blue"
                    variant="ghost"
                    size="small"
                    class="my-4"
                    @click="goToMobileAction"
                >
                    {{ t('goToKeywordTracking') }}
                </ma-button>
            </ma-empty>
        </template>
        <template #top-section>
            <ma-typography
                type="body-4"
                weight="semibold"
                class="text-gray-900 p-2 mb-1"
            >
                {{ t('keywordGroup') }}
            </ma-typography>
        </template>
        <ma-select-option
            v-for="{ label, value: key, color, count} in options"
            :key="key"
        >
            <div
                class="flex gap-2 items-center justify-between w-full"
            >
                <div class="flex gap-2 items-center">
                    <ma-icon
                        name="dot"
                        :style="{ color }"
                        size="xs"
                        class="!h-2 !w-2"
                    />
                    <span>
                        {{ label }}
                    </span>
                </div>
                <ma-badge size="small" type="secondary">
                    {{ count }}
                </ma-badge>
            </div>
        </ma-select-option>
    </ma-select>
</template>

<script setup>
    import { MaBadge, MaButton, MaEmpty, MaIcon, MaSelect, MaSelectOption, MaTypography } from '@mobileaction/action-kit';
    import { computed } from 'vue';
    import { INSIGHTS_DOMAIN } from '@/common/Config/index';
    import messages from '@/components/KeywordAddModal/KeywordList.i18n';
    import { useI18n } from 'vue-i18n';

    defineOptions({
        i18n: { messages },
    });
    const { t } = useI18n();

    const props = defineProps({
        keywordGroups: { type: Array, required: true },
        value: { type: [Number, null], required: true },
        loading: { type: Boolean },
    });
    const emit = defineEmits(['update:value']);

    const options = computed(() => {
        return props.keywordGroups.map((kg) => {
            return {
                value: kg.groupId,
                label: kg.groupName,
                color: kg.colorCode,
                count: kg.keywords?.length || 0,
            };
        });
    });

    const goToMobileAction = () => {
        const landingUrl = INSIGHTS_DOMAIN;
        window.open(landingUrl + '/aso-intelligence/basic/keyword-tracking', '_blank');
    };
</script>

<style lang="less">
    .ma-keyword-group-dropdown {
        @apply ~'min-w-[240px]' ~'z-[1000]';
    }
</style>
