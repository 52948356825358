import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { faApple } from '@fortawesome/free-brands-svg-icons/faApple';
import { faFacebook } from '@fortawesome/free-brands-svg-icons/faFacebook';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';

import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle';
import { faDotCircle as farDotCircle } from '@fortawesome/free-regular-svg-icons/faDotCircle';
import { faQuestionCircle as farQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle';
import { faCopy as farCopy } from '@fortawesome/free-regular-svg-icons/faCopy';

import { faSlash } from '@fortawesome/free-solid-svg-icons/faSlash';
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen';
import { faWifi } from '@fortawesome/free-solid-svg-icons/faWifi';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons/faBalanceScale';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch';
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faChartPie } from '@fortawesome/free-solid-svg-icons/faChartPie';
import { faChartLine } from '@fortawesome/free-solid-svg-icons/faChartLine';
import { faChartArea } from '@fortawesome/free-solid-svg-icons/faChartArea';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faMedkit } from '@fortawesome/free-solid-svg-icons/faMedkit';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons/faBullhorn';
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons/faCrosshairs';
import { faMedal } from '@fortawesome/free-solid-svg-icons/faMedal';
import { faPlug } from '@fortawesome/free-solid-svg-icons/faPlug';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign';
import { faOutdent } from '@fortawesome/free-solid-svg-icons/faOutdent';
import { faIndent } from '@fortawesome/free-solid-svg-icons/faIndent';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause';
import { faStop } from '@fortawesome/free-solid-svg-icons/faStop';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons/faPlusSquare';
import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons/faSortAmountDown';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { faClone } from '@fortawesome/free-solid-svg-icons/faClone';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons/faGraduationCap';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSearchDollar } from '@fortawesome/free-solid-svg-icons/faSearchDollar';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft';
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faFlagCheckered } from '@fortawesome/free-solid-svg-icons/faFlagCheckered';
import { faBrain } from '@fortawesome/free-solid-svg-icons/faBrain';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons/faThumbtack';
import { faFont } from '@fortawesome/free-solid-svg-icons/faFont';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons/faPenSquare';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons/faAngleDoubleRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowRightArrowLeft';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faBahai } from '@fortawesome/free-solid-svg-icons/faBahai';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons/faMoneyBill';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons/faFilePdf';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo';
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons/faPlayCircle';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPercentage } from '@fortawesome/free-solid-svg-icons/faPercentage';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faBroom } from '@fortawesome/free-solid-svg-icons/faBroom';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH';
import { faExpand } from '@fortawesome/free-solid-svg-icons/faExpand';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { faMobile } from '@fortawesome/free-solid-svg-icons';
import { faTransgenderAlt } from '@fortawesome/free-solid-svg-icons';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation';
import { faStream } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
// brand icons
library.add(
    faApple,
    faFacebook,
    faTwitter,
    faLinkedin
);
// regular icons
library.add(
    farCircle,
    farDotCircle,
    farQuestionCircle,
    farCopy,
    farTimesCircle
);

// solid icons
library.add(
    faArrowRightArrowLeft,
    faSlash,
    faWifi,
    faBalanceScale,
    faShoppingCart,
    faCircleNotch,
    faThLarge,
    faTachometerAlt,
    faCalendar,
    faCalendarAlt,
    faChartPie,
    faChartLine,
    faChartArea,
    faChartBar,
    faMedkit,
    faPaperPlane,
    faBullhorn,
    faRocket,
    faSync,
    faCrosshairs,
    faMedal,
    faPlug,
    faDollarSign,
    faOutdent,
    faIndent,
    faCog,
    faHeadset,
    faQuestionCircle,
    faCheck,
    faCheckSquare,
    faCheckCircle,
    faPlusCircle,
    faCircle,
    faPause,
    faStop,
    faInfo,
    faInfoCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faReply,
    faCreditCard,
    faPlus,
    faPlusSquare,
    faSortAmountDown,
    faDownload,
    faUpload,
    faTag,
    faClone,
    faFilter,
    faBars,
    faChevronDown,
    faChevronUp,
    faArrowLeft,
    faArrowRight,
    faSignOutAlt,
    faMinus,
    faMinusSquare,
    faTimesCircle,
    faTimes,
    faGraduationCap,
    faChevronRight,
    faCertificate,
    faLock,
    faLockOpen,
    faCloudUploadAlt,
    faTrash,
    faSearch,
    faSearchDollar,
    faCopy,
    faArrowCircleLeft,
    faHistory,
    faSpinner,
    faFlagCheckered,
    faBrain,
    faUser,
    faUsers,
    faThumbtack,
    faFont,
    faBell,
    faPenSquare,
    faEnvelope,
    faAngleDoubleRight,
    faArrowUp,
    faArrowDown,
    faMobileAlt,
    faEye,
    faEdit,
    faStar,
    faBahai,
    faHome,
    faPencilAlt,
    faThList,
    faCaretUp,
    faCaretDown,
    faLayerGroup,
    faCaretRight,
    faRedo,
    faMoneyBill,
    faShareAlt,
    faFilePdf,
    faLink,
    faGlobe,
    faChevronLeft,
    faEllipsisV,
    faExternalLinkAlt,
    faList,
    faAsterisk,
    faEllipsisH,
    faUndo,
    faPlayCircle,
    farCheckCircle,
    faPercentage,
    faCogs,
    faBroom,
    faSlidersH,
    faExpand,
    faCompress,
    faBroom,
    faListUl,
    faFlag,
    faStream,
    faFlag,
    faToggleOn,
    faToggleOff,
    faSquare,
    faMapMarker,
    faMobile,
    faTransgenderAlt,
    faColumns,
    faFolderOpen,
    faPlay,
    faExclamation
);

export default {
    install(Vue) {
        // eslint-disable-next-line vue/multi-word-component-names
        Vue.component('fa', FontAwesomeIcon);
        Vue.component('fa-layers', FontAwesomeLayers);
    },
};
