<template>
    <div class="text-gray-400 text-center p-16">
        <img class="ma-no-data-icon" :src="icon">
        <p class="text-lg font-semibold">
            {{ innerTitle }}
        </p>
        <p class="text-base my-1">
            {{ innerMessage }}
        </p>
        <slot/>
    </div>
</template>

<script>
    import DocumentSearchIcon from '@/assets/no-data-icons/document-search.svg';
    import NoDataIcon from '@/assets/no-data-icons/no-data.svg';
    import TableLineIcon from '@/assets/no-data-icons/table-line.svg';
    import ColumnChartIcon from '@/assets/no-data-icons/column-chart.svg';
    import SettingsIcon from '@/assets/no-data-icons/gears.svg';
    import ProcessingIcon from '@/assets/no-data-icons/workflow.svg';

    const ICONS = {
        search: DocumentSearchIcon,
        table: TableLineIcon,
        data: NoDataIcon,
        chart: ColumnChartIcon,
        setting: SettingsIcon,
        process: ProcessingIcon,
    };

    export default {
        name: 'ma-no-data-message',
        props: {
            title: {
                type: [String],
            },
            message: {
                type: [String],
            },
            type: {
                type: [String],
                default: 'search',
                validator: t => Object.keys(ICONS).includes(t),
            },
        },
        computed: {
            innerTitle() {
                return this.title || this.$t('messages.noData.nothingToShow');
            },
            innerMessage() {
                return this.message || this.$t('messages.noData.noDataAvailable');
            },
            icon() {
                return ICONS[this.type];
            },
        },
    };
</script>

<style scoped>
    .ma-no-data-icon {
        width: 50px;
        @apply inline mb-4;
    }
</style>
