<template>
    <img
        :src="src"
        :alt="alt"
        :class="classes"
        class="ma-app-icon shadow-sm"
        @onerror="loadDummyIcon"
    >
</template>

<script>
    import { secureIconUrl } from '@/common/MaUtils.mjs';
    import MA_ERROR_ICON from '@/assets/ma-on-error-icon.png';

    const SIZE_CLASSES = {
        mini: 'w-6 h-6 rounded-md',
        xsmall: 'w-8 h-8 rounded-md',
        small: 'w-10 h-10 rounded-md',
        medium: 'w-14 h-14 rounded-md',
        large: 'w-16 h-16 rounded-lg',
    };

    export default {
        name: 'ma-app-icon',
        props: {
            icon: { type: String },
            alt: { type: String },
            size: {
                type: String,
                default: 'small',
                validator: val => Object.keys(SIZE_CLASSES).includes(val),
            },
        },
        data() {
            return {
                errorIcon: false,
            };
        },
        computed: {
            src() {
                if (this.errorIcon || !this.icon) {
                    return MA_ERROR_ICON;
                }
                return secureIconUrl(this.icon);
            },
            classes() {
                return SIZE_CLASSES[this.size];
            },
        },
        methods: {
            loadDummyIcon() {
                this.errorIcon = true;
            },
        },
    };
</script>

