export const SIDEBAR_ITEMS = [
    {
        isGroup: false,
        isNewFeature: false,
        route: { name: 'overview' },
        labelKey: 'overview',
        icon: 'home-bulk',
    },
    {
        isGroup: false,
        isNewFeature: false,
        route: { name: 'dashboard' },
        routeFeature: 'SA_ADS_MANAGER',
        routeAlternative: { name: 'dashboard-lite' },
        routeNameInURL: ['dashboard', 'dashboard-lite'],
        labelKey: 'adsManager',
        icon: 'grid-bulk',
    },
    {
        isGroup: false,
        isNewFeature: false,
        route: { name: 'report' },
        labelKey: 'funnels',
        icon: 'filter-bulk',
    },
    {
        isGroup: false,
        isNewFeature: false,
        route: { name: 'automation' },
        labelKey: 'automations',
        icon: 'share-bulk',
    },
    {
        isGroup: true,
        key: 'coPilot',
        titleKey: 'coPilot',
        titleKeyAbb: 'coPilotAbb',
        groupItems: [
            {
                isNewFeature: false,
                route: { name: 'spotlight' },
                labelKey: 'spotlight',
                icon: 'favorite-chart-bulk',
                isDashboard: true,
            },
            {
                isNewFeature: false,
                route: { name: 'campaign-wizard' },
                labelKey: 'campaignWizard',
                icon: 'settings-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'optimize' },
                labelKey: 'optimize',
                icon: 'flash-circle-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'keyword-advisor' },
                labelKey: 'keywordAdvisor',
                icon: 'document-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'smart-bidding' },
                labelKey: 'smartBidding',
                icon: 'glass-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'budget-allocation' },
                labelKey: 'budgetAllocation',
                icon: 'moneys-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'audit' },
                labelKey: 'audit',
                icon: 'ranking-bulk',
            },
        ],
    },
    {
        isGroup: true,
        key: 'insightsCenter',
        titleKey: 'insightCenter',
        titleKeyAbb: 'ic',
        groupItems: [
            {
                isNewFeature: false,
                route: { name: 'sa-benchmarks' },
                routeNameInURL: 'benchmarks',
                labelKey: 'benchmarks',
                icon: 'align-left-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'recommendations' },
                labelKey: 'recommendations',
                icon: 'clipboard-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'campaign-forecasting' },
                labelKey: 'campaignForecasting',
                icon: 'trend-up-bulk',
            },
        ],
    },
    {
        isGroup: true,
        key: 'competitionAnalysis',
        titleKey: 'competitionAnalysis',
        titleKeyAbb: 'competitionAnalysisAbb',
        groupItems: [
            {
                isNewFeature: false,
                route: { name: 'keyword-auction-insights' },
                labelKey: 'keywordAuctionInsights',
                icon: 'microscope-bulk',
            },
            {
                isNewFeature: false,
                routeLink: 'https://insights.mobileaction.co/search-ads-intelligence/custom-product-pages/cpp-intelligence/',
                mixpanelKey: 'cpp-intelligence',
                labelKey: 'cppIntelligence',
                tooltipKey: 'cppIntelligenceTooltip',
                icon: 'mobile-bold',
            },
            {
                isNewFeature: false,
                route: { name: 'keyword-hunt' },
                labelKey: 'organicKeywordsHunt',
                icon: 'data-bulk',
            },
        ],
    },
    {
        isGroup: true,
        key: 'mobileMeasurementPartner',
        titleKey: 'mobileMeasurementPartner',
        titleKeyAbb: 'mmp',
        groupItems: [
            {
                isNewFeature: false,
                route: { name: 'attributions' },
                labelKey: 'attribution',
                icon: 'convert-shape-bulk',
            },
            {
                isNewFeature: false,
                route: { name: 'conversions' },
                labelKey: 'goals',
                icon: 'add-circle-bulk',
            },
        ],
    },
];

export const getLabelKeyFromRouteName = (routeName) => {
    let sideBarItem;
    SIDEBAR_ITEMS.forEach((item) => {
        if (item.isGroup) {
            const found = item.groupItems.find(i => i.route?.name === routeName);
            if (found) {
                sideBarItem = found;
                return;
            }
        } else if (item.route?.name === routeName) {
            sideBarItem = item;
            return;
        }
    });
    return sideBarItem?.labelKey || '';
};
