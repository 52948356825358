import { makeRequest } from '@/common/MaRequestPooler';
import { paramsToQuery } from '@/common/MaUtils.mjs';


/**
 * Takes filtered dashboard request and export ad group metadata values to xlsx file
 * @typedef {{ filterFields, orgIdList, sortField, sortOrder, limit, offset, reAttrType, goalId, daily, totalRows, username }} DataType
 * @param {DataType} data - Request Body
 */
export function downloadCampaignAndAdgroupMetadataXlsx({ startDate, endDate, requestLevel, data }) {
    return makeRequest(`/sa/metadata/management/bulk-campaign/export/${ requestLevel }?${ paramsToQuery({ startDate, endDate }) }`, {
        method: 'POST',
        data,
    });
}


/**
 * Takes XLSX file and convert to JSON objects for each row
 * @param file
 */
export function convertCampaignSettingsXlsxToJson(file) {
    const formData = new FormData();
    formData.append('file', file[0]);

    return makeRequest(`/sa/metadata/management/bulk-campaign/convert`, {
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

/**
 * Returns template file data of bulk campaign update
 */
export function getSettingsXlsxTemplate() {
    return makeRequest(`/sa/metadata/management/bulk-campaign/template-download`, {
        method: 'GET',
    });
}

/*
* For AD_GROUP and KEYWORD, entityList -> campaignId cannot be null.
* changeType: AMOUNT, PERCENTAGE
* @param requestLevel: CAMPAIGN, AD_GROUP, KEYWORD
* entityType: ACCOUNT, APP, CAMPAIGN, ADGROUP, KEYWORD
* On requestLevel:CAMPAIGN -> change Daily Budget or Lifetime Budget of Campaign.
* On requestLevel:AD_GROUP -> change Default Bid of AdGroup.
* On requestLevel:KEYWORD -> change Amount Bid of Keyword.
* e.g:
* @param data = {
* entityType: CAMPAIGN,
* daily: true (Daily Budget or Lifetime Budget for CAMPAIGN requestLevel)
* bidChangeInfo: {strategy: INCREASE, changeType: PERCENTAGE, changeAmount: 10, limit: 100},
* entityList: [{entityId: "", campaignId: null, orgId: 1L}, {entityId: "", campaignId: null, orgId: 1L}]
* }
* */
export function updateAdGroupBid({ orgId, campaignId, adGroupId, data }) {
    return makeRequest(`/sa/metadata/management/bulk-bid-update/org/${orgId}/campaign/${campaignId}/adgroup/${adGroupId}`, {
        method: 'PUT',
        data,
    });
}


//@param requestLevel: CAMPAIGN, AD_GROUP, KEYWORD
//e.g;
// @param data = metadataList: [{keywordId: 1L, adGroupId: 1L, creativeSetId: 1L, campaignId: 1L, orgId: 1L},...]
export function changeStateOfMetadata(data, requestLevel, activate) {
    const type = activate ? 'activate' : 'pause';
    return makeRequest(`/sa/metadata/management/${type}/${requestLevel}`, {
        method: 'PUT',
        data,
    });
}

//@param requestLevel: CAMPAIGN, AD_GROUP, KEYWORD, NEGATIVE
//e.g;
// @param data = metadataList: [{keywordId: 1L, adGroupId: 1L, campaignId: 1L, orgId: 1L},...]
export function deleteMetadata(data, requestLevel) {
    return makeRequest(`/sa/metadata/management/${requestLevel}`, {
        method: 'DELETE',
        data,
    });
}


export function importCampaignLtvData(file) {
    const formData = new FormData();
    formData.append('file', file[0]);
    return makeRequest(`/sa/metadata/management/campaign/ltv-import`, {
        method: 'POST',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
