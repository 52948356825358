{
    "conditions": {
        "SPEND": "@:headers.spend.title",
        "CPA": "@:headers.averageCPA.title",
        "TOTAL_CPA": "@:headers.totalCpa.title",
        "CPT": "@:headers.averageCPT.title",
        "TTR": "@:headers.ttr.title",
        "CR": "@:headers.conversionRate.title",
        "TOTAL_CR": "@:headers.totalCr.title",
        "INSTALLS": "@:headers.installs.title",
        "VIEW_INSTALLS": "@:headers.viewInstalls.title",
        "TOTAL_INSTALLS": "@:headers.totalInstalls.title",
        "TAPS": "@:headers.taps.title",
        "IMPRESSIONS": "@:headers.impressions.title",
        "TOTAL_BUDGET": "@:headers.totalBudget.title",
        "DAILY_BUDGET": "@:headers.dailyBudget.title",
        "NEW_DOWNLOADS": "@:headers.newDownloads.title",
        "VIEW_NEW_DOWNLOADS": "@:headers.viewNewDownloads.title",
        "TOTAL_NEW_DOWNLOADS": "@:headers.totalNewDownloads.title",
        "REDOWNLOADS": "@:headers.redownloads.title",
        "VIEW_REDOWNLOADS": "@:headers.viewRedownloads.title",
        "TOTAL_REDOWNLOADS": "@:headers.totalRedownloads.title",
        "ATTR_INSTALLS": "@:headers.attrInstalls.title",
        "GOALS": "@:headers.numberOfGoals.title",
        "GOALS_REVENUE": "@:headers.goalsRevenue.title",
        "INSTALL_RATE": "@:headers.installRate.title",
        "CPI": "@:headers.averageCPI.title",
        "COST_PER_GOAL": "@:headers.costPerGoal.title",
        "GOALS_RATE": "@:headers.goalsRate.title",
        "REVENUE_PER_GOAL": "@:headers.revenuePerGoal.title",
        "GOALS_ROAS": "@:headers.goalsROAS.title",
        "ARPU": "@:headers.averageRevenuePerUser.title",
        "GOALS_REVENUE_1": "@:headers.goalRevenue1.title",
        "GOALS_REVENUE_3": "@:headers.goalRevenue3.title",
        "GOALS_REVENUE_7": "@:headers.goalRevenue7.title",
        "GOALS_REVENUE_14": "@:headers.goalRevenue14.title",
        "GOALS_REVENUE_30": "@:headers.goalRevenue30.title",
        "GOALS_REVENUE_60": "@:headers.goalRevenue60.title",
        "GOALS_COUNT_1": "@:headers.goalCount1.title",
        "GOALS_COUNT_3": "@:headers.goalCount3.title",
        "GOALS_COUNT_7": "@:headers.goalCount7.title",
        "GOALS_COUNT_14": "@:headers.goalCount14.title",
        "GOALS_COUNT_30": "@:headers.goalCount30.title",
        "GOALS_COUNT_60": "@:headers.goalCount60.title",
        "GOALS_ROAS_1": "@:headers.goalRoas1.title",
        "GOALS_ROAS_3": "@:headers.goalRoas3.title",
        "GOALS_ROAS_7": "@:headers.goalRoas7.title",
        "GOALS_ROAS_14": "@:headers.goalRoas14.title",
        "GOALS_ROAS_30": "@:headers.goalRoas30.title",
        "GOALS_ROAS_60": "@:headers.goalRoas60.title",
        "CUSTOM_COLUMN": "@:headers.CUSTOM_COLUMN.title",
        "LABEL": "@:headers.label.title",
        "APPLE_SHARE_OF_VOICE_DAILY": "@:headers.appleShareOfVoiceDaily.title",
        "APPLE_SHARE_OF_VOICE_WEEKLY": "@:headers.appleShareOfVoiceWeekly.title",
        "CPA_GOAL": "@:headers.cpaGoal.title",
        "LTV": "@:headers.ltv.title",
        "BID_AMOUNT": "@:headers.bidAmount.title",
        "DEFAULT_BID_AMOUNT": "@:headers.defaultBidAmount.title",
        "ORGANIC_RANK": "@:headers.organicRank.title",
        "POPULARITY": "@:headers.popularityRank.title",
        "STOREFRONT": "@:headers.storefront.title",
        "CAMPAIGN_NAME": "@:headers.campaign.title",
        "CAMPAIGN_GROUP_NAME": "@:headers.orgName.title",
        "AD_GROUP_NAME": "@:headers.adGroupName.title",
        "SEARCH_MATCH": "@:headers.searchMatch.title",
        "DEVICE": "@:headers.deviceClass.title",
        "GENDER": "@:headers.gender.title",
        "CUSTOMER_TYPE": "@:headers.customerType.title",
        "LOCATION": "@:headers.locality.title",
        "AGE_RANGE": "@:headers.age.title",
        "KEYWORD": "@:headers.keyword.title",
        "MATCH_TYPE": "@:headers.matchType.title",
        "SUGGESTED_BID_AMOUNT": "@:headers.suggestedBidAmount.title",
        "BID_STRENGTH": "@:headers.bidStrength.title",
        "ADDED_AS_KEYWORD": "@:headers.addedAsKeyword.title",
        "SEARCH_TERM_SOURCE": "@:headers.searchTermSource.title",
        "SEARCH_TERM_TEXT": "@:headers.searchTermText.title",
        "LOW_VOLUME_TERM": "@:headers.lowVolumeKeyword.title"
    },
    "headers": {
        "GROUP_BY_CAMPAIGN_TYPE": {
            "title": "캠페인 유형",
            "tooltip": "캠페인 그룹에서 캠페인을 구성하는 방식은 실적, 보고 및 확장 기능에 영향을 미칠 수 있습니다. 성공적인 캠페인을 설정하려면 키워드 테마 또는 전략별로 캠페인을 구분하는 것이 좋습니다. <br> <strong> 브랜드 캠페인<\/strong> 앱 또는 회사명을 검색하는 고객에 초점을 맞춘 캠페인<br><strong> 일반 캠페인<\/strong> 앱의 카테고리와 앱의 기능을 설명하는 비브랜드 용어를 검색하는 고객에 초점을 맞춘 캠페인<br><strong> 경쟁사 캠페인<\/strong> 동일 또는 관련 앱 카테고리 내에서 내 앱과 유사한 앱을 검색하는 고객에 초점을 맞춘 캠페인<br><strong> 발견 캠페인<\/strong> 앱과 관련된 새로운 검색어를 찾는 데 초점을 맞춘 캠페인입니다."
        },
        "actionFrequency": {
            "title": "액션 빈도",
            "tooltip": "해당 조건이 달성될 때<\/br>해당 액션을 진행할 시간주기를 설정합니다."
        },
        "actions": {
            "title": "액션",
            "tooltip": "액션"
        },
        "addedAsKeyword": {
            "title": "키워드로 추가"
        },
        "adChannelType": {
            "title": "광고 채널"
        },
        "adGroupId": {
            "title": "광고 그룹 ID",
            "tooltip": "광고 그룹용 Apple 검색 광고 식별자."
        },
        "adGroupDisplayStatus": {
            "title": "상태",
            "tooltip": "상태"
        },
        "adGroupStatus": {
            "title": "Ad Group 상태",
            "tooltip": "Ad Group 상태"
        },
        "adId": {
            "title": "광고 ID",
            "tooltip": "광고용 Apple 검색 광고 식별자."
        },
        "adName": {
            "title": "광고 이름",
            "tooltip": "광고 이름"
        },
        "adPlacement": {
            "title": "광고 게재 위치",
            "tooltip": "광고 게재 위치"
        },
        "adStatus": {
            "title": "광고 상태",
            "tooltip": "광고 상태"
        },
        "age": {
            "title": "나이 범위",
            "tooltip": "나이 범위"
        },
        "ageRange": {
            "title": "나이 범위",
            "tooltip": "나이 범위"
        },
        "allConversionsRate": {
            "title": "전체전환율",
            "tooltip": "총 설치수 대비 총 전환수의 비율"
        },
        "app": {
            "title": "App",
            "tooltip": "애플리케이션"
        },
        "appId": {
            "title": "앱 ID",
            "tooltip": "애플리케이션용 Apple 검색 광고 식별자."
        },
        "shareOfVoice": {
            "title": "Apple 음성 점유율",
            "tooltip": "동일한 국가 및 지역에서 동일한 검색어 또는 키워드에 대해 게재된 총 노출 수에서 광고가 차지한 노출 비율입니다. Apple SOV는 지난 주 데이터입니다."
        },
        "appleShareOfVoiceDaily": {
            "title": "Apple 음성 점유율(일간)"
        },
        "appleShareOfVoiceWeekly": {
            "title": "Apple 음성 점유율(주간)"
        },
        "appName": {
            "title": "App(앱)",
            "tooltip": "App(앱)"
        },
        "appShare": {
            "title": "임프레션 비중",
            "tooltip": "검색한 앱이 해당키워드를 통해 얻고 있는 임프레션(%)을 예측합니다."
        },
        "appliedData": {
            "title": "적용범위",
            "tooltip": "Automation 규칙이 설정된 데이터 세트입니다."
        },
        "arpu": {
            "title": "@:headers.averageRevenuePerUser.title",
            "tooltip": "@:headers.averageRevenuePerUser.tooltip"
        },
        "assets": {
            "title": "자산",
            "tooltip": "에셋은 앱의 기본 언어와 iPhone 6.5'' 디스플레이에 따라 표시됩니다."
        },
        "attrInstallRate": {
            "title": "@:headers.installRate.title",
            "tooltip": "@:headers.installRate.tooltip"
        },
        "attrInstalls": {
            "title": "어트리뷰션툴 기준 인스톨",
            "tooltip": "사용자가 앱을 다운로드 후 최초 실행한 건수입니다.<br> N\/A 수치는 SearchAds.com과 어트리뷰션툴의 연동이 아직 완료되지 않았음을 의미해요."
        },
        "automationActions": {
            "title": "액션",
            "tooltip": "Automation 규칙에서 수행할 액션입니다."
        },
        "automationConditions": {
            "title": "조건",
            "tooltip": "Automation규칙에 미리 설정된 조건"
        },
        "automationCreatedAt": {
            "title": "규칙 생성 일자",
            "tooltip": "Automation규칙이 생성된 일자"
        },
        "automationName": {
            "title": "이름",
            "tooltip": "Automation규칙명"
        },
        "automationState": {
            "title": "규칙적용상태",
            "tooltip": "Automation규칙 적용상태"
        },
        "automationUpdatedAt": {
            "title": "업데이트 날짜",
            "tooltip": "자동화 규칙의 업데이트 날짜입니다."
        },
        "averageCPA": {
            "shortTitle": "평균CPA",
            "title": "평균CPA",
            "tooltip": "<b> 평균 유저 다운로드 단가(CPA) <\/b> <b> <b>입니다. 다운로드당 비용의 평균값입니다. <br> 총 집행금액을 총 다운로드 횟수로 나누어 계산합니다."
        },
        "averageCPI": {
            "title": "어트리뷰션툴 기준 CPI",
            "tooltip": "<b>평균 CPI(설치당 비용)입니다.<\/b> <b> <b>입니다. 총 집행금액 총 설치수로 나눕니다. <br>N\/A 수치는 SearchAds.com과 어트리뷰션툴의 연동이 아직 완료되지 않았음을 나타냅니다."
        },
        "averageCPT": {
            "shortTitle": "평균CPT",
            "title": "평균CPT",
            "tooltip": "<b>탭당 평균비용(CPT) <\/b> <b> <b>입니다. 탭당 발생한 평균비용입니다. <br>총 집행비용을 탭 수로 나눈 것입니다."
        },
        "averageRevenuePerUser": {
            "title": "ARPU",
            "tooltip": "<b>ARPU(사용자당 평균수익) : <\/b> <b> 사용자당 평균수익을 표시합니다. <b>총 수익을 총 설치수로 나누어 계산합니다."
        },
        "bidAmount": {
            "title": "입찰가"
        },
        "budgetChangeSource": {
            "title": "출처",
            "tooltip": "이 열에는 캠페인의 일일 예산 변경의 출처가 표시됩니다. <br> <ul> <li> <b> 대시보드의 네 가지 소스 유형이 있습니다:<\/b> 캠페인의 일일 예산은 광고 관리자 대시보드에서 업데이트됩니다.<\/li><li> <b> 외부:<\/b> 캠페인의 일일 예산은 Apple 네이티브에서 업데이트됩니다.<\/li><li> <b> 자동화:<\/b> 캠페인의 일일 예산은 자동화 규칙을 사용하여 업데이트됩니다.<\/li><li> <b> 예산 할당:<\/b> 캠페인의 일일 예산은 예산 할당을 사용하여 업데이트됩니다.<\/li><li> <b> 빠른 자동화:<\/b> 캠페인의 일일 예산은 빠른 자동화를 사용하여 업데이트됩니다.<\/li><\/ul>"
        },
        "defaultBidAmount": {
            "title": "기본 입찰 금액"
        },
        "bidStrength": {
            "title": "입찰 강도",
            "tooltip": "<b>입찰 경쟁력이 약함(빨간색)<\/b> 은 다른 광고주의 입찰가와 비교하여 입찰가가 경쟁력이 없음을 의미합니다. 광고가 게재될 가능성이 상대적으로 낮습니다.<br> <b>보통 입찰 강도(노란색)<\/b> 입찰의 경쟁력을 개선하고 광고가 게재될 가능성을 높일 기회가 있음을 나타냅니다.<br><b> 강한 입찰 강도(녹색)<\/b> 입찰이 시장의 다른 광고주 사이에서 좋은 위치에 있음을 의미합니다.<br><b> 표시 없음<\/b> 입찰 범위를 제안하거나 입찰 강도를 측정할 데이터가 부족하거나 키워드가 일시 중지되었거나 광고 그룹 또는 캠페인이 보류 중이거나 현재 입찰이 이미 권장 사항에 맞춰져 있는 경우 표시되지 않습니다."
        },
        "campaign": {
            "title": "캠페인",
            "tooltip": "연동되어 있는 캠페인의 이름입니다."
        },
        "campaignDailyBudget": {
            "title": "일일예산",
            "tooltip": "하루에 사용할 수 있는 총 금액입니다. 평생 예산이 가득 차 있지 않은 경우 매일 초기화됩니다."
        },
        "campaignId": {
            "title": "Campaign ID",
            "tooltip": "캠페인용 Apple 검색 광고 식별자."
        },
        "campaignStatus": {
            "title": "캠페인 상태",
            "tooltip": "캠페인 상태"
        },
        "campaignTotalBudget": {
            "title": "평생 예산",
            "tooltip": "캠페인에 할당된 평생 예산입니다."
        },
        "categoryName": {
            "title": "카테고리명",
            "tooltip": "앱이 속한 카테고리"
        },
        "categoryRank": {
            "title": "카테고리 랭킹",
            "tooltip": "앱의 랭킹을 카테고리별로 표시합니다."
        },
        "categoryRanking": {
            "title": "카테고리 랭킹",
            "tooltip": "카테고리 랭킹"
        },
        "chance": {
            "title": "Chance",
            "tooltip": "Chance 점수는 앱[br]이 특정 키워드에 대해 검색결과 Top 10에 노출될 가능성을 의미합니다.<br>점수는 0에서 100로 구분되며, 수치가 높을수록 10위 안에 노출될 가능성이 높음을 의미합니다. "
        },
        "competitors": {
            "title": "경쟁사",
            "tooltip": "경쟁사를 추가하여 경쟁사에 대한 추천 및 인사이트 얻기"
        },
        "conditions": {
            "title": "조건",
            "tooltip": "Automation 규칙을 정의할 조건입니다."
        },
        "conversionRate": {
            "title": "전환율(Conversion Rate)",
            "tooltip": "<b>전환율(CR)<\/b> <b>입니다.이는 총 Tap수 대비 다운로드 비율을 의미합니다."
        },
        "costPerConversion": {
            "title": "Cost Per Conversion",
            "tooltip": "<b>Cost Per Conversion <\/b> <b>전환당 평균비용입니다. <br>총 비용을 총 전환수로 나누어 계산합니다."
        },
        "costPerGoal": {
            "title": "Cost per Goal",
            "tooltip": "Goal(목표달성)을 위해 지출한 금액의 평균입니다"
        },
        "country": {
            "title": "국가"
        },
        "cpaGoal": {
            "title": "CPA Cap",
            "tooltip": "<b>CPA Cap<\/b><b> 다운로드당 비용목표(Goal)는 전환(다운로드)당 지출할 금액입니다. CPA Cap(목표)를 설정하는 것은 선택 사항입니다.<b><b>사용팁: 실질적인 목표(Goal)를 설정합니다.<\/b> <b> CPA 목표치를 너무 낮게 설정할 경우 [br] 노출량(impression)과 유저의 탭(tap)을 놓칠 수 있습니다."
        },
        "cpi": {
            "title": "@:headers.averageCPI.title",
            "tooltip": "@:headers.averageCPI.tooltip"
        },
        "cr": {
            "title": "CR",
            "tooltip": "<b>전환율(CR)<\/b> <b>입니다.이는 총 Tap수 대비 다운로드 비율을 의미합니다."
        },
        "creativeSetName": {
            "title": "Creative Set",
            "tooltip": "Creative Set"
        },
        "creativeSetStatus": {
            "title": "Creative Set 상태",
            "tooltip": "Creative Set 상태"
        },
        "creativeType": {
            "title": "크리에이티브 유형",
            "tooltip": "크리에이티브 유형"
        },
        "csvReport": {
            "title": "CSV 리포트",
            "tooltip": "적용된 규칙에 대한 CSV 보고서입니다.<br><br> 변경사항이 발생한 규칙을 리포트에 반영합니다."
        },
        "currency": {
            "title": "화폐단위"
        },
        "currentBid": {
            "title": "현재 입찰",
            "tooltip": "현재 입찰"
        },
        "currentDailyBudget": {
            "title": "현재 일일 예산",
            "tooltip": "현재 일일 예산"
        },
        "currentLifetimeBudget": {
            "title": "현재 평생 예산",
            "tooltip": "현재 평생 예산"
        },
        "customColumn": {
            "new": "신규 사용자 정의 Column",
            "title": "사용자 정의 Column"
        },
        "customerType": {
            "title": "Customer Type",
            "tooltip": "<br><br> 모든 사용자 <br>신규 사용자<br>재방문 사용자<br>내 다른 앱의 사용자 등 고객 유형별로 광고 그룹 수준에서 타겟을 세분화할 수 있습니다."
        },
        "dailyBudget": {
            "title": "일일 예산",
            "tooltip": "하루에 쓸 수 있는 총량입니다. 총 예산이 부족하면 매일 재설정됩니다."
        },
        "dayPart": {
            "title": "데이파트너링",
            "tooltip": "데이파트너링"
        },
        "defaultAmount": {
            "title": "@:headers.defaultMaxCPTBid.title",
            "tooltip": "@:headers.defaultMaxCPTBid.tooltip"
        },
        "defaultCPTAmount": {
            "title": "기본 CPT 입찰가",
            "tooltip": "<b>기본 최대 CPT 입찰가<\/b> <br> 광고 탭에 대해<br>지불할 의향이 있는 최대 금액입니다. 이 입찰가는 개별 키워드 입찰가를 적용하지 않는 한 이 광고 그룹의 모든 키워드<br>에 적용됩니다."
        },
        "defaultMaxCPMBid": {
            "title": "기본 CPM 입찰가",
            "tooltip": "<b>기본 최대 CPM 입찰가<\/b> <br> 광고 1,000회 노출에 대해<br>지불할 의향이 있는 최대 금액입니다."
        },
        "defaultMaxCPTBid": {
            "title": "기본 CPT 비딩가격",
            "tooltip": "<b>기본 최대 CPT 비딩가격 <\/b> <b>입니다. 광고를 위해 지불되는 최대 비딩단가입니다. 이 비딩단가는 개별 키워드 비딩을 적용하지 않는 한 본 Ad group의 모든 키워드[br]에 공통 적용됩니다."
        },
        "deviceClass": {
            "title": "디바이스",
            "tooltip": "디바이스"
        },
        "difficultyRank": {
            "title": "난이도 점수",
            "tooltip": "난이도는 앱이 키워드에 대해 유기적으로 상위 순위에 오르는 것이 얼마나 어려운지를 측정하는 지표입니다. 자세한 내용은 <a href=\"https:\/\/help.mobileaction.co\/en\/articles\/4201403-what-is-difficulty-score\" target=\"_blank\" style=\"color: #1c8de0\">난이도 점수<\/a> 에서 확인하세요."
        },
        "dimensions": {
            "title": "Dimensions(치수)",
            "tooltip": "Dimension은 사용자 정의 리포트에 포함할 수 있는 캠페인의 요소입니다. 예를 들어 캠페인 ID 또는 CPT가 있습니다. Dimension은 사용자 정의 리포트에 행으로 표시됩니다."
        },
        "adDisplayStatus": {
            "title": "광고 표시 상태",
            "tooltip": "기본 플랫폼에서 표시되는 읽기 전용 상태입니다."
        },
        "downloadPercentage": {
            "title": "% of Organic Downloads",
            "tooltip": "대부분의 Organic Downloads를 발생시키는 상위 키워드입니다."
        },
        "endDate": {
            "title": "종료일",
            "tooltip": "종료일"
        },
        "engagements": {
            "title": "참여",
            "tooltip": "참여 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "frequency": {
            "title": "발생빈도 선택",
            "tooltip": "설정해 놓은 조건의 발생여부를 지속적으로 확인(Frequency)"
        },
        "gameEvents": {
            "title": "게임 이벤트",
            "tooltip": "게임 이벤트 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "gender": {
            "title": "성별",
            "tooltip": "성별"
        },
        "goalCount1": {
            "title": "목표 카운트 1",
            "tooltip": "앱에서 1일 동안 발생한 총 목표 수입니다."
        },
        "goalCount14": {
            "title": "목표 수 14",
            "tooltip": "앱에서 14일 동안 발생한 총 목표 수입니다."
        },
        "goalCount3": {
            "title": "목표 카운트 3",
            "tooltip": "앱에서 3일 동안 발생한 총 목표 수입니다."
        },
        "goalCount30": {
            "title": "목표 수 30",
            "tooltip": "앱에서 30일 동안 발생한 총 목표 수입니다."
        },
        "goalCount60": {
            "title": "목표 카운트 60",
            "tooltip": "앱에서 60일 동안 발생한 총 목표 수입니다."
        },
        "goalCount7": {
            "title": "목표 카운트 7",
            "tooltip": "앱에서 7일 동안 발생한 총 목표 수입니다."
        },
        "goalRevenue": {
            "title": "@:headers.goalsRevenue.title",
            "tooltip": "@:headers.goalsRevenue.tooltip"
        },
        "goalRevenue1": {
            "title": "Revenue 1",
            "tooltip": "설치 후 1일간의 수익을 코호트 집계한 수치입니다."
        },
        "goalRevenue14": {
            "title": "Revenue 14",
            "tooltip": "설치 후 14일간의 수익을 코호트 집계한 수치입니다."
        },
        "goalRevenue3": {
            "title": "Revenue 3",
            "tooltip": "설치 후 3일간의 수익을 코호트 집계한 수치입니다."
        },
        "goalRevenue30": {
            "title": "Revenue 30",
            "tooltip": "설치 후 30일간의 수익을 코호트 집계한 수치입니다."
        },
        "goalRevenue60": {
            "title": "목표 수익 60",
            "tooltip": "앱의 광고 캠페인이 60일 동안 창출한 총 수익입니다."
        },
        "goalRevenue7": {
            "title": "Revenue 7",
            "tooltip": "설치 후 7일간의 수익을 코호트 집계한 수치입니다."
        },
        "goalRoas": {
            "title": "@:headers.goalsROAS.title",
            "tooltip": "@:headers.goalsROAS.tooltip"
        },
        "goalRoas1": {
            "title": "Goals ROAS 1",
            "tooltip": "1일 동안 발생한 총 광고비 대비 발생한 매출의 비율입니다. <br>이 수치는 사용자 정의에 따라 개별 Goal(목표수익)을 고려합니다 <br><b>집행광고비가 1만원이고 발생매출이 1만원이면 ROAS는 100%입니다<\/b>."
        },
        "goalRoas14": {
            "title": "Goals ROAS 14",
            "tooltip": "14일 동안 발생한 총 광고비 대비 발생한 매출의 비율입니다. <br>이 수치는 사용자 정의에 따라 개별 Goal(목표수익)을 고려합니다 <br><b>집행광고비가 1만원이고 발생매출이 1만원이면 ROAS는 100%입니다<\/b>."
        },
        "goalRoas3": {
            "title": "Goals ROAS 3",
            "tooltip": "3일 동안 발생한 총 광고비 대비 발생한 매출의 비율입니다. <br>이 수치는 사용자 정의에 따라 개별 Goal(목표수익)을 고려합니다 <br><b>집행광고비가 1만원이고 발생매출이 1만원이면 ROAS는 100%입니다<\/b>."
        },
        "goalRoas30": {
            "title": "Goals ROAS 30",
            "tooltip": "30일 동안 발생한 총 광고비 대비 발생한 매출의 비율입니다. <br>이 수치는 사용자 정의에 따라 개별 Goal(목표수익)을 고려합니다 <br><b>집행광고비가 1만원이고 발생매출이 1만원이면 ROAS는 100%입니다<\/b>."
        },
        "goalRoas60": {
            "title": "목표 ROAS 60",
            "tooltip": "60일 동안의 총 광고 지출에서 발생한 수익의 비율입니다. <br><br> <b> 목표 수익 60\/지출 * 100%<\/b>."
        },
        "goalRoas7": {
            "title": "Goals ROAS 7",
            "tooltip": "7일 동안 발생한 총 광고비 대비 발생한 매출의 비율입니다. <br>이 수치는 사용자 정의에 따라 개별 Goal(목표수익)을 고려합니다 <br><b>집행광고비가 1만원이고 발생매출이 1만원이면 ROAS는 100%입니다<\/b>."
        },
        "goalsROAS": {
            "title": "Goals ROAS",
            "tooltip": "총 광고집행대비 발생한 매출비율<br>여기서 매출값은 사용자가 정의한 전환기준으로 발생한 매출을 의미합니다.다.<br><b>집행광고비가 1만원이고 Goal매출이 1만원이면 ROAS는 100%입니다<\/b>입니다."
        },
        "goalsRate": {
            "title": "Goals Rate",
            "tooltip": "사용자가 included into Goal로 설정한 총 전환건수 대비 일정 기간 내에 attribution tool에서 집계한 install [b] 수로 나뉩니다.<br><b>목표율 =Goal \/install *100%<\/b>입니다."
        },
        "goalsRevenue": {
            "title": "Goals Revenue",
            "tooltip": "전환에 포함된 전환값의 총 합계(전환에 포함된 각 in-app event와 연관됨) [br]입니다."
        },
        "goalsRoasDays": {
            "title": "Goals ROAS 1\/3\/7\/14\/30일",
            "tooltip": "해당기간(1,3,7,14,30일) 동안 발생한 광고비 대비 발생한 매출비율<br>여기서 매출값은 사용자가 정의한 전환기준으로 발생한 매출을 의미합니다.다.<br><b>집행광고비가 1만원이고 Goal매출이 1만원이면 ROAS는 100%입니다<\/b>입니다."
        },
        "goalValue": {
            "title": "목표(Goal) 수치",
            "tooltip": "실제 또는 사용자 설정값이어야 하며, 이는 매출을 계산하는 데 사용됩니다."
        },

        "impressions": {
            "title": "노출횟수(Imp.)",
            "tooltip": "광고 노출 횟수"
        },
        "installRate": {
            "title": "Apple기준(설치) 대비 어트리뷰션툴(실행) 비율",
            "tooltip": "어트리뷰션 툴이 집계한 총install수와 Apple이 집계한 총install수의 비율입니다. <b>Attr Installs \/ Installs (신규다운로드 + 재다운로드) * 100<\/b>입니다."
        },
        "installs": {
            "title": "총 다운로드",
            "tooltip": "신규다운로드와 재다운로드의 총합"
        },
        "keyword": {
            "title": "키워드",
            "tooltip": "해당앱이 비딩중인 키워드는 다음과 같습니다."
        },
        "keywordDisplayStatus": {
            "title": "상태",
            "tooltip": "상태"
        },
        "keywordMatchType": {
            "title": "@:headers.matchType.title",
        },
        "keywordStatus": {
            "title": "키워드 상태"
        },
        "label": {
            "title": "라벨",
            "tooltip": "사용자가 설명한 레이블입니다."
        },
        "localSpend": {
            "title": "광고비집행"
        },
        "locality": {
            "title": "Locaction",
            "tooltip": "Locaction"
        },
        "lowVolume": {
            "title": "낮은 검색량",
            "tooltip": "Low Volume(낮은 검색량)'은 데이터가 Apple의 개인정보 보호 임계값보다 낮음을 나타냅니다. 검색조건은 최소 10회 이상의 임프레션에 도달해야 하며, 나이, 성별 또는 위치 관련 리포트는 검색조건의 구체적인 데이터 전에 최소한 100회 이상의 임프레션이 필요합니다. 볼륨 낮음 표시기는 이러한 임계값보다 낮은 모든 항에 대한 누적 리포트 값을 표시합니다."
        },
        "lowVolumeKeyword": {
            "title": "저용량 기간",
            "tooltip": "저용량 기간"
        },
        "ltv": {
            "title": "LTV",
            "tooltip": "캠페인의 라이프타임 가치를 표시합니다."
        },
        "matchSource": {
            "title": "Match Source",
            "tooltip": "Match Soure열에는 임프레션값이 Search Match 또는 비딩 키워드의 결과인지 여부가 표시됩니다."
        },
        "matchType": {
            "title": "Match Type"
        },
        "message": {
            "title": "메시지"
        },
        "newDownloads": {
            "title": "신규 다운로드",
            "tooltip": "최초 다운로드 건수를 의미합니다"
        },
        "notification": {
            "title": "알림"
        },
        "numberOfAds": {
            "title": "광고 수",
            "tooltip": "이 제품 페이지가 사용된 광고의 수입니다."
        },
        "numberOfApps": {
            "title": "전채 앱 수치",
            "tooltip": "해당 키워드로 검색시 노출되는 앱의 총 개수입니다."
        },
        "numberOfGoal": {
            "title": "@:headers.numberOfGoals.title",
            "tooltip": "@:headers.numberOfGoals.tooltip"
        },
        "numberOfGoals": {
            "title": "목표(In-app event기준)",
            "tooltip": "사용자가 지정한  In-app event 전환수치입니다. <br> 사용자가 가장 중요하게 생각하는 in-app event를 Goal로 설정하게 됩니다."
        },
        "numberOfInstall": {
            "title": "@:headers.attrInstalls.title",
            "tooltip": "@:headers.attrInstalls.tooltip"
        },
        "orgName": {
            "title": "캠페인 그룹",
            "tooltip": "캠페인 그룹 이름"
        },
        "organicRank": {
            "title": "Organic Rank",
            "tooltip": "해당 앱이 검색된 Organic Keyword(자연검색어)를 보여줍니다."
        },
        "otherEvents": {
            "title": "기타",
            "tooltip": "기타 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "others": {
            "title": "기타앱",
            "tooltip": "해당 키워드를 통해 노출(%)을 확보하고 있는 앱"
        },
        "overallRanking": {
            "title": "전체 랭킹",
            "tooltip": "전체 랭킹"
        },
        "policy": {
            "title": "정책",
            "tooltip": "해당 사용자 지정 목표(Goal)와 연관된 In-app event 값을 트랙킹할 방법을 선택합니다. 이 값은 매출을 계산하는 데 사용됩니다.\n"
        },
        "popularityRank": {
            "title": "Popularity",
            "tooltip": "이 점수는 Apple SearchAds에서 제공하는 것으로, 스토어 첫 화면에서 키워드의 인기도를 보여줍니다."
        },
        "productPageName": {
            "title": "제품 페이지 이름",
            "tooltip": "제품 페이지 이름"
        },
        "productPageId": {
            "title": "제품 페이지 ID",
            "tooltip": "제품 페이지 ID"
        },
        "purchases": {
            "title": "구매",
            "tooltip": "구매 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "keywordId": {
            "title": "Keyword Id",
            "tooltip": "키워드에 대한 Apple 검색 광고 식별자."
        },
        "reAttrType": {
            "title": "Re-Attribution 타입",
            "tooltip": "Automation 설정 시 Re-attribution(재설치\/재오픈)을 표시합니다."
        },
        "recommendedAction": {
            "title": "권장 조치",
            "tooltip": "전환율이 현저히 낮은 키워드를 추천합니다."
        },
        "recommendedActions": {
            "title": "액션",
            "tooltip": "추천을 개별적으로 적용하거나 해제할 수 있습니다. 시장 가격, 소비자 선호도 및 기타 요소는 유동적이므로 권장 사항을 사용한다고 해서 성과가 개선되는 것은 아닙니다."
        },
        "recommendedBid": {
            "title": "추천 입찰",
            "tooltip": "키워드가 경쟁사와 경쟁하기에 노출 수가 충분하지 않아 추천됨"
        },
        "recommendedDailyBudget": {
            "title": "일일 권장 예산",
            "tooltip": "전환율이 훨씬 더 높고 일일 예산을 두 번 초과 달성한 캠페인이므로 추천합니다."
        },
        "recommendedLifetimeBudget": {
            "title": "권장 평생 예산",
            "tooltip": "권장 평생 예산"
        },
        "redownloads": {
            "title": "재다운로드",
            "tooltip": "사용자가 디바이스에서 이전에 삭제한 앱을 다시 다운로드했거나 사용자의 또다른 디바이스에서 동일한 앱을 다운로드한 경우를 표시합니다."
        },
        "reportDate": {
            "title": "리포트 일자",
            "tooltip": "Apple Search Ads 리포트 일자"
        },
        "returnOnAdvertisingSpend": {
            "title": "ROAS",
            "tooltip": "<b>ROAS(광고비용 대비 매출 비율):<\/b> <b> 광고비용 대비 매출을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "revenue": {
            "title": "Revenue",
            "tooltip": "광고 캠페인을 통해 발생된 총 매출입니다."
        },
        "revenuePerConversion": {
            "title": "전환당 발생한 매출입니다.",
            "tooltip": "각 전환당 발생한 평균 수익입니다."
        },
        "revenuePerGoal": {
            "title": "Revenue per Goal",
            "tooltip": "각 Goal당 발생한 평균매출을 나타냅니다. <br>총 Goal 매출액을<br> 총Goal 발생건수로 나누어 계산합니다."
        },
        "roas1": {
            "title": "ROAS 1",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b>\n<br>1일 동안 광고비용대비 매출액을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "roas14": {
            "title": "ROAS 14",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b>\n<br>14일 동안 광고비용대비 매출액을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "roas3": {
            "title": "ROAS 3",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b>\n<br>3일 동안 광고비용대비 매출액을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "roas30": {
            "title": "ROAS 30",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b>\n<br>30일 동안 광고비용대비 매출액을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "roas7": {
            "title": "ROAS 7",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b>\n<br>7일 동안 광고비용대비 매출액을 표시합니다. <br>ROAS는 광고가 집행된 각 단위별 매출액입니다. <br>이것은 <br><b>라는 공식으로 계산됩니다.ROAS=매출\/광고비용 * 100%<\/b>입니다."
        },
        "roasDays": {
            "title": "ROAS 1\/3\/7\/14\/30 일",
            "tooltip": "<b>ROAS(광고비 지출 대비 성과)는 다음과 같습니다.<\/b> <b> 1\/3\/7\/14\/30일 동안 광고에 대한 발생매출을 표시합니다. <r>ROAS는 광고에 지출한 각 단위당 발생한 매출입니다. <br>이에 대한 계산식은 다음과 같습니다:<b>ROAS=매출\/광고비 * 100%<\/b>입니다."
        },
        "ruleActions": {
            "title": "액션",
            "tooltip": "조건이 충족될 때 수행되는 행동입니다."
        },
        "ruleChanges": {
            "title": "변경횟수",
            "tooltip": "변경횟수\n<br> <br>\n<strong> 변경사항 없음<\/strong>-변경사항 없이 규칙실행<br>\n<strong>N건 : 성공<\/strong>-규칙에 따라 변경된 건수<br>\n<strong>N건 : 경고<\/strong>-규칙에 따라 경고와 함께 변경된 건수(예:예산부족) <br>\n<strong>N건 : 오류<\/strong>-규칙에 맞는 상황이었으나 변경되지 않은 건수(기술적 에러가 아닌 일예산, 전체예산 등의 이슈로)"
        },
        "ruleDateChecked": {
            "title": "액션시작일자",
            "tooltip": "규칙 설정에 따라 액션이 시작된 시간입니다."
        },
        "ruleDescription": {
            "title": "규칙설명",
            "tooltip": "설정한 규칙에 대한 세부내용입니다.<br><br>\n특정규칙을 기준으로 정렬하길 원할 경우<br><br>\n화면 오른쪽의 Fliters 를 클릭 후 원하는 항목을 선택하세요"
        },
        "ruleLogState": {
            "title": "상태",
            "tooltip": "상태'는 액션이 구현된 상황을 표시합니다.<br>\n<strong>성공<\/srtong>-오류 없이 수행됨<br>\n<strong>Apple 주의<\/strong>-Apple Search Ads의 제한 내에서 수행됨<br>\n<strong>사용자 주의<\/strong>-사용자 제한 내에서 수행됨<br>\n<strong>Apple경고<\/strong>-액션이 Apple의 제한으로 수행되지 않음<br>\n<strong>사용자 경고<\/strong>-사용자 제한으로 수행되지 않음<br>\n<strong>오류<\/strong>-오류로 인해 액션이 수행되지 않음"
        },
        "ruleName": {
            "title": "규칙이름",
            "tooltip": "Automation 규칙을 구분하기 위한 이름입니다."
        },
        "ruleRunState": {
            "title": "상태",
            "tooltip": "상태'는 규칙의 정상실행여부를 표시합니다.<br>\n<strong> 성공<\/strong>-오류 없이 실행됨<br>\n<strong>-경고[\/strong]-예산 제한처럼 미리 설정된 제약 조건 하에서 실행됨<br>\n<strong> 비기술적 오류<\/strong>-데일리캡 또는 예산 초과와 같은 비기술적인 오류와 함께 실행됨<br>\n<strong>기술적 오류<\/strong>-규칙이 기술적 오류와 함께 실행됨"
        },
        "ruleType": {
            "title": "규칙 유형",
            "tooltip": "\"클라이언트 파트너 자동화\" 규칙 유형은 클라이언트 파트너가 계정을 사칭하는 경우에만 표시됩니다."
        },
        "rulesFor": {
            "title": "규칙 적용 범위 선택",
            "tooltip": "다양한 캠페인, Ad Group 또는 키워드에 대한 Automation 규칙을 생성할 수 있습니다."
        },
        "searchAdsVisibilityScore": {
            "title": "SA Visibility Score",
            "tooltip": "해당 수치는은 Apple App Store의 SearchAds에서의 앱 Visibility를 보여 줍니다. 점수는 0-100점으로 구분됩니다."
        },
        "searchMatch": {
            "title": "Search Match",
            "tooltip": "<b>Search Match <\/b>는 여러 리소스를 사용하여 App Store의 관련 검색, App Store 목록의 Metadada, 장르의 유사한 앱에 대한 정보 <br> 및 기타 검색 데이터와 광고를 자동으로 일치시킵니다."
        },
        "searchScore": {
            "title": "Popularity(검색량)",
            "tooltip": "해당 키워드의 Popularity(인기도) 점수입니다. \n점수가 높을 수록 해당키워드를 통한 볼륨이 크다는 것을 의미합니다. "
        },
        "searchTerm": {
            "title": "Search Term",
            "tooltip": "Search Term는 사용자가 찾고 있는 특정 유형의 앱을 찾을 때 사용한 키워드와 문구입니다."
        },
        "searchTermKeyword": {
            "title": "키워드",
            "tooltip": "Search Term Text"
        },
        "searchTermKeywordId": {
            "title": "Search Term Keyword ID",
            "tooltip": "키워드용 Apple 검색 광고 식별자"
        },
        "searchTermSource": {
            "title": "Search Term Source",
            "tooltip": "Search Term Source"
        },
        "searchTermText": {
            "title": "Search Term Text",
            "tooltip": "Search Term Text"
        },
        "source": {
            "title": "출처",
            "tooltip": "이 열에는 키워드 입찰가 변경의 출처가 표시됩니다. 네 가지 소스 유형이 있습니다:<br><ul> <li> <b> 대시보드:<\/b> 광고 관리자 대시보드에서 키워드 입찰가가 업데이트됩니다.<\/li><li> <b> 외부:<\/b><\/li> <li> <b> 자동화를 통해 키워드 입찰가가 업데이트됩니다:<\/b><\/li> <li> <b> 스마트 입찰: 키워드 입찰가가 자동화 규칙을 사용하여 업데이트됩니다:<\/b> 스마트 입찰: 키워드 입찰가가 스마트 입찰을 사용하여 업데이트됩니다.<\/li><\/ul>"
        },
        "spend": {
            "title": "실집행금액",
            "tooltip": "현재까지 집행된 광고금액입니다."
        },
        "startDate": {
            "title": "시작 날짜",
            "tooltip": "시작 날짜"
        },
        "stateReason": {
            "title": "일시 중지 이유",
            "tooltip": "자동화가 일시 중지된 이유"
        },
        "status": {
            "title": "연동상태",
            "tooltip": "연동상태"
        },
        "storefront": {
            "title": "국가",
            "tooltip": "캠페인이 진행 중인 국가(마켓)를 표시합니다."
        },
        "strategies": {
            "title": "전략",
            "tooltip": "이 열에는 현재 날짜에 할당된 자동화가 표시됩니다."
        },
        "strategiesWithSb": {
            "title": "전략",
            "tooltip": "이 열에는 현재 날짜에 할당된 스마트 입찰 및 자동화가 표시됩니다."
        },
        "subscriptions": {
            "title": "구독",
            "tooltip": "구독 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "taps": {
            "title": "Taps",
            "tooltip": "광고에 대해서 Tap이 발생한 수치"
        },
        "todaysRank": {
            "title": "랭킹(오늘)",
            "tooltip": "해당 키워드 검색에 따른 해당 앱의 Organic ranking결과입니다."
        },
        "totalBudget": {
            "title": "평생 예산",
            "tooltip": "캠페인에 할당된 평생 예산입니다."
        },
        "totalPaidKeywords": {
            "title": "총 비딩키워드 개수",
            "tooltip": "해당앱이 사용중인 비딩키워드 개수"
        },
        "trials": {
            "title": "평가판",
            "tooltip": "평가판 목표 카테고리와 함께 제공되는 이벤트 수입니다."
        },
        "ttr": {
            "title": "TTR",
            "tooltip": "<b>Tap-through rate(TTR)<\/b><b><b>입니다.이 값은 총Tap수를 총impression수로 나눈 것입니다."
        },
        "yesterdaysRank": {
            "title": "랭킹(어제)",
            "tooltip": "해당 키워드에 대한 선택한 앱의 순위"
        }
    }
}
