export default {
    methods: {
        mx_validateNumber(val) {
            if (!val) {
                return this.$t('messages.errors.fieldIsRequired');
            }
            const pattern = /^\d+(\.\d+)?$/;
            if (!pattern.test(val) || `${val}`.split('.').length > 2) {
                return this.$t('messages.errors.decimalFormatIsNotValid');
            }
            if (Number(val) <= 0) {
                return this.$t('messages.errors.fieldMustBeGreaterThan0');
            }
        },
    },
};
