import { createI18n } from 'vue-i18n';
import messages from '@/root.i18n';
const DEFAULT_LOCALE = 'en';

export default {
    install(app) {
        const i18n = createI18n({
            locale: app.config.globalProperties.$maLocalStorage.locale || DEFAULT_LOCALE, // set locale either from localStorage or config
            fallbackLocale: DEFAULT_LOCALE,
            messages, // set locale messages
            legacy: true,
            globalInjection: true,
            allowComposition: true,
            silentTranslationWarn: true,
            silentFallbackWarn: true,
        });
        app.use(i18n);
    },
};
