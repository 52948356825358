import { globalProperties } from '@/main';
import { defineStore } from 'pinia';
import { trimAndCleanKeyword } from '@/common/MaUtils.mjs';

const _updateKeywordCart = (keywords) => {
    globalProperties.$maSessionStorage.keywordCart = keywords.map(k => ({
        keyword: k.keyword,
        searchScore: k.searchScore,
        numberOfApps: k.numberOfApps,
    }));
};

const _trimKeywords = (kw) => {
    kw.keyword = trimAndCleanKeyword(kw.keyword);
    return kw;
};

export const useKeywordCartStore = defineStore('keyword-cart', {
    state: () => ({
        keywords: globalProperties.$maSessionStorage.keywordCart || [],
    }),
    getters: {
        addedKeywords() {
            return this.keywords?.map(k => k.keyword) || [];
        },
        trimmedKeywords() {
            return this.keywords
                .map(_trimKeywords);
        },
        keywordList() {
            return this.trimmedKeywords.map(k => k.keyword);
        },
    },
    actions: {
        emptyCart() {
            this.keywords = [];
            _updateKeywordCart(this.keywords);
        },
        addMultipleToCart(keywords) {
            let kws = keywords.map(_trimKeywords);
            const filtered = kws.filter(k => this.keywordList.indexOf(k.keyword) === -1);
            this.keywords = this.keywords.concat(filtered);
            _updateKeywordCart(this.keywords);
        },
        addToCart(keyword) {
            keyword.keyword = trimAndCleanKeyword(keyword.keyword);
            if (this.keywordList.indexOf(keyword.keyword) === -1) {
                this.keywords.push(keyword);
                _updateKeywordCart(this.keywords);
            }
        },
        removeFromCart(keyword) {
            this.keywords = this.trimmedKeywords.filter(k => k.keyword !== trimAndCleanKeyword(keyword.keyword));
            _updateKeywordCart(this.keywords);
        },
    },
});
