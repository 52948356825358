<template>
    <label-selector
        ref="labelSelectorComponent"
        :default-selected="labelIds"
        @labels-selected="labels => emit('update:labelIds', labels.map(l => l.id))"
    >
        <ma-badge
            v-if="!labelIds?.length"
            icon="add"
            type="line"
            :class="{
                'cursor-pointer': true,
            }"
        >
            {{ $t('buttons.add') }}
        </ma-badge>
        <!--Something needs to be rendered here so that ant design knows where to mount the popover-->
        <div v-else/>
    </label-selector>
    <template v-if="labelIds.length">
        <template v-if="labelIds.length <= 1">
            <ma-tooltip
                v-for="id in labelIds"
                :key="id"
                :title="labelIdMap[id]?.name"
                type="primary"
            >
                <div
                    class="ma-label-tag"
                    :style="{ backgroundColor: labelIdMap[id]?.color + '22' }"
                    @click="openPopover"
                >
                    <span class="max-w-[48px] font-medium !text-[11px] truncate" :style="{ color: labelIdMap[id]?.color }">
                        {{ labelIdMap[id]?.name }}
                    </span>
                    <ma-icon
                        name="close"
                        size="xs"
                        :color="labelIdMap[id]?.color"
                        @click.stop="removeLabel(labelIdMap[id])"
                    />
                </div>
            </ma-tooltip>
        </template>
        <template v-else>
            <ma-tooltip
                :title="labelIdMap[labelIds[0]].name"
            >
                <div
                    class="ma-label-tag"
                    :style="{ backgroundColor: labelIdMap[labelIds[0]]?.color + '22' }"
                    @click="openPopover"
                >
                    <span class="max-w-[48px] font-medium !text-[11px] truncate" :style="{ color: labelIdMap[labelIds[0]].color }">
                        {{ labelIdMap[labelIds[0]]?.name }}
                    </span>
                    <ma-icon
                        name="close"
                        size="xs"
                        :color="labelIdMap[labelIds[0]]?.color"
                        @click.stop="removeLabel(labelIdMap[labelIds[0]])"
                    />
                </div>
            </ma-tooltip>
            <div class="ma-label-tag text-gray-600 font-medium text-[11px]" @click="openPopover">
                +{{ labelIds.length - 1 }}
            </div>
        </template>
    </template>
</template>

<script setup>
    import LabelSelector from '@/pages/Automations/Components/LabelSelector/LabelSelector.vue';
    import { MaBadge, MaIcon, MaTooltip } from '@mobileaction/action-kit';
    import { useLabelsStore } from '@/pinia/Labels';
    import { computed, ref } from 'vue';

    const labelsStore = useLabelsStore();
    const labels = computed(() => labelsStore.labels);

    const props = defineProps({
        labelIds: { type: Array, default: () => [] },
    });
    const emit = defineEmits(['update:labelIds']);

    //don't display labels after they are deleted
    const getFilteredLabels = () => props.labelIds.filter(id => labels.value.some(label => label.id === id));
    const filteredIds = computed(getFilteredLabels);
    const labelIdMap = computed(() => {
        const labelsMap = {};
        filteredIds.value.forEach((labelId) => {
            const label = labels.value?.find(label => label.id === labelId);
            labelsMap[labelId] = label || { name: labelId, color: '#ddd' }; //default value in case label is not found
        });
        return labelsMap;
    });

    const labelSelectorComponent = ref(null);
    const openPopover = () => {
        labelSelectorComponent.value?.openSelectionPopover();
    };

    const removeLabel = async (label) => {
        emit('update:labelIds', props.labelIds.filter(id => id !== label.id));
    };
</script>


<style lang="less">
    .ma-label-tag {
        @apply flex gap-1 items-center py-1 px-2 rounded-full bg-gray-100 cursor-pointer;
        .ma-icon {
            @apply ~'w-[10px]' ~'h-[10px]' shrink-0;
        }
    }
</style>
