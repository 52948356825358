import { defineStore } from 'pinia';

export const useNavigationStateStore = defineStore('navigation-state', {
    state: () => ({
        fromRoute: null,
        toRoute: null,
        params: null,
    }),
    actions: {
        async setNavigationState(fromRoute, toRoute, params) {
            this.fromRoute = fromRoute;
            this.toRoute = toRoute;
            this.params = params;
        },
        emptyNavigationState(navigateTo) {
            if (this.toRoute !== navigateTo) {
                this.toRoute = null;
                this.fromRoute = null;
                this.params = null;
            }
        },
    },
});
