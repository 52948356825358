import { globalProperties as app } from '@/main';

export const hubspotQueue = window._hsq = window._hsq || [];


export function identifyVisitor(user) {
    if (!user) {
        app.$log.error('MaHubspot: user object is empty');
        return;
    }
    app.$log.debug('MaHubspot | identify visitor with email: ', user.username);
    let metadata = { email: user.username };
    hubspotQueue.push(['identify', metadata]);
}

export function trackPageView(path) {
    app.$log.debug('MaHubspot | tracked page view: ', path);
    hubspotQueue.push(['setPath', path]);
    hubspotQueue.push(['trackPageView']);
}

export function trackEvent(event) {
    if (!event) {
        app.$log.error('MaHubspot: eventName missing');
        return;
    }
    app.$log.debug('MaHubspot | tracked event: ', event);
    let metadata = { id: event };
    hubspotQueue.push(['trackEvent', metadata]);
}

export default {
    identifyVisitor,
    trackPageView,
    trackEvent,
};
