{
    "addedKeywords": {
        "keyword": "추가된 Keyword"
    },
    "hideSelection": "선택항목 숨기기",
    "keywordBid": "Keyword 입찰(Bidding)",
    "limitForAdGroup": "Ad Group Keyword의 제한은 5000개로, 초과된 Keyword는 목록에 추가되지 않아요",
    "noNegativeKeywords": "아직 Negative Keyword가 추가되지 않았어요.",
    "none": "내용없음",
    "pleaseInputCorrectBidAmount": "정확한 비딩단가를 입력해 주세요.",
    "select": "선택",
    "specialCharacters": "특수문자({chars})는 허용되지 않습니다. 제거하고 다시 시도해 주세요.",
    "youCanEitherAddKeywords": "여기서 Keyword를 수동으로 추가하거나 왼쪽에서 선택할 수 있어요."
}
