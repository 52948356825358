{
    "nFreeLabelsLeft": "{n} 無料ラベルが残っています。",
    "upgradeForMoreLabels": "新しいラベルを追加するためのアップグレード",
    "addNewLabel": "新しいラベルの追加",
    "areYouSureDeleteLabel": "選択したラベルを削除しますか？",
    "cancel": "キャンセル",
    "color": "色",
    "createLabel": "ラベルを追加",
    "description": "ルール内容",
    "description_optional": "説明文 (任意)",
    "editLabel": "ラベルを編集",
    "enterAColor": "色を指定してください",
    "enterALabelName": "ラベル名が必要です",
    "failedToCreateLabel": "ラベルの作成に失敗しました {msg}",
    "failedToFetchLabel": "ラベルの読み込みに失敗しました {msg}",
    "failedToUpdateLabel": "ラベルの更新に失敗しました {msg}",
    "label": "ラベル",
    "labelNameCannotBeStartedWithAt": "ラベル名は{'@'}で開始することができません",
    "labelNameShouldBeUnique": "ラベル名は既存のものと異なる必要があります",
    "mainCategoryCanBeOnlyOne": "メインカテゴリー(#から開始)は各対象に対して１つまでとなります",
    "name": "ルール名",
    "newLabel": "新しいラベル",
    "noLabelFound": "ラベルがありません",
    "preDefinedLabelCannotBeDeleted": "既存ラベルは削除できません",
    "preDefinedLabelCannotBeEdited": "既存ラベルは修正できません",
    "searchByName": "名前で検索",
    "succeedToCreateLabel": "ラベル {label} が作成されました",
    "succeedToUpdateLabel": "ラベル {label} が更新されました"
}
