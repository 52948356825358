import mixpanel from 'mixpanel-browser';
import { isProduction } from '@/common/Config';

export default function (key) {
    if (!key) {
        console.error('[Mixpanel] Mixpanel project Key is required');
        return;
    }

    mixpanel.init(key, {
        debug: !isProduction,
    });

    console.warn(`[Mixpanel] Mixpanel is initialized in ${ isProduction ? 'production' : 'development' } mode`);
    return true;
}
