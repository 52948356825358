<template>
    <div class="ma-color-selector">
        <input
            v-model="color"
            class="cursor-pointer bg-transparent border rounded h-8 border-gray-300"
            type="color"
            list="predefinedColors"
            @change="select(color)"
        >
        <datalist id="predefinedColors">
            <option
                v-for="(val, ind) in predefinedColors"
                :key="ind"
            >
                {{ val }}
            </option>
        </datalist>
    </div>
</template>

<script>
    export default {
        name: 'ma-color-selector',
        props: {
            modelValue: { type: String, required: true },
            predefinedColors: {
                type: Array,
                required: false,
                default: () => [
                    '#ff4500',
                    '#ff8c00',
                    '#ffd700',
                    '#90ee90',
                    '#00ced1',
                    '#1e90ff',
                    '#c71585',
                    '#DA0D0D',
                    '#da7e0d',
                    '#BF40FF',
                ],
            },
        },
        emits: ['update:modelValue'],
        data() {
            return {
                color: null,
            };
        },
        methods: {
            select(color) {
                this.$emit('update:modelValue', color);
            },
        },
        watch: {
            modelValue: {
                immediate: true,
                handler() {
                    if (this.color !== this.modelValue) {
                        this.color = this.modelValue;
                    }
                },
            },
        },
    };
</script>
