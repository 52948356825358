import cn from '@/i18n/components/MaKeywordsCheck/cn.json';
import en from '@/i18n/components/MaKeywordsCheck/en.json';
import ja from '@/i18n/components/MaKeywordsCheck/ja.json';
import ko from '@/i18n/components/MaKeywordsCheck/ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
