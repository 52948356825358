export const IGNORE = 'IGNORE'; // // you dont need to do anything, apple search ads will IGNORE this anyway
export const CANCEL_ADDING = 'CANCEL_ADDING'; // 'Cancel adding keyword',
export const CANCEL_ADDING_NEG_KW = 'CANCEL_ADDING_NEG_KW';
export const REMOVE_NEGATIVE_KEYWORD = 'REMOVE_NEGATIVE_KEYWORD';
export const UPDATE_MATCH_TYPE = 'UPDATE_MATCH_TYPE'; // Update match type of the incumbent keyword
export const UPDATE_BID_AMOUNT = 'UPDATE_BID_AMOUNT'; // Update bid to the incumbent keyword
export const PAUSE_KEYWORD = 'PAUSE_KEYWORD'; // Pause the incumbent keyword

export default {
    IGNORE,
    CANCEL_ADDING,
    CANCEL_ADDING_NEG_KW,
    REMOVE_NEGATIVE_KEYWORD,
    UPDATE_MATCH_TYPE,
    UPDATE_BID_AMOUNT,
    PAUSE_KEYWORD,
};
