import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { labels as fetchLabels } from '@/common/MaRequests/MetadataLabel';

export const useLabelsStore = defineStore('labels', () => {
    const labels = ref([]);
    const labelsLoading = ref(false);

    const labelsList = computed(() => labels.value);
    const labelIdMap = computed(() => {
        return labels.value.reduce((acc, label) => {
            acc[label.id] = label;
            return acc;
        }, {});
    });

    const updateLabels = (newLabels) => {
        labels.value = newLabels;
    };

    const getLabels = async ({ cacheInvalidate } = { cacheInvalidate: false }) => {
        try {
            labelsLoading.value = true;
            labels.value = await fetchLabels(cacheInvalidate);
        } catch (error) {
            labels.value = [];
            labelsLoading.value = false;
            console.error('Failed to fetch labels', error);
        } finally {
            labelsLoading.value = false;
        }
    };

    return {
        labels,
        labelsList,
        labelIdMap,
        updateLabels,
        getLabels,
        labelsLoading,
    };
});
