{
    "chooseAdGroup": "Ad Group을 선택하세요",
    "chooseApp": "앱을 선택하세요",
    "chooseCampaign": "캠페인을 선택하세요",
    "chooseCountry": "국가를 선택하세요",
    "emptyTextSubList": "Ad group을 보려면 캠페인을 선택하세요",
    "selectAccountText": "계정을 선택하세요",
    "selectAdGroupText": "목록에 추가하기 위해 Ad group을 선택하세요",
    "selectAppText": "앱을 선택하세요",
    "selectCampaignText": "캠페인을 선택하세요",
    "selectCountryText": "국가를 선택하세요",
    "selectedAccounts": "선택된 계정이 없습니다. | {n}개의 계정 | {n}개의 계정",
    "selectedAdGroups": "선택된 Ad group이 없습니다. | {n}개의 Ad group | {n}개의 Ad group",
    "selectedApps": "선택된 앱이 없습니다. | {n}개의 앱 | {n}개의 앱",
    "selectedCampaigns": "선택된 캠페인이 없습니다. | {n}개의 캠페인 | {n}개의 캠페인",
    "selectedCountries": "선택된 국가가 없습니다. | {n}개의 국가 | {n}개의 국가",
    "types": {
        "AD_GROUP": "Ad Group",
        "APP": "App",
        "CAMPAIGN": "캠페인",
        "COUNTRY": "국가",
        "KEYWORD": "키워드"
    }
}
