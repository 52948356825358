export default {
    PUBLIC_SITE_DOMAIN: import.meta.env.VITE_PUBLIC_SITE_DOMAIN || 'https://www.mobileaction.co',
    SEARCHADS_PUBLIC_SITE_DOMAIN: import.meta.env.VITE_SEARCHADS_PUBLIC_SITE_DOMAIN || 'https://searchads.com',
    GATEWAY_DOMAIN: import.meta.env.VITE_GATEWAY_DOMAIN || 'https://gateway.mobileaction.co',
    INSIGHTS_DOMAIN: import.meta.env.VITE_INSIGHTS_DOMAIN || 'https://insights.mobileaction.co',
    REQUEST_DEMO_URL: import.meta.env.VITE_REQUEST_DEMO_URL || 'https://meetings.hubspot.com/mobileaction/searchads-product-tour',
    STRIPE_PUBLISHABLE_KEY: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
    MIXPANEL_PROJECT_KEY: import.meta.env.VITE_MIXPANEL_PROJECT_KEY || 'da68a425e2ad751bf013b05d32213a96',
    SENTRY_SAMPLE_RATE: import.meta.env.VITE_SENTRY_SAMPLE_RATE || 0.2,
    DD_APPLICATION_ID: import.meta.env.VITE_DD_APPLICATION_ID || 'ebf15289-7b62-4f92-80cb-862590a0d8e4',
    DD_CLIENT_TOKEN: import.meta.env.VITE_DD_CLIENT_TOKEN || 'pub83674409578cbfc051ac21676a93da3e',
    MAINTENANCE_MODE: import.meta.env.VITE_MAINTENANCE_MODE || 'OFF',
    APP_VERSION: import.meta.env.VITE_VERSION,
    DD_ENV: 'prod',
    DD_SESSION_SAMPLE_RATE: import.meta.env.VITE_DD_SESSION_SAMPLE_RATE || 100,
    DD_SESSION_REPLAY_SAMPLE_RATE: import.meta.env.VITE_DD_SESSION_REPLAY_SAMPLE_RATE || 20,
    DD_DEFAULT_PRIVACY_LEVEL: import.meta.env.VITE_DD_DEFAULT_PRIVACY_LEVEL || 'mask-user-input',
    SB_LIMIT_REMOVED_ACCOUNTS: import.meta.env.VITE_SB_LIMIT_REMOVED_ACCOUNTS || '244547', // SMART BIDDING LIMIT REMOVED ACCOUNTS
};
