import { MixpanelEvent } from '@/common/Mixpanel';

const bannerEvents = route => ({
    // Verify Email Banner
    verifyEmailBannerResend: new MixpanelEvent(
        'SA-Verify Email Banner-ResendEmail',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    verifyEmailBannerClose: new MixpanelEvent(
        'SA-Verify Email Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    // Trial Status Banner
    trialStatusBannerContact: new MixpanelEvent(
        'SA-Trial Status Banner-ContactSales',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    trialStatusBannerClose: new MixpanelEvent(
        'SA-Trial Status Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    expiredIntegrationsBannerClose: new MixpanelEvent(
        'SA-Expired Integrations Banner-Closed',
        [{ name: 'Page', default: route?.fullPath }]
    ),
    expiredIntegrationsBannerConnect: new MixpanelEvent(
        'SA-Expired Integrations Banner-Connect',
        [{ name: 'Page', default: route?.fullPath }]
    ),
});

export default bannerEvents;
