<template>
    <a-modal
        :title="customTitle || title"
        :open="pVisible"
        :mask-closable="false"
        class="ma-metadata-selector-dialog-body"
        :get-popup-container="trigger => trigger.parentElement"
        destroy-on-close
        width="1200px"
        :z-index="zIndex"
        @cancel="cancel"
    >
        <a-spin :spinning="loading">
            <div ref="metadata-selector-content" class="ma-custom-tree-container flex justify-between">
                <div class="flex ma-tree-table" :class="isAdGroup ? 'ma-tree-table-3' : 'ma-tree-table-2'">
                    <div class="ma-metadata-selector-section">
                        <div class="flex items-center space-x-2 pb-[16px]">
                            <ma-input
                                v-model:value="filterText"
                                :placeholder="$t('common.searchByNameOrId')"
                                class="flex-1"
                            >
                                <template #prefix>
                                    <ma-icon name="search-normal" class="text-gray-400" size="xs"/>
                                </template>
                            </ma-input>
                        </div>
                        <div class="ma-info-table">
                            <div class="flex items-center justify-between">
                                <div class="font-medium text-[14px] leading-[20px] text-gray-500">
                                    {{ typeLabel(0) }}
                                </div>
                                <div v-if="expandedKeys.length > 0">
                                    <ma-button
                                        class="!px-0 space-x-1"
                                        color="blue"
                                        variant="ghost"
                                        size="small"
                                        @click="collapseAllSelection"
                                    >
                                        <ma-icon name="hamburger"/>
                                        {{ $t('buttons.collapseAll') }}
                                    </ma-button>
                                </div>
                            </div>
                        </div>
                        <a-divider class="!p-0 !m-0 !my-[12px] !border-gray-100"/>
                        <div class="ma-block ma-block-tree">
                            <a-tree
                                :checked-keys="checkedKeys"
                                :tree-data="nestedData"
                                :field-names="defaultProps"
                                default-expand-all
                                :checkable="!isAdGroup"
                                :auto-expand-parent="autoExpandParent"
                                :expanded-keys="expandedKeys"
                                block-node
                                @select="handleNodeClick"
                                @expand="expandNode"
                                @check="handleCheckChange"
                            >
                                <template #title="{ dataRef: data, label, expanded }">
                                    <div class="flex space-x-1">
                                        <div class="ma-custom-tree-node">
                                            <template v-if="isAccount">
                                                <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ label }}
                                                    </div>
                                                </ma-tooltip>
                                            </template>
                                            <template v-else-if="isKeyword">
                                                <div class="flex items-center space-x-2">
                                                    <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ label }}
                                                        </div>
                                                    </ma-tooltip>
                                                    <ma-badge
                                                        v-if="data.children?.length"
                                                        type="secondary"
                                                        size="small"
                                                        class="font-medium"
                                                    >
                                                        {{ lengthOfChildren(data) }}
                                                    </ma-badge>
                                                </div>
                                                <span v-if="data.children?.length">
                                                    <ma-icon v-if="expanded" name="chevron-down"/>
                                                    <ma-icon v-else name="chevron-right"/>
                                                </span>
                                            </template>
                                            <template v-else-if="isCountry">
                                                <template v-if="!data.id">
                                                    <div class="flex items-center space-x-2">
                                                        <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ label }}
                                                            </div>
                                                        </ma-tooltip>
                                                        <ma-badge
                                                            v-if="data.children?.length"
                                                            type="secondary"
                                                            size="small"
                                                            class="font-medium"
                                                        >
                                                            {{ lengthOfChildren(data) }}
                                                        </ma-badge>
                                                    </div>
                                                    <ma-icon v-if="expanded" name="chevron-down"/>
                                                    <ma-icon v-else name="chevron-right"/>
                                                </template>
                                                <template v-else>
                                                    <span>
                                                        <ma-flag
                                                            v-if="data.id"
                                                            class="mr-0.5"
                                                            :code="`${data.id}`"
                                                        />
                                                        {{ label }}
                                                    </span>
                                                </template>
                                            </template>
                                            <template v-else-if="isApp">
                                                <template v-if="!data.id">
                                                    <div class="flex items-center space-x-2">
                                                        <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ label }}
                                                            </div>
                                                        </ma-tooltip>
                                                        <ma-badge
                                                            v-if="data.children?.length"
                                                            type="secondary"
                                                            size="small"
                                                            class="font-medium"
                                                        >
                                                            {{ lengthOfChildren(data) }}
                                                        </ma-badge>
                                                    </div>
                                                    <ma-icon v-if="expanded" name="chevron-down"/>
                                                    <ma-icon v-else name="chevron-right"/>
                                                </template>
                                                <template v-else>
                                                    <a-tooltip
                                                        :title="label"
                                                        placement="top"
                                                        overlay-class-name="ma-tooltip-md"
                                                    >
                                                        <div class="flex items-center">
                                                            <ma-app-icon
                                                                v-if="appInfoMapping[data.appId]"
                                                                :icon="appInfoMapping[data.appId].iconUrl"
                                                                class="mr-2"
                                                                size="mini"
                                                            />
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ nodeAppLabel(data) }}
                                                            </div>
                                                        </div>
                                                    </a-tooltip>
                                                </template>
                                            </template>
                                            <template v-else-if="isGoal">
                                                <template v-if="data.children">
                                                    <a-tooltip
                                                        :title="`${ label }`"
                                                        placement="top"
                                                        overlay-class-name="ma-tooltip-md"
                                                        class="ma-custom-tree-node--label"
                                                    >
                                                        <div class="flex items-center">
                                                            <ma-app-icon
                                                                v-if="data.children && data.children.length && appInfoMapping[data.children[0].appId]"
                                                                :icon="appInfoMapping[data.children[0].appId].iconUrl"
                                                                class="mr-2"
                                                                size="mini"
                                                            />
                                                            <span>
                                                                {{ nodeAppLabel(data) }}
                                                            </span>
                                                            <ma-badge
                                                                v-if="data.children?.length"
                                                                type="secondary"
                                                                size="small"
                                                                class="font-medium"
                                                            >
                                                                {{ lengthOfChildren(data) }}
                                                            </ma-badge>
                                                        </div>
                                                    </a-tooltip>
                                                    <ma-icon v-if="expanded" name="chevron-down"/>
                                                    <ma-icon v-else name="chevron-right"/>
                                                </template>
                                                <div v-else class="flex items-center">
                                                    <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                    <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ label }}
                                                        </div>
                                                    </ma-tooltip>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <template v-if="!data.displayStatus || !data.status">
                                                    <ma-tooltip
                                                        v-if="data.disabled"
                                                        :title="$t('common.alreadyInBudgetAllocationGroupAll', { appName: data.label})"
                                                        placement="top"
                                                        type="primary"
                                                        overlay-class-name="ma-tooltip-md"
                                                        class="ma-custom-tree-node--label"
                                                    >
                                                        <div class="flex items-center">
                                                            <ma-app-icon
                                                                v-if="data.children && data.children.length && appInfoMapping[data.children[0].appId]"
                                                                :icon="appInfoMapping[data.children[0].appId].iconUrl"
                                                                class="mr-2"
                                                                size="mini"
                                                            />
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ nodeAppLabel(data) }}
                                                            </div>
                                                            <ma-badge
                                                                v-if="data.children?.length"
                                                                type="secondary"
                                                                size="small"
                                                                class="font-medium"
                                                            >
                                                                {{ lengthOfChildren(data) }}
                                                            </ma-badge>
                                                        </div>
                                                    </ma-tooltip>
                                                    <div v-else class="flex items-center">
                                                        <ma-app-icon
                                                            v-if="data.children && data.children.length && appInfoMapping[data.children[0].appId]"
                                                            :icon="appInfoMapping[data.children[0].appId].iconUrl"
                                                            class="mr-2"
                                                            size="mini"
                                                        />
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ nodeAppLabel(data) }}
                                                        </div>
                                                        <ma-badge
                                                            v-if="data.children?.length"
                                                            type="secondary"
                                                            size="small"
                                                            class="font-medium"
                                                        >
                                                            {{ lengthOfChildren(data) }}
                                                        </ma-badge>
                                                    </div>
                                                    <ma-icon v-if="expanded" name="chevron-down"/>
                                                    <ma-icon v-else name="chevron-right"/>
                                                </template>
                                                <div v-else-if="data.displayStatus">
                                                    <ma-tooltip
                                                        v-if="data.disabled" type="primary"
                                                        placement="left"
                                                        :title="$t('common.alreadyInBudgetAllocationGroup')"
                                                    >
                                                        <div
                                                            v-if="data.displayStatus === DISPLAY_STATUS.PAUSED"
                                                            class="flex items-center"
                                                        >
                                                            <ma-icon name="dot" class="text-gray-400 ma-icon--status"/>
                                                            <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                <div class="ma-custom-tree-node--label">
                                                                    {{ label }}
                                                                </div>
                                                            </ma-tooltip>
                                                        </div>
                                                        <div
                                                            v-else-if="data.displayStatus === DISPLAY_STATUS.ON_HOLD"
                                                            class="flex items-center"
                                                        >
                                                            <ma-icon name="dot" class="text-orange-500 ma-icon--status"/>
                                                            <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                <div class="ma-custom-tree-node--label">
                                                                    {{ label }}
                                                                </div>
                                                            </ma-tooltip>
                                                        </div>
                                                        <div v-else class="flex items-center">
                                                            <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                            <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                <div class="ma-custom-tree-node--label">
                                                                    {{ label }}
                                                                </div>
                                                            </ma-tooltip>
                                                        </div>
                                                    </ma-tooltip>
                                                    <div v-else>
                                                        <div
                                                            v-if="data.displayStatus === DISPLAY_STATUS.PAUSED"
                                                            class="flex items-center"
                                                        >
                                                            <ma-icon name="dot" class="text-gray-400 ma-icon--status"/>
                                                            <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                <div class="ma-custom-tree-node--label">
                                                                    {{ label }}
                                                                </div>
                                                            </ma-tooltip>
                                                        </div>
                                                        <div
                                                            v-else-if="data.displayStatus === DISPLAY_STATUS.ON_HOLD"
                                                            class="flex items-center"
                                                        >
                                                            <ma-icon name="dot" class="text-orange-500 ma-icon--status"/>
                                                            <div class="ma-custom-tree-node--label">
                                                                <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                    {{ label }}
                                                                </ma-tooltip>
                                                            </div>
                                                        </div>
                                                        <div v-else class="flex items-center">
                                                            <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                            <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                                <div class="ma-custom-tree-node--label">
                                                                    {{ label }}
                                                                </div>
                                                            </ma-tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <div
                                                        v-if="data.status === STATUS.PAUSED"
                                                        class="flex items-center"
                                                    >
                                                        <ma-icon name="dot" class="text-gray-400 ma-icon--status"/>
                                                        <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ label }}
                                                            </div>
                                                        </ma-tooltip>
                                                    </div>
                                                    <div v-else class="flex items-center">
                                                        <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                        <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title="label">
                                                            <div class="ma-custom-tree-node--label">
                                                                {{ label }}
                                                            </div>
                                                        </ma-tooltip>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </a-tree>
                        </div>
                    </div>
                    <div v-if="isAdGroup" class="ma-metadata-selector-section">
                        <ma-input
                            v-model:value="filterSubText"
                            :placeholder="$t('common.searchByNameOrId')"
                        >
                            <template #prefix>
                                <ma-icon name="search-normal" class="text-gray-400" size="xs"/>
                            </template>
                        </ma-input>
                        <div
                            v-if="!selectedSubList.length"
                            class="flex justify-center items-center h-full"
                        >
                            <ma-empty size="small" variant="selection">
                                <template #description>
                                    <div class="text-gray-400">
                                        {{ $t('emptyTextSubList') }}
                                    </div>
                                </template>
                            </ma-empty>
                        </div>
                        <template v-else>
                            <div class="ma-block ma-sub-block">
                                <a-table
                                    stripe
                                    size="small"
                                    class="ma-sub-list"
                                    :data-source="selectedSubList"
                                    :locale="{ emptyText: $t('emptyTextSubList') }"
                                    :row-class-name="tableRowClassName"
                                    :row-key="r => r.key"
                                    :row-selection="{
                                        onSelect: selectAdGroup,
                                        onSelectAll: selectAllAdGroupsChanged,
                                        selectedRowKeys: chosenData.map(a => a.key),
                                    }"
                                    :pagination="{
                                        size: 'small',
                                        hideOnSinglePage: true,
                                        showSizeChanger: false,
                                    }"
                                >
                                    <a-table-column
                                        data-index="name"
                                        align="left"
                                        :title="$tc('common.nAdGroup', subData.length, { n: subData.length })"
                                        :ellipsis="true"
                                    >
                                        <template #default="{ record: row }">
                                            <template v-if="!row.displayStatus">
                                                <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title=" row.name">
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ row.label }}
                                                    </div>
                                                </ma-tooltip>
                                            </template>
                                            <template v-else>
                                                <div v-if="row.displayStatus === DISPLAY_STATUS.PAUSED" class="flex items-center">
                                                    <ma-icon name="dot" class="text-gray-400 ma-icon--status"/>
                                                    <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title=" row.name">
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ row.name }}
                                                        </div>
                                                    </ma-tooltip>
                                                </div>
                                                <div v-else-if="row.displayStatus === DISPLAY_STATUS.ON_HOLD" class="flex items-center">
                                                    <ma-icon name="dot" class="text-orange-500 ma-icon--status"/>
                                                    <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title=" row.name">
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ row.name }}
                                                        </div>
                                                    </ma-tooltip>
                                                </div>
                                                <div v-else class="flex items-center">
                                                    <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                    <ma-tooltip :mouse-enter-delay="0.5" type="primary" :title=" row.name">
                                                        <div class="ma-custom-tree-node--label">
                                                            {{ row.name }}
                                                        </div>
                                                    </ma-tooltip>
                                                </div>
                                            </template>
                                        </template>
                                    </a-table-column>
                                </a-table>
                            </div>
                        </template>
                    </div>
                    <div class="ma-metadata-selector-section ma-metadata-selector-summary">
                        <div class="~'h-[29px]'"/>
                        <div
                            class="flex items-center justify-between pl-3"
                        >
                            <div v-if="chosenData.length" class="font-medium text-gray-900 text-md">
                                {{ $t('summaryOfYourList') }}
                                <br>
                                <span class="text-gray-500 text-xs font-[400]">
                                    {{ $t('youCanChangeThem') }}
                                </span>
                            </div>

                            <div v-if="chosenData.length">
                                <ma-dropdown
                                    trigger="click"
                                    placement="bottomRight"
                                    overlay-class-name="!z-[2101]"
                                >
                                    <ma-icon
                                        name="ellipsis-vertical"
                                        size="sm"
                                        class="cursor-pointer focus:outline-none"
                                    />
                                    <template #overlay>
                                        <div
                                            class="font-medium text-md cursor-pointer hover:bg-blue-25 px-4 py-1 rounded"
                                            @click="clearAllSelection"
                                        >
                                            {{ $t('buttons.clearAll') }}
                                        </div>
                                    </template>
                                </ma-dropdown>
                            </div>
                        </div>

                        <div
                            v-if="!chosenData.length"
                            class="flex justify-center items-center h-full"
                        >
                            <ma-empty size="small" variant="selection">
                                <template #description>
                                    <div class="font-semibold text-gray-500">
                                        {{ typeLabel(1) }}
                                    </div>
                                    <div class="text-gray-400">
                                        {{ typeLabel(2) }}
                                    </div>
                                </template>
                            </ma-empty>
                        </div>
                        <div
                            v-else
                            class="ma-block ma-selected-block"
                        >
                            <a-table
                                :show-header="false"
                                stripe
                                size="small"
                                :locale="{ emptyText: typeLabel(2) }"
                                :data-source="selectedFilteredList"
                                :row-key="r => r.key"
                                :pagination="{
                                    size: 'small',
                                    hideOnSinglePage: true,
                                    showSizeChanger: false,
                                }"
                            >
                                <a-table-column
                                    data-index="name"
                                    align="left"
                                    :ellipsis="true"
                                >
                                    <template #default="{ text, record: row }">
                                        <template v-if="!row.displayStatus">
                                            <ma-tooltip :title="row.name" type="primary">
                                                <div
                                                    v-if="appInfoMapping?.[row.appId]?.iconUrl"
                                                    class="flex items-center space-x-2"
                                                >
                                                    <ma-app-icon
                                                        :icon="appInfoMapping[row.appId].iconUrl"
                                                        size="mini"
                                                    />
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ row.name }}
                                                    </div>
                                                </div>
                                                <div v-else class="ma-custom-tree-node--label">
                                                    {{ row.name }}
                                                </div>
                                            </ma-tooltip>
                                        </template>
                                        <template v-else>
                                            <ma-tooltip :mouse-enter-delay="0.5" :title="text" type="primary">
                                                <div v-if="row.displayStatus === DISPLAY_STATUS.PAUSED" class="flex items-center">
                                                    <ma-icon name="dot" class="text-gray-400 ma-icon--status"/>
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ text }}
                                                    </div>
                                                </div>
                                                <div v-else-if="row.displayStatus === DISPLAY_STATUS.ON_HOLD" class="flex items-center">
                                                    <ma-icon name="dot" class="text-orange-500 ma-icon--status"/>
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ text }}
                                                    </div>
                                                </div>
                                                <div v-else class="flex items-center">
                                                    <ma-icon name="dot" class="text-green-500 ma-icon--status"/>
                                                    <div class="ma-custom-tree-node--label">
                                                        {{ text }}
                                                    </div>
                                                </div>
                                            </ma-tooltip>
                                        </template>
                                    </template>
                                </a-table-column>

                                <a-table-column
                                    align="center"
                                    :width="22"
                                >
                                    <template #default="{ record: row }">
                                        <span
                                            class="w-4 h-4 p-[3px] bg-gray-200 text-gray-800 cursor-pointer rounded-full flex justify-center items-center"
                                            @click="removeItem(row)"
                                        >
                                            <ma-icon name="close" size="small"/>
                                        </span>
                                    </template>
                                </a-table-column>
                            </a-table>
                        </div>
                    </div>
                </div>
            </div>
        </a-spin>
        <a-divider class="!p-0 !m-0 !my-[12px] !border-gray-100"/>
        <template #footer>
            <div class="font-medium">
                {{ typeLabel(1) }}
            </div>
            <ma-button
                size="medium"
                class="!px-12"
                :disabled="applyDisabled"
                @click="showConfirm"
            >
                {{ $t('components.common.apply') }}
            </ma-button>
        </template>
    </a-modal>
</template>

<script setup>
    import { MaBadge, MaButton, MaDropdown, MaEmpty, MaIcon, MaInput, MaTooltip } from '@mobileaction/action-kit';
</script>
<script>
    import { mapGetters } from 'vuex';
    import { mapState, mapActions } from 'pinia';
    import { useAccountStore } from '@/pinia/Account';
    import {
        SA_REGIONS,
        isNumeric,
    } from '@/common/MaUtils.mjs';
    import {
        FILTER_LEVELS,
        changeActiveOrgId,
    } from '@/common/FilteringUtils';
    import { findOrgsOfApp } from '@/common/AccountUtils';
    import { findAllMetadata } from '@/common/MaRequests/Metadata';
    import { SUPPLY_SOURCE } from '@/pages/Manage/ManageUtils.mjs';
    import sharedMessages from '@/components/MaCountrySelector/MaCountrySelector.i18n';
    import messages from '@/components/MaMetadataSelector/MaMetadataSelector.i18n';

    const DISPLAY_STATUS = {
        RUNNING: 'RUNNING',
        ON_HOLD: 'ON_HOLD',
        PAUSED: 'PAUSED',
    };

    const DATA_LEVEL = {
        accounts: 'ACCOUNT',
        apps: 'APP',
        campaigns: 'CAMPAIGN',
        adGroups: 'AD_GROUP',
        creativeSets: 'CREATIVE_SET',
        'creative-sets': 'CREATIVE_SET',
        'ad-groups': 'AD_GROUP',
        keywords: 'KEYWORD',
        GOAL: 'GOAL',
        // automation 2.0 restriction entities map
        ACCOUNT: 'ACCOUNT',
        APP: 'APP',
        CAMPAIGN: 'CAMPAIGN',
        ADGROUP: 'AD_GROUP',
        KEYWORD: 'KEYWORD',
    };

    export default {
        name: 'ma-metadata-selector',
        props: {
            visible: { type: Boolean, default: false },
            single: { type: Boolean, default: false },
            bulkOperation: { type: Boolean, default: false },
            noGoalFilter: { type: Boolean, default: true },
            enabledEntities: { type: Array, default: ()  => [] },
            preGoal: { type: Object, default: () => {} },
            preFilters: { type: Array, default: () => [] },
            preAppId: { type: Number, required: false },
            selectedData: { type: Array, default: () => [], required: false },
            type: { type: String, required: false },
            multiCurrencyCheck: { type: Boolean, default: true },
            restrictAdChannelType: { type: Boolean },
            adChannel: {
                type: String,
                default: SUPPLY_SOURCE.APPSTORE_SEARCH_RESULTS,
                validator: function (value) {
                    return Object.values(SUPPLY_SOURCE).includes(value);
                },
            },
            hidePausedCampaigns: { type: Boolean },
            checkOnClickNode: { type: Boolean },
            hideFilterRemoveWarning: { type: Boolean, default: false },
            restrictGoalListByPreGoal: { type: Boolean, default: false },
            restrictPreGoalSelection: { type: Boolean, default: true },
            applyAppFilterToGoalList: { type: Boolean, default: false },
            isExperiment: { type: Boolean, default: false },
            accountPreFilter: { type: Array, default: () => [] },
            customTitle: { type: String, default: '' },
            zIndex: { type: Number, default: null },
        },
        emits: ['selection-cancel', 'data-loaded', 'data-chosen', 'update:visible', 'selector-closed', 'cancel'],
        data() {
            return {
                pVisible: false,
                chosenData: [],
                allData: [],
                subData: [],
                chosenDataClone: [],
                dataLevel: DATA_LEVEL,
                STATUS: {
                    ENABLED: 'ENABLED',
                    PAUSED: 'PAUSED',
                },
                DISPLAY_STATUS,
                regions: SA_REGIONS,
                selectedType: this.type,
                defaultProps: {
                    children: 'children',
                    title: 'label',
                },
                filterText: '',
                filterSelectedText: '',
                filterSubText: '',
                treeExpanded: true,
                expandedKeys: [],
                checkedKeys: [],
                autoExpandParent: false,
                loadingData: false,
            };
        },
        computed: {
            ...mapState(useAccountStore, {
                orgList: 'orgList',
                appInfoMapping: 'appInfoMapping',
                accountInfoLoading: 'loading',
            }),
            ...mapGetters('account', ['goals', 'currencies']),
            ...mapGetters('dashboard', ['tabFilters']),
            treeKeysFlat() {
                const dataList = [];
                const generateList = (data, parentKey = null) => {
                    for (let i = 0; i < data.length; i++) {
                        const node = data[i];
                        const { id, label } = node;
                        dataList.push({ id, label, key: this.getId(node) || node.key, hasChildren: !!node.children?.length, parentKey });
                        if (node.children?.length > 0) {
                            generateList(node.children, parentKey || node.key);
                        }
                    }
                };
                if (this.nestedData) {
                    generateList(this.nestedData);
                }
                return dataList;
            },
            nestedData() {
                const filterRecursiveData = data => data?.filter(node => this.filterNode(this.filterText, node) || filterRecursiveData(node.children));

                if (!this.allData?.length) {
                    return;
                }
                let data = this.allData;
                if (!this.isKeyword) {
                    data = this.restrictedDataList;
                }
                if (this.preAppId) {
                    data = data.filter(d => d.appId === this.preAppId);
                    if (!data || !data.length) {
                        data = this.allData;
                    }
                }
                if (this.isAdGroup) {
                    data = this.extractCampaignList(data);
                }

                const list = filterRecursiveData(this.convertData(data))
                    .map((node) => {
                        const children = node.children
                            ?.map(d => ({
                                ...d,
                                disabled: this.shouldDisableNode(d),
                            }));
                        return {
                            ...node,
                            children: filterRecursiveData(children),
                            disabled: children?.every(d => d.disabled),
                        };
                    });

                if (this.isAccount) {
                    return list;
                }
                return list.filter(node => !!node.children?.length);
            },
            applyDisabled() {
                return this.loading || (!this.chosenData.length);
            },
            loading() {
                return this.accountInfoLoading || this.loadingData;
            },
            title() {
                const typeKey = this.type && this.dataLevel[this.type];
                let key = 'chooseCountry';
                if (typeKey === 'APP') {
                    key = 'chooseApp';
                } else if (typeKey === 'CAMPAIGN') {
                    key = 'chooseCampaign';
                } else if (typeKey === 'AD_GROUP') {
                    key = 'chooseAdGroup';
                } else if (typeKey === 'ACCOUNT') {
                    key = 'chooseAccount';
                } else if (typeKey === 'KEYWORD') {
                    key = 'chooseKeyword';
                } else if (typeKey === 'CREATIVE_SET') {
                    key = 'chooseCreativeSet';
                } else if (typeKey === 'GOAL') {
                    key = 'chooseGoal';
                }
                return this.$t(key);
            },
            typeKeyLabel() {
                const typeKey = this.type && this.dataLevel[this.type];
                const key = this.isCountry ? 'COUNTRY' : typeKey;
                return this.$t(`types.${key}`);
            },
            isAccount() {
                return this.type && this.dataLevel[this.type] === FILTER_LEVELS.ACCOUNT;
            },
            isCampaign() {
                return this.type && this.dataLevel[this.type] === FILTER_LEVELS.CAMPAIGN;
            },
            isAdGroup() {
                return this.type && this.dataLevel[this.type] === FILTER_LEVELS.AD_GROUP;
            },
            isApp() {
                return this.type && this.dataLevel[this.type] === FILTER_LEVELS.APP;
            },
            isKeyword() {
                return this.type && this.dataLevel[this.type] === FILTER_LEVELS.KEYWORD;
            },
            isCountry() {
                return this.type && this.type === 'country';
            },
            isGoal() {
                return this.type && this.type === 'GOAL';
            },
            selectedSubList() {
                const ids = this.chosenData.filter(d => !!d?.key).map(d => d.key);
                const search = this.filterSubText && this.filterSubText.toLowerCase();
                return this.subData
                    .filter(data => !search
                        || data.name.toLowerCase().includes(search)
                        || data.id.toString().includes(search))
                    .map((d) => {
                        d.added = ids.includes(d.key);
                        d.key = this.getId(d);
                        return d;
                    });
            },
            selectedFilteredList() {
                const search = this.filterSelectedText && this.filterSelectedText.toLowerCase();
                return this.chosenData.filter(data => !search
                    || data.searchLabel.toLowerCase().includes(search)
                    || data.id.toString().includes(this.filterSelectedText.toLowerCase()));
            },
            isIncludeApp() {
                return ['apps', 'campaigns', 'ad-groups', 'GOAL'].includes(this.selectedType);
            },
            totalDataLength() {
                if (this.isAccount) {
                    return this.nestedData?.length;
                }
                return this.nestedData?.reduce((res, node) => (res + (node.children || []).length), 0);
            },
            restrictedDataList() {
                if (this.isKeyword) {
                    return this.chosenData;
                }
                let initalRestriction = this.allData;
                if (this.isCampaign && this.accountPreFilter.length > 0) {
                    initalRestriction = initalRestriction.filter(data => this.accountPreFilter.map(account => account.orgId).includes(data.orgId));
                }
                const hasSupplySource = this.isCampaign || this.isAdGroup;
                if (hasSupplySource && this.restrictAdChannelType && this.adChannel) {
                    initalRestriction = initalRestriction.filter(d => d.supplySources?.includes(this.adChannel));
                }
                return initalRestriction;
            },
            eligibleEntities() {
                return this.enabledEntities.length ?  this.enabledEntities.concat(this.selectedData) : [];
            },
        },
        methods: {
            ...mapActions(useAccountStore, { fetchApps: 'fetchApps' }),
            lengthOfChildren(data) {
                if (!data || !data.children) {
                    return '';
                }
                if (this.filterText) {
                    return data.children.filter(d => this.filterNode(this.filterText, d)).length;
                }
                return data.children.length;
            },
            shouldDisableNode(node) {
                if (!this.eligibleEntities.length) {
                    return false;
                }
                return !this.eligibleEntities.some(entity => this.getId(entity) === this.getId(node));
            },
            tableRowClassName(row) {
                if (row.added) {
                    return 'ma-selected-row';
                } else {
                    return 'ma-no-selected-row';
                }
            },
            cancel() {
                this.pVisible = false;
                this.$emit('cancel');
            },
            getId(data) {
                if (this.isAccount) {
                    return data.orgId;
                } else if (this.isApp) {
                    return `${data.orgId}-${data.appId}`;
                } else if (this.isCampaign) {
                    return data.campaignId;
                } else if (this.isAdGroup) {
                    return data.adGroupId;
                } else {
                    return data.id;
                }
            },
            typeLabel(ind) {
                let key = {
                    selectable: 'common.nCampaign',
                    selected: 'selectedCampaigns',
                    empty: 'selectCampaignText',
                };
                if (this.isAdGroup) {
                    key = { selectable: 'common.nCampaign', selected: 'selectedAdGroups', empty: 'selectAdGroupText' };
                } else if (this.isApp) {
                    key = { selectable: 'common.nApp', selected: 'selectedApps', empty: 'selectAppText' };
                } else if (this.isAccount) {
                    key = { selectable: 'common.nAccount', selected: 'selectedAccounts', empty: 'selectAccountText' };
                } else if (this.isCountry) {
                    key = { selectable: 'common.nCountry', selected: 'selectedCountries', empty: 'selectCountryText' };
                }  else if (this.isGoal) {
                    key = { selectable: 'common.nGoal', selected: 'selectedGoals', empty: 'selectGoalText' };
                } else if (this.isKeyword) {
                    key = { selectable: 'common.nKeyword', selected: 'selectedKeywords', empty: 'selectKeywordText' };
                }
                const prop = Object.keys(key)[ind];
                const n = ind === 0
                    ? this.totalDataLength
                    : this.chosenData.length;
                let appending = '';
                if (this.hidePausedCampaigns) {
                    appending = ' - ' + this.$t('showingActiveCampaign');
                }
                if (this.isExperiment) { // override appending if isExperiment=true
                    appending = ' - ' + this.$t('showingEligibleItems');
                }
                return this.$tc(key[prop], n, { n }) + appending;
            },
            nodeAppLabel(data) {
                let child = null;
                let { label } = data;
                if (!this.isApp) {
                    child = data.children && data.children.length && data.children[0];
                }
                const { appId } = child || data;
                if ((!label || isNumeric(label)) && appId) {
                    label = (this.appInfoMapping[appId] && this.appInfoMapping[appId].name);
                }
                return label || appId;
            },
            nodeSupLabel(supName) {
                let label;
                if (isNumeric(supName) && this.isIncludeApp) {
                    label = (this.appInfoMapping[supName] && this.appInfoMapping[supName].name);
                }
                return label || supName;
            },
            filterNode(value, data) {
                const toLower = str => `${str || ''}`?.toLowerCase();
                const includes = (str, inc) => toLower(str)?.includes(toLower(inc));
                const idProp = this.isAdGroup ? 'campaignId' : 'id';
                if (!value || !toLower(data.searchLabel)) {
                    return true;
                }
                return (includes(data.searchLabel, value) || includes(data[idProp], value));
            },
            checkChosenAll() {
                if (this.isAdGroup) {
                    return;
                }
                if (!this.chosenData.length) {
                    return;
                }
                //It can be String also, so check them...
                if (this.chosenData.length && typeof this.chosenData[0] !== 'object') {
                    return;
                }
                this.checkedKeys = this.chosenData.map(d => d.key);
            },
            collapseAllSelection() {
                this.expandedKeys = [];
            },
            clearAllSelection() {
                this.chosenData = [];
                this.checkedKeys = [];
            },
            removeItem(row) {
                const index = this.chosenData.findIndex(x => x.key === row.key);
                this.chosenData.splice(index, 1);
                const parentKey = this.treeKeysFlat.filter(node => row.key === node.key)[0]?.parentKey;
                this.checkedKeys = this.checkedKeys.filter(key => key !== row.key).filter(key => key !== parentKey);
                if (!this.chosenData.length) {
                    this.checkedKeys = [];
                }
            },
            expandNode(expandedKeys) {
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            handleNodeClick(keys, { node: { dataRef: data } }) {
                if (data.children?.length) {
                    if (!this.expandedKeys.includes(data.key)) {
                        this.expandNode([...this.expandedKeys, data.key]);
                    } else {
                        this.expandedKeys = this.expandedKeys.filter(expandedKey => expandedKey !== data.key);
                    }
                }
                if (this.isAdGroup && !data.children) {
                    this.handleCheckChange(this.checkedKeys, { node: { dataRef: data, checked: false } });
                }
            },
            handleCheckChange(keys, { node: { dataRef: data, checked: wasChecked }, halfCheckedKeys }) {
                this.checkedKeys = keys;
                const clickedOnHalfChecked = halfCheckedKeys?.includes(data.key);
                if (data.children && !clickedOnHalfChecked) {
                    data.children
                        .filter(d => !d.disabled)
                        .filter(d => this.filterNode(this.filterText, d))
                        .forEach(d => this.singleHandleCheckChange(d, !wasChecked));
                } else {
                    if (this.filterNode(this.filterText, data)) {
                        this.singleHandleCheckChange(data, !wasChecked);
                    }
                }
            },
            singleHandleCheckChange(data, checked) {
                if (this.isAdGroup) {
                    this.subData = [];
                    this.allData.forEach((d) => {
                        if (d.campaignId === data.campaignId) {
                            this.checkChangeForSubData(d, checked);
                        }
                    });
                } else {
                    this.checkChangeForChosenData(data, checked);
                }
            },
            checkChangeForChosenData(data, checked) {
                let index = this.chosenData.findIndex(x => x.key === data.key);
                if (checked && index === -1) {
                    this.checkAndPush(this.chosenData, data);
                } else if (!checked && index !== -1) {
                    this.chosenData.splice(index, 1);
                }
            },
            setDataName(data, prop = 'name') {
                let name = data[prop];
                if (!name && this.isApp) {
                    name = this.appInfoMapping[data.id] && this.appInfoMapping[data.id].name;
                }
                return name || data.id;
            },
            checkChangeForSubData(data, checked) {
                const index = this.subData.findIndex(x => x.key === data.key);
                if (checked && index === -1) {
                    this.checkAndPush(this.subData, data);
                } else if (!checked && index !== -1) {
                    this.subData.splice(index, 1);
                }
            },
            indexMethod(index) {
                return 1 + index;
            },
            selectAdGroup(row, include) {
                if (!row) {
                    return;
                }
                const prop = 'adGroupId';
                let index = this.chosenData.map(d => d[prop]).indexOf(row[prop]);
                if (include && index === -1) {
                    this.checkAndPush(this.chosenData, row);
                }
                if (!include && index !== -1) {
                    this.chosenData.splice(index, 1);
                }
            },
            selectAllAdGroupsChanged(include, selectedRows) {
                selectedRows.forEach(row => this.selectAdGroup(row, include));
            },
            selectionChanged(selection) {
                this.selectAdGroup(selection, true);
            },
            checkAndPush(list, data) {
                const existingIds = list.map(l => this.getId(l));
                if (existingIds.includes(data.key)) {
                    return;
                }
                const appendItem = data.supName ? ` (${ this.nodeSupLabel(data.supName) })` : null;
                data.supLabel = data.supName ? this.nodeSupLabel(data.supName) : null;
                data.name = this.setDataName(data);
                if (appendItem && !data.name.includes(appendItem)) {
                    data.name = data.name.concat(appendItem);
                }
                list.push(data);
            },
            convertData(data) {
                let reduced;
                if (this.isAccount) {
                    data.forEach((d) => {
                        d.key = this.getId(d);
                        d.searchLabel = this.currencies.length > 1 ? `${d.orgName} (${d.currency})` : d.orgName;
                        d.label = this.currencies.length > 1 ? `${d.orgName} (${d.currency})` : d.orgName;
                    });
                    return data;
                } else if (this.isCountry) {
                    reduced = this.convertToRegionMap(data);
                } else if (this.isApp) {
                    reduced = this.convertToOrgMap(data);
                } else if (this.isKeyword) {
                    reduced = this.convertToKeywordMap(data);
                } else if (this.isGoal) {
                    reduced = this.convertToGoalMap(data);
                } else {
                    reduced = this.convertToAppMap(data);
                }

                const getTreeNodeObject = (id, item) => {
                    return {
                        key: id,
                        label: this.setNestedLabel(id, item),
                        searchLabel: this.setNestedLabel(id, item),
                        children: [...item],
                    };
                };

                if (reduced instanceof Map) {
                    return [...reduced.keys()].filter(parentKey => reduced.get(parentKey)?.length).map((parentKey) => {
                        const item = reduced.get(parentKey);
                        return getTreeNodeObject(parentKey, item);
                    });
                } else {
                    return Object.keys(reduced || {}).filter(parentKey => reduced[parentKey]?.length).map((parentKey) => {
                        const item = reduced[parentKey];
                        return getTreeNodeObject(parentKey, item);
                    });
                }

            },
            setNestedLabel(key, item) {
                if (this.isCountry) {
                    return key;
                } else if (this.isApp) {
                    return this.currencies.length > 1 ? `${item[0].orgName} (${item[0].currency})` : item[0].orgName;
                } else if (this.isKeyword) {
                    return item[0].campaignName;
                } else if (this.isGoal) {
                    return item[0].trackId;
                }
                return item[0].appName;
            },
            sortByNameProp(data, prop) {
                return data.sort((a,b) => {
                    //consider display status
                    if ((this.isAdGroup || this.isCampaign) && a.displayStatus !== b.displayStatus) {
                        const aStatus = Object.keys(DISPLAY_STATUS).findIndex(s => s === a.displayStatus);
                        const bStatus = Object.keys(DISPLAY_STATUS).findIndex(s => s === b.displayStatus);
                        if (!a.displayStatus || aStatus > bStatus) {
                            return 1;
                        }
                        if (!b.displayStatus || bStatus > aStatus) {
                            return -1;
                        }
                    }
                    if (!a[prop]) {
                        return 1;
                    }
                    if (!b[prop]) {
                        return -1;
                    }
                    if (a[prop]?.toLowerCase() === b[prop]?.toLowerCase()) {
                        return 0;
                    }
                    return a[prop]?.toLowerCase() > b[prop]?.toLowerCase() ? 1 : -1;
                });
            },
            convertToKeywordMap(data) {
                if (!data || !data.length) {
                    return;
                }
                return data.reduce((m, d) => {
                    const cursor = d.campaignId;

                    if (!m.hasOwnProperty(cursor)) {
                        m[cursor] = [];
                    }
                    this.chosenDataClone.filter(c => c.campaignId === cursor).forEach((c) => {
                        c.key = this.getId(c);
                        c.searchLabel = this.buildSearchLabel([(c.keyword || c.entityName), c.campaignName]);
                        c.label = (c.keyword || c.entityName);
                        c.campaignName = d.campaignName;
                        m[cursor].push(c);
                    });
                    return m;
                }, {});
            },
            convertToOrgMap(data) {
                if (!data || !data.length) {
                    return;
                }
                const sortedData = this.sortByNameProp(data, 'orgName');
                const sortedMap = new Map();
                sortedData.forEach((d) => {
                    const cursor = d.orgId;

                    if (!sortedMap.has(cursor)) {
                        sortedMap.set(cursor, []);
                    }
                    d.key = this.getId(d);
                    d.searchLabel = this.buildSearchLabel([d.orgName, d.appName, d.appId]);
                    d.label = d.appName;
                    sortedMap.get(cursor)?.push(d);
                });
                return sortedMap;
            },
            convertToRegionMap() {
                return this.regions.reduce((m, region) => {
                    const cursor = this.$t(region.tk);

                    if (!m.hasOwnProperty(cursor)) {
                        m[cursor] = [];
                    }
                    region.countries.forEach((country) => {
                        country.id = country.alpha2;
                        country.key = country.alpha2;
                        country.searchLabel = this.buildSearchLabel([this.$t(country.alpha2), region.name, country.name]);
                        country.label = this.$t(country.alpha2);
                    });
                    m[cursor] = region.countries;
                    return m;
                }, {});
            },
            convertToGoalMap(rawData) {
                let data = [...rawData];
                if (!data || !data.length) {
                    return;
                }
                //hide all goals unrelated to selected goal's app
                //hide selected goal itself if prop is true
                if (this.preGoal && this.restrictGoalListByPreGoal) {
                    data = rawData.filter((goal) => {
                        if (this.restrictPreGoalSelection && goal.id === this.preGoal.id) {
                            return false;
                        }
                        return goal.trackId === this.preGoal.trackId;
                    });
                }
                const appFilter = this.tabFilters.find((f) => {
                    return f.columnProp === 'appId' && f.filterType?.enum === 'IN';
                });
                if (this.applyAppFilterToGoalList && appFilter) {
                    data = data.filter((goal) => {
                        return goal.trackId === parseInt(appFilter.value);
                    });
                }
                return data.reduce((m, d) => {
                    const cursor = d.trackId;

                    if (!m.hasOwnProperty(cursor)) {
                        m[cursor] = [];
                    }
                    d.key = this.getId(d);
                    d.label = d.goalName;
                    if (!this.appInfoMapping[d.appId]) {
                        return m;
                    }
                    d.searchLabel = this.buildSearchLabel([d.label, this.appInfoMapping[d.appId]?.name, d.appId]);
                    m[cursor].push(d);
                    return m;
                }, {});
            },
            buildSearchLabel(fields) {
                return fields
                    .filter(a => a)
                    .join(' - ');
            },
            convertToAppMap(data) {
                if (!data || !data.length) {
                    return;
                }
                const sortedData = this.sortByNameProp(data, 'appName');
                const sortedMap = new Map();
                sortedData.forEach((d) => {
                    const cursor = d.appId;

                    if (!sortedMap.has(cursor)) {
                        sortedMap.set(cursor, []);
                    }
                    d.key = this.getId(d);
                    d.searchLabel = this.buildSearchLabel([d.campaignName, d.appName, d.adGroupId, d.campaignId, d.appId]);
                    d.label = d.campaignName;
                    sortedMap.get(cursor)?.push(d);
                });
                return sortedMap;
            },
            extractCampaignList(data) {
                if (!this.isAdGroup) {
                    return;
                }
                const sortedData = this.sortByNameProp(data, 'campaignName');
                const sortedMap = new Map();
                sortedData.forEach((d) => {
                    if (!sortedMap.has(d.campaignId)) {
                        sortedMap.set(d.campaignId, d);
                    }
                });
                return [...sortedMap.keys()].map(k => sortedMap.get(k));
            },
            initChosenData() {
                this.chosenData = [...this.selectedData];
            },
            setChosenData() {
                if (this.selectedData) {
                    this.initChosenData();
                }
                if (!this.chosenData?.length) {
                    if (this.isAdGroup) {
                        this.subData = [];
                        this.allData.forEach((adGroup) => {
                            adGroup.key = this.getId(adGroup);
                        });
                    }
                    return;
                }
                let newList = [];
                if (this.isKeyword) {
                    this.chosenData.forEach((c) => {
                        c.id = c.id || c.keywordId;
                        c.key = this.getId(c);
                        c.name = c.keyword;
                        c.searchLabel = c.keyword;
                        c.label = c.keyword;
                        this.checkAndPush(newList, c);
                    });
                    this.chosenDataClone = [...this.chosenData];
                    return;
                } else {
                    this.allData.forEach((d) => {
                        this.chosenData.forEach((c) => {
                            if (typeof c === 'object') {
                                if (this.isApp && `${c.appId}` === `${d.appId}` && `${c.orgId}` === `${d.orgId}`) {
                                    this.checkAndPush(newList, d);
                                } else if (!this.isApp && `${c.id || this.getId(c)}` === `${d.id || this.getId(d)}`) {
                                    this.checkAndPush(newList, d);
                                }
                            } else if (`${c}` === `${d.id}`) { //ChosenData is String
                                this.checkAndPush(newList, d);
                            }
                        });
                    });
                }
                newList.forEach((d) => {
                    d.key = this.getId(d);
                });
                this.chosenData = [];
                this.chosenData = newList;
                if (this.isAdGroup) {
                    this.subData = [...newList];
                }
            },
            async fetch() {
                const orgs = this.orgList?.map(o => o.orgId) || [];
                if (!orgs.length) {
                    this.$message.error(this.$t('messages.errors.youAreNotAuthorizedToThisOperation'));
                    this.allData = [];
                    this.cancel();
                    return;
                }

                const params = {
                    data: orgs,
                    requestLevel: [FILTER_LEVELS.KEYWORD].includes(this.dataLevel[this.type])
                        ? 'CAMPAIGN'
                        : this.dataLevel[this.type],
                    isExperiment: this.isExperiment,
                };
                try {
                    this.allData = await findAllMetadata(params);
                    this.allData = this.sortByNameProp(this.allData, 'name');
                    if (this.hidePausedCampaigns) {
                        this.allData = this.allData.filter(({ status }) => status !== 'PAUSED');
                    }
                    if (this.isIncludeApp) {
                        await this.getApps();
                    }
                    this.setChosenData();
                } catch (e) {
                    this.$log.error('Failed to fetch all metadata data:', e);
                    this.allData = [];
                }
            },
            async getApps() {
                if (Object.keys(this.appInfoMapping)?.length) { // no need to fetch apps if the request already sent
                    return;
                }
                await this.fetchApps();
            },
            async initForGoals() {
                this.allData = this.goals.map(g => Object.assign({
                    appId: g.trackId,
                    name: g.goalName,
                    supName: g.trackId }, g));
                await this.getApps();
            },
            async init() {
                this.selectedType = this.type;
                if (this.isCountry) {
                    this.allData = [];
                    this.regions.flatMap(r => r.countries).forEach((c) => {
                        this.allData.push({ id: c.alpha2, name: c.name });
                    });
                    this.setChosenData();
                }
                if (this.isGoal) {
                    await this.initForGoals();
                } else if (!this.allData?.length) {
                    await this.fetch();
                }
                this.$emit('data-loaded');
            },
            apply() {
                if (this.isApp) {
                    this.chosenData.forEach(c => c.appName = this.setDataName(c));
                }
                let ids = this.chosenData.map(s => this.isCountry ? `'${ s.id }'` : s.id).join();
                this.$emit('data-chosen', ids, this.chosenData);
                this.pVisible = false;
            },
            showRemoveFilterConfirm() {
                this.$confirm({
                    content: this.$t('confirm.filterRemove'),
                    title: this.$t('common.warning'),
                    okText: this.$t('buttons.OK'),
                    cancelText: this.$t('buttons.cancel'),
                    type: 'warning',
                    center: 'true',
                    getContainer: () => this.$refs['metadata-selector-content'],
                    onOk: () => this.apply(),
                    onCancel: () => this.$message.info({
                        content: this.$t('confirm.selectionCanceled'),
                    }),
                });
            },
            showRemoveGoalConfirm() {
                this.$confirm({
                    content: this.$t('confirm.goalRemove'),
                    title: this.$t('common.warning'),
                    okText: this.$t('buttons.OK'),
                    cancelText: this.$t('buttons.cancel'),
                    type: 'warning',
                    getContainer: () => this.$refs['metadata-selector-content'],
                    onOk: () => this.apply(),
                    onCancel: () => this.$message.info({
                        content: this.$t('confirm.selectionCanceled'),
                    }),
                });
            },
            showConfirm() {
                const currencies = [...new Set(this.chosenData.map(c => c.currency))];
                this.chosenData[0].orgId && changeActiveOrgId(this.chosenData[0].orgId);
                if (this.multiCurrencyCheck && currencies.length > 1) {
                    this.$message.warning({
                        key: 'confirm.singleCurrency',
                        content: this.$t('confirm.singleCurrency'),
                    });
                    return;
                }
                if (this.single && this.chosenData.length > 1) {
                    this.$message.warning({
                        key: 'confirm.singleItem',
                        content: this.$t('confirm.singleItem', { type: this.typeKeyLabel }),
                    });
                    return;
                }
                if (this.bulkOperation && (this.isCampaign || this.isAdGroup) && this.chosenData.length > 20) {
                    this.$message.warning({
                        key: 'confirm.bulkOperation',
                        content: this.$t('confirm.bulkOperation', { type: this.typeKeyLabel }),
                    });
                    return;
                }
                if (this.isAccount && this.preGoal && this.preGoal.orgs
                    && this.allContains(this.preGoal.orgs, this.chosenData, 'id')) {
                    this.apply();
                    return;
                }
                if (this.isApp && this.preGoal && this.preGoal.trackId
                    && this.allContains([this.preGoal.trackId], this.chosenData, 'id')) {
                    this.apply();
                    return;
                } else if (this.isApp && this.preGoal && this.preGoal.trackId) {
                    this.showRemoveGoalConfirm();
                    return;
                }
                if (this.isGoal) {
                    let clearGoal = false;
                    const orgs = findOrgsOfApp(this.chosenData[0].appId);
                    this.preFilters.forEach((f) => {
                        const prop = (f.columnProp || f.name);
                        if (prop === 'orgId' && !this.allContains(orgs, f.value.toString().split(','))) {
                            clearGoal = true;
                        } else if (prop === 'appId' && `${ f.value }` !== `${ this.chosenData[0].appId }`) {
                            clearGoal = true;
                        }
                    });
                    if (!clearGoal) {
                        this.apply();
                        return;
                    }
                }
                if (!this.isGoal || this.noGoalFilter || this.hideFilterRemoveWarning) {
                    this.apply();
                    return;
                }
                this.showRemoveFilterConfirm();
            },
            allContains(sourceList, targetList, prop) {
                const source = sourceList || [];
                for (const t of targetList) {
                    if (!source.find(x => `${ x }` === `${ prop ? t[prop] : t }`)) {
                        return false;
                    }
                }
                return true;
            },
        },
        mounted() {
            this.$store.dispatch('account/getGoals');
        },
        watch: {
            visible() {
                this.pVisible = this.visible;
            },
            pVisible() {
                this.$emit('update:visible', this.pVisible);
                if (this.pVisible) {
                    this.filterText = '';
                    this.filterSelectedText = '';
                    this.allData = [];
                    this.loadingData = true;
                    this.init().finally(() => {
                        this.loadingData = false;
                        this.checkChosenAll();
                        if (this.isKeyword && !this.chosenDataClone.length) {
                            this.chosenDataClone = [...this.chosenData];
                        }
                    });
                } else {
                    this.chosenData = [];
                    this.chosenDataClone = [];
                    this.checkedKeys = [];
                    this.expandedKeys = [];
                    this.$emit('selector-closed', true);
                }
            },
            filterText(newVal, oldVal) {
                if (!newVal) {
                    this.autoExpandParent = false;
                    this.expandedKeys = [];
                } else {
                    this.autoExpandParent = true;
                    this.expandedKeys = this.treeKeysFlat.filter(node => node.hasChildren).map(node => node.key);
                }
                this.checkChosenAll(oldVal.length > newVal.length);
            },
        },
        i18n: { messages, sharedMessages },
    };
</script>

<style scoped lang="less">
    .ma-metadata-dialog {
        .ant-modal {
            @media (min-width: 1340px) {
                width: 50%;
                max-width: 500px;
            }
            @media (max-width: 767px) {
                width: 100%;
                max-width: 500px;
            }
            @media (min-width: 768px) {
                width: 80%;
                max-width: 500px;
            }
        }
    }

    .ma-custom-tree-node {
        @apply overflow-hidden flex items-center justify-between w-full;
    }

    .ma-custom-tree-container {
        .ma-tree-table {
            @apply w-full space-x-6;
            :deep(.ant-input) {
                @apply font-normal border;
            }
            :deep(.ant-table) {
                @apply border-none border-b border-t rounded-none;
            }
        }

        .ma-tree-table-2 > div {
            @apply ~'w-1/2' align-top;
        }

        .ma-tree-table-3 > div {
            @apply ~'w-1/3' align-top;
        }

        .ma-block {
            min-height: 250px;
            max-height: 350px;
            padding: 0 3px;
            overflow: auto;
        }

        .ma-sub-block {
            padding: 0;
            max-height: 400px;
        }

        .ma-selected-block {
            max-height: 400px;
        }

        .ma-block-tree {
            padding-top: 10px;
        }

        .ma-tree-table tr td.ma-info-table {
            @apply px-2;
            color: #827d7d;
        }
    }

    .ma-clear-button {
        @apply p-0 m-0 w-6;
    }

    .ma-color-red {
        @apply hover:text-red-600 text-gray-600;
    }

    .ant-model-body {
        padding: 0;
    }

    .ma-limited-sup-col {
        max-width: 80%;
        overflow: hidden;
    }

    .ma-metadata-selector-dialog-body {
        @apply p-0;
    }

    :deep(.ma-selected-app-info) {
        display: flex;
        justify-content: flex-start;

        .ma-app-icon {
            width: 30px;
            height: 30px;
        }
    }
</style>

<style lang="less">
    .ma-metadata-selector-dialog-body {
        @apply rounded-lg;
        .ant-modal-content {
            @apply p-0 ~'rounded-[12px]';
            .ant-modal-header {
                @apply ~'rounded-t-[12px]' border-none ~'pt-[24px]' ~'px-[32px]';
                .ant-modal-title {
                    @apply font-medium ~'text-[18px]' ~'leading-[18px]';
                }
            }
            .ant-modal-body {
                @apply p-0 ~'px-[32px]';

                .ant-tree {
                    .ant-tree-treenode {
                        @apply ~'rounded-[8px]' ~'px-[16px]' ~'py-[8px]' mb-1 hover:bg-blue-25;

                        &.ant-tree-treenode-checkbox-checked {
                            @apply bg-blue-25;
                        }
                        .ant-tree-switcher {
                            @apply hidden;
                        }
                        .ant-tree-node-content-wrapper {
                            @apply hover:bg-transparent;

                            &.ant-tree-node-selected {
                                @apply bg-transparent;
                            }

                            .ma-custom-tree-node--label {
                                @apply truncate max-w-full text-sm font-medium space-x-1 text-gray-900;
                            }
                        }
                    }
                }
            }
            .ant-modal-footer {
                @apply flex items-center justify-between
                    text-gray-500 ~'text-[14px]'
                    ~'rounded-b-[12px]' border-none ~'pb-[24px]' ~'px-[32px]';
            }

            .ma-metadata-selector-section {
                @apply border border-gray-100 ~'rounded-[12px]' px-6 py-4;

                &.ma-metadata-selector-summary {
                    @apply bg-gray-50 border-gray-50;

                    .ma-selected-block {
                        @apply mt-6;
                    }
                    .ant-table-cell {
                        @apply bg-gray-50 px-2;
                    }
                }
                .ant-table-cell {
                    @apply bg-transparent border-0 py-3 px-0 text-sm;

                    .ma-custom-tree-node--label {
                        @apply truncate max-w-full font-medium;
                    }
                }
                thead.ant-table-thead th.ant-table-cell {
                    @apply border-b border-gray-100 font-medium ~'text-[14px]' ~'leading-[20px]' text-gray-500;
                }

                .ma-icon--status.ak-icon--dot {
                    @apply ~'w-[10px]' ~'h-[10px]' ~'min-w-[10px]' ~'min-h-[10px]' mx-2;
                }

                .ma-empty {
                    .ma-empty__description {
                        @apply text-center;
                    }
                }

                .antd-input {
                    @apply text-md;
                }

                .ant-tree-checkbox.ant-tree-checkbox-checked {
                    &:hover, &:after {
                        @apply ~'rounded-[4px]';
                    }
                }
                .ant-tree-checkbox-inner {
                    @apply ~'rounded-[4px]';
                    .ant-tree-checkbox-inner {
                        @apply bg-dark-blue border-dark-blue;
                    }
                }

                .ant-pagination {
                    li.ant-pagination-item {
                        @apply rounded-md border-gray-300 text-[#060B27] mx-1;

                        &.ant-pagination-item-active {
                            @apply bg-black border-black;
                        }
                    }
                    li:not(.ant-pagination-disabled) {
                        a {
                            @apply text-[#060B27];
                        }

                        &.ant-pagination-item-active {
                            a {
                                @apply text-white;
                            }
                        }
                    }
                    .ant-pagination-item-link {
                        .ant-pagination-item-link-icon {
                            @apply text-[#060B27];
                        }
                    }
                }
            }
        }
    }
</style>
