// this MUST be the first import
import { createApp } from 'vue';

export const app = createApp(App);
// this MUST come second
import UiModules from '@/plugins/ui-modules';

// then the others
import { isProduction, MIXPANEL_PROJECT_KEY } from '@/common/Config';
import MaIntercom from '@/common/MaIntercom.mjs';
import MaErrorHandlers from '@/plugins/ErrorHandlers.mjs';
import MaCurrencySymbol from '@/components/global/MaCurrencySymbol.vue';
import MaStatusIcon from '@/components/global/MaStatusIcon.vue';
import MaAppIcon from '@/components/global/MaAppIcon.vue';
import MaClipText from '@/components/global/MaClipText.vue';
import MaNoDataMessage from '@/components/global/MaNoDataMessage.vue';
import MaFlag from '@/components/global/MaFlag.vue';
import MaTableHeader from '@/components/TableHeader.vue';

// intercom
// common global styles
import '@/styles/common.less';
import '@/styles/tailwind-base.css';
import '@/styles/tailwind.css';
import '@/styles/custom-animations.css';
import 'devextreme/dist/css/dx.light.css';
import '@/styles/dx.generic.devextreme-searchads.css';
import Antd from '@/plugins/antd';
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@/plugins/HeadScripts';

import VueSocialSharing from 'vue-social-sharing';
import VueResizeDirective from 'vue-resize-directive';
import { plugin as VueInputAutowidth } from 'vue-input-autowidth';
import FontAwesome from '@/plugins/FontAwesome';


import App from '@/App.vue';
import Mixpanel from '@/plugins/mixpanel';
import pinia from '@/plugins/pinia.mjs';
import i18n from '@/plugins/i18n';
import router from '@/router';
import store from '@/store';
import Sentry from '@/plugins/Sentry';


app.use(UiModules);
app.use(i18n);
app.use(router);
app.use(store);
app.use(pinia);

app.use(VueSocialSharing);
app.use(Antd);
app.directive('resize', VueResizeDirective);
app.use(VueInputAutowidth);
app.use(FontAwesome);

app.use(MaErrorHandlers);
app.use(Mixpanel, {
    key: MIXPANEL_PROJECT_KEY,
    vueRouter: router,
});
app.use(Sentry, {
    router,
});
app.component('ma-currency-symbol', MaCurrencySymbol);
app.component('ma-status-icon', MaStatusIcon);
app.component('ma-app-icon', MaAppIcon);
app.component('ma-clip-text', MaClipText);
app.component('ma-no-data-message', MaNoDataMessage);
app.component('ma-flag', MaFlag);
app.component('ma-table-header', MaTableHeader);

// initialize custom intercom functions
app.use(MaIntercom, { router });

// todo - VUE3 - re-consider exporting globalProperties
export const { globalProperties } = app.config;

app.config.performance = !isProduction;
app.mount('#app');
