{
    "duplicateKeywordSuccess": "There is no conflicting issues, you can safely save keywords now!",
    "explanation": {
        "case_1_1": "You won’t get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.1",
        "case_1_10": "You already have the same keyword on a different ad group <br><br>Issue Code: 1.10",
        "case_1_12": "You already have the same keyword with different match type on a different ad group <br><br>Issue Code: 1.12",
        "case_1_16": "You already have the same keyword on a different campaign <br><br>Issue Code: 1.16",
        "case_1_18": "You already have the same keyword with different match type on a different campaign <br><br>Issue Code: 1.18",
        "case_1_19": "You won't get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.19",
        "case_1_2": "You won't get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.2",
        "case_1_20": "You won't get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.20",
        "case_1_21": "You won't get any impression from the exact search term but you might get impression from other variants <br><br>Issue Code: 1.21",
        "case_1_22": "You won't get any impression from the exact search term but you might get impression from other variants <br><br>Issue Code: 1.22",
        "case_1_23": "You already have the same keyword as a exact keyword with the same bid <br><br>Issue Code: 1.23",
        "case_1_24": "You already have the same keyword with a different bid and different match type <br><br>Issue Code: 1.24",
        "case_1_25": "You already have the same keyword with the same bid and the same match type <br><br>Issue Code: 1.25",
        "case_1_26": "You already have the same keyword with a different bid <br><br>Issue Code: 1.26",
        "case_1_3": "You already have the same keyword with same characteristics (Bid - Exact) <br><br>Issue Code: 1.3",
        "case_1_30": "You already have the same keyword with different match type on a different ad group <br><br>Issue Code: 1.30",
        "case_1_32": "You already have the same keyword on a different ad group <br><br>Issue Code: 1.32",
        "case_1_36": "You already have the same keyword with different match type on a different campaign <br><br>Issue Code: 1.36",
        "case_1_38": "You already have the same keyword on a different campaign <br><br>Issue Code: 1.38",
        "case_1_39": "You won’t get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.39",
        "case_1_4": "You already have the same keyword with same characteristics (Bid - Exact) <br><br>Issue Code: 1.4",
        "case_1_40": "You wont get any impression from the exact search term but you might get impression from close variants <br><br>Issue Code: 1.40",
        "case_1_5": "You already have the same keyword as a broad keyword with the same bid <br><br>Issue Code: 1.5",
        "case_1_6": "You already have the same keyword with a different bid and match type <br><br>Issue Code: 1.6",
        "case_2_1": "Remove the duplicate negative keywords or be aware that it will be ignored if you try to continue adding <br><br>Issue Code: 2.1",
        "case_2_10": "You are trying to add the same negative keyword which exists as broad negative keyword in this ad group <br><br>Issue Code: 2.10",
        "case_2_11": "You are trying to add negative keyword which exists as exact keyword in this ad group <br><br>Issue Code: 2.11",
        "case_2_12": "You are trying to add negative keyword which exists as broad keyword in this ad group <br><br>Issue Code: 2.12",
        "case_2_2": "You are trying to add the same negative keyword with different match type! <br><br>Issue Code: 2.2",
        "case_2_21": "You are trying to add the same negative keyword which exists as exact negative keyword in this campaign, if you continue you wont get any impression from this negative keyword <br><br>Issue Code: 2.21",
        "case_2_22": "You are trying to add the same negative keyword which exists as broad negative keyword in this campaign, if you continue you wont get any impression from this negative keyword <br><br>Issue Code: 2.22",
        "case_2_23": "You are trying to add the same negative keyword which exists as exact keyword in this campaign, if you continue you wont get any impression from this keyword <br><br>Issue Code: 2.23",
        "case_2_24": "You are trying to add the same negative keyword which exists as broad keyword in this campaign, if you continue you wont get any impression from this keyword <br><br>Issue Code: 2.24",
        "case_2_3": "You are trying to add the same negative keyword which exists as exact keyword in this campaign, if you continue you wont get any impression from this negative keyword <br><br>Issue Code: 2.3",
        "case_2_33": "Remove the duplicate negative keywords or be aware that it will be ignored if you try to continue adding <br><br>Issue Code: 2.33",
        "case_2_34": "You are trying to add the same negative keyword which exists as broad negative keyword in this ad group <br><br>Issue Code: 2.34",
        "case_2_35": "You are trying to add negative keyword which exists as exact keyword in this ad group <br><br>Issue Code: 2.35",
        "case_2_36": "You are trying to add negative keyword which exists as broad keyword in this ad group <br><br>Issue Code: 2.36",
        "case_2_4": "You are trying to add the same negative keyword which exists as broad keyword in this campaign, if you continue you wont get any impression from this negative keyword <br><br>Issue Code: 2.4",
        "case_2_9": "Remove the duplicate negative keywords or be aware that it will be ignored if you try to continue adding <br><br>Issue Code: 2.9"
    },
    "issue": "Issue",
    "issueAdGroup": "No keyword | You are adding keywords to Ad Group, check below if it has conflicting issue! | You are adding keywords to {count} different Ad Groups, check below tables if they have conflicting issue!",
    "issueCampaign": "No keyword | You are adding negative keywords to Campaign, check below if it has conflicting issue! | You are adding negative keywords to {count} different Campaigns, check below tables if they have conflicting issue!",
    "issueWarning": "{conflictingKeywordCount} out of your {totalKeywordCount} added keywords has conflicting issues listed below.",
    "keywordIssues": "Keyword Issues",
    "matchTypeConfirmTitle": "Please confirm that match type of this keyword will be set to {type}?",
    "nIssues": "No issue | 1 issue | {n} issues",
    "noConflictDetected": "There is no conflicts detected, saving your keywords...",
    "noConflictingIssue": "No conflicting issue found",
    "noKeywordIssue": "No keyword issue",
    "resolved": "Resolved",
    "solveConflictsBeforeContinue": "Please solve conflicting issues of this keyword before continue to have healthier campaign structure!",
    "statusConfirmTitle": "Please confirm that status of this keyword will be set to {status}?",
    "sureCancelAdding": "Are you sure to cancel adding this keyword?",
    "sureDeleteNegativeKeyword": "Are you sure to delete this negative Keyword?",
    "targetedAdGroup": "Targeted Ad Group",
    "targetedCampaign": "Targeted Campaign",
    "toggleStatus": "Toggle keyword status",
    "updateMatchType": "Update match type"
}
