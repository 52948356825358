{
    "activationEmailResentCompleted": "Activation email was sent successfully.",
    "connect": "Connect",
    "failedToResentTheActivationEmail": "Ups, Failed to resent the activation email.",
    "resend": "Resend",
    "verifyEmail": "Your email is <u>not verified</u>. We have sent you an activation email to proceed",
    "expiredIntegrations": "It seems you Apple Search Ads integrations are expired. Please connect a valid Apple Search Ads account to use SearchAds.com",
    "subscription": "subscription",
    "trial": "trial",
    "trialMessage": "<span class='font-bold'>{days} days </span> left in your <span class='underline'>Search Ads</span> {planType}",
    "tryQuickAutomations": "Try Optimize",
    "quickAutomations": "You can increase your Performance for your Campaigns with just <span class='underline'>one click</span>"
}
