{
    "adGroupNegativeKeywords": "广告组否定关键词仅适用于此广告组。如果希望将否定关键词应用于所有广告组，请在广告系列级别设置它们。",
    "addAllKeywords": "添加页面上的所有关键词",
    "addKeywords": "添加关键词",
    "addNegativeKeywords": "添加否定关键词到广告组",
    "added": "已添加",
    "allKeywordsExcluded": "所有关键词都已除外。没有关键词可以添加。",
    "areYouSureYouWantContinue": "有重复的关键词，你确定要继续吗？",
    "bidAmountFormatIsNotValid": "出价格式无效。",
    "checkKeywordIssues": "检查关键词问题",
    "chooseACampaignGroup": "选择一个广告系列组",
    "chooseAnApp": "选择一个应用程序",
    "chooseNegativeKeywordTarget": "可以将否定关键词添加到广告系列或广告组中。请从下面的框里选择其中一个。",
    "considerGivingCampaign": "请确认在分析广告投放的效果前，有没有给予足够的时间系统学习。添加太多的否定关键词可能会限制你的广告曝光。",
    "failedToGetStatistics": "无法获取错误的详细信息：",
    "makeSureBidAmountFilled": "请确保已填写所有出价金额！",
    "maxCPTBid": "最高每次点击费用出价",
    "negativeKwLevel": "否定关键词级别：",
    "negativeKwsToAdGroup": "否定关键词到广告组",
    "negativeKwsToCampaign": "否定关键词到广告系列",
    "noKeywordAddedList": "没有关键词添加到列表",
    "organicKeywords": "Organic关键词",
    "paidKeywords": "付费关键词",
    "select": "选择",
    "selectTarget": "选择目标",
    "setCustomValuesOnTable": "自定义表上的数值",
    "singleTypeToUpload": "请选择单个{type}以进行上传操作。",
    "theseKeywordsWillNotBeShown": "这些关键词将不会被显示",
    "thisWillProcessConflictingKeywords": "这将处理重复的关键词，你的广告系列可能暂时无法正常运作",
    "tips": "提示",
    "typeToUploadKws": "你要上传的是哪个 {type} 的关键词？",
    "useDefaultMaxCPT": "使用广告组默认最高每次点击费用出价",
    "youreHere": "你在此"
}
