import { IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT,
    REMOVE_NEGATIVE_KEYWORD, PAUSE_KEYWORD } from './actions.mjs';

// key structure            ?:optional
// <ADDs What>-<Insertion Level>-<Duplicate Keyword Position>-<Duplicate Keyword Type>-<Bid Value>?
// debugId: is used for matching case ono excel sheet, displaying case id on tooltip for debug purposes
// see detailed cases: https://docs.google.com/spreadsheets/d/1ZxYhMT9wv34rr3EtMBASFMfkuM4RCu2WjwsJfYTTRvY/edit#gid=630018202

export default {
    'BroadKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Exact': {
        debugId: '1.21',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_21', // explanation translation key
    },
    'BroadKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Broad': {
        debugId: '1.22',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_22',
    },
    'BroadKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact-SameBid': {
        debugId: '1.23',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_23',
    },
    'BroadKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact-DifferentBid': {
        debugId: '1.24',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT, PAUSE_KEYWORD],
        tKey: 'case_1_24',
    },
    'BroadKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad-SameBid': {
        debugId: '1.25',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING],
        tKey: 'case_1_25',
    },
    'BroadKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad-DifferentBid': {
        debugId: '1.26',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT],
        tKey: 'case_1_26',
    },
    'BroadKeyword-AdGroupLevel-DifferentAdGroup-Keyword-Exact-DifferentBid': {
        debugId: '1.30',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_30',
    },
    'BroadKeyword-AdGroupLevel-DifferentAdGroup-Keyword-Broad-DifferentBid': {
        debugId: '1.32',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_32',
    },
    'BroadKeyword-AdGroupLevel-OtherCampaign-Keyword-Exact-DifferentBid': {
        debugId: '1.36',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_36',
    },

    'BroadKeyword-AdGroupLevel-OtherCampaign-Keyword-Broad-DifferentBid': {
        debugId: '1.38',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_38',
    },
    'BroadKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Exact': {
        debugId: '1.39',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_39',
    },
    'BroadKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Broad': {
        debugId: '1.40',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_40',
    },
};
