<template>
    <div class="ma-countdown">
        <slot/>
        <span v-if="showSeconds" class="ma-countdown-seconds">{{ remainingSecs }}s</span>
        <div v-else class="ma-countdown-inner">
            <template v-for="(t, i) in remainingTimeVals" :key="'sep' + i">
                <div v-if="i" class="time-sep">
                    :
                </div>
                <div class="time">
                    <div class="time-value">
                        {{ t.timeValue }}
                    </div>
                    <div class="time-unit">
                        {{ t.timeUnit }}
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    const TIME_IN_SECS = {
        day: 24 * 60 * 60,
        hour: 60 * 60,
        min: 60,
        sec: 1,
    };

    const SEC_IN_MS = 1000;
    export const MaCountdown = {
        name: 'ma-countdown',
        emits: ['time-up', 'update:remainingSecs'],
        props: {
            secs: { type: Number, required: true },
            showSeconds: { type: Boolean, default: false },
        },
        data() {
            return {
                nowMs: Date.now(),
            };
        },
        computed: {
            endTimeMs() {
                return new Date().getTime() + this.secs * SEC_IN_MS;
            },
            remainingSecs() {
                const remainingSecs = ((this.endTimeMs - this.nowMs) / SEC_IN_MS) | 0;
                return remainingSecs <= 0 ? 0 : remainingSecs;
            },
            remainingTimeVals() {
                let diff = this.remainingSecs;
                const vals = [
                    { unit: 'day', val: 0 },
                    { unit: 'hour', val: 0 },
                    { unit: 'min', val: 0 },
                    { unit: 'sec', val: 0 },
                ];
                if (diff <= 0) {
                    return vals;
                }
                vals.forEach((t) => {
                    t.val = (diff / TIME_IN_SECS[t.unit]) | 0;
                    diff -= t.val * TIME_IN_SECS[t.unit];

                    t.timeValue = this.paddedTimeValue(t);
                    t.timeUnit = this.suffixedTimeUnit(t);
                });
                return vals[0].val > 0 ? vals.slice(0, 3) : vals.slice(1, 4);
            },
        },
        methods: {
            paddedTimeValue(t) {
                return t.val > 9 ? t.val : '0' + t.val;
            },
            suffixedTimeUnit(t) {
                return t.val > 1 ? t.unit + 's' : t.unit;
            },
        },
        mounted() {
            setInterval(() => {
                this.nowMs = Date.now();
            }, 500);
        },
        watch: {
            remainingSecs(to, fr) {
                if (fr !== to) {
                    this.$emit('update:remainingSecs', to);
                    if (to <= 0) {
                        this.$emit('time-up');
                    }
                }
            },
        },
    };
    export default MaCountdown;
</script>

<style scoped>
    .ma-countdown {
        @apply w-full;
    }

    .ma-countdown-inner {
        @apply w-full flex justify-evenly;
    }

    .time-value, .time-sep, .ma-countdown-seconds {
        font-size: 1em; /* using em for inheritable sizes */
    }

    .time-unit {
        font-size: 0.4em; /* using em for inheritable sizes */
        overflow: visible;
    }
</style>
