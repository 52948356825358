
export const IO_STANDARD_INVOICE = 'IO_STANDARD_INVOICE';
export const IO_BUDGET_ORDER = 'IO_BUDGET_ORDER';
export const LINE_OF_CREDIT = 'LOC';

export const initialLocInvoiceDetails = {
    clientName: null,
    orderNumber: null,
    buyerName: null,
    buyerEmail: null,
    billingContactEmail: null,
};

export const BILLING_EVENT = {
    TAPS: 'TAPS',
    IMPRESSIONS: 'IMPRESSIONS',
};

export const SUPPLY_SOURCE = {
    APPSTORE_SEARCH_RESULTS: 'APPSTORE_SEARCH_RESULTS',
    APPSTORE_SEARCH_TAB: 'APPSTORE_SEARCH_TAB',
    APPSTORE_TODAY_TAB: 'APPSTORE_TODAY_TAB',
    APPSTORE_PRODUCT_PAGES_BROWSE: 'APPSTORE_PRODUCT_PAGES_BROWSE',
};

export const CREATIVE_TYPE = {
    CREATIVE_SET: 'CREATIVE_SET',
    CUSTOM_PRODUCT_PAGE: 'CUSTOM_PRODUCT_PAGE',
};

export const isCampaignSearchTab = (campaign) => {
    return campaign?.supplySources?.includes(SUPPLY_SOURCE.APPSTORE_SEARCH_TAB);
};
export const isCampaignTodayTab = (campaign) => {
    return campaign?.supplySources?.includes(SUPPLY_SOURCE.APPSTORE_TODAY_TAB);
};
export const isCampaignProductPage = (campaign) => {
    return campaign?.supplySources?.includes(SUPPLY_SOURCE.APPSTORE_PRODUCT_PAGES_BROWSE);
};
export const isCampaignSearchResults = (campaign) => {
    return campaign?.supplySources?.includes(SUPPLY_SOURCE.APPSTORE_SEARCH_RESULTS);
};

export const isAdGroupSearchTab = (adGroup) => {
    return adGroup?.supplySources?.includes(SUPPLY_SOURCE.APPSTORE_SEARCH_TAB);
};

export const AD_CHANNEL_TYPE = {
    SEARCH: 'SEARCH',
    DISPLAY: 'DISPLAY',
};

export const PRICING_MODEL = {
    CPC: 'CPC',
    CPM: 'CPM',
};

export const isImageVertical = (imageUrl) => {
    const [dimensions] = imageUrl?.match(/(?:[0-9]+x[0-9]+)\.[a-z]{0,3}$/) || [];
    const [width, height] = dimensions?.split('.')?.[0]?.split('x') || [];
    return Number(width) < Number(height);
};

export const campaignSearchTabFormatter = (data) => {
    if (data?.length) {
        // format campaign data
        data = [...data].map(campaign => ({
            isCampaignSearchTab: isCampaignSearchTab(campaign),
            ...campaign,
        }));
    }
    return data;
};

export const adGroupSearchTabFormatter = (data) => {
    if (data?.length) {
        // format ad group data
        data = [...data].map(adGroup => ({
            isAdGroupSearchTab: isAdGroupSearchTab(adGroup),
            ...adGroup,
        }));
    }
    return data;
};

/**
 * formats request level data adds a field isCampaignSearchTab or isAdGroupSearchTab right after it's fetched
 *
 * @param requestLevel
 * @returns list of data
 */
export const searchTabFormatter = (requestLevel) => {
    return (wrappedData) => {
        // searchTabFormatter is used by either simple data array or pagination data
        // pagination data is like [{ tableData: [], pagination: {} }]
        // we should first check with which data we're working on
        const paginatedData = Object.keys(wrappedData[0] || {}).includes('tableData');
        let data = wrappedData;
        if (paginatedData) {
            data = [...(wrappedData[0]?.tableData || [])];
        }
        if (data?.length) {
            // format campaign level data
            if (requestLevel === 'CAMPAIGN') {
                data = data.map(campaign => ({
                    isCampaignSearchTab: isCampaignSearchTab(campaign),
                    ...campaign,
                }));
            }

            // format adgroup level data
            if (requestLevel === 'AD_GROUP') {
                data = data.map(adGroup => ({
                    isAdGroupSearchTab: isAdGroupSearchTab(adGroup),
                    ...adGroup,
                }));
            }
        }
        // set data according to its type[pagination or not]
        if (paginatedData) {
            wrappedData[0].tableData = [...data];
        } else {
            wrappedData = [...data];
        }
        return wrappedData;
    };
};
