import { computed } from 'vue';
import { useLabelsStore } from '@/pinia/Labels';
import { storeToRefs } from 'pinia';
import { useFilterPresetStore } from '@/pinia/FilterPreset';
import { useColumnPresetStore } from '@/pinia/ColumnPreset';
import { usePlanStore } from '@/pinia/Plan';

const LABELS_LIMIT = 3;
const COLUMN_PRESETS_LIMIT = 3;
const CUSTOM_COLUMNS_LIMIT = 3;
const FILTER_PRESETS_LIMIT = 3;

export const useLimitedAdsManager = () => {
    const planStore = usePlanStore();
    const filtersStore = useFilterPresetStore();
    const { filterPresetsList } = storeToRefs(filtersStore);
    const userHasLimitedAccess = computed(() => {
        return !planStore.hasAdsManagerAccess;
    });

    const labelsStore = useLabelsStore();
    const { labelsList } = storeToRefs(labelsStore);
    const remainingLabelCreationLimit = computed(() => {
        const userLabels = labelsList.value?.filter(l => !l.campaignType) || [];
        if (userLabels?.length >= LABELS_LIMIT) {
            return 0;
        }
        return LABELS_LIMIT - (userLabels?.length || 0);
    });

    const columnPresetStore = useColumnPresetStore();
    const { customColumns, presets } = storeToRefs(columnPresetStore);
    const remainingColumnPresetsLimit = computed(() => {
        const columnPresets = presets.value || [];
        if (columnPresets?.length >= COLUMN_PRESETS_LIMIT) {
            return 0;
        }
        return COLUMN_PRESETS_LIMIT - (columnPresets?.length || 0);
    });

    const remainingCustomColumnsLimit = computed(() => {
        if (customColumns.value?.length >= CUSTOM_COLUMNS_LIMIT) {
            return 0;
        }
        return CUSTOM_COLUMNS_LIMIT - (customColumns.value?.length || 0);
    });

    const remainingFilterPresetsLimit = computed(() => {
        if (filterPresetsList.value?.length >= FILTER_PRESETS_LIMIT) {
            return 0;
        }
        return FILTER_PRESETS_LIMIT - (filterPresetsList.value?.length || 0);
    });

    return {
        userHasLimitedAccess,
        remainingLabelCreationLimit,
        remainingColumnPresetsLimit,
        remainingCustomColumnsLimit,
        remainingFilterPresetsLimit,
    };
};
