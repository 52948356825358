import mActions from './actions';
import initMixpanel from './init';
import { accessPropertyOption } from '@/plugins/mixpanel/eventDefinitions';
import { REQUIRED_FEATURES } from '@/common/MaSmartBiddingUtils';

export default {
    install(app, { key, vueRouter }) {
        if (!initMixpanel(key)) {
            app.config.globalProperties.$log.error('[Mixpanel] Mixpanel couldn\'t initialized!');
            return;
        }

        app.config.globalProperties.$mixpanel = mActions;

        vueRouter.beforeEach((to, from, next) => {
            if (to.path !== from.path) {
                const { path: Page } = to;
                const mixpanelPageViewEvent = mActions.e.pageView;
                mixpanelPageViewEvent.setPropertyValues({ Page: Page });
                mixpanelPageViewEvent.setPropertyValues({ Access: pageAccessProperty(to) });
                mActions.track(...mixpanelPageViewEvent.toMixpanel());
            }
            next();
        });
        const pageAccessProperty = (to) => {
            let requiredFeature = to.meta.feature;
            if (to.name === 'smart-bidding' && REQUIRED_FEATURES[to.params.tab]) {
                requiredFeature = REQUIRED_FEATURES[to.params.tab];
            }
            if (!requiredFeature) {
                return accessPropertyOption.GRANTED;
            }
            const validFeatures = app.config?.globalProperties?.$maSessionStorage?.validFeatures;
            return validFeatures.includes(requiredFeature) ? accessPropertyOption.GRANTED : accessPropertyOption.DENIED;
        };
    },
};
