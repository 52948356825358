{
    "add": "Add",
    "addNewNotifier": "Add new notifier",
    "addNewChannel": "Add new Slack channel",
    "addLabel": "Add Label",
    "selectAll": "Select All",
    "selectedLabels": "Selected label(s)",
    "addFirstLabel": "Add your first label here",
    "addNewLabel": "Add new label",
    "cancel": "No, cancel",
    "clickToRemove": "Click to remove",
    "confirmActivateTitle": "Are you sure you want to activate selected automation(s)?",
    "confirmActivateButton": "Yes, activate",
    "confirmDeleteTitle": "Are you sure you want to delete selected automation(s)?",
    "confirmDeleteButton": "Yes, delete",
    "confirmDuplicateTitle": "Are you sure you want to duplicate selected automation(s)?",
    "confirmDuplicateButton": "Yes, duplicate",
    "confirmPauseTitle": "Are you sure you want to pause selected automation(s)?",
    "confirmPauseButton": "Yes, pause",
    "createLabel": "Create label",
    "days": "Days",
    "hours": "Hours",
    "editRuleName": "Edit rule name",
    "editLabel": "Edit label",
    "allLabels": "All Labels",
    "allUsers": "All users",
    "compareOptions": {
        "GREATER_THAN": "Greater than",
        "LESS_THAN": "Less than",
        "IN_RANGE": "In range",
        "NOT_IN_RANGE": "Not in range",
        "EQUALS": "Equals",
        "NOT_EQUALS": "Not equals",
        "CONTAINS": "Contains",
        "NOT_CONTAINS": "Does not contain",
        "EMPTY": "Is empty",
        "NOT_EMPTY": "Is not empty",
        "IN": "in",
        "IS": "is",
        "IS_NOT": "is not"
    },
    "createCustomTimeframe": "Create custom timeframe",
    "disabledCustomTimeFrameTooltip": "These custom time frames cannot be edited as they lead to the malfunction of other algorithms",
    "customTimeframes": "Custom timeframes",
    "dayRangeText": "From {start} – {end} days ago",
    "defaultTimeframes": "Default timeframes",
    "defaultDateRanges": {
        "FROM_START": "From campaign start",
        "CURRENT": "Current price",
        "YESTERDAY": "Yesterday",
        "LAST_HOUR": "Last hour",
        "LAST_DAY": "Last 1 day (Inc. today)",
        "LAST_3_DAY": "Last 3 days (Inc. today)",
        "LAST_WEEK": "Last 7 days (Inc. today)",
        "DURING_LAST_WEEK": "Last Week (Sun. to Mon.)",
        "LAST_14_DAY": "Last 14 days (Inc. today)",
        "THIS_MONTH": "This month",
        "LAST_MONTH": "Last 30 Days (Inc. Today)",
        "LAST_2_MONTH": "Last 60 Days (Inc. Today)",
        "LAST_3_MONTH": "Last 90 Days (Inc. Today)",
        "CUSTOM_HOUR": "Custom Hour"
    },
    "delete": "Delete",
    "duplicate": "Duplicate",
    "hourRangeText": "From {start} – {end} hours ago",
    "integrateSlack": "Integrate Slack",
    "label": "Label",
    "labelNameMustBeUnique": "Label name must be unique",
    "lastNDays": "Last {n} days (including today)",
    "lastNHours": "Last {n} hours (including current hour)",
    "noEmails": "No emails found",
    "pause": "Pause",
    "searchLabel": "Search for a label",
    "selected": "Selected",
    "selectNotifiers": "Select Notifiers",
    "selectTimeframe": "Select timeframe",
    "selectedNotifiers": "Selected Notifier(s)",
    "setActive": "Set active",
    "untitledTimeframe": "Untitled timeframe",
    "today": "Today",
    "youDontHaveSlack": "You don't have Slack integration"
}
