import EventBus from '@/common/EventBus.mjs';
import routes, { LAYOUTS } from './Routes';
import { createRouter, createWebHistory } from 'vue-router';
import { useNavigationStateStore } from '@/pinia/NavigationState';

export const isLayoutAuthentication = () => {
    const route = router.resolve(window.location.pathname);
    return route.meta.layout === LAYOUTS.AUTHENTICATION;
};
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.onError((err) => {
    if (/loading (\w* )?chunk \d* failed./i.test(err.message)) {
        EventBus.bus.$emit(EventBus.c.RELOAD_PAGE, { cause: EventBus.reloadCause.CHUNK_LOAD_ERROR });
    }
});

router.beforeEach(async (to, from, next) => {
    const navigationState = useNavigationStateStore();
    navigationState.emptyNavigationState(to.name);
    next();

    const title = 'Search Ads Dashboard';
    document.title = to.meta?.title
        ? `${to.meta?.title} - ${title}`
        : title;
});

export default router;
