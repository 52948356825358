{
    "buttons": {
        "joinBeta": "Request to join Beta Program",
        "contactSales": "Contact Sales",
        "integrateMMP": "Integrate Your MMP"
    },
    "integrateAttrPartners": "Integrate your attribution partners to track ROI of each campaign & benefit from other features such as Goals and Funnels.",
    "integrationWallStep": {
        "1": {
            "heading": "All-in-one platform",
            "description": "Merge your Apple Search Ads and Attribution Partner (MMP) to track your users’ app journey in a single place!"
        },
        "2": {
            "heading": "Customizable dashboard",
            "description": "Pick the most important KPIs for your campaigns from 30+ alternatives and check desired trends for a more convenient campaign management experience!"
        },
        "3": {
            "heading": "Bulk actions",
            "description": "Take various bulk actions just with a click to save time. Increase/decrease the bid on a bulk of keywords, put them all on pause or create automations."
        },
        "4": {
            "heading": "Automate & Notified",
            "description": "Set up automation rules to optimize your bid amount, freeze campaigns or add negative keywords. Get notified of sudden changes!"
        }
    },
    "linkMMPPartners": "Link your MMP partners to track your entire user acquisition funnel",
    "requestedBeta": "We’ve received your request and will get back to you soon",
    "sendBetaRequest": "Send request to our Client Partner team from the below button",
    "signInWithAppleSearchAds": "Sign In with Apple Search Ads",
    "unlockFullPotential": "Unlock full Apple Search Ads potential",
    "upgradePlan": "Upgrade your plan to access this page",
    "youNeedToIntegrateASA": "You need to integrate Apple Search Ads to unlock",
    "youNeedToIntegrateMMP": "You need to integrate your Attribution partner to unlock"
}
