export const TABLE_COLUMNS = [
    { dataIndex: 'state', width: 75, permanent: true, fixed: 'left' },
    {
        dataIndex: 'name',
        width: 250,
        permanent: true,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: null,
        sortOrder: null,
        sortKey: 'ruleName', //key that BE expects as sortField. If unspecified, dataIndex is used as fallback.
    },
    { dataIndex: 'actions', width: 200, default: true },
    { dataIndex: 'labels', width: 270, align: 'center', default: true },
    { dataIndex: 'supplySources', width: 200 },
    { dataIndex: 'automationLevel', width: 300 },
    { dataIndex: 'conditions', width: 300 },
    { dataIndex: 'schedule', width: 300 },
    { dataIndex: 'emailNotifier', width: 300 },
    { dataIndex: 'goal/reAttrType', width: 250 },
    { dataIndex: 'createDate', width: 200, type: 'date' },
    { dataIndex: 'type', width: 200, isCPModeOnly: true },
    { dataIndex: 'stateReason', width: 200, isCPModeOnly: true },
    {
        dataIndex: 'updateDate',
        width: 170,
        fixed: 'right',
        type: 'date',
        permanent: true,
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'descend',
        sortOrder: 'descend',
        sortKey: 'ruleUpdateDate',
    },
];

export const AUTOMATION_STATE = {
    ACTIVE: 'Active',
    PASSIVE: 'Passive',
};

export const LOGS_TABLE_COLUMNS = [
    { dataIndex: 'time', sorter: true, sortDirections: ['ascend', 'descend'], defaultSortOrder: 'descend', sortOrder: 'descend', width: 100 },
    { dataIndex: 'name' },
    { dataIndex: 'actionType' },
    { dataIndex: 'successfulChanges', align: 'center' },
    { dataIndex: 'warningChanges', align: 'center' },
    { dataIndex: 'errorChanges', align: 'center' },
];

export const getDefaultColumns = () => {
    return TABLE_COLUMNS.filter(c => c.permanent || c.default)
        .map(c => c.dataIndex);
};

export const SORT_ORDER_KEY = {
    ascend: 'ASC',
    descend: 'DESC',
};

