{
    "adGroupNegativeKeywords": "Ad group negative keywords apply to this ad group only. If you want negative keywords to apply to all ad groups, set them at the campaign level.",
    "addAllKeywords": "Add All Keywords on page",
    "addKeywords": "Add Keywords",
    "addNegativeKeywords": "Add Negative Keywords to Ad Group",
    "added": "Added",
    "allKeywordsExcluded": "All keywords are excluded, there is no keyword to be added!",
    "areYouSureYouWantContinue": "Are you sure you want to continue conflicting keywords?",
    "bidAmountFormatIsNotValid": "Bid amount format is not valid. Decimal separator is dot (0.00)",
    "changeOrClearFilters": "Please change or clear your filters.",
    "checkKeywordIssues": "Check Issues",
    "chooseACampaignGroup": "Choose A Campaign Group",
    "chooseAnApp": "Choose An App",
    "chooseNegativeKeywordTarget": "Negative keywords can be added to Campaigns Or Ad Groups. Please choose one of them from below box.",
    "clearFilters": "Clear fitlers",
    "considerGivingCampaign": "Consider giving campaigns time to run before analyzing ad performance to learn which keywords may not work for you. Adding too many negative keywords may limit your ad exposure.",
    "filterByKeyword": "Filter by keyword",
    "failedToGetStatistics": "Failed to get statistics with error:",
    "goToKeywordTracking": "Go to Keyword Tracking",
    "makeSureBidAmountFilled": "Please make sure that all bid amounts filled!",
    "maxCPTBid": "Max CPT Bid",
    "negativeKwLevel": "Negative Keyword Level:",
    "negativeKwsToAdGroup": "Negative Keywords To Ad Group",
    "negativeKwsToCampaign": "Negative Keywords To Campaign",
    "noDataForCurrentFilters": "No data found with current filters.",
    "noKeywordAddedList": "No keyword added to list!",
    "noTrackedKeywords": "No keyword is tracked for this app and country.",
    "organicKeywords": "Organic Keywords",
    "paidKeywords": "Paid Keywords",
    "select": "Select",
    "selectTarget": "Select Target",
    "setCustomValuesOnTable": "Set Custom Values On Table",
    "singleTypeToUpload": "Please choose a single {type} to go on upload operation.",
    "theseKeywordsWillNotBeShown": "These keywords will not be shown",
    "thisWillProcessConflictingKeywords": "This will process conflicting keywords, your campaigns may not work properly",
    "tips": "Tips",
    "trackKeywordsForSelected": "To view your tracked keywords list, track keywords for the selected app & country.",
    "typeToUploadKws": "Which {type} do you want to upload keywords?",
    "useDefaultMaxCPT": "Use Ad Group Default Max CPT Bid",
    "youreHere": "You're here"
}
