<template>
    <a-modal
        :open="visible"
        :mask="false"
        :title="$t('createLabel')"
        :ok-text="$t('createLabel')"
        :cancel-text="$t('cancel')"
        :z-index="3000"
        :confirm-loading="loading"
        @cancel="handleClose"
        @ok="handleCreate"
    >
        <div>
            <a-spin :spinning="loading">
                <a-form ref="createLabelForm" :model="form" :rules="rules">
                    <a-form-item
                        name="color"
                        :label="$t('color')"
                    >
                        <ma-color-selector
                            v-model="form.color"
                            size="small"
                            popper-class="ma-color-picker"
                            :predefine="predefineColors"
                        />
                    </a-form-item>
                    <a-form-item
                        name="name"
                        :label="$t('name')"
                    >
                        <a-input
                            v-model:value="form.name"
                            :max-length="100"
                            :placeholder="$t('name')"
                        />
                    </a-form-item>
                    <a-form-item
                        name="description"
                        :label="$t('description')"
                    >
                        <a-input
                            v-model:value="form.description"
                            type="textarea"
                            :placeholder="$t('description_optional')"
                            :max-length="255"
                        />
                    </a-form-item>
                </a-form>
            </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import MetadataLabelUtils from '@/mixins/MetadataLabelUtils.mjs';
    import { addLabel, labels } from '@/common/MaRequests/MetadataLabel';
    import { parseError } from '@/common/MaUtils.mjs';
    import MaColorSelector from '@/components/Selector/MaColorSelector.vue';
    import { useLabelsStore } from '@/pinia/Labels';
    import messages from '@/components/MaMetadataLabel/MaMetadataLabel.i18n';

    export default {
        name: 'ma-create-label-popup',
        components: { MaColorSelector },
        emits: ['created', 'close'],
        mixins: [MetadataLabelUtils],
        props: {
            visible: { type: Boolean },
            existingLabels: { type: Array, required: true },
        },
        data() {
            return {
                form: {
                    id: null,
                    name: '',
                    color: '#409EFF',
                    description: '',
                },
                loading: false,
                labelsStore: useLabelsStore(),
            };
        },
        computed: {
            rules() {
                return {
                    name: [
                        { required: true, message: this.$t('enterALabelName'), trigger: 'change' },
                    ],
                    color: [
                        { required: true, message: this.$t('enterAColor'), trigger: 'change' },
                    ],
                };
            },
            labelNames() {
                return this.existingLabels.map(label => label.name);
            },
        },
        methods: {
            handleClose() {
                this.$emit('close');
            },
            async handleCreate() {
                try {
                    await this.$refs.createLabelForm?.validate();
                } catch (e) {
                    return;
                }
                if (this.form.name.startsWith('@')) {
                    this.$message.error(this.$t('labelNameCannotBeStartedWithAt'));
                    return;
                }
                if (this.labelNames.includes(this.form.name)) {
                    this.$message.error(this.$t('labelNameShouldBeUnique'));
                    return;
                }
                this.createLabel();
            },
            createLabel() {
                this.loading = true;
                addLabel(this.form)
                    .then((data) => {
                        this.$message.success(this.$t('succeedToCreateLabel', { label: this.form.name }));
                        this.getLabelsAndClose(data);
                    })
                    .catch((error) => {
                        this.loading = false;
                        const { displayedMessage } = parseError(error);
                        this.$log.error('Failed to create label:', error);
                        this.$message.error(this.$t('failedToCreateLabel', { msg: displayedMessage }));
                    });
            },

            getLabelsAndClose(createdLabel) {
                labels()
                    .then((data) => {
                        this.labelsStore.updateLabels(data);
                    })
                    .catch(({ errors }) => {
                        this.labelsStore.updateLabels([]);
                        this.$log.error('Failed to fetch labels', errors);
                    })
                    .then(() => {
                        this.loading = false;
                        this.$emit('created', createdLabel.id);
                        this.$refs.createLabelForm.resetFields();
                        this.$emit('close');
                    });
            },
        },
        i18n: { messages },
    };
</script>
