{
    "adGroupNegativeKeywords": "Ad Group의 Negative keyword는 해당 Ad Group에만 적용됩니다. 모든 Ad Group에 Negative Keyword를 적용하려면 캠페인 단계에서 설정해 주세요.",
    "addAllKeywords": "화면 페이지에 노출된 모든 키워드 추가하기",
    "addNegativeKeywords": "Negative Keyword을 Ad Group에 추가하기",
    "added": "추가완료",
    "allKeywordsExcluded": "모든 키워드가 제외됩니다. 추가할 키워드가 없습니다.",
    "chooseAnApp": "앱 선택하기",
    "chooseNegativeKeywordTarget": "Negative keywords는 캠페인이나 Ad group에 추가할 수 있습니다. 아래 상자에서 하나를 선택하세요.",
    "considerGivingCampaign": "캠페인이 일정기간 운영된 후에 광고성과를 분석하는 것이 좋습니다.  Negative Keyword를 너무 많이 추가하면 광고 노출량이 제한될 수 있습니다.",
    "failedToGetStatistics": "오류로 해당수치를 가져오지 못했어요.",
    "makeSureBidAmountFilled": "모든 비딩금액이 채워졌는지 확인해 주세요!",
    "maxCPTBid": "최대 CPT 비딩가",
    "negativeKwLevel": "Negative Keyword 단계:",
    "negativeKwsToAdGroup": "Negative Keywords를 Ad group에 적용",
    "negativeKwsToCampaign": "Negative Keywords를 캠페인에 적용",
    "noKeywordAddedList": "목록에 키워드가 추가되지 않았어요.",
    "organicKeywords": "Organic Keywords",
    "paidKeywords": "Paid Keywords(Bidding)",
    "select": "선택",
    "setCustomValuesOnTable": "사용자 정의 값 설정",
    "singleTypeToUpload": "업로드 작업을 위해 하나의 {type}을 선택하세요.",
    "theseKeywordsWillNotBeShown": "이 키워드는 표시되지 않아요.",
    "thisWillProcessConflictingKeywords": "이렇게 하면 키워드간의 충돌로 캠페인이 제대로 작동하지 않을 수 있습니다.",
    "tips": "Tips",
    "typeToUploadKws": "키워드를 업로드할 {type}을 선택하세요.",
    "useDefaultMaxCPT": "Ad group의 기본 최대 CPT 비딩단가를 사용합니다"
}
