{
    "duplicateKeywordSuccess": "重複は検出されませんでした。キーワードを追加します",
    "explanation": {
        "case_1_1": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.1",
        "case_1_10": "別の広告グループに存在するキーワードです <br><br>Issue Code: 1.10",
        "case_1_12": "別の広告グループに、異なるマッチタイプ（完全一致\/部分一致）で存在するキーワードです <br><br>Issue Code: 1.12",
        "case_1_16": "別のキャンペーンに存在するキーワードです <br><br>Issue Code: 1.16",
        "case_1_18": "別のキャンペーンに、異なるマッチタイプ（完全一致\/部分一致）で存在するキーワードです <br><br>Issue Code: 1.18",
        "case_1_19": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.19",
        "case_1_2": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.2",
        "case_1_20": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.20",
        "case_1_21": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.21",
        "case_1_22": "指定の検索語句と完全に一致するインプレッションは発生しませんが、近似するキーワードでインプレッションが発生する場合があります <br><br>Issue Code: 1.22",
        "case_1_23": "同じキーワード・同じ入札額での重複が検出されました <br><br>Issue Code: 1.23",
        "case_1_24": "同じキーワード・異なる入札額・異なるマッチタイプ（完全一致\/部分一致）での重複が検出されました <br><br>Issue Code: 1.24",
        "case_1_25": "同じキーワード・同じ入札額・同じマッチタイプ（完全一致\/部分一致）での重複が検出されました <br><br>Issue Code: 1.25",
        "case_1_26": "同じキーワード・異なる入札額での重複が検出されました <br><br>Issue Code: 1.26",
        "case_1_3": "同じキーワード・同じ入札・マッチタイプでの重複が検出されました <br><br>Issue Code: 1.3",
        "case_1_30": "同じキーワード・異なるマッチタイプ（完全一致\/部分一致）での重複が、別の広告グループ内で検出されました <br><br>Issue Code: 1.30",
        "case_1_32": "同じキーワードの重複が、別の広告グループ内で検出されました <br><br>Issue Code: 1.32",
        "case_1_36": "同じキーワード・異なるマッチタイプ（完全一致\/部分一致）での重複が、別のキャンペーン内で検出されました <br><br>Issue Code: 1.36",
        "case_1_38": "同じキーワードの重複が、別のキャンペーン内で検出されました <br><br>Issue Code: 1.38",
        "case_1_39": "完全一致での検索語句ではインプレッションが発生しませんが、揺らぎ検索などでインプレッションが発生する場合があります <br><br>Issue Code: 1.39",
        "case_1_4": "同じキーワード・同じ入札・マッチタイプでの重複が検出されました <br><br>Issue Code: 1.4",
        "case_1_40": "正確な検索語からのインプレッションは得られないが、近いバリアントからのインプレッションは得られるかもしれない<br><br> Issue Code：1.40",
        "case_1_5": "すでに同じキーワードを同じ入札額でブロードキーワードとしてお持ちの場合<br><br> Issue Code：1.5",
        "case_1_6": "すでに同じキーワードで異なる入札とマッチタイプを持っている<br><br> Issue Code：1.6",
        "case_2_1": "重複したネガティブキーワードを削除するか、<br><br> Issue Codeを追加し続けようとすると無視されますのでご注意ください：2.1",
        "case_2_10": "この広告グループ（<br><br> Issue Code）にブロードネガティブキーワードとして存在する同じネガティブキーワードを追加しようとしています：2.10",
        "case_2_11": "この広告グループに完全一致キーワードとして存在するネガティブキーワードを追加しようとしています。<br><br> Issue Code：2.11",
        "case_2_12": "この広告グループにブロードキーワードとして存在するネガティブキーワードを追加しようとしています。<br><br> Issue Code：2.12",
        "case_2_2": "同じネガティブキーワードを異なるマッチタイプで追加しようとしています！<br><br>問題コード2.2",
        "case_2_21": "このキャンペーンに完全否定キーワードとして存在する同じ否定キーワードを追加しようとしています。このままでは、この否定キーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.21",
        "case_2_22": "このキャンペーンにブロードネガティブキーワードとして存在する同じネガティブキーワードを追加しようとしています。このままでは、このネガティブキーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.22",
        "case_2_23": "このキャンペーンに完全一致キーワードとして存在する同じネガティブキーワードを追加しようとしています。このままでは、このキーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.23",
        "case_2_24": "このキャンペーンにブロードキーワードとして存在する同じネガティブキーワードを追加しようとしています。このままでは、このキーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.24",
        "case_2_3": "このキャンペーンに完全キーワードとして存在する同じネガティブキーワードを追加しようとしています。このままでは、このネガティブキーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.3",
        "case_2_33": "重複したネガティブキーワードを削除するか、<br><br> Issue Codeを追加し続けようとすると無視されますのでご注意ください：2.33",
        "case_2_34": "この広告グループ（<br><br> Issue Code）にブロードネガティブキーワードとして存在する同じネガティブキーワードを追加しようとしています：2.34",
        "case_2_35": "この広告グループに完全一致キーワードとして存在するネガティブキーワードを追加しようとしています。<br><br> Issue Code：2.35",
        "case_2_36": "この広告グループにブロードキーワードとして存在するネガティブキーワードを追加しようとしています。<br><br> Issue Code：2.36",
        "case_2_4": "このキャンペーンにブロードキーワードとして存在する同じネガティブキーワードを追加しようとしています。このままでは、このネガティブキーワードからインプレッションを得ることはできません。<br><br> Issue Code：2.4",
        "case_2_9": "重複したネガティブキーワードを削除するか、<br><br> Issue Codeを追加し続けようとすると無視されますのでご注意ください：2.9"
    },
    "issue": "問題",
    "issueAdGroup": "キーワードがありません | 広告グループにキーワードを追加します。下記の重複状況を確認してください | {count}個の異なる広告グループにキーワードを追加します。下記の重複状況を確認してください",
    "issueCampaign": "キーワードがありません | 広告グループに除外キーワードを追加します。下記の重複状況を確認してください | {count}個の異なる広告グループに除外キーワードを追加します。下記の重複状況を確認してください",
    "issueWarning": "追加しようとした {length} 個のキーワードのうち {count} 個に重複が確認されました。最終追加前に下記の一覧からチェックしてください。",
    "keywordIssues": "キーワード重複ポイント",
    "matchTypeConfirmTitle": "一致タイプは{type}で正しいですか？",
    "nIssues": "問題なし | 1件の問題 | {n}件の問題",
    "noConflictDetected": "重複は検出されませんでした。キーワードを追加します",
    "noConflictingIssue": "重複が検出されませんでした",
    "noKeywordIssue": "キーワードの問題はありません",
    "resolved": "解決済み",
    "solveConflictsBeforeContinue": "キーワードの重複が検出されました。競合入札を避けるため、各キーワードの重複状況をご確認ください\n",
    "statusConfirmTitle": "キーワードステータスは{status}に変更されます",
    "sureCancelAdding": "このキーワード追加をキャンセルしますか？",
    "sureDeleteNegativeKeyword": "除外キーワードを削除しますか？",
    "targetedAdGroup": "指定された広告グループ",
    "targetedCampaign": "指定されたキャンペーン",
    "toggleStatus": "キーワード状態を変更",
    "updateMatchType": "マッチタイプを更新"
}
