import cn from '@/i18n/root.cn.json';
import en from '@/i18n/root.en.json';
import ja from '@/i18n/root.ja.json';
import ko from '@/i18n/root.ko.json';


export default {
    cn,
    en,
    ja,
    ko,
};
