export const automatedKeywordsOptInTooltip = '<b>Search Match</b>' +
    'Search Match uses multiple resources to automatically match your\n' +
    'ad to relevant searches in the App Store, including the metadata\n' +
    'from your App Store listing, information about similar apps\n' +
    'in the genre and other search data.\n\n' +
    '<b>Tip: Optimize your metadata and imagery.</b>\n' +
    'Search Ads prioritizes relevance when determining which ad to\n' +
    'show so it’s more important than ever that you craft your app\n' +
    'name, imagery, description, and keywords so they’re appealing\n' +
    'and highly relevant. Take the time to review yours. Changes\n' +
    'made to your app’s metadata, imagery, and any other applicable\n' +
    'data contained in iTC may take up to 17 hours to take effect\n' +
    'in Search Ads.';

export const adGroupKeywordsTooltip = '<b>Keyword Targeting</b>' +
    'An optional Search Ads feature that allows you to add keywords\n' +
    'to your campaigns. Keywords are relevant words or phrases someone\n' +
    'may use when searching for an app like yours. You can bid on any\n' +
    'keyword independently to optimize return on investment. Broad match\n' +
    'will automatically include variations such as synonyms and phrases\n' +
    'that include that term, as well as common misspellings and plurals,\n' +
    'so you don\'t need to add those.\n\n' +
    '<b>Tip: Use with Search Match.</b>\n' +
    'Use Search Match even if you choose to add your own keywords.\n' +
    'It’s a great way to discover relevant keywords that are driving\n' +
    'impressions, taps and conversions for your app, so\n' +
    'you don\'t have to think of all the possibilities.\n';

export const franceStorefrontNotice = `Apple and Search Ads users comply with the
French law of 29 January 1993 and of 9 December 2016
known as “Sapin law”. Apple is currently restricting
advertising agents from using Search Ads on the
France App Store. Accordingly, if you are acting as an agent,
you may notundertake a campaign on behalf of, or for
the benefit of, an advertiser customer on the France
App Store if such an advertiser customer is
a “French Company” (i.e. a company that is either registered,
established or otherwise located in France.)`;


export const defaultMaxCPTBidTooltip = `<b>Default Max CPT Bid</b>
This is the maximum amount you’re willing to pay for a tap on your ad.
This bid will apply to all keywords in this ad group unless you apply
an individual keyword bid.

<b>Tip: Start with what you know an average customer is worth.</b>

For example:
If you can afford $2.50 to acquire a customer and estimate 40% of
people who tap your ad will download your app, you could pay up to
 40% of $2.50, or $1.00 per tap.`;

export const cpaGoalTooltip = `<p>CPA Cap</p>
This optional setting specifies the amount you’re willing to spend for conversion and will set a bid ceiling for all keywords in your ad group.<br>
It’s calculated as follows: CPA Cap x Conversion Rate<br>
If the keyword max CPT bid is lower than the result of the above equation, it’ll be applied instead.<br>
Setting a CPA cap is completely optional and limits impressions and conversions.<br>`;

export const invoicingOptionTooltip = `<b>Invoicing Option</b>
<b>Budget Order</b>
This option lets you to set the maximum amount you would like to
spend across multiple campaigns. All campaigns associated with this
budget order appear on the same invoice at the end of the month.
Your campaigns will pause once your budget order is spent or you
reach its end date, so keep a check on spend against your budget
orders to ensure your campaigns continue to run.

<b>Standard Invoice</b>

You will receive an invoice for any spend on this campaign, together
with other campaigns billed under this option. If you choose to
specify an order number, your campaigns will be grouped and invoiced
separately by each unique order number.`;


export const billingContactEmailTooltip = `<b>Billing Contact Email</b>
The person associated with this email account is the one who
receives the Search Ads invoices.`;

export const campaignIncludeKeywords = `<b>Include in Your New Campaign</b>
If your original campaign includes ad group keywords, negative
keywords, and Creative Sets, you can include them in your duplicated
campaign now or add them later.

<b>Please note:</b>

If you don’t include keywords in your new campaign but you have
Search Match turned on for select ad groups, only those ad groups
will serve ads. If Search Match is turned off for all ad groups,
your campaign will not serve ads.`;

export const adGroupIncludeKeywords = `<b>Include in Your New Ad Groups</b>
If your original campaign includes ad group keywords, negative
keywords, and Creative Sets, you can include them in your duplicated
campaign now or add them later.

<b>Please note:</b>

If you don’t include keywords in your new ad groups but you have
Search Match turned on for select ad groups, only those ad groups
will serve ads. If Search Match is turned off for all ad groups,
your campaign will not serve ads.`;

export const duplicateAdSchedule = `<b>Schedule</b>
Pausing your duplicated campaign or delaying its start date will
give you time to modify the campaign and ad group settings before
your ads run.`;

export const duplicateAdGroupOptions = `<b>Options</b>
You can include keywords, negative keywords or Creative Sets.
Only relevant Creative Sets will be duplicated.`;
