<template>
    <span class="ma-flag">
        <i :class="flag"/>
    </span>
</template>

<script>
    export default {
        name: 'ma-flag',
        props: {
            code: { // alpha2 countryCode
                type: String,
                required: true,
            },
        },
        computed: {
            sCode() {
                return this.code
                    ? (this.code + '')
                    : '';
            },
            flag() {
                return [
                    'flag-icon',
                    `flag-icon-${ this.sCode.toLowerCase() }`,
                ];
            },
        },
    };
</script>

<style>
    .ma-flag {
        i.flag-icon {
            @apply rounded-sm shadow-sm;
        }
    }
</style>
