<template>
    <div class="m-10 flex justify-center">
        <div class="mobile:w-full tablet:w-3/4 desktop:w-2/4">
            <div class="flex tablet:flex-row mobile:flex-col-reverse items-baseline">
                <div class="break-normal">
                    <h2 class="font-medium text-2xl text-blue-500">
                        {{ title }} :(
                    </h2>

                    <p class="text-gray-400 my-2">
                        {{ message }}
                    </p>
                    <slot name="action">
                        <a-button class="space-x-2" @click="reloadPage">
                            <fa icon="sync"/>
                            {{ $t('buttons.refresh') }}
                        </a-button>
                    </slot>
                </div>
                <slot v-if="image" name="image">
                    <div class="flex justify-center items-center w-52 h-52">
                        <img :src="image" alt="error_image">
                    </div>
                </slot>
                <fa-layers v-else size="3x" class="w-1/3">
                    <fa
                        class="text-red-500"
                        icon="wifi"
                        size="3x"
                    />
                    <fa
                        class="text-red-600"
                        icon="slash"
                        size="3x"
                    />
                </fa-layers>
            </div>
        </div>
    </div>
</template>

<script>
    import { FETCH_ERROR } from '@/common/ErrorMessages';

    export default {
        name: 'ma-error-with-action',
        props: {
            image: {
                type: String,
                required: false,
                default: '',
            },
            errorReason: {
                type: String,
                required: true,
                validator(val) {
                    return ['FETCH_ERROR'].includes(val);
                },
            },
        },
        data() {
            return {
                allReasons: {
                    FETCH_ERROR,
                },
            };
        },
        computed: {
            title() {
                return this.allReasons[this.errorReason].title || 'Something Went Wrong';
            },
            message() {
                return this.allReasons[this.errorReason].message || 'It seems like there is an error. Please try again with a refresh. If the error persists please contact us.';
            },
        },
        methods: {
            reloadPage() {
                window.location.reload();
            },
        },
    };
</script>
