{
    "addedKeywords": "添加的关键词",
    "addToKeywords": "添加到关键字",
    "addToNegativeKeywords": "添加到否定关键字",
    "dataIsNotReady": "我们仍在获取您当前的数据。完成后，您可以将它们添加到您的列表中。",
    "limitForAdGroupKeywords": "广告组关键字上限为 5000，不能再添加",
    "limitForAdGroupKeywords2": "广告组关键词上限为 5000，超过上限的关键词不会添加到列表中",
    "noKeywordAddedList": "列表中未添加关键词！",
    "popularity": "流行度",
    "runAds": "运行广告",
    "total": "共计"
}
