{
    "ALL_USERS": "全てのユーザー",
    "ALL": "全体",
    "ADDED": "追加",
    "NOT_ADDED": "未追加",
    "ADDED_AND_EXCLUDED": "追加と除外",
    "EXCLUDED": "除外のみ",
    "FEMALE": "女性",
    "IPAD": "iPadのみ",
    "IPHONE": "iPhoneのみ",
    "IPHONE_IPAD": "iPhoneおよびiPad",
    "MALE": "男性",
    "MALE_FEMALE": "全体",
    "NEW_USERS": "新規ユーザー",
    "ONLY_RE_ATTR_TYPE": "リアトリビューションのみ",
    "PAUSED_BY_USER": "ユーザーにより停止",
    "RETURNING_USERS": "復帰ユーザー",
    "TOTAL_BUDGET_EXHAUSTED": "生涯予算を使い果たす",
    "USERS_OF_MY_OTHER_APPS": "自社の別アプリのユーザー",
    "WITHOUT_RE_ATTR_TYPE": "新規のみ",
    "WITH_RE_ATTR_TYPE": "新規+リアトリビューション",
    "adCreativeRejected": "広告クリエイティブ",
    "active": "有効",
    "adGroupStatus": "広告グループのステータス",
    "addCreativeSets": "クリエイティブセットを追加して、新しい広告バリエーションを作る",
    "addLabels": "ラベルを追加",
    "adName": "広告名",
    "addNegativeKeywords": "広告グループに除外キーワードを追加",
    "addSearchTermsToAppear": "キーワードを追加せずにクエリに表示される検索語を追加する",
    "allCampaigns": "全てのキャンペーン",
    "appIcon": "アプリアイコン",
    "appStoreProductPages": "App Storeの商品ページ",
    "appStoreSearchResults": "AppStore 検索マッチ",
    "appStoreSearchTab": "AppStore検索タブ",
    "appStoreTodayTab": "App Store Todayタブ",
    "automationRule": "自動化ルール",
    "bidKeywords": "キーワードに入札することで、関連するApp Storeの検索結果に広告が表示されます。",
    "buttons": {
        "addAutomationRule": "オートメーション・ルールの追加",
        "compare": "比較する",
        "createNewCampaign": "キャンペーンを作成",
        "noCancel": "いいえ、キャンセル",
        "rule": "ルール",
        "yesContinue": "はい、続けます",
        "automationRule": "自動化ルール",
        "cancel": "キャンセル",
        "update": "更新"
    },
    "campaignStatus": "キャンペーン状況",
    "cannotDuplicateCPM": "一部の広告グループにはCPM価格モデルがあり、これらの広告グループを複製することはできません。",
    "cannotDuplicateMoreThanOneAccount": "一度に複数のキャンペーングループを複製することはできません。",
    "categoryLabel": "{category} ( items){n} ",
    "changeGoal": "目標イベント変更",
    "clear": "クリア",
    "clickToSeeRejectReasons": "不合格の理由を見る",
    "collapse": "縮小",
    "compareGoal": "比較するゴールを選択する",
    "compareSelectedItems": "選択したアイテムを比較",
    "compareSelectedItemsInfo": "選択したアイテムをグラフで比較",
    "compareSelectedMetadata": "比較する: {n} 件の {type}",
    "copyAllKeywords": "キーワードをコピー",
    "copyAllKeywordsToOther": "広告グループ内の全てのキーワードを別の広告グループにコピー",
    "copyAllNegativeKeywords": "全ての除外キーワードをコピー",
    "copyAllNegativeKeywordsToOther": "全ての除外キーワードを別の広告グループにコピー",
    "copyKeywordsToOther": "選択されたキーワードを別の広告グループにコピー",
    "copyNegativeKeywordsToOther": "選択されたキーワードを別の広告グループにコピー",
    "copySelectedCount": "データがありません | 選択された{n}件の検索語句をコピー | 選択された{n}件の検索語句をコピー",
    "copySelectedKeywords": "選択したキーワードをコピー",
    "copySelectedNegativeKeywords": "選択されたキーワードをコピー",
    "copySelectedSearchTerms": "選択された検索語句を別の広告グループにキーワードとしてコピー",
    "createAutomationRule": "自動化ルールを作成",
    "createCampaigns": "キャンペーンを作成し、App Storeの検索結果またはApp Storeの検索タブで広告を掲載します。",
    "creativeSetStatus": "Creative Set ステータス",
    "creativeTypes": {
        "CREATIVE_SET": "Creative Set",
        "CUSTOM_PRODUCT_PAGE": "カスタム製品ページ",
        "DEFAULT_PRODUCT_PAGE": "デフォルトの製品ページ"
    },
    "dashboardCurrency": "ダッシュボードの通貨：データは選択した通貨で提供されます。",
    "whoMadeUpdate": "誰がアップデートを行ったのですか？",
    "bidAdjustmentMade": "入札の調整は、カスタマー・サクセス・マネージャーが行います。",
    "budgetAdjustmentMade": "予算調整はカスタマー・サクセス・マネージャーが行います。",
    "automationMadeUpdate": "オートメーションによる更新",
    "clickToView": "クリックして見る",
    "deletedRule": "削除されたオートメーション・ルール",
    "deletingAdsNotProvided": "広告の削除はAppleが提供するものではありません",
    "downloadDailyData": "データ(日次)をダウンロード",
    "downloadKeywords": "キーワードをダウンロード",
    "downloadSOVReport": "シェア・オブ・ボイス レポートをダウンロード",
    "displayStatusColumn": {
        "ACTIVE": "有効",
        "AD_GROUP_ON_HOLD": "保留中の広告グループ",
        "CAMPAIGN_ON_HOLD": "キャンペーンは保留",
        "DELETED": "削除されました",
        "INVALID": "無効",
        "ON_HOLD": "保留",
        "PAUSED": "停止",
        "REMOVED": "削除",
        "RUNNING": "稼働中"
    },
    "duplicateAccount": "複製する",
    "duplicateCampaign": "複製",
    "duplicateCampaignWithAttributes": "すべての属性でキャンペーンを複製する。",
    "duplicateCreativeSet": "Creative Setを複製",
    "editSelectedAdGroup": "選択された広告グループを編集",
    "editSelectedCampaign": "選択されたキャンペーンを編集",
    "editSelectedCreativeSet": "選択されたCreative Setを編集",
    "expand": "拡大する",
    "fileIsNotCorrectOrElse": "データインポートエラー",
    "funnel": "ファネル",
    "goOnWithOld": "旧バージョンへ",
    "goalInfo": {
        "eventName": "イベント",
        "goalId": "目標イベントID",
        "goalName": "目標イベント名",
        "trackId": "アプリ"
    },
    "groupBy": "グループ別",
    "issuesFound": "見つかった問題",
    "keywordMatchTypeUpdated": "キーワードの一致形式が更新されました",
    "labels": "ラベルを追加",
    "lowVolumeTerms": "(少量条件)",
    "ltvData": "LTVデータ",
    "max20": "最大。20",
    "maximizeWindow": "ウィンドウの最大化",
    "messages": {
        "copyMain": {
            "keyword": "キーワード",
            "negative": "除外キーワード",
            "term": "検索語句"
        },
        "copySuccess": {
            "keyword": "キーワードが広告グループにコピーされました",
            "negative": "除外キーワードが新規の広告グループにコピーされました",
            "term": "検索語句が広告グループにコピーされました"
        },
        "duplicatedKeyword": {
            "keyword": "対象のキャンペーン・広告グループに既に追加されたキーワードがあります",
            "negative": "対象のキャンペーン・広告グループに既に追加された除外キーワードがあります",
            "term": "対象のキャンペーン・広告グループに既に追加された検索語句があります"
        },
        "errors": {
            "adGroupBidUpdated": "最大入札額の更新時に予期せぬエラーが発生しました。",
            "dailyBudgetUpdated": "毎日の予算更新で予期せぬエラーが発生しました。{msg}",
            "dataDiscrepancyOrUnexpected": "データインポートエラー",
            "errorType": {
                "adGroupCampaign": "キャンペーンの広告グループ",
                "campaign": "キャンペーン",
                "creativeSet": "Creative Set",
                "negagativeKeywordsCampaign": "キャンペーンの除外キーワード",
                "negativeKeywords": "除外キーワード",
                "targetedKeyword": "対象のキーワード"
            },
            "failedCampaign": "キャンペーンのステータス変更に失敗しました: {msg}",
            "failedDuplicate": "複製に失敗しました: {msg}, 再ログインしても問題が継続する場合、ご連絡ください",
            "failedDuplicatedError": "複製に失敗しました: {0}, with error {1}",
            "failedToFetchChart": "チャート取得がタイムアウトしました。下記のボタンからリトライしてください。",
            "failedToFetchSummary": "サマリの取得がタイムアウトしました。下記のボタンからリトライしてください。",
            "failedToFetchTable": "表データの取得がタイムアウトしました。下記のボタンからリトライしてください。",
            "keywordBidUpdateFailed": "キーワード入札の更新で予期しないエラーが発生しました。",
            "noReportData": "データが見つかりません",
            "totalBudgetUpdated": "生涯予算の更新で予期せぬエラーが発生しました。{msg}",
            "checkPermission": "パーミッションをご確認ください",
            "failedChangeState": "{level}のステータス変更に失敗しました: {msg}",
            "keywordBidUpdated": "キーワード入札の更新時に不明なエラーが発生しました"
        },
        "info": {
            "clickToFilter": "クリックして絞り込む",
            "deleteAdGroup": "これらの広告グループを削除しますか？",
            "deleteAds": "選択した広告を本当に削除しますか？",
            "deleteCampaign": "選択されたキャンペーンを削除しますか?",
            "deleteCreativeSet": "選択したCreative Setを削除しますか？",
            "deleteNegativeKeyword": "選択した除外キーワードを削除しますか？",
            "enterNewBidAmount": "新しい入札額を入力",
            "invalidKeywordBid": "入札の値が無効です",
            "ltvNoFile": "LTVデータをアップロードするには、キャンペーンID、キャンペーン名、LTVの値をそれぞれエクセルで入力してください。アップロード後すぐに編集が反映されますので、送信前によくご確認ください。",
            "ltvUploadError": "キャンペーンIDとLTVデータを数値で入力してください。LTVデータの欄は空欄にしないでください。",
            "ltvUploadSuccessful": "{successfulCount} キャンペーンの LTV データが正常にインポートされました。",
            "noTodayDataYet": "今日のデータはまだ取得されていません。本日のデータを取得するには、トップバーの「更新」ボタンをクリックしてください。",
            "oneAdGroup": "(1度に1件の広告グループまで)",
            "oneCampaign": "(1度に1件のキャンペーンまで)",
            "oneCreativeSet": "(1度に1件のCreative Setまで)",
            "refreshDataRestriction": "Apple Search Ads側で読み取り対象のデータがありません",
            "updateKeywordBids": "選択したキャンペーンの入札を更新",
            "addGoalTabInfo": "このタブを選択すると、目標イベントを切り替えて表示させることができます。目標イベントは「目標イベント設定」のメニューから作成可能です。",
            "dataIsFetching": "データ取得中...",
            "goalTabInfo": "このタブを選択すると、目標イベントを切り替えて表示させることができます。目標イベントは「目標イベント設定」のメニューから作成可能です。",
            "noGoalTabInfo": "計測パートナー数値が転送されていないか、目標イベントが設定されていません"
        },
        "success": {
            "adCreated": "広告の作成に成功",
            "adGroupDuplicated": "広告グループ({msg})が複製されました",
            "campaignDuplicated": "キャンペーン({msg})が複製されました",
            "completedChangeState": "ステータス変更依頼を受けつけました。完了次第、通知されます。",
            "completedChangeStateLater": "変更を受けつけました。反映に時間がかかる場合があります。",
            "completedDeleteItem": "削除依頼を受けつけました。完了次第、通知されます。",
            "completedDeleteItemLater": "削除を受けつけました。反映に時間がかかる場合があります。",
            "deleteItem": "キャンペーンは削除されませんでした | {n}個のキャンペーンを削除しました | {n}個のキャンペーンを削除しました",
            "keywordBids": "選択されたキャンペーンのキーワード入札が更新されました",
            "latestDataCalled": "データ更新のリクエストを受けつけました。この更新には5分以上かかる場合があります。",
            "keywordBidUpdated": "キーワード入札の更新時に不明なエラーが発生しました"
        },
        "warning": {
            "areYouSureToPause": "本当に一時停止するのか？",
            "campaignsAreUnderBudgetAllocation": "選択されたキャンペーンは予算配分中であるため、一時停止アクションは上書きされる可能性があります。キャンペーンを一時停止したい場合は、まず予算配分を停止してから一時停止アクションを実行することをお勧めします。",
            "defaultCreativeSetNotDuplicate": "デフォルトのCreative Setは複製できません",
            "defaultCreativeSetNotEditable": "デフォルトのCreative Setは編集できません",
            "defaultCreativeSetsIgnored": "デフォルトのクリエイティブセットは複製できないため、自動的に無視されます。",
            "fetchingCurrentData": "最新のデータを取得中",
            "fetchingCurrentDataInfo": "最新データを取得中です。完了後に再度ご確認ください。\n",
            "onlyOneSingleAdGroup": "単一の広告グループのみ編集可能です。1つだけ選択してください",
            "onlyOneSingleCampaign": "単一のキャンペーンのみ編集可能です。1つだけ選択してください",
            "onlyOneSingleCreativeSet": "単一のCreative Setのみ編集可能です。1つだけ選択してください",
            "onlySameAppsAtBulkDuplicate": "同じアプリケーションのクリエイティブセットを一括複製できます。",
            "thereIsAlreadyACampaignName": "{name}というキャンペーン名はすでに使用されています。続行するにはキャンペーン名を変更してください。"
        }
    },
    "metricNotAvailable": "この指標はこのレベルでは利用できない",
    "metricNotEventTime": "この指標はイベントタイムビューには適用されない。",
    "minimizeWindow": "ウィンドウの最小化",
    "multiple": "複数",
    "nAdUpdated": "広告の更新なし｜{n} 広告の更新に成功｜{n} 広告の更新に成功",
    "nextSteps": {
        "title": "次のステップ",
        "header": "カスタム商品ページの問題を解決するには、以下の手順に従ってください：",
        "step1": "訪問",
        "step2": "Todayタブ広告に使用するカスタム製品ページの特定された問題に対処します。",
        "step3": "カスタム商品ページを再送信してApp Store Connectで承認を受ける",
        "footer": "カスタム商品ページがApp Store Connectで承認されると、Todayタブ広告の承認のために自動的に再送信されます。広告が承認されると、すぐに広告を掲載できます。",
        "haveQuestion": "質問がありますか？",
        "contactUs": "お問い合わせ"
    },
    "noAccounts": "キャンペーン・グループは表示されない",
    "noAdGroups": "表示する広告グループがない",
    "noAds": "広告を表示しない",
    "noAdsRunningForSearchAds": "Apple Search Adsで広告を掲載しているアプリは見つかりませんでした。",
    "noAttribution": "計測パートナーなし",
    "noAttributionInfo": "まず最初に、アトリビューションをSearchAds Dashboardに統合して、Goalsを実行する必要があります。",
    "noBudgetChange": "このキャンペーンに予算の変更はない",
    "noCampaigns": "表示するキャンペーンがない",
    "noCreativeSets": "クリエイティブ・セットなし",
    "noChange": "このキーワードに変更はありません。",
    "noDataFoundForTimeGranularity": "日単位のデータが見つからない",
    "noGoal": "目標イベントなし",
    "noGoalInfo": "まず最初に、アトリビューションデータを見るためのゴールを作成する必要があります。",
    "noKeywords": "キーワードを表示しない",
    "noMatchingData": "フィルタをクリアしてください！",
    "noSearchTerms": "表示する検索語句がない",
    "nonExistingRow": "存在しないキャンペーン内の広告グループを更新しようとしています。",
    "notEventTakenLately": "最近少なくとも30日間は摂取していない。",
    "notSelected": "未選択",
    "pendingIntegration": "計測パートナーからのイベント受信待ちです。24~48時間程度かかる場合があります",
    "pendingIntegrationWarned": "このアプリの統合後、48時間経ってもインストールやアプリ内イベントが発生しません。",
    "pleaseChooseSingleGoal": "続けるには単一の目標イベントを選択してください",
    "reasonCode": {
        "APP_SCREENSHOTS_REQUIRED": {
            "description": "アプリのスクリーンショットが必要です。",
            "title": "アプリのスクリーンショットが必要"
        },
        "EXCEEDS_CHARACTER_LIMIT": {
            "description": "文字数制限を超えるため、広告がApple検索広告の広告要件に準拠していません。",
            "title": "文字数制限を超える"
        },
        "AD_CONTENT_NOT_COMPLIANT": {
            "description": "広告コンテンツがApple Search Adsの広告要件に準拠していない。",
            "title": "広告コンテンツが準拠していない"
        },
        "PRICING_OFFERS_OR_RANKING_CLAIMS": {
            "description": "アプリのサブタイトルに、価格、オファー、ランキング、その他のインセンティブを伴うプロモーションを含めることはできません。",
            "title": "プライシング・オファーまたはランキング・クレーム"
        },
        "LANGUAGE_CONFLICT": {
            "description": "アプリ名またはサブタイトルの言語は、App Store Connectで選択した言語と一致している必要があります。",
            "title": "言語の衝突"
        },
        "GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "アプリのタイトル、サブタイトル、アイコンに、暴力的、攻撃的、性的、その他不適切な表現は使用できません。",
            "title": "グラフィックまたはアダルトをテーマにしたコンテンツ"
        },
        "NOT_COMPLIANT": {
            "description": "不適合",
            "title": "不適合"
        },
        "INCORRECT_BEZEL": {
            "description": "不適切なベゼル",
            "title": "不適切なベゼル"
        },
        "CONTROLLED_SUBSTANCES": {
            "description": "規制物質",
            "title": "規制物質"
        },
        "POOR_IMAGE_QUALITY": {
            "description": "画質が悪い",
            "title": "画質が悪い"
        },
        "APP_ICON_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "暴力的、攻撃的、性的に露骨、またはその他の不適切な画像は、アプリアイコンでは許可されていません。",
            "title": "アプリのアイコン グラフィックまたはアダルトテーマコンテンツ"
        },
        "APP_ICON_NOT_ELIGIBLE": {
            "description": "アプリアイコンはApple広告ガイドラインに準拠していません。",
            "title": "アプリのアイコンは対象外"
        },
        "APP_NAME_LANGUAGE_CONFLICT": {
            "description": "アプリ名の言語は、App Store Connectで選択した言語と一致している必要があります。",
            "title": "アプリ名 言語の衝突"
        },
        "APP_NAME_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "暴力的、攻撃的、性的、その他不適切な表現をアプリ名に使用することはできません。",
            "title": "アプリ名 Graphic Or Adult テーマコンテンツ"
        },
        "APP_NAME_NOT_ELIGIBLE": {
            "description": "アプリ名がAppleの広告ガイドラインに準拠していない。",
            "title": "アプリ名 対象外"
        },
        "APP_NOT_ELIGIBLE_AT_THIS_TIME": {
            "description": "このアプリはTodayタブでのプロモーション対象外です。",
            "title": "現時点では対象外"
        },
        "MIMICS_APP_STORE_TODAY_CARD": {
            "description": "Game of the DayとApp of the Dayのフレーズは、アプリのアイコン、名前、サブタイトルには使用できません。",
            "title": "App Store Todayカードを模倣"
        },
        "PRODUCT_PAGE_OPTIMIZATION_EXPERIMENT_APP_ICON_NOT_ELIGIBLE": {
            "description": "製品ページ最適化（PPO）実験のアプリアイコンは、Apple広告ガイドラインに準拠していません。",
            "title": "商品ページ最適化実験 アプリアイコンは対象外"
        },
        "SUBTITLE_GRAPHIC_OR_ADULT_THEMED_CONTENT": {
            "description": "アプリのサブタイトルには、暴力的、攻撃的、性的、その他不適切な表現は使用できません。",
            "title": "字幕 グラフィックまたはアダルトをテーマにしたコンテンツ"
        },
        "SUBTITLE_LANGUAGE_CONFLICT": {
            "description": "アプリの字幕の言語は、App Store Connectのプライマリロケールの言語と一致している必要があります。",
            "title": "サブタイトル 言語紛争"
        },
        "SUBTITLE_NOT_ELIGIBLE": {
            "description": "このアプリのサブタイトルは、Apple検索広告の広告要件に準拠していません。",
            "title": "サブタイトル 対象外"
        },
        "SUBTITLE_PRICING_OFFERS_OR_RANKING_CLAIMS": {
            "description": "アプリのサブタイトルに、価格、オファー、ランキング、その他のインセンティブを伴うプロモーションを含めることはできません。",
            "title": "サブタイトル プライシング・オファーあるいはランキング・クレーム"
        }
    },
    "reAttributionInfo": "<b>Re-Attribution<\/b><br> 再帰属データの影響を受けるメトリクスの場合、メトリクスの計算を、再帰属ユーザーのみに対して行うか、新 規に帰属したユーザーのみに対して行うか、または両方のユーザー タイプに対して行うかを選択できます。",
    "result": {
        "fromNAccounts": "{n} キャンペーングループの結果",
        "fromNAdGroups": "{n} 個の広告グループ実績",
        "fromNAds": "{n} 広告の結果",
        "fromNApps": "{n} 個のアプリ実績",
        "fromNCampaigns": "{n} 個のキャンペーン実績",
        "fromNCpps": "{n} CPPの結果",
        "fromNCreativeSets": "{n} 個のCreative Set実績",
        "fromNKeywords": "{n} 個のキーワード実績",
        "fromNSearchTerms": "{n} 個の検索語句実績"
    },
    "quickEdit": {
        "undefined": "不明なエラーがある場合は、当社までお問い合わせください。",
        "quickEditTitle": "クイック編集",
        "reviewChangesOf": "の変更を見直す。",
        "oldValue": "古い価値",
        "newValue": "変更後の値",
        "attribute": "属性",
        "allValues": "すべての価値",
        "replaceWith": "と置き換える",
        "selectOption": "オプション選択",
        "action": "アクション",
        "amount": "量",
        "maxLimit": "上限",
        "use": "用途",
        "message": "メッセージ",
        "status": "ステータス",
        "nameHint": "マクロの前後にマクロを追加\/テキスト化するために使用する。",
        "selectAtLeast": "クイック編集を使用するキャンペーンを少なくとも1つ選択してください。",
        "macroError": "無効な入力です。すべてのマクロが正しく使用されているか確認してください。",
        "selectedItems": "選択された項目",
        "selectedCountries": "国を選択",
        "DAILY_BUDGET": "1日の上限",
        "COUNTRY_OR_REGION": "国または地域",
        "NAME": "ルール名",
        "CPA_GOAL": "目標CPA",
        "DEFAULT_MAX_BID": "デフォルトの最大入札額",
        "DEVICE": "デバイス",
        "GENDER": "性別",
        "CUSTOMER_TYPES": "お客様のタイプ",
        "AGE_RANGE": "年齢の範囲",
        "IPHONE": "iPhone",
        "IPAD": "iPad",
        "AD_GROUP": "広告グループ",
        "CAMPAIGN": "キャンペーン",
        "SET": "に設定する。",
        "INCREASE": "増加",
        "DECREASE": "減少率",
        "ALL_USERS": "全てのユーザー",
        "NEW_USERS": "新規ユーザー",
        "RETURNING_USERS": "復帰ユーザー",
        "OTHER_APP_USERS": "自社の別アプリのユーザー",
        "COUNTRY_NOT_ELIGIBLE_IN_APP": "アプリの対象外国",
        "ACTION_NOT_TAKEN": "行動を起こさない",
        "CPA_NOT_AVAILABLE": "CPA値は利用できません",
        "DUPLICATE_CAMPAIGN_NAME": "すでに同じ名前のキャンペーンがある",
        "INVALID_FORMAT_CAMPAIGN_NAME": "命名形式が無効",
        "USER_LIMIT_ACTION_NOT_TAKEN": "ユーザーによるアクションの制限が行われない",
        "FEMALE": "女性",
        "MALE": "男性",
        "ALL": "全体",
        "campaignName": "キャンペーンの現在の名称",
        "adGroupName": "広告グループの現在の名前",
        "countries": "店頭",
        "deviceClass": "デバイス・タイプ",
        "allMacros": "すべてのマクロ",
        "allDataReviewMessage": "このプレビューは現在のページデータに基づいていることに注意してください。",
        "allDataReviewDescription": "こちらで選択された内容に従って全てのアイテムに反映されます"
    },
    "searchCampaign": "キャンペーンを検索",
    "seeLess": "少ない",
    "seeMore": "もっと見る",
    "selectGoalInfo": "クリックして計測パートナーの数値を表示",
    "selectOneForSOV": "シェア・オブ・ボイス・レポートをダウンロードするには、キャンペーン・グループを1つ選択してください。",
    "servingReasons": {
        "ADGROUP_END_DATE_REACHED": "終了日に達しました",
        "AD_APPROVAL_PENDING": "広告クリエイティブ未定",
        "AD_APPROVAL_REJECTED": "広告クリエイティブが却下された",
        "AD_GROUP_MISSING": "広告グループが見つかりません",
        "AD_GROUP_PAUSED_BY_USER": "ユーザーにより停止",
        "AD_PROCESSING_IN_PROGRESS": "広告処理中",
        "APP_NOT_ELIGIBLE": "アプリが対応していません",
        "APP_NOT_ELIGIBLE_SEARCHADS": "アプリが広告に対応していません",
        "APP_NOT_LINKED_TO_CAMPAIGN_GROUP": "アプリがキャンペーングループにリンクされていません",
        "APP_NOT_PUBLISHED_YET": "アプリがリリースされていません",
        "APP_NOT_SUPPORT": "アプリが対象外です",
        "AUDIENCE_BELOW_THRESHOLD": "オーディエンス下限",
        "BO_END_DATE_REACHED": "予算オーダー日程に達しています",
        "BO_EXHAUSTED": "予算が使い切られています",
        "BO_START_DATE_IN_FUTURE": "予算オーダー日程に達していません",
        "CAMPAIGN_END_DATE_REACHED": "終了日に達しました",
        "CAMPAIGN_NOT_RUNNING": "キャンペーンが稼働していません",
        "CAMPAIGN_START_DATE_IN_FUTURE": "開始日に達していません",
        "CONTENT_PROVIDER_UNLINKED": "コンテンツプロバイダー",
        "CREATIVE_SET_INVALID": "クリエイティブ・セット無効",
        "CREATIVE_SET_UNSUPPORTED": "クリエイティブセット未対応",
        "CREDIT_CARD_DECLINED": "クレジットカードが無効です",
        "DAILY_CAP_EXHAUSTED": "一日の予算を使い果たす",
        "DELETED_BY_USER": "ユーザーにより削除",
        "FEATURE_NOT_YET_AVAILABLE": "まだ入手できない",
        "FEATURE_NO_LONGER_AVAILABLE": "機能は利用できなくなりました",
        "LOC_EXHAUSTED": "Loc exhausted",
        "MISSING_BO_OR_INVOICING_FIELDS": "請求項目の入力が不足しています",
        "NO_AVAILABLE_ADS": "対象広告はありません",
        "NO_AVAILABLE_AD_GROUPS": "広告グループを運営しない",
        "NO_ELIGIBLE_COUNTRIES": "有効な国がありません",
        "NO_PAYMENT_METHOD_ON_FILE": "支払い方法未設定",
        "ORG_CHARGE_BACK_DISPUTED": "Org charge back disputed",
        "ORG_PAYMENT_TYPE_CHANGED": "支払い方法が変更されました",
        "ORG_SUSPENDED_FRAUD": "Org suspended for fraud",
        "ORG_SUSPENDED_POLICY_VIOLATION": "Org suspended for policy violation",
        "PAUSED_BY_SYSTEM": "システムにより停止",
        "PAUSED_BY_USER": "ユーザーにより停止",
        "PRODUCT_PAGE_DELETED": "商品ページ削除",
        "PRODUCT_PAGE_HIDDEN": "製品ページ非表示",
        "PRODUCT_PAGE_INCOMPATIBLE": "製品ページに互換性がない",
        "PRODUCT_PAGE_INSUFFICIENT_ASSETS": "製品ページの資産不足",
        "PENDING_AUDIENCE_VERIFICATION": "オーディエンス有効化を確認中",
        "SAPIN_LAW_AGENT_UNKNOWN": "Unknown Law agent",
        "SAPIN_LAW_FRENCH_BIZ": "Law French BIZ",
        "SAPIN_LAW_FRENCH_BIZ_UNKNOWN": "Unknown Law French BIZ",
        "START_DATE_IN_THE_FUTURE": "開始日に達していません",
        "TAX_VERIFICATION_PENDING": "税額承認待ち",
        "TOTAL_BUDGET_EXHAUSTED": "生涯予算を使い果たす"
    },
    "settingsExcelExp": "一度に編集できるのは最大500件です。編集内容はアップロード後すぐに公開されますので、投稿前によく確認してください。",
    "showFunnel": "ファネルを表示",
    "status": {
        "active": "有効",
        "invalid": "無効",
        "onHold": "保留",
        "paused": "停止",
        "running": "稼働中",
        "startDateNotReached": "開始日に達していません"
    },
    "tableColumn": {
        "NA": "N\/A",
        "appName": "アプリ名",
        "campaign": "キャンペーン",
        "campaignId": "キャンペーンID",
        "editColumn": "カラムを編集",
        "enabled": "有効",
        "activate": "有効化"
    },
    "totalBudget": "生涯予算",
    "totalOfAllAccount": "全キャンペーングループの合計",
    "totalOfAllAdGroups": "全ての広告グループ合計",
    "totalOfAllApp": "全てのアプリ合計",
    "totalOfAllCampaign": "全てのキャンペーン合計",
    "totalValueCopied": "コピーされた総額",
    "uploadData": "データのアップロード",
    "updateKeywordsBids": "入札更新",
    "updateKeywordsBidsSelected": "選択されたキャンペーンのキーワード入札を更新",
    "uploadLTVData": "LTVデータ（XLSXファイル）のアップロード",
    "useAdGroups": "広告グループを使って広告を見る人を定義する",
    "viewReasons": "理由を見る",
    "actions": "アクション",
    "callLatestData": "更新する",
    "dailyBudget": "1日の上限",
    "dashboard": "ダッシュボード",
    "viewInAutomations": "オートメーションで見る",
    "disabled": "無効",
    "downloadData": "データをダウンロード",
    "duplicateAdGroup": "広告グループを複製",
    "goalSelection": "目標イベント設定",
    "lastUpdateTime": "最終更新日時",
    "noApps": "表示するアプリがない",
    "noNegativeKeywords": "ネガティブ・キーワードを表示しない",
    "selectedN": "{n}個が選択されています",
    "trackKeywords": "トラックキーワード",
    "uploadKeywords": "キーワードをアップロード",
    "uploadNegativeKeywords": "除外キーワードをアップロード",
    "aiPoweredRecommendations": "AI搭載キーワード最適化ツール",
    "aiPoweredRecommendationsExp": "AI主導のベストプラクティスを使用して、Apple検索広告のパフォーマンスを自動的に最適化します。",
    "aiPoweredRecommendationsUsage": "キャンペーンの成果を最大限に高めるには、この機能を有効にしておくことをお勧めします。",
    "automationFromAnotherAccount": "このオートメーションは別のSearchAdsアカウントから作成されています。"
}
