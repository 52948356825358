import { defineStore } from 'pinia';
import { getUserApps } from '@/common/MaRequests/User';
import { safeTrackIdEquals } from '@/common/MaUtils.mjs';

export const useAccountStore = defineStore('account', {
    state: () => ({
        allOrgs: [],
        loading: false,
    }),
    getters: {
        hasValidOrganization() {
            return this.allOrgs?.length > 0;
        },
        appIdList() {
            const apps = this.allOrgs.flatMap(d => d.apps);
            return [...new Set(apps.map(d => Number(d.trackId)))];
        },
        apps() {
            const apps = this.allOrgs.flatMap(d => d.apps);
            return this.appIdList
                .map(trackId => ({
                    ...apps.find(app => safeTrackIdEquals(app.trackId, trackId)),
                }));
        },
        appInfoMapping() {
            return this.apps.reduce((res, app) => (res[app.trackId] = app, res), {});
        },
        /*
            Sample object mapping as below
            { trackId => [...OrgIds] };
            {
                995280265: [2955230,2955231,2955232],
                995280266: [2955233],
            }
         */
        appIdOrgIdsMapping() {
            return this.appIdList.reduce((res, trackId) => {
                const orgs = this.allOrgs
                    .map(({ apps, orgId }) => {
                        return apps?.some(app => safeTrackIdEquals(trackId, app.trackId))
                            ? orgId
                            : null;
                    })
                    .filter(org => org);
                return {
                    ...res,
                    [trackId]: orgs,
                };
            }, {});
        },
        /*
            Sample array list as below
            [123123, 123124, ...]
         */
        orgIdList() {
            return [...new Set(this.orgList.map(d => d.orgId))];
        },
        /*
            Sample object mapping as below
            {
                123123: { apps: [], orgName, orgId, currency },
                123124: { apps: [], orgName, orgId, currency },
            }
         */
        orgIdMapping() {
            return this.allOrgs
                .reduce((result, org) => (result[org.orgId] = org, result), {});
        },
        /*
            Sample object mapping as below
            {
                123123: [ ...appList ],
                123124: [ ...appList ],
            }
         */
        orgIdAppMapping() {
            return this.allOrgs
                .reduce((result, org) => (result[org.orgId] = org.apps, result), {});
        },
        orgList() {
            return this.allOrgs
                .map(({ orgId, orgName, currency }) => ({
                    orgId, orgName, currency,
                }));
        },
    },
    actions: {
        /**
         * Fetches all valid organization/account lists from integrated ASAs
         * @returns {array}
         */
        async fetchApps(live = false) {
            this.loading = true;
            try {
                this.allOrgs = await getUserApps(live);
            } catch (e) {
                console.error('Failed to fetch user apps', e);
            } finally {
                this.loading = false;
            }
        },
        /**
         * Find the currency of the organization by orgId
         * It returns null if the organization is not found
         * @param orgId
         * @returns {*}
         */
        findOrgCurrency(orgId) {
            return this.orgList.find(org => org.orgId === orgId)?.currency;
        },
    },
});
