{
    "contactTheTeam": "サポートに連絡",
    "featureList": {
        "1": "プレミアム広告マネージャーの経験がある",
        "2": "自動化ルールを設定してキャンペーン戦略を自動化する",
        "3": "キャンペーン管理を最適化するスマートビッド開始",
        "4": "Benchmarks Reportで最新のApple検索広告業界のベンチマークをご覧ください。"
    },
    "upgradeNow": "今すぐアップグレード",
    "upgradeToStart": "まずはプランをアップグレードしてください。",
    "withUpgradedPlan": "アップグレードプランでは、以下のことが可能です：",
    "upgradePlan": "このページにアクセスするには、プランをアップグレードしてください。"
}
