import cn from '@/i18n/pages/Automations/AutomationComponents.cn.json';
import en from '@/i18n/pages/Automations/AutomationComponents.en.json';
import ja from '@/i18n/pages/Automations/AutomationComponents.ja.json';
import ko from '@/i18n/pages/Automations/AutomationComponents.ko.json';

export default {
    messages: {
        cn,
        en,
        ja,
        ko,
    },
};
