{
    "audit": "キャンペーン診断",
    "optimize": "最適化",
    "campaignWizard": "クイック・キャンペーン・セットアップ",
    "coPilot": "CO-PILOT",
    "coPilotAbb": "CPだ。",
    "hideTheMenu": "メニューを隠す",
    "adsManager": "管理画面",
    "aso": "KPだ。",
    "attribution": "計測パートナー",
    "automations": "自動化ルール",
    "benchmarks": "入札相場",
    "budgetAllocation": "予算配分",
    "collapseMenu": "メニューを縮小",
    "campaignForecasting": "キャンペーン予測",
    "competitionAnalysis": "COMPETITION ANALYSIS",
    "competitionAnalysisAbb": "CAだ。",
    "competitors": "競合アプリ",
    "contactSupport": "サポートへ連絡",
    "credit": "クレジット",
    "expandMenu": "メニューを拡大",
    "funnels": "ファネル",
    "goals": "目標イベント設定",
    "ic": "ICだ。",
    "insightCenter": "INSIGHT CENTER",
    "integrations": "接続",
    "keywordAdvisor": "キーワードアドバイザー",
    "keywordAuctionInsights": "他社出稿ワード検索",
    "cppIntelligence": "CPPインテリジェンス",
    "cppIntelligenceTooltip": "競合他社のカスタム商品ページ戦略を明らかにする、データ主導のソリューションをご覧ください。",
    "keywordPlanner": "キーワードプランナー",
    "ma": "MA",
    "mmp": "計測パートナー",
    "mobileMeasurementPartner": "計測パートナー",
    "optimization": "最適化",
    "organicKeywordsHunt": "オーガニックワード検索",
    "overview": "オーバービュー",
    "quickActions": "クイックアクション",
    "recommendations": "レコメンド",
    "report": "レポート",
    "requestDemo": "デモリクエスト",
    "settings": "設定",
    "setup": "セットアップ",
    "setupAbb": "セット",
    "setupCompleteness": "設定完了度",
    "smartBidding": "全自動入札調整",
    "spotlight": "スポットライト",
    "switchTo": "に切り替える。",
    "tooltips": {
        "adsManager": "キャンペーンの作成及び管理、パフォーマンスの確認のための管理画面です。自動化ルールを設定し、キャンペーンを効率的に運用しましょう。",
        "automations": "自動化ルールを設定し、キャンペーンが常に最適化されるようにします。",
        "spotlight": "スマートツールで自動的にパフォーマンスを向上。",
        "campaignWizard": "たった3ステップでApple検索広告キャンペーンを構築！",
        "keywordAdvisor": "自動的にハイインテントキーワードを見つける。",
        "smartBidding": "適切なキーワードに適切な入札額を設定することで、広告費の無駄を省きます。UAとコスト予測の精度を高める。",
        "audit": "監査で貴社のApple Search Adsアカウントを詳細に分析し、予算と時間を浪費している非効率な部分を発見しましょう。",
        "campaignForecasting": "1日の予算を増やすことで、より多くの広告が表示されるようになります。様々な1日予算を試して、検索結果キャンペーンのインプレッション、タップ数、インストール数を予測しましょう。",
        "organicKeywordsHunt": "自社・他社アプリのオーガニック検索ランキングを確認し、発見したキーワードのボリューム等をチェックしながら、即座に出稿することができます。",
        "goals": "カスタム目標を設定し、正確なROASをトラッキングします。アトリビューション統合を設定し、トラッキングを開始します。"
    },
    "grader": "キャンペーン診断",
    "integration": "アカウント接続",
    "quickAutomation": "クイック・オートメーション",
    "smartToolsAbb": "ST。"
}
