import mixpanel from 'mixpanel-browser';
import { utmMap } from '@/common/MaUtmKeys';
import events from '@/plugins/mixpanel/events';
import { globalProperties as app } from '@/main';

export default {
    requiredPages: [
        'accounts',
        'campaigns',
        'ad-groups',
        'keywords',
    ],
    identify(user) {
        const { maImp, maImpPrefix, jwtData } = app.$store.getters;
        const { name, surname } = user;
        const username = user.username || jwtData.username;
        const userId = maImpPrefix + username;
        const { company, jobFunction: jobTitle } = user.contactInfo || {};
        // identify user
        mixpanel.identify(userId);
        mixpanel.people.set({
            userId,
            $email: username,
            imp: maImp,
            accountId: user.accountId,
            pricingPlan: user.plan,
            name: (name && surname)
                ? `${name} ${surname}`
                : '',
            platform: jwtData?.platform,
            membershipType: jwtData?.membershipType,
            registrationDate: user.createdAt,
            lastLogin: new Date(jwtData.iat * 1000).toISOString(),
            locale: app?.$maLocalStorage?.locale || 'en',
            company,
            jobTitle,
            ...utmMap,
        });
        // set super properties
        mixpanel.register({
            userId,
            accountId: user.accountId,
            $email: username,
        });
    },
    track(...args) {
        mixpanel.track(...args, (response) => {
            if (response === 0) {
                app.$log.error('Mixpanel event is failed to be sent.');
            }
        });
    },
    setUserProperty(...args) {
        mixpanel.people.set(...args, (response) => {
            if (response === 0) {
                app.$log.error('Mixpanel user property failed to update.');
            }
        });
    },
    trackLink(...args) {
        mixpanel.track_links(...args);
    },
    reset() {
        mixpanel.reset();
    },
    isPageRequired(pageRoute) {
        return this.requiredPages.some(page => pageRoute.includes(page));
    },
    trackAuth(isNewUser, userId, type, isExperiment) {
        if (isNewUser) { // track signup
            const { username } = app.$store.getters.jwtData || {};
            if (username) {
                mixpanel.alias(username);
            }
            const mixpanelSignupEvent = this.e.signup;
            mixpanelSignupEvent.setPropertyValues({
                UserId: userId,
                SignupProduct: this.s.sa,
                SignupBy: type?.toUpperCase(),
                Place: (isExperiment ? this.s.experiment : null),
            });
            this.track(...mixpanelSignupEvent.toMixpanel());
        } else { // track login
            const mixpanelLoginEvent = this.e.login;
            mixpanelLoginEvent.setPropertyValues({
                UserId: userId,
                LoginProduct: this.s.sa,
                LoginBy: type?.toUpperCase(),
                Place: (isExperiment ? this.s.experiment : null),
            });
            this.track(...mixpanelLoginEvent.toMixpanel());
        }
    },
    e: events,
    s: { // sample property values
        onboarding: 'SA_ONBOARDING',
        experiment: 'EXPERIMENT',
        quickCampaign: 'QUICK_CAMPAIGN_SETUP',
        integration: 'INTEGRATION',
        keywordAdvisor: 'KEYWORD_ADVISOR',
        quickAutomation: 'QUICK_AUTOMATION',
        budgetAllocation: 'BUDGET_ALLOCATION',
        spotlight: 'SPOTLIGHT',
        sa: 'SA',
    },
};
