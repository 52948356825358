{
    "activationEmailResentCompleted": "メールが送信されました",
    "failedToResentTheActivationEmail": "メールの再送に失敗しました。",
    "resend": "再送",
    "verifyEmail": "あなたの電子メールは<u>認証されていません<\/u> 。続行するためのアクティベーションメールを送信しました。",
    "subscription": "サブスクリプション",
    "trial": "トライアル",
    "trialMessage": "<span class='font-bold'>{days} 日間 残り 検索広告<\/span> <span class='underline'><\/span> {planType}",
    "tryQuickAutomations": "クイック・オートメーションを試す",
    "quickAutomations": "クイック・オートメーション",
    "connect": "接続",
    "expiredIntegrations": "Apple Search Adsとの統合の有効期限が切れているようです。SearchAds.comを使用するには、有効なApple Search Adsアカウントを接続してください。"
}
