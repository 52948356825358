{
    "disabledCustomTimeFrameTooltip": "这些自定义时间框架不能编辑，因为它们会导致其他算法失灵",
    "defaultDateRanges": {
        "CURRENT": "当前价格",
        "FROM_START": "从竞选开始",
        "LAST_HOUR": "过去一小时",
        "LAST_DAY": "最近 1 天（包括今天）",
        "LAST_3_DAY": "最近 3 天（包括今天）",
        "LAST_WEEK": "最近 7 天（包括今天）",
        "DURING_LAST_WEEK": "上周（周日至周一）",
        "LAST_14_DAY": "最近 14 天（包括今天）",
        "THIS_MONTH": "这个月",
        "LAST_MONTH": "最近 30 天（包括今天）",
        "LAST_2_MONTH": "最近 60 天（包括今天）",
        "LAST_3_MONTH": "过去 90 天（包括今天）",
        "CUSTOM_HOUR": "自定义时间"
    },
    "add": "添加",
    "addNewNotifier": "添加新的通知程序",
    "addNewChannel": "添加新的 Slack 频道",
    "addLabel": "添加标签",
    "selectAll": "全部选择",
    "selectedLabels": "所选标签",
    "addFirstLabel": "在此添加第一个标签",
    "addNewLabel": "添加新标签",
    "cancel": "否，取消",
    "clickToRemove": "点击移除",
    "confirmActivateTitle": "您确定要激活所选的自动装置吗？",
    "confirmActivateButton": "是，激活",
    "confirmDeleteTitle": "您确定要删除选定的自动化？",
    "confirmDeleteButton": "是，删除",
    "confirmDuplicateTitle": "您确定要复制选定的自动操作？",
    "confirmDuplicateButton": "是，重复",
    "confirmPauseTitle": "您确定要暂停选定的自动操作吗？",
    "confirmPauseButton": "是，暂停",
    "createLabel": "创建标签",
    "days": "天数",
    "hours": "小时数",
    "editRuleName": "编辑规则名称",
    "editLabel": "编辑标签",
    "allLabels": "所有标签",
    "allUsers": "所有用户",
    "compareOptions": {
        "GREATER_THAN": "大于",
        "LESS_THAN": "小于",
        "IN_RANGE": "在范围内",
        "NOT_IN_RANGE": "不在范围内",
        "EQUALS": "等于",
        "NOT_EQUALS": "不等于",
        "CONTAINS": "包含",
        "NOT_CONTAINS": "不包含",
        "EMPTY": "是空的",
        "NOT_EMPTY": "不为空",
        "IN": "于",
        "IS": "是",
        "IS_NOT": "不是"
    },
    "createCustomTimeframe": "创建自定义时间框架",
    "customTimeframes": "自定义时间框架",
    "dayRangeText": "来自{start} -{end} 天前",
    "defaultTimeframes": "默认时限",
    "delete": "删除",
    "duplicate": "重复",
    "hourRangeText": "来自{start} -{end} 小时前",
    "integrateSlack": "整合 Slack",
    "label": "标签",
    "labelNameMustBeUnique": "标签名称必须唯一",
    "lastNDays": "最近{n} 天（包括今天）",
    "lastNHours": "最近{n} 小时（包括当前小时）",
    "noEmails": "未找到电子邮件",
    "pause": "暂停",
    "searchLabel": "搜索标签",
    "selected": "已选择",
    "selectNotifiers": "选择通知程序",
    "selectTimeframe": "选择时间框架",
    "selectedNotifiers": "选定的通知者",
    "setActive": "设置激活",
    "untitledTimeframe": "无标题时间框架",
    "today": "今天",
    "youDontHaveSlack": "您没有集成 Slack"
}
