import { randomString, MEMBERSHIP_TYPE } from '@/common/MaUtils.mjs';
import { createStore } from 'vuex';
import account from './account';
import dashboard from './dashboard';
import jwtDecode from 'jwt-decode';
import * as MaCookieStorage from '@/common/MaCookieStorage';
import { SB_LIMIT_REMOVED_ACCOUNTS } from '@/common/Config';

export const types = {
    UPDATE_USER: 'UPDATE_USER',
    SET_USER_AUTH: 'SET_USER_AUTH',
    SET_INTEGRATION_ON_BOARDING: 'SET_INTEGRATION_ON_BOARDING',
    SET_FORWARD_TO: 'SET_FORWARD_TO',
};

export default {
    install(app) {
        const store = createStore({
            state: {
                unauthorized: app.config.globalProperties.$maSessionStorage.unauthorized,
                loginCsrf: app.config.globalProperties.$maLocalStorage.loginCsrf,
                userAuth: MaCookieStorage.get(MaCookieStorage.COOKIES.AUTH),
                newsletterOptIn: app.config.globalProperties.$maLocalStorage.newsletterOptIn,
                user: app.config.globalProperties.$maLocalStorage.userInfo,
                integrationOnBoardingCompleted: app.config.globalProperties.$maLocalStorage.integrationOnBoardingCompleted,
                forwardTo: app.config.globalProperties.$maLocalStorage.forwardTo,
            },
            getters: {
                userHasAuth: s => !!s.userAuth,

                integrationOnBoardingCompleted: s => s.integrationOnBoardingCompleted || false,

                jwtData(state) {
                    let jwt = state.userAuth?.jwt;
                    if (!jwt) {
                        return null;
                    }
                    try {
                        return jwtDecode(jwt);
                    } catch (e) {
                        app.config.globalProperties.$log.debug('Failed to parse jwt token', e);
                        return null;
                    }
                },

                rftData(state) {
                    let jwt = state.userAuth?.refreshToken;
                    if (!jwt) {
                        return null;
                    }
                    try {
                        return jwtDecode(jwt);
                    } catch (e) {
                        app.config.globalProperties.$log.debug('Failed to parse refresh token', e);
                        return null;
                    }
                },

                isAdminUser(state, getters) {
                    return getters.jwtData?.membershipType === MEMBERSHIP_TYPE.ADMIN;
                },

                /**
                 * Tries to return imp id, otherwise null
                 * @returns {null|*|null}
                 */
                maImp(state, getters) {
                    return getters.jwtData?.imp || null;
                },

                maImpFullAccess(state, getters) {
                    return (getters.jwtData?.imp && getters.jwtData?.fullAccessImp) || false;
                },

                /**
                 * Determines a username prefix depending on imp
                 * @param state
                 * @param getters
                 * @returns {string|string}
                 */
                maImpPrefix(state, getters) {
                    return getters.maImp ? `MA-admin:${ getters.maImp }:` : '';
                },
                /**
                 * checks if account is in the white list for no limit on smart bidding target
                 * @param state
                 * @param getters
                 * @returns {Boolean}
                 */
                isUserSBLimitRemoved(state, getters) {
                    const limitRemovedAccounts = SB_LIMIT_REMOVED_ACCOUNTS?.split(',');
                    return getters?.jwtData?.accountId && limitRemovedAccounts?.length
                        && limitRemovedAccounts?.includes(`${getters?.jwtData?.accountId}`);
                },
            },
            actions: {
                updateUser({ commit }, user) {
                    app.config.globalProperties.$maLocalStorage.userInfo = user;
                    commit(types.UPDATE_USER, user);
                },
                regenerateLoginCsrf({ state }) {
                    state.loginCsrf = app.config.globalProperties.$maLocalStorage.loginCsrf = randomString(32);
                },
                setUserAuth({ commit }, userAuth) {
                    MaCookieStorage.set(MaCookieStorage.COOKIES.AUTH, userAuth, 30, MaCookieStorage.AUTH_COOKIE_OPTIONS);
                    commit(types.SET_USER_AUTH, MaCookieStorage.get(MaCookieStorage.COOKIES.AUTH));
                },
                setIntegrationOnBoarding({ commit }, completed) {
                    app.config.globalProperties.$maLocalStorage.integrationOnBoardingCompleted = completed;
                    commit(types.SET_INTEGRATION_ON_BOARDING, completed);
                },
                setForwardTo({ commit }, forwardTo) {
                    app.config.globalProperties.$maLocalStorage.forwardTo = forwardTo;
                    commit(types.SET_FORWARD_TO, app.config.globalProperties.$maLocalStorage.forwardTo);
                },
            },
            mutations: {
                [types.UPDATE_USER](s, user) {
                    s.user = user;
                },
                [types.SET_USER_AUTH](s, userAuth) {
                    s.userAuth = userAuth;
                },
                [types.SET_INTEGRATION_ON_BOARDING](s, completed) {
                    s.integrationOnBoardingCompleted = completed;
                },
                [types.SET_FORWARD_TO](s, forwardTo) {
                    s.forwardTo = forwardTo;
                },
                updateUser(state, user) {
                    app.config.globalProperties.$maLocalStorage.userInfo = user;
                    state.user = user;
                },
                updateNewsletterOptIn(state, newsletterOptIn) {
                    state.newsletterOptIn = app.config.globalProperties.$maLocalStorage.newsletterOptIn = newsletterOptIn;
                },
            },
            modules: {
                account: account(app),
                dashboard,
            },
        });
        app.use(store);
    },
};
