<template>
    <div class="ma-auth-section">
        <div class="w-full flex justify-center items-center">
            <router-view/>
        </div>
        <ma-update-checker v-if="!disableUpdateChecker"/>
    </div>
</template>

<script setup>
    import MaUpdateChecker from '@/components/MaUpdateChecker/index.vue';
    import { useRoute } from 'vue-router';
    import { computed, onMounted } from 'vue';
    import { setTrialParameters } from '@/common/MaUtmKeys';

    defineOptions({
        name: 'ma-auth-layout',
    });

    const route = useRoute();
    const disableUpdateChecker = computed(() => route.meta.disableUpdateChecker);

    onMounted(() => setTrialParameters());
</script>

<style>
    body {
        background-color: #f9f9f9;
    }

</style>

<style lang="less" scoped>
    .ma-auth-section {
        @apply flex basis-auto box-border flex-grow min-w-0 h-full bg-no-repeat bg-cover;
        background-image: url('@/assets/auth-bg.png');
        background-image: -webkit-image-set('@/assets/auth-bg.webp' 1x, '@/assets/auth-bg.png' 1x);
        background-image: image-set('@/assets/auth-bg.webp' 1x, '@/assets/auth-bg.png' 1x);

        :deep(.ant-card) {
            width: 550px;
            @apply border-none text-white bg-white bg-opacity-10;

            .ma-auth-footer-action {
                @apply text-white;
            }
        }
    }

    :deep(h4) {
        @apply my-1 mx-0;
    }

    :deep(.sa-auth-page-image) {
        @apply flex items-center justify-center;

        img {
            @apply w-full max-w-xl rounded-tl-lg rounded-bl-lg text-center;
        }
    }

    :deep(.ant-alert) {
        &.ant-alert-error {
            @apply bg-red-200 border-red-300;
            .ant-alert-message,
            .ant-alert-description {
                @apply text-red-900;
            }
        }
        &.ant-alert-success {
            @apply bg-green-200 border-green-300 text-green-900;
            .ant-alert-message,
            .ant-alert-description {
                @apply text-green-900;
            }
        }
        .ant-alert-message,
        .ant-alert-description {
            @apply w-full font-medium ;
        }
    }
    :deep(.ma-auth-footer-action)  {
        @apply block text-center ~"my-2.5";
    }
    :deep(a)  {
        @apply ~"text-[#4a90e2]" no-underline;
    }
</style>
