<template>
    <div class="h-full">
        <ma-banners v-show="!loading"/>
        <div
            id="app"
            v-resize.initial:throttle="onResize"
            class="h-full"
        >
            <ma-sidebar-v2 v-if="!iframed && showMenu && !onBoardingPage"/>
            <div v-if="userHasAuth" id="app-body">
                <sa-topbar v-if="showMenu" :show-actions="userHasCurrentPageAccess"/>
                <div v-show="loading && !isTimeout" class="relative">
                    <ma-loading-indicator/>
                </div>
                <div v-if="isTimeout" class="flex justify-center">
                    <ma-result
                        :title="$t('messages.errors.somethingWentWrong')"
                        :sub-title="$t('messages.errors.timeoutMessage')"
                        status="warning"
                    >
                        <ma-button size="medium" variant="stroke" @click="refreshPage">
                            {{ $t('buttons.reloadPage') }}
                        </ma-button>
                        <ma-button size="medium" @click="checkStatus">
                            {{ $t('buttons.checkStatus') }}
                        </ma-button>
                    </ma-result>
                </div>

                <section
                    v-if="!loading && accountHasProduct"
                    id="page-content"
                    v-resize.initial:throttle="onResizeContent"
                    :class="[$route.meta.className]"
                >
                    <a-alert
                        :message="$t('messages.warnings.mobileScreenWarning')"
                        type="warning"
                        :center="true"
                        :show-icon="true"
                        class="ma-mobile-alert"
                    />
                    <template v-if="statusFetched">
                        <ma-general-wall-template
                            v-if="wallType !== null"
                            :wall-type="wallType"
                            :img-file="imgFileName"
                            :heading-text="heading"
                            :list-items="listItems"
                        />
                        <div v-else-if="isUserNoAccess" class="absolute inset-0 bg-white flex items-center justify-center">
                            <div class="flex items-center flex-col gap-3 relative -top-8">
                                <ma-icon name="lock-bold"/>
                                <ma-typography type="body-2" weight="medium" class="text-gray-900">
                                    {{ $t('common.noSearchAdsAuthorization') }}
                                </ma-typography>
                                <ma-typography type="body-2" weight="medium" class="text-gray-900">
                                    {{ $t('common.contactTeamAdministrator') }}
                                </ma-typography>
                                <img src="~@/assets/squiggly.svg">
                            </div>
                        </div>
                        <!-- if you remove this if then router view will be rendered before account control list loads -->
                        <router-view v-else/>
                    </template>
                </section>

                <ma-error-with-action
                    v-else-if="!loading"
                    error-reason="FETCH_ERROR"
                />
            </div>
            <ma-keyword-cart v-if="cartVisible && userHasAuth && userHasCurrentPageAccess && !wallType"/>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { isProduction } from '@/common/Config';
    import MaFullStory from '@/common/MaFullStory.mjs';
    import * as UserReqs from '@/common/MaRequests/User';
    import MaKeywordCart from '@/components/KeywordCart/KeywordCart.vue';
    import MaSidebarV2 from '@/components/MaSidebar/SidebarV2.vue';
    import SaTopbar from '@/components/Topbar/Topbar.vue';
    import AccountIntegratedCheck from '@/mixins/AccountIntegratedCheck.mjs';
    import { getOrganizationsOfUser } from '@/common/MaRequests/UserPermission';
    import { validOrgList } from '@/common/FilteringUtils';
    import MaHubspot from '@/common/MaHubspot.mjs';
    import MaErrorWithAction from '@/components/MaErrorWithAction.vue';
    import MaBanners from '@/components/banner/MaBanners.vue';
    import { piniaActions, piniaState } from '@/plugins/pinia.mjs';
    import { useAccountStore } from '@/pinia/Account';
    import { initializeBeamer } from '@/plugins/beamer';
    import { useSetupGuideStore } from '@/pinia/SetupGuide';
    import MaGeneralWallTemplate from '@/components/Walls/MaGeneralWallTemplate.vue';
    import WallChecker from '@/mixins/WallChecker.mjs';
    import MaLoadingIndicator from '@/components/MaLoadingIndicator.vue';
    import { MaResult, MaButton, MaTypography, MaIcon } from '@mobileaction/action-kit';
    import { isTimeout } from '@/common/MaUtils.mjs';
    import { USER_ACCESS_TYPE } from '@/pages/Settings/composables/useTeamMemberOptions';
    import { usePlanStore } from '@/pinia/Plan';

    const STATUS_PAGE = 'https://status.mobileaction.co';

    export default {
        name: 'ma-default-layout',
        components: {
            MaIcon,
            MaTypography,
            MaLoadingIndicator,
            MaGeneralWallTemplate,
            MaSidebarV2,
            SaTopbar,
            MaKeywordCart,
            MaBanners,
            MaErrorWithAction,
            MaResult,
            MaButton,
        },
        mixins: [AccountIntegratedCheck, WallChecker],
        data() {
            return {
                isLoading: false,
                loadingRequests: true, // user, integrations, organizations
                isTimeout: false,
                loadingIntegrations: false,
                cartPages: ['ad-intelligence', 'keyword-hunt', 'keyword-advisor', 'keyword-auction-insights', 'search-by-app', 'keyword-gap'],
                fetchedUserAndIntegration: false,
                showCart: false,
                cartCanBeShown: true,
                user: null,
                resizeFlag: false,
            };
        },
        computed: {
            ...mapGetters(['userHasAuth']),
            ...mapGetters('account', ['integrations', 'activeOrg', 'isUserNoAccess']),
            ...piniaState(usePlanStore, ['validFeatures', 'isPlanFetched', 'accountHasProduct', 'hasAdsManagerAccess', 'isSearchAdsFreeUser', 'isSearchAdsUser', 'hasAccessSearchAdsTools']),
            ...mapState('dashboard', ['isLoadedFirstTime']),
            ...piniaState(useAccountStore, {
                campaignGroupApps: 'apps',
            }),
            ...piniaState(useSetupGuideStore, {
                statusFetched: 'statusFetched',
                onboardingStatus: 'onboardingStatus',
            }),
            loading() {
                return this.loadingRequests || !this.isPlanFetched;
            },
            hasIntegration() {
                return !!Object.keys(this.integrations || {}).length;
            },
            showMenu() {
                return !['invoice'].includes(this.$route.name) && !this.isOnboardingExperiment && !this.onBoardingPage;
            },
            iframed() {
                return window.top.location !== window.location;
            },
            cartVisible() {
                return this.showCart && this.cartCanBeShown;
            },
            layout() {
                return this.$route && this.$route.meta.layout;
            },
            onBoardingPage() {
                return ['onboarding', 'sa-integ-callback', 'experiments-onboarding'].includes(this.$route.name);
            },
            isOnboardingExperiment() {
                return ['experiments-onboarding'].includes(this.$route.name);
            },
            integrationFetchingCompleted() {
                if (!this.accountIntegrated) {
                    return false;
                }
                //One of integrations is completed then there is some reports to show.
                const keys = Object.keys(this.integrations);
                for (let i = 0; i < keys.length; i++) {
                    if (this.integrations[keys[i]].every(o => o.fetchCompleted)) {
                        return true;
                    }
                }
                return false;
            },
            isCartPage() {
                return this.cartPages.includes(this.$route.name);
            },
            noIntegrations() {
                return !Object.keys(this.integrations).length;
            },
        },
        methods: {
            ...mapActions('account', ['updateIntegrations']),
            ...mapActions('dashboard', ['changeFirstTime']),
            ...mapActions(['setIntegrationOnBoarding']),
            ...piniaActions(usePlanStore, {
                updateProducts: 'updateProducts',
            }),
            ...piniaActions(useAccountStore, {
                fetchCampaignGroupApps: 'fetchApps',
            }),
            ...piniaActions(useSetupGuideStore, {
                fetchOnboardingStatus: 'fetchOnboardingStatus',
            }),
            refreshPage() {
                window.location.reload();
            },
            checkStatus() {
                window.open(STATUS_PAGE, '_blank');
            },
            onResize(el) {
                this.cartCanBeShown = el.offsetWidth > 768;
            },
            onResizeContent() {
                this.resizeFlag = !this.resizeFlag;
            },
            initialiseWootric(user) {
                const script = document.createElement('script');

                script.type = 'text/javascript';
                script.async = true;
                script.onload = () => {
                    // remote script has loaded
                    window.wootricSettings = {
                        email: user.username,
                        // external_id: 'abc123',
                        created_at: user.createdAt,
                        account_token: 'NPS-396c2da6', // This is your unique account token.
                        product_name: 'searchads',
                        properties: {
                            product_name: 'searchads',
                        },
                    };
                    if (user.productMap) {
                        Object.entries(user.productMap).forEach(([k, v]) => {
                            window.wootricSettings.properties['p_' + k] = v;
                        });
                    }
                    window.wootric('run');
                };
                script.src = 'https://cdn.wootric.com/wootric-sdk.js';
                document.getElementsByTagName('head')[0].appendChild(script);
            },
            openDialog() {
                this.dialogVisible = true;
            },
            showIntercom() {
                this.$maIntercom.show();
            },
            fetchIntegrations() {
                return UserReqs.getIntegrations()
                    .then((integrations) => {
                        this.updateIntegrations(integrations);
                        return integrations;
                    })
                    .catch((e) => {
                        const { errors } = e;
                        if (errors && errors.some(e => e === 'USER_NOT_FOUND')) {
                            this.updateIntegrations(null);
                        } else {
                            this.$log.error('Failed to get integrations:', e);
                        }
                        throw e;
                    });
            },
            checkOnBoarding() {
                //temporarily disable redirect to onboarding
                // if (!this.onBoardingPage && this.noIntegrations) {
                //     this.$router.replace({ name: 'onboarding' }).catch(this.$error.suppressDuplicateNavigation);
                // }

                // If user doesnt't have a valid integration they should be redirected to integrations page
                if (!this.accountIntegrated) {
                    this.$router.replace({ name: 'integration' });
                }
                this.setIntegrationOnBoarding(this.integrationFetchingCompleted);
            },
            fetchUser() {
                return new Promise ((resolve, reject) => {
                    UserReqs.getUserInfo()
                        .then((user) => {
                            if (!user) {
                                this.$log.error('Received empty user info');
                                return;
                            }
                            this.$maLocalStorage.userInfo = user;
                            this.$store.dispatch('updateUser', user).catch(this.$log.error);
                            this.user = user;
                            try { // catch in any case so other functions do not fail
                                // if imp id exists use test intercom otherwise use normal intercom
                                const appId = !isProduction ? 'pkyyne4k' : 'o1c6jpdh';
                                let { name, surname } = user.contactInfo;

                                name = name && surname ? `${name} ${surname}` : '';

                                this.$maIntercom.boot({
                                    name,
                                    email: user.username,
                                    created_at: user.createdAt,
                                    user_id: `${user.userId}`,
                                    user_hash: user.intercom[appId]?.userHash,
                                    app_id: appId,
                                });
                                this.$mixpanel.identify(user);
                                this.$sentry?.setUser(user);
                                // avoid only mobileaction.co accounts to record FS sessions
                                // this will enable impersonations to record
                                if (!user.username.endsWith('mobileaction.co')) {
                                    MaFullStory.initialize(user);
                                    this.initialiseWootric(user);
                                    initializeBeamer(user);
                                }
                            } catch (e) {
                                this.$log.error('Failed to initialize some tools', e);
                            }
                            resolve();
                        })
                        .catch((e) => {
                            this.$log.error('Failed to get user info:', e?.errors);
                            reject(e);
                        });
                });
            },
            fetchUserOrganizations() {
                return new Promise ((resolve, reject) => {
                    const user = this.user || this.$store.state.user;
                    if (!user?.userId) {
                        return;
                    }
                    getOrganizationsOfUser()
                        .then((data) => {
                            const orgs = validOrgList();
                            this.$store.dispatch('account/updateUserAccessType', data.userAccessType);
                            if (user.admin || data.userAccessType === USER_ACCESS_TYPE.ALL_ACCESS) {
                                this.$store.dispatch('account/updateUserOrgs', orgs);
                            } else if (data.userAccessType === USER_ACCESS_TYPE.NO_ACCESS) { //null means no-org on default state
                                this.$store.dispatch('account/updateUserOrgs', []);
                            } else { //limited access
                                const filteredData = orgs.filter(o => data.organizationList?.includes(Number(o.orgId)));
                                this.$store.dispatch('account/updateUserOrgs', filteredData);
                            }
                            resolve();
                        })
                        .catch((e) => {
                            this.$log.error('Failed to fetch user organizations', e?.errors);
                            reject(e);
                        });
                });
            },
            async fetchUserProducts() {
                try {
                    await this.updateProducts(true);
                } catch (e) {
                    this.$log.error('Failed to update products:', e);
                }
            },
            /*
            * Fetches both user, integrations and finally organizations
            * - united three requests to show a common loading screen and prevent content components from mounting multiple times
             */
            async fetchUserAndApps() {
                this.fetchedUserAndIntegration = false;
                this.loadingRequests = true;
                this.isTimeout = false;
                try {
                    await Promise.all([this.fetchUser(), this.fetchCampaignGroupApps(), this.fetchIntegrations()]);
                    this.fetchedUserAndIntegration = true;
                    // wait integrations to populate then fetch organizations
                    await this.fetchUserOrganizations();
                    if (!this.isPlanFetched) {
                        await this.fetchUserProducts();
                    }
                } catch (e) {
                    if (isTimeout(e)) {
                        this.isTimeout = true;
                    }
                } finally {
                    this.loadingRequests = false;
                    if (this.user) {
                        this.checkOnBoarding();
                    }
                }
            },
        },
        mounted() {
            this.showCart = this.isCartPage;

            this.fetchUserAndApps();
            this.fetchOnboardingStatus();
        },

        watch: {
            $route() {
                this.showCart = this.activeOrg !== null && this.isCartPage;
                MaHubspot.trackPageView(this.$route.fullPath);
            },
            user() {
                MaHubspot.identifyVisitor(this.user);
            },
            integrations(fr, to) {
                const frStr = fr && JSON.stringify(fr);
                const toStr = to && JSON.stringify(to);
                if (this.hasIntegration && frStr !== toStr) {
                    this.fetchUserOrganizations();
                }
            },
            fetchedUserAndIntegration() {
                if (this.fetchedUserAndIntegration) {
                    this.$nextTick(() => {
                        this.fetchUserOrganizations();
                    });
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .ma-height-with-banner {
        height: calc(100% - 2.5rem);
    }

    body {
        #app {
            display: flex;
            flex-direction: row;
        }
    }

    #app-body {
        width: 73%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
    }

    #page-content {
        position: relative;
        flex-grow: 1; // make section fill #app
        padding: 25px 15px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    a, a:visited {
        color: #1c8de0;
    }
    .ma-mobile-alert {
        @media (min-width: 768px) {
            @apply hidden;
        }
        @media (max-width: 767px) {
            @apply flex text-center;
        }
        @apply ~"mb-1.5";
    }
</style>

<style lang="less">
    body {
        background-color: #fff;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: right;
    }

    .highcharts-loading {
        right: 10px;
        margin: auto;
    }

    .ma-dialog-xs-50-responsive {
        &.ant-modal {
            @media (min-width: 1340px) {
                width: 50%!important;
                max-width: 500px!important;
            }
            @media (max-width: 767px) {
                width: 100%!important;
                max-width: 500px!important;
            }
            @media (min-width: 768px) {
                width: 80%!important;
                max-width: 500px!important;
            }
        }
    }

    @dialog-width-lg: 850px;
    .ant-modal.ma-dialog-lg {
        max-width: @dialog-width-lg;
        width: @dialog-width-lg;
    }

    @media (max-width: @dialog-width-lg) {
        .ant-modal.ma-dialog-lg {
            max-width: 100vw;
            width: 100vw;
        }
    }

    .ma-cursor-pointer {
        cursor: pointer;
    }

    .tooltip-fixed-width-popper {
        width: 250px;
    }

    .ma-semibold-text {
        color: #827d7d;
        font-weight: 400;
        word-break: break-word;
    }

    span.currency-append-custom-label {
        border-radius: 0 4px 4px 0;
        border: 1px solid #DCDFE6;
        border-left: none;
        box-sizing: border-box;
        padding: 11px 19px 10px 19px;
        background-color: #f5f7fa;
        color: #909399;
    }

    span.currency-prepend-custom-label {
        border-radius: 4px 0 0 4px;
        border: 1px solid #DCDFE6;
        border-right: none;
        box-sizing: border-box;
        padding: 11px 19px 10px 19px;
        background-color: #f5f7fa;
        color: #909399;
    }

    .ma-card-footer-line {
        @apply h-10 w-full bg-gray-100;
    }

    // dx-datagrid global normalization to make it look like old-table
    .ma-datagrid {
        --scroll-margin: 0;
        .dx-scrollable-container {
            @apply overflow-scroll;
            &::-webkit-scrollbar-track {
                @apply bg-gray-100;
                margin-left: var(--scroll-margin);
            }

            &::-webkit-scrollbar {
                @apply sticky bottom-0;
            }

            &::-webkit-scrollbar:horizontal {
                @apply ~"h-1.5";
            }

            &::-webkit-scrollbar:vertical {
                @apply ~"w-1.5";
            }

            &::-webkit-scrollbar-thumb {
                @apply bg-gray-500 rounded;
            }
        }
        @apply h-full;

        .dx-datagrid {
            color: #606266;
            font-size: 13px;

            // devextreme light css has vertical-align: top for td cells
            .dx-datagrid-content .dx-datagrid-table .dx-row td {
                @apply py-4 align-middle;
            }

            .dx-datagrid-headers {
                td {
                    @apply bg-white;
                }

                .dx-header-row {
                    td {
                        @apply font-bold text-sm py-4;
                    }
                }
            }

            .dx-column-lines {
                td {
                    @apply border-r border-l border-gray-200;
                }
            }
            .dx-row-lines {
                td {
                    @apply border-t border-b border-gray-200;
                }
            }

            // dx selection column width needs to be set via CSS :(
            .dx-datagrid-table .dx-row td.dx-command-select {
                width: 40px;
                min-width: 40px;
                max-width: 40px;
            }

            // devextreme altering row background color
            .dx-row-alt > td, .dx-row-alt > tr > td {
                background-color: #fafafa;
                border-top: 1px solid #fafafa;
            }

            // to make dx-data-grid header look like old-table
            .dx-datagrid-headers {
                @apply border-0 border-t;
                border-color: #EBEEF5;
            }
            .dx-datagrid-rowsview {
                @apply border-0 border-b;
                border-color: #EBEEF5;
            }
            .dx-datagrid-total-footer {
                @apply border-0 border-b;
                border-color: #EBEEF5;
            }
        }


    }

    .ant-tooltip {
        .ant-tooltip-arrow::before {
            @apply bg-gray-700;
        }
        .ant-tooltip-inner {
            @apply bg-gray-700 text-sm;
        }
    }
</style>
