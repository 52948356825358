{
    "adsManager": "애드 매니저",
    "aso": "KP.",
    "benchmarks": "벤치마크",
    "budgetAllocation": "예산 할당",
    "collapseMenu": "사이드바 축소",
    "competitionAnalysis": "경쟁 분석",
    "competitionAnalysisAbb": "CA.",
    "contactSupport": "문의하기",
    "credit": "신용",
    "expandMenu": "사이드바 확대",
    "funnels": "퍼널",
    "goals": "목표설정(Goal)",
    "grader": "채점자",
    "ic": "IC.",
    "insightCenter": "인사이트 센터",
    "integrations": "통합",
    "keywordAuctionInsights": "경쟁앱 키워드 분석",
    "cppIntelligence": "CPP 인텔리전스",
    "cppIntelligenceTooltip": "데이터 기반 솔루션을 통해 경쟁사의 맞춤 제품 페이지 전략을 파악할 수 있습니다.",
    "keywordPlanner": "키워드 플래너",
    "ma": "MA",
    "mmp": "MMP",
    "mobileMeasurementPartner": "모바일 측정 파트너",
    "optimization": "최적화",
    "organicKeywordsHunt": "오가닉 키워드 분석",
    "overview": "개요",
    "quickActions": "빠른 작업",
    "quickAutomation": "빠른 자동화",
    "recommendations": "권장 사항",
    "report": "Report",
    "requestDemo": "데모 요청하기",
    "setup": "설정",
    "setupAbb": "SET.",
    "setupCompleteness": "단계별 설정 튜토리얼",
    "smartBidding": "스마트 입찰",
    "smartToolsAbb": "ST.",
    "switchTo": "로 전환",
    "automations": "자동화",
    "campaignForecasting": "캠페인 예측",
    "keywordAdvisor": "키워드 어드바이저",
    "attribution": "어트리뷰션",
    "competitors": "경쟁사",
    "settings": "설정"
}
