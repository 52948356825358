{
    "nFreeLabelsLeft": "您还有{n} 免费标签",
    "upgradeForMoreLabels": "升级以添加新标签",
    "addNewLabel": "添加新标签",
    "areYouSureDeleteLabel": "您确定要删除所选标签吗？",
    "cancel": "取消",
    "color": "颜色",
    "createLabel": "创建标签",
    "description": "规则说明",
    "description_optional": "说明（可选）",
    "editLabel": "编辑标签",
    "enterAColor": "需要颜色",
    "enterALabelName": "标签名称为必填项",
    "failedToCreateLabel": "创建标签失败{msg}",
    "failedToFetchLabel": "获取标签失败{msg}",
    "failedToUpdateLabel": "更新标签失败{msg}",
    "label": "标签",
    "labelNameCannotBeStartedWithAt": "标签名称不能以{'@'} 符号开头。",
    "labelNameShouldBeUnique": "标签名称应唯一",
    "mainCategoryCanBeOnlyOne": "每个实体的主要类别（以 # 开头）不能超过一个",
    "name": "名称",
    "newLabel": "新标签",
    "noLabelFound": "未找到标签",
    "preDefinedLabelCannotBeDeleted": "预设标签不能删除。",
    "preDefinedLabelCannotBeEdited": "预设标签不可编辑。",
    "searchByName": "按名称搜索",
    "succeedToCreateLabel": "标签{label} 创建成功。",
    "succeedToUpdateLabel": "标签{label} 已成功更新。"
}
