import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';

export const useKeywordAddModalStore = defineStore('keywordAddModal', () => {
    const state = reactive({
        total: 0,
        successfulRequests: [],
        failedRequests: [],
    });

    const doneRequestsCount = computed(() => state.successfulRequests.length + state.failedRequests.length);

    const setTotal = (payload) => {
        state.total = payload;
    };

    const addSuccessfulRequest = (payload)=> {
        state.successfulRequests.push(payload);
    };

    const addFailedRequest = (payload) => {
        state.failedRequests.push(payload);
    };

    const resetSummary = ()=> {
        state.total = 0;
        state.successfulRequests = [];
        state.failedRequests = [];
    };

    return {
        ...toRefs(state),
        doneRequestsCount,
        setTotal,
        addSuccessfulRequest,
        addFailedRequest,
        resetSummary,
    };
});
