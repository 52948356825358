import { formatNumber, formatMoney } from 'accounting-js';
import CurrencySymbolMap from 'currency-symbol-map';
import result from 'lodash/result';
import dayjs from '@/plugins/dayjs.mjs';
import vuex from 'vuex';
import { nFormatter, toTitleCase } from '@/common/MaUtils.mjs';

export default {
    computed: {
        ...vuex.mapState('account', ['currencyView']),
    },
    methods: {
        getCurrencySymbol(currency) {
            return this.currencyView === 'code' ? currency : CurrencySymbolMap(currency);
        },
        // accepts only amount properties which have currency sibling
        formatMoneyCol(row, column) {
            const propPath = column.property.replace(/\.amount$/, '');
            const money = result(row, propPath);
            return money
                ? this.formatMoney(money.amount, money.currency)
                : '-'; // if no field found just show -
        },
        formatMoney(amount, currency, precision) {
            const symbol = this.getCurrencySymbol(currency) || '$';
            return formatMoney(Number(amount) || 0, {
                symbol,
                format: '%s %v',
                precision: [null, undefined].includes(precision) ? 2 : precision,
            });
        },
        formatMoneyWithN(amount, currency, precision) {
            const symbol = this.getCurrencySymbol(currency) || '$';
            const val = Number(amount) || 0;
            return `${symbol} ${ nFormatter(val, precision || 2)}`;
        },
        formatCurrency(amount, currency, precision) {
            return formatMoney(Number(amount) || 0, {
                symbol: currency || '',
                format: '%s %v',
                precision: [null, undefined].includes(precision) ? 2 : precision,
            });
        },
        formatRateCol(row, column) {
            return this.formatRate(result(row, column.property));
        },
        safeDivision(n, d) {
            return d
                ? n / d
                : null;
        },
        safeDivisionRate(n, d) {
            const d1 = this.safeDivision(n, d);
            return d1
                ? d1 * 100
                : null;
        },
        formatPureRate(value, precision = 2) {
            return `${ formatNumber((Number(value) || 0), { precision }) }%`;
        },
        formatPureRateCol(row, column) {
            return this.formatPureRate(result(row, column.property));
        },
        formatRate(value, precision = 2) {
            return `${ formatNumber((Number(value) || 0) * 100, { precision }) }%`;
        },
        rateCol(row, column) {
            return this.formatPureRate(result(row, column.property));
        },
        formatNumberCol(row, column) {
            const val = result(row, column.property);
            if (Number.isNaN(val)) {
                return '-';
            }
            return this.formatNumber(val);
        },
        formatNumber(value, precision = 2) {
            return formatNumber(Number(value) || 0, {
                precision,
                stripZeros: true,
            });
        },
        formatNumberWithN(value, precision = 2) {
            const val = Number(value) || 0;
            return nFormatter(val, precision);
        },
        formatInteger(value) {
            return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-';
        },
        formatDecimal(row, column) {
            return row[column.property] ? row[column.property].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-';
        },
        formatSpend(row, column, placeholder) {
            if (placeholder && !row[column.property]) {
                return placeholder;
            }
            return this.formatMoney(row[column.property], row['currency']);
        },
        formatDate(date) {
            return dayjs(date).format('YYYY/MM/DD');
        },
        formatDateTime(datetime) {
            return dayjs.utc(datetime).format('YYYY/MM/DD HH:mm A');
        },
        formatDateCol(row, dataField) {
            return this.formatDate(result(row, dataField));
        },
        formatDateTimeCol(row, column) {
            return this.formatDateTime(result(row, column.property));
        },
        toTitleCase,
        toTitleCaseCol(row, column) {
            return this.toTitleCase(result(row, column.property));
        },
        camelCaseToTitleCase(str) {
            if (!str) {
                return str;
            }
            return str
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')
                .split(' ')
                .map(s => s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase())
                .join(' ');
        },
    },
};
