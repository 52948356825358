import { IGNORE, CANCEL_ADDING, UPDATE_MATCH_TYPE,
    CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD } from './actions.mjs';

// key structure            ?:optional
// <ADDs What>-<Insertion Level>-<Duplicate Keyword Position>-<Duplicate Keyword Type>-<Bid Value>?
// debugId: is used for matching case ono excel sheet, displaying case id on tooltip for debug purposes
// see detailed cases: https://docs.google.com/spreadsheets/d/1ZxYhMT9wv34rr3EtMBASFMfkuM4RCu2WjwsJfYTTRvY/edit#gid=630018202

export default {
    // CampaignLevel
    'ExactNegativeKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Exact': {
        debugId: '2.1',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW],
        tKey: 'case_2_1',
    },
    'ExactNegativeKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Broad': {
        debugId: '2.2',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, UPDATE_MATCH_TYPE],
        tKey: 'case_2_2',
    },
    'ExactNegativeKeyword-CampaignLevel-SameCampaign-Keyword-Exact': {
        debugId: '2.3',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD],
        tKey: 'case_2_3',
    },
    'ExactNegativeKeyword-CampaignLevel-SameCampaign-Keyword-Broad': {
        debugId: '2.4',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD],
        tKey: 'case_2_4',
    },
    // AdGroupLevel
    'ExactNegativeKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Exact': {
        debugId: '2.9',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_MATCH_TYPE],
        tKey: 'case_2_9',
    },
    'ExactNegativeKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Broad': {
        debugId: '2.10',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_MATCH_TYPE],
        tKey: 'case_2_10',
    },
    'ExactNegativeKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact': {
        debugId: '2.11',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_2_11',
    },
    'ExactNegativeKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad': {
        debugId: '2.12',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_2_12',
    },
};
