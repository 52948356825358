<template>
    <div class="ma-keywords-check">
        <div v-if="noConflictDetected">
            <div class="flex justify-center">
                <a-alert type="success" :closable="false" :message="$t('noConflictDetected')"/>
            </div>
            <a-spin v-if="noConflictDetected && loading" :spinning="true" class="min-h-[100px]"/>
        </div>

        <div v-else>
            <div v-if="!isCampaignLevel">
                <a-alert type="info" :show-icon="true" :closable="false">
                    <template #message>
                        {{ $tc('issueAdGroup', adGroups.length, { count: adGroups.length }) }}
                    </template>
                </a-alert>
            </div>
            <div v-else>
                <a-alert type="info" :show-icon="true" :closable="false">
                    <template #message>
                        {{ $tc('issueCampaign', campaigns.length, { count: campaigns.length }) }}
                    </template>
                </a-alert>
            </div>

            <a-card
                v-for="(issue, ind) in issues"
                :key="`${ind}-campaign-issues`"
                :body-style="{ padding: '0px' }"
                class="ma-campaign-issues"
            >
                <template #title>
                    <div class="ma-campaign-issues-header">
                        <a-button type="text" class="ma-collapse-header" @click="toggleCollapsed(ind)">
                            <span class="ma-collapse-icon">
                                <fa v-if="!issue.remainingCount && !loading" icon="check"/>
                                <template v-else>
                                    <fa
                                        icon="chevron-right"
                                        class="transition ease-in-out duration-150 origin-center"
                                        :class="!issue.collapsed ? 'rotate-0' : 'rotate-90'"
                                    />
                                </template>
                            </span>
                            <span v-if="issue.adGroupName">
                                <b>{{ $t('targetedAdGroup') }}:</b> {{ issue.adGroupName }}
                            </span>
                            <span v-else>
                                <b>{{ $t('targetedCampaign') }}:</b> {{ issue.name }}
                            </span>
                        </a-button>
                        <div>
                            <a-alert
                                v-if="issue.remainingCount"
                                :closable="false"
                                type="warning"
                            >
                                <a-tooltip
                                    overlay-class-name="ma-keywords-check-tooltip"
                                    :title="issue.warningText"
                                >
                                    <fa icon="exclamation-triangle"/>
                                </a-tooltip>
                                <template #message>
                                    {{ $tc('nIssues', issue.remainingCount, { n: issue.remainingCount }) }}
                                </template>
                            </a-alert>
                            <a-alert
                                v-else-if="!loading"
                                :closable="false"
                                type="success"
                            >
                                <a-tooltip
                                    overlay-class-name="ma-keywords-check-tooltip"
                                    :title="`${$t('duplicateKeywordSuccess')}`"
                                >
                                    <fa icon="check-circle"/>
                                </a-tooltip>
                                <template #message>
                                    {{ $t('resolved') }}
                                </template>
                            </a-alert>
                        </div>
                    </div>
                </template>
                <div v-show="!issue.collapsed" class="ma-card-content">
                    <a-spin :spinning="loading">
                        <a-table
                            v-if="loading || issue.issueCount"
                            ref="issueTable"
                            class="ma-issues-table ma-table-explicit-scroll"
                            default-expand-all-rows
                            :scroll="{ y: '500' }"
                            :row-key="(row) => `${ind}-${row.rowKey}`"
                            :locale="{ emptyText: $t('noKeywordIssue') }"
                            :data-source="issue.keywordsWithIssues"
                            :row-class-name="tableRowClassName"
                            :pagination="false"
                        >
                            <template #expandIcon="{ expanded, onExpand, record }">
                                <a-button
                                    type="text" class="px-2 cursor-pointer"
                                    :class="tableCellClassName(record)"
                                    @click="onExpand(record)"
                                >
                                    <fa
                                        icon="chevron-right"
                                        class="transition ease-in-out duration-150 origin-center"
                                        :class="!expanded ? 'rotate-0' : 'rotate-90'"
                                    />
                                </a-button>
                            </template>
                            <template #expandedRowRender="{ record: row }">
                                <ma-keywords-check-issues
                                    v-show="row.issues.length"
                                    :type="type"
                                    :issues="row.issues"
                                    :issue-type="row.issueType"
                                    :cell-class-func="tableCellClassName"
                                    @resolved="resolveKeyword"
                                />
                            </template>

                            <a-table-column
                                data-index="text"
                                :title="$t('columns.keyword')"
                                ellipsis
                            >
                                <template #default="{ record: row }">
                                    <div class="flex justify-start items-center">
                                        <ma-status-icon
                                            :class="{ 'invisible' : !row.issues.length }"
                                            class="mr-1"
                                            show-tooltip
                                            to-be-added
                                        />
                                        <div :title="matchTypeTitle(row.matchType)">
                                            <span v-if="row.matchType === 'BROAD'">{{ row.text }}</span>
                                            <span v-else>[{{ row.text }}]</span>
                                        </div>
                                    </div>
                                </template>
                            </a-table-column>
                            <a-table-column :title="$t('components.keywordAddition.keywordType')">
                                <template #default>
                                    <a-tag
                                        v-if="type === 'KEYWORD'"
                                        color="blue"
                                    >
                                        {{ $t('columns.keyword') }}
                                    </a-tag>
                                    <a-tag
                                        v-else
                                        color="red"
                                    >
                                        {{ $t('components.keywordAddition.negative') }}
                                    </a-tag>
                                </template>
                            </a-table-column>
                            <a-table-column
                                v-if="type === 'KEYWORD'"
                                data-index="bidAmount.amount"
                                :title="$t('columns.bidAmount')"
                            >
                                <template #default="{ record: row }">
                                    <div v-if="row.bidAmount && type === 'KEYWORD'">
                                        {{ formatMoney(row.bidAmount.amount, row.bidAmount.currency) }}
                                    </div>
                                </template>
                            </a-table-column>
                            <a-table-column
                                :title="$t('common.campaign')"
                                ellipsis
                            >
                                <template #default>
                                    <span v-if="!issue.isCampaignLevel">{{ campaigns[0].name }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column
                                :title="$t('common.adGroup')"
                                ellipsis
                            >
                                <template #default>
                                    <span v-if="!issue.isCampaignLevel">{{ issue.adGroupName }}</span>
                                </template>
                            </a-table-column>
                            <a-table-column
                                data-index="issue"
                                :title="$t('issue')"
                                align="center"
                                width="60"
                                :custom-cell="(row) => ({ class: tableCellClassName(row) })"
                            >
                                <template #default="{ record: row }">
                                    <fa v-if="row.done" class="check"/>
                                    <a-tooltip
                                        v-else
                                        overlay-class-name="ma-keywords-check-tooltip"
                                        :title="$t('solveConflictsBeforeContinue')"
                                    >
                                        <fa v-if="row.issueType === 'warning'" icon="exclamation-triangle"/>
                                        <fa v-else-if="row.issueType === 'danger'" icon="times-circle"/>
                                    </a-tooltip>
                                </template>
                            </a-table-column>
                            <a-table-column :title="$t('buttons.actions')" align="right">
                                <template #default="{ record: row }">
                                    <a-popconfirm
                                        :ok-text="$t('buttons.confirm')"
                                        :cancel-text="$t('buttons.cancel')"
                                        :title="$t('sureCancelAdding')"
                                        @confirm="excludeKeyword(row, issue)"
                                    >
                                        <a-button v-if="row.actions && row.actions.length && !row.done">
                                            <fa icon="trash"/>
                                        </a-button>
                                    </a-popconfirm>
                                </template>
                            </a-table-column>
                        </a-table>
                    </a-spin>
                </div>
            </a-card>
        </div>
    </div>
</template>

<script>
    import { checkKeywords, checkKeywordsWithCountries } from '@/common/MaRequests/Management';
    import { cases, buildKey } from '@/common/DuplicateKeywordCases/index.mjs';
    import MaKeywordsCheckIssues from '@/components/MaKeywordsCheckIssues.vue';
    import Formatter from '@/mixins/Formatter.mjs';
    import { isNoData } from '@/common/MaUtils.mjs';
    import messages from '@/components/MaKeywordsCheck/MaKeywordsCheck.i18n';

    export default {
        name: 'ma-keywords-check',
        components: {
            MaKeywordsCheckIssues,
        },
        mixins: [Formatter],
        props: {
            type: { type: String, validator: t => ['KEYWORD', 'NEGATIVE'].includes(t) }, // keyword type
            keywords: { type: Array },
            campaigns: { type: Array },
            adGroups: { type: Array },
            countryCodes: { type: Array }, // used create campaign page, yet campaign has no id
            issuesCount: { type: Number, default: 0 },
            keywordsToBeAddedCount: { type: Number, default: 0 },
            excludedKeywords: { type: Array, default: () => [] },
            isCampaignLevel: { type: Boolean },
            loading: { type: Boolean },
        },
        emits: ['update:loading', 'submit', 'update:issuesCount', 'update:keywordsToBeAddedCount', 'update:excludedKeywords'],
        data() {
            return {
                fullscreen: false,
                issuedKeywords: [],
                excludedKeywordList: [],
                resolvedKeywordList: [],
                allKeywords: [],
                collapsedList: [],
                noConflictDetected: false,
                transition: false,
            };
        },
        computed: {
            params() {
                const keywords = this.keywords.slice()
                    .map((k) => {
                        if (!k.bidAmount) {
                            k.bidAmount = { amount: 0, currency: 'USD' };
                        }
                        return k;
                    });
                return {
                    campId: this.campaignId, // || 391122153, //,
                    appId: this.appId, // || 391126996, //,
                    keywordType: this.type,
                    keywords: keywords, // || [{ text: 'test', adGroupId: 391126996, matchType: 'BROAD', bidAmount: { amount: 1, currency: 'USD' } }], // this.keywords,
                };
            },
            resolvedKeywords() {
                return this.resolvedKeywordList.map(k => k.id);
            },
            remainingIssuesCount() {
                return this.issues.reduce((a, b) => (a + b.remainingCount), 0);
            },
            keywordToBeAddedCount() {
                return this.issues.reduce((a, b) => (a + b.keywordsToBeAdded.length), 0);
            },
            issues() {
                const list = this.isCampaignLevel ? this.campaignIssues : this.adGroupIssues;

                return list
                    .map((item, index) => {
                        const kws = item.keywordsWithIssues;
                        const filterFunc = e => this.isCampaignLevel
                            ? e.campaignId === item.campaignId
                            : e.adGroupId === item.adGroupId;
                        const excludedKws = this.excludedKeywordList.filter(filterFunc).map(e => e.text);
                        item.resolvedCount = kws.filter(i => i.done).length;
                        item.issueCount = kws.length;
                        item.collapsed = this.collapsedList.length ? this.collapsedList.includes(index) : false;
                        item.remainingCount = kws.length - item.resolvedCount;
                        item.keywordsToBeAdded = this.keywords.filter(k => !excludedKws.includes(k.text));
                        item.warningText = this.$t('issueWarning', {
                            totalKeywordCount: this.keywords.length,
                            conflictingKeywordCount: item.remainingCount,
                        });
                        return item;
                    });
            },
            adGroupIssues() {
                return this.adGroups
                    .map((a) => {
                        // find campaign specific issued keywords
                        const issuedKeywords = this.allKeywords.filter(k => k.checkedAdGroupId === a.id);
                        // don't show same keyword multiple times for each storefront
                        const issuedKeywordsGrouped = this.removeDuplicatesIgnoringCountry(issuedKeywords);
                        const excludedKeywords = this.excludedKeywordList
                            .filter(k => k.adGroupId === a.id)
                            .map(k => k.text);

                        let issueMetadata = { adGroupId: a.id };
                        if (this.campaigns && this.campaigns.length === 1) {
                            issueMetadata.campaignId = this.campaigns[0].id;
                        }
                        const issues = this.getKeywordsWithIssues(issuedKeywordsGrouped, excludedKeywords, issueMetadata);
                        return Object.assign({
                            isCampaignLevel: false,
                            adGroupId: a.id,
                            keywordsWithIssues: issues.slice(),
                        }, a);
                    });
            },
            campaignIssues() {
                return this.campaigns
                    .map((c) => {
                        const issuedKeywords = this.allKeywords.filter(k => k.checkedCampaignId === c.id);
                        const excludedKeywords = this.excludedKeywordList
                            .filter(k => k.campaignId === c.id)
                            .map(k => k.text);

                        const issueMetadata = { campaignId: c.id };
                        const issues = this.getKeywordsWithIssues(issuedKeywords, excludedKeywords, issueMetadata);
                        return Object.assign({
                            isCampaignLevel: true,
                            campaignId: c.id,
                            keywordsWithIssues: issues.slice(),
                        }, c);
                    });
            },
            appMapping() {
                return this.campaigns.reduce((a, b) => {
                    a[b.id] = b.app;
                    return a;
                }, {});
            },
        },
        methods: {
            toggleCollapsed(ind) {
                const index = this.collapsedList.indexOf(ind);
                if (index === -1) {
                    this.collapsedList.push(ind);
                } else {
                    this.collapsedList.splice(index, 1);
                }
            },
            findOutIssue(k, i) {
                const key = buildKey(this.type, k, i);
                return key ? cases[key] : undefined;
            },
            tableRowClassName(row) {
                let classes = ['ma-keywords-check-parent-row'];
                if (!row.issues || !row.issues.length) {
                    classes.push('ma-keywords-check-no-expand-row');
                }
                return classes;
            },
            excludeKeyword(row, issue) {
                const { isCampaignLevel, campaignId, adGroupId } = issue;
                const { text } = row;
                this.excludedKeywordList.push({ text, isCampaignLevel, campaignId, adGroupId });
                if (this.$refs.issueTable) {
                    // todo doesnt work! why ? it would be awesome to collapse table expands if no child is available
                    // this.$refs.issueTable.toggleRowExpansion(row);
                }
            },
            resolveKeyword(kw, toBeUpdatedProp = null) {
                const { isCampaignLevel } = this;
                const { id, campaignId, adGroupId } = kw;
                this.resolvedKeywordList.push({ id, campaignId, adGroupId, isCampaignLevel });

                if (toBeUpdatedProp) {
                    const keyword = this.allKeywords.find(k => k.id === kw.id);
                    if (keyword) {
                        keyword[toBeUpdatedProp] = kw[toBeUpdatedProp];
                    }
                }
            },
            tableCellClassName(row) {
                let status = 'danger';
                if (row.issues && row.issues.length) {
                    const types = row.issues.map(a => a.issueType).filter(a => a);
                    if (types.includes('danger')) {
                        status = 'danger';
                    } else if (types.includes('warning')) {
                        status = 'warning';
                    } else {
                        status = 'success';
                    }
                }

                let classes = row.issueType ? [row.issueType] : [];
                // if (column.property === 'issue') {
                classes.push('ma-keywords-check-issue-cell');
                classes.push(status);
                // } else if (column.type === 'expand') {
                //     classes.push(status);
                // }
                const classString = [...new Set(classes)].join(' ');
                return classString;
            },
            check() {
                let promises = [];
                if (!this.isCampaignLevel) {
                    promises = this.adGroups
                        .map((adGroup) => {
                            const { campaignId } = adGroup;
                            const { adamId = null, trackId } = this.appMapping[campaignId];
                            const options = {
                                campaignId,
                                adGroupId: adGroup.id,
                                appId: adamId || trackId,
                                orgId: adGroup.orgId,
                            };
                            return this.getRequest(options);
                        });
                } else {
                    promises = this.campaigns
                        .map((campaign) => {
                            const { adamId = null, trackId } = campaign.app;
                            const options = {
                                campaignId: campaign.id,
                                adGroupId: null,
                                appId: adamId || trackId,
                                orgId: campaign.orgId,
                            };
                            return this.getRequest(options);
                        });
                }

                return new Promise((resolve, reject) => {
                    this.$emit('update:loading', true);
                    this.noConflictDetected = false;

                    Promise.all(promises)
                        .then((responses) => {
                            this.allKeywords = responses.reduce((a, b) => a.concat(b), []);
                            if (this.allKeywords.length) {
                                reject('Issues need to be resolved first!');
                            } else {
                                this.noConflictDetected = true;
                                // timeout needed to wait counts to reflect upper component
                                setTimeout(() => {
                                    resolve('No issue detected');
                                }, 500);
                            }
                        })
                        .catch((e) => {
                            if (isNoData(e)) {
                                this.issuedKeywords = [];
                                this.noConflictDetected = true;
                                resolve('No issue detected');
                                return;
                            }
                            this.$log.error(`Failed to fetch issues of duplicate keywords`, e);
                        })
                        .then(() => {
                            this.$emit('update:loading', false);
                        });
                });
            },
            getRequest({ campaignId, adGroupId, appId, orgId }) {
                const keywords = this.keywords.slice()
                    .map((k) => {
                        let { bidAmount } = k;
                        if (!bidAmount) {
                            bidAmount = { amount: 0, currency: 'USD' };
                        }
                        k.adGroupId = adGroupId;
                        k.campaignId = campaignId;
                        return {
                            text: k.text,
                            matchType: k.matchType,
                            adGroupId,
                            campaignId,
                            bidAmount,
                        };
                    });

                const params = {
                    appId,
                    keywordType: this.type,
                    keywords,
                };
                if (this.countryCodes) {
                    params.countryCodes = this.countryCodes.join(',');
                } else {
                    params.campId = campaignId;
                }
                const request = this.countryCodes ? checkKeywordsWithCountries : checkKeywords;
                return new Promise((resolve, reject) => {
                    request(params)
                        .then((data) => {
                            const _data = data.map(d => Object.assign({
                                orgId,
                                checkedCampaignId: campaignId,
                                checkedAdGroupId: adGroupId,
                            }, d));
                            resolve(_data);
                        })
                        .catch(e => reject(e));
                });
            },

            matchTypeTitle(matchType) {
                // Match Type: Exact
                return `${this.$t('columns.matchType')}: ${this.$t(`components.keywordAddition.${matchType}`)}`;
            },

            getKeywordsWithIssues(issuedKeywords, excludedKeywords, issueMetadata) {
                let list = this.keywords.slice();
                const isKeyword = this.type === 'KEYWORD';

                const kws = issuedKeywords.map(k => k.text);
                // send done keywords to the end of list
                const sortFunction = (a, b) => (a.done === true ? 1 : (b.done === true ? -1 : 0));

                list = list
                    // set each campaign/adgroup id so that each case would be unique to where they are being added
                    // if it's being added to an adGroup case match would be accordingly different
                    .map((k) => {
                        if (issueMetadata.adGroupId) {
                            k.adGroupId = issueMetadata.adGroupId;
                        }
                        if (issueMetadata.campaignId) {
                            k.campaignId = issueMetadata.campaignId;
                        }
                        k.rowKey = `${k.text}-${k.matchType}`;
                        k.excluded = false;
                        k.issueType = '';
                        k.done = null;
                        k.issues = [];
                        return k;
                    })
                    .filter(k => kws.includes(k.text))
                    .map((k) => {
                        const kw = Object.assign({}, k);
                        if (excludedKeywords.includes(kw.text)) {
                            const row = Object.assign({}, k);
                            row.excluded = true;
                            row.issueType = 'success';
                            row.done = true;
                            row.hasCase = true;
                            row.issues = [];
                            return row;
                        }

                        // find issues with this keyword
                        kw.issues = issuedKeywords
                            .filter(_kw => _kw.text === kw.text)
                            .map((d) => {
                                const issue = this.findOutIssue(k, d);
                                const formatted = issue ? { // dont wanna confuse id and type props with keywords'
                                    issueDebugId: issue.debugId,
                                    issueType: issue.type,
                                    actions: issue.actions,
                                    explanation: this.$t(`explanation.${issue.tKey}`),
                                } : {};

                                let res = Object.assign({}, d);
                                if (this.resolvedKeywords.includes(d.id)) {
                                    res.done = true;
                                    res.actions = [];
                                    res.issueType = 'success';
                                } else {
                                    res = Object.assign({}, formatted, d);
                                    res.isUpdate = false;
                                    res.newBid = 0;
                                    res.done = false;
                                    if (res.bidAmount) {
                                        res.newBid = res.bidAmount.amount;
                                    }

                                    res.loading = false;
                                }
                                return res;
                            })
                            .filter(i => i.issueDebugId || i.done) // show only keywords that have matching case
                            .sort(sortFunction);

                        // find issue type level
                        const types = kw.issues.map(j => j.issueType);
                        kw.issueType = types.includes('danger')
                            ? 'danger'
                            : (types.includes('warning') ? 'warning' : 'success');

                        // find if cancel adding action is in the case
                        const action = isKeyword ? 'CANCEL_ADDING' : 'CANCEL_ADDING_NEG_KW';
                        const hasAction = kw.issues
                            .map(j => j.actions)
                            .find(acts => acts.includes(action));
                        kw.actions = hasAction ? [action] : [];

                        // check if all issues conflicting with this keyword is resolved as done
                        kw.done = !!(kw.issues.length && kw.issues.filter(j => j.done).length === kw.issues.length);

                        kw.hasCase = !!kw.issues.length || kw.done;
                        return kw;
                    })
                    .filter(k => k.hasCase) // show resolved ones or keywords that have conflict
                    .sort(sortFunction);

                return list;
            },
            removeDuplicatesIgnoringCountry(kwArray) {
                return kwArray.filter((kw, index) => {
                    delete kw['countryOrRegion'];
                    const kwString = JSON.stringify(kw);
                    return index === kwArray.findIndex((k) => {
                        delete k['countryOrRegion'];
                        return JSON.stringify(k) === kwString;
                    });
                });
            },
        },
        mounted() {
            this.check()
                .then(() => {
                    // no conflict detected
                    this.$emit('submit');
                })
                .catch(() => { /* there are conflicts needs to be resolved first*/ });
        },
        watch: {
            remainingIssuesCount() {
                this.$emit('update:issuesCount', this.remainingIssuesCount);
            },
            keywordToBeAddedCount() {
                this.$emit('update:keywordsToBeAddedCount', this.keywordToBeAddedCount);
            },
            issues: {
                deep: true,
                handler() {
                    this.$emit('update:keywordsToBeAddedCount', this.keywordToBeAddedCount);
                    this.$emit('update:issuesCount', this.remainingIssuesCount);
                },
            },
            excludedKeywordList: {
                deep: true,
                handler() {
                    this.$emit('update:excludedKeywords', this.excludedKeywordList.slice());
                },
            },
        },
        i18n: { messages },
    };
</script>

<style lang="less">
    .ma-keywords-check {
        .ma-campaign-issues {
            margin: 20px 0;

            .ma-campaign-issues-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .ma-collapse-header {
                    cursor: pointer;

                    .ma-collapse-icon {
                        padding-right: 10px;
                    }
                }
            }

            .ma-campaign-issues-alert {
                padding: 20px;
            }
        }

        .ma-keywords-check-success {
            margin: 50px 0;
        }

        .ma-keywords-check-parent-row {
            td:first-child {
                border-left: 5px solid rgba(255, 0, 0, 0.5);
            }
        }

        .ma-keywords-check-no-expand-row {
            svg {
                @apply hidden;
            }
        }

        .ma-issues-table {
            tr:hover {
                .ma-keywords-check-issue-cell {
                    &.danger {
                        background-color: rgba(254, 240, 240, 0.71);
                    }

                    &.warning {
                        background: rgba(253, 246, 236, 0.71);
                    }
                }
            }

            .ma-keywords-check-issue-cell {
                color: #0a55d1;

                &.danger {
                    color: #F56C6C;
                    background-color: #fef0f0;
                }

                &.warning {
                    color: #E6A23C;
                    background: #fdf6ec;
                }

                &.success {
                    color: #67c23a;
                    background: #f0f9eb;
                }
            }
        }
    }

    .ma-keywords-check-tooltip {
        @apply ~'max-w-[250px]';
    }
</style>
