import { cacheRequest, makeRequest } from '@/common/MaRequestPooler';
import { HOUR_IN_MS, getAsArray, paramsToQuery } from '@/common/MaUtils.mjs';

export function conversions(cacheInvalidate = false) {
    return makeRequest(`/sa-attrs/goals`, {}, { cacheInvalidate });
}

export function getConversion(goalId) {
    return makeRequest(`/sa-attrs/goals/${ goalId }`);
}

export function conversionsByApps(trackIds) {
    return makeRequest(`/sa-attrs/goals/by-apps?trackIds=${ trackIds }`);
}

export function addConversion(data) {
    return makeRequest(`/sa-attrs/goals`, {
        method: 'POST',
        data,
    });
}

export function updateConversion(goalId, data) {
    return makeRequest(`/sa-attrs/goals/${ goalId }`, {
        method: 'PUT',
        data,
    });
}

export function deleteConversion(goalId) {
    return makeRequest(`/sa-attrs/goals/${ goalId }`, {
        method: 'DELETE',
    });
}

export function updateGoalSelection(goalId, include) {
    return makeRequest(`/sa-attrs/goals/${ goalId }/goal-selection/${ include ? 'enable' : 'disable' }`, {
        method: 'PUT',
    });
}

export function updateBulkGoalSelection(data) {
    return makeRequest(`/sa-attrs/goals/goal-selections`, {
        method: 'PUT',
        data: { goalSelectionList: data },
    });
}

export function getSpendSummary(startDate, endDate, data) {
    return cacheRequest(`/sa/summary/expense?${paramsToQuery({ startDate, endDate })}`, {
        method: 'POST',
        data: getAsArray(data),
    }, { cacheTimeout: HOUR_IN_MS });
}

export function getAppCategories(trackId) {
    return makeRequest(`/sa-attrs/goals/app/${ trackId }/categories`);
}
