<template>
    <div class="ma-table-header">
        <span v-if="!isSm" class="ma-table-header--label">{{ title }}</span>
        <span v-if="tooltip" class="ma-table-header--tooltip">
            <ma-tooltip
                v-if="tooltip && !isSm"
                ref="tooltip"
                overlay-class-name="ma-tooltip-md"
                type="primary"
            >
                <ma-icon name="info-circle-2" size="xs"/>
                <template #title>
                    <span v-html="tooltip"/>
                </template>
            </ma-tooltip>
        </span>
        <span v-if="isSm" class="ma-table-header--label">{{ title }}</span>
        <ma-icon v-if="showSortArrow" :name="sortArrowLocation" size="xs"/>
    </div>
</template>

<script setup>
    import { MaIcon, MaTooltip } from '@mobileaction/action-kit';
</script>
<script>
    import { sendSentryError } from '@/common/MaUtils.mjs';
    import { COLUMN_KEY_CONVERTER } from '@/components/MaTableColumn/MaColumnsUtils';
    import messages from '@/components/TableHeader.i18n';

    export default {
        name: 'ma-table-header',
        props: {
            column: { type: Object },
            headerKey: { type: String, default: null },
            titleKey: { type: String, default: 'title' },
            computed: { type: Boolean, default: false },
            sortable: { type: Boolean, default: false },
            showSortArrow: { type: Boolean, default: false },
            sortDirection: {
                type: String,
                default: null,
                validator(val) {
                    return [null, 'asc', 'ascending', 'desc', 'descending'].includes(val.toLowerCase());
                },
            },
            isCustomColumn: { type: Boolean, default: false },
        },
        computed: {
            sortArrowLocation() {
                return this.sortDirection?.indexOf('asc') > -1 ? 'arrow-up' : 'arrow-down';
            },
            hKey() {
                return this.headerKey || this.column?.label;
            },
            i18nTitlePath() {
                return `headers.${ this.hKey }.${ this.titleKey }`;
            },
            title() {
                if (this.isCustomColumn) {
                    return this.column?.columnName;
                }
                const computed = this.computed ? ` (${this.$t('common.computed')})` : '';
                return this.$t(this.i18nTitlePath) + computed;
            },
            tooltip() {
                if (this.isCustomColumn) {
                    return this.customColumnDescription;
                }
                const key = `headers.${ this.hKey }.tooltip`;
                return this.$te(key) && this.$t(key);
            },
            isSm() {
                return this.$mq === 'sm' || this.$mq === 'md';
            },
            customColumnDescription() {
                return `${this.column?.description || `${this.$t('common.formula')}:`}<br>${this.formattedFormula(this.column?.formula)}`;
            },
        },
        methods: {
            formattedFormula(f) {
                return f?.replace(/\{([^\\}]+)\}/g, (_, k) => {
                    //remove goal & attr type values
                    //example: costPerGoal_1111_WITHOUT => costPerGoal
                    const prop = k.split('_')[0];
                    return this.$t(`headers.${this.i18nHeaderKey(prop)}.title`);
                });
            },
            i18nHeaderKey(prop) {
                return COLUMN_KEY_CONVERTER[prop] || prop;
            },
        },
        mounted() {
            const existingLocale = ['en', 'ja', 'ko'] // every locale should be in this list so that we dont send misleading error to bugsnag
                .some(locale => this.$te(this.i18nTitlePath, locale));
            if (!existingLocale && !this.isCustomColumn) {
                const errorMsg = `MaTableHeader: unknown key: ${ this.hKey }, i18nTitlePath:  ${ this.i18nTitlePath}`;
                this.$log.error('Missing table header:', errorMsg);
                sendSentryError(new Error(errorMsg));
            }
        },
        watch: {
            isSm() { // if size changed to small force update
                this.$forceUpdate();
            },
        },
        i18n: {
            messages,
        },
    };
</script>

<style lang="scss" scoped>
    .ma-table-header {
        @apply inline-flex items-center leading-tight whitespace-normal space-x-1 z-50;
        &--label {
            @apply m-0.5;
        }
    }
</style>
