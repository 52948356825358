<template>
    <div class="ma-sa-topbar border-b border-gray-200 z-2000 h-16">
        <div class="ma-sa-topbar--desktop">
            <div v-if="['onboarding', 'sa-integ-callback'].includes($route.name)" class="inline-flex pl-4">
                <img
                    src="@/assets/sa-logo-white.svg"
                    class="ml-2 filter invert h-11"
                >
            </div>
            <div v-else class="flex gap-2 items-center">
                <ma-typography
                    type="body-2"
                    weight="medium"
                    class="text-black flex gap-2 items-center cursor-default"
                >
                    <ma-tooltip
                        v-if="$te(`tooltips.${getLabelKeyFromRouteName(this.$route.matched[0]?.name)}`)"
                        :title="$t(`tooltips.${getLabelKeyFromRouteName(this.$route.matched[0]?.name)}`)"
                        :mouse-enter-delay="0.4"
                    >
                        {{ $t(getLabelKeyFromRouteName(this.$route.matched[0]?.name)) }}
                    </ma-tooltip>
                    <div v-else>
                        {{ $t(getLabelKeyFromRouteName(this.$route.matched[0]?.name)) }}
                    </div>
                </ma-typography>
                <ma-latest-data-refresh-button v-if="$route.name === 'dashboard'"/>
            </div>

            <div class="flex gap-2 items-center h-full">
                <ma-update-checker mode="badge"/>

                <template v-if="!isUserNoAccess">
                    <ma-api-state/>

                    <ma-actions-details-dropdown/>

                    <ma-currency-view-selector :spend="spend"/>
                </template>

                <ma-locale-selector is-topbar/>

                <div id="ma-beamer-container" class="ma-beamer-container">
                    <div class="border border-gray-200 rounded-lg px-2 py-[6px] hover:bg-gray-50">
                        <ma-icon size="sm" name="notification"/>
                    </div>
                </div>

                <ma-user-avatar-new @logout="logout"/>
            </div>
        </div>
        <div class="ma-sa-topbar--mobile">
            <div class="flex justify-between items-center px-3">
                <a-dropdown
                    ref="menu"
                    class="ma-menu"
                    :trigger="['click']"
                    placement="bottomLeft"
                >
                    <div class="flex justify-start items-center cursor-pointer">
                        <fa icon="bars" class="text-white mx-2 text-lg"/>
                        <img src="@/assets/sa-logo-white.svg" class="h-10">
                    </div>
                    <template #overlay>
                        <div class="bg-white rounded-md shadow-xl border outline-1">
                            <div class="text-center py-2">
                                <a-button
                                    v-if="showActions && accountIntegrated && !isUserNoAccess"
                                    size="small"
                                    type="link"
                                    @click="goCreate"
                                >
                                    <fa icon="plus"/>
                                    <span class="text-semibold ml-2">
                                        {{ $t('buttons.createCampaign') }}
                                    </span>
                                </a-button>
                            </div>

                            <ma-mobile-sidebar/>
                        </div>
                    </template>
                </a-dropdown>

                <ma-user-avatar-new @logout="logout"/>
            </div>
        </div>

        <ma-upgrade-plan-modal
            v-model:visible="upgradePlanModalVisible"
        />
    </div>
</template>

<script>
    import dayjs from '@/plugins/dayjs.mjs';
    import { mapGetters, mapActions } from 'vuex';
    import { redirectToLogin } from '@/common/MaUtils.mjs';
    import MaUpgradePlanModal from '@/components/MaUpgradePlanModal/MaUpgradePlanModal.vue';
    import MaLocaleSelector from '@/components/Topbar/MaLocaleSelector.vue';
    import AccountIntegratedCheck from '@/mixins/AccountIntegratedCheck.mjs';
    import MaUpdateChecker from '@/components/MaUpdateChecker/index.vue';
    import MaApiState from './MaApiState.vue';
    import Formatter from '@/mixins/Formatter.mjs';
    import { campaignsEvent } from '@/plugins/mixpanel/eventDefinitions';
    import { getLabelKeyFromRouteName, SIDEBAR_ITEMS } from '@/common/SidebarUtils';
    import MaMobileSidebar from '@/components/Topbar/MaMobileSidebar.vue';
    import { MaIcon, MaTooltip, MaTypography } from '@mobileaction/action-kit';
    import sidebarMessages from '@/components/MaSidebar/Sidebar.i18n';
    import messages from '@/components/Topbar/Topbar.i18n';
    import MaUserAvatarNew from '@/components/Topbar/MaUserAvatarNew.vue';
    import MaCurrencyViewSelector from '@/components/Topbar/MaCurrencyViewSelector.vue';
    import MaActionsDetailsDropdown from '@/components/Topbar/MaActionsDetailsDropdown.vue';
    import { piniaState } from '@/plugins/pinia.mjs';
    import { usePlanStore } from '@/pinia/Plan';
    import FetchCompletedCheck from '@/mixins/FetchCompletedCheck.mjs';
    import MaLatestDataRefreshButton from '@/components/Topbar/MaLatestDataRefreshButton.vue';

    export default {
        name: 'ma-sa-topbar',
        components: {
            MaLatestDataRefreshButton,
            MaTooltip,
            MaTypography,
            MaActionsDetailsDropdown,
            MaCurrencyViewSelector,
            MaUserAvatarNew,
            MaIcon,
            MaMobileSidebar,
            MaApiState,
            MaUpdateChecker,
            MaUpgradePlanModal,
            MaLocaleSelector,
        },
        mixins: [AccountIntegratedCheck, Formatter, FetchCompletedCheck],
        props: {
            showActions: { type: Boolean, default: true },
        },
        data() {
            return {
                sidebarVisible: false,
                upgradePlanModalVisible: false,
                user: null,
                selectedIntegration: null,
                startDate: dayjs().add(-30, 'days').format('YYYY-MM-DD'),
                endDate: dayjs().format('YYYY-MM-DD'),
                remainingTimeVals: [],
                openingMobileAction: false,
                SIDEBAR_ITEMS,
            };
        },
        computed: {
            ...mapGetters('account', ['activeOrg', 'accountTotalSpend', 'integrations', 'isUserAccessLimited', 'userOrgs', 'isUserNoAccess']),
            ...piniaState(usePlanStore, ['hasAccessSearchAdsTools']),
            activeIndex() {
                return (this.$route.name || 'dashboard').split('/')[0];
            },
            totals() {
                return this.accountTotalSpend.map(d => Object.assign({}, d, {
                    formatted: this.formatMoney(d.amount, d.currency),
                }));
            },
            spend() { // ignore currency differences and calculate approximate totals
                if (!this.totals.length) {
                    return 0;
                }
                return this.totals.map(t => t.formatted).join(', ');
            },
        },
        methods: {
            getLabelKeyFromRouteName,
            ...mapActions(['setUserAuth']),
            goCreate() {
                this.$router.push({ name: 'campaign-management' })
                    .then(() => this.$mixpanel.track(...campaignsEvent.create.toMixpanel()))
                    .catch(this.$error.suppressDuplicateNavigation);
            },
            goRuleView() {
                let name = 'automation-rules';
                if (this.$route.name === 'automation-rules') {
                    name = 'automations';
                }
                this.$router.push({ name }).catch(this.$error.suppressDuplicateNavigation);
            },
            logout() {
                this.$mixpanel.reset();
                this.$maSessionStorage.clear();
                this.$maLocalStorage.userInfo = null;
                this.$sentry?.clearUser();
                this.setUserAuth(null);
                setTimeout(() => {
                    redirectToLogin(true);
                }, 150);
            },
        },
        i18n: {
            messages,
            sharedMessages: sidebarMessages.messages,
        },
        watch: {
            integrations(newVal) {
                if (newVal) {
                    this.$store.dispatch('account/fetchAccountTotalSpend');
                }
            },
        },
    };
</script>

<style lang="less" scoped>
    .ma-sa-topbar {
        .ma-sa-topbar--desktop {
            @apply mobile:hidden desktop:flex justify-between items-center py-4 px-6;
        }
        .ma-gravatar-icon {
            @apply h-6 rounded-full shadow-md;
        }
        .ma-gravatar-icon--lg {
            @apply h-12;
        }

        .ma-sa-topbar--mobile {
            @apply p-1 desktop:hidden;
            background-color: #1b2038;
            height: 48px;
        }

        .ak-badge {
            @apply h-8 text-green-500;
        }
    }

    .ma-menu-dropdown {
        @apply border-r-0;
        .ma-a-icon {
            @apply mr-0;
            transform: translateY(-3px);
        }
        :deep(li.ant-menu-item) {
            @apply m-0;
            height: 25px;
            line-height: 25px;
        }
    }
</style>

