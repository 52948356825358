import { IGNORE, CANCEL_ADDING, UPDATE_MATCH_TYPE,
    CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD } from './actions.mjs';

// key structure            ?:optional
// <ADDs What>-<Insertion Level>-<Duplicate Keyword Position>-<Duplicate Keyword Type>-<Bid Value>?
// debugId: is used for matching case ono excel sheet, displaying case id on tooltip for debug purposes
// see detailed cases: https://docs.google.com/spreadsheets/d/1ZxYhMT9wv34rr3EtMBASFMfkuM4RCu2WjwsJfYTTRvY/edit#gid=630018202

export default {
    // CampaignLevel
    'BroadNegativeKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Exact': {
        debugId: '2.21',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, UPDATE_MATCH_TYPE],
        tKey: 'case_2_21',
    },
    'BroadNegativeKeyword-CampaignLevel-SameCampaign-NegativeKeyword-Broad': {
        debugId: '2.22',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW],
        tKey: 'case_2_22',
    },
    'BroadNegativeKeyword-CampaignLevel-SameCampaign-Keyword-Exact': {
        debugId: '2.23',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD],
        tKey: 'case_2_23',
    },
    'BroadNegativeKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact-DifferentBid': {
        debugId: '2.24',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING_NEG_KW, PAUSE_KEYWORD],
        tKey: 'case_2_24',
    },
    // AdGroupLevel
    'BroadNegativeKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Exact': {
        debugId: '2.33',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_MATCH_TYPE],
        tKey: 'case_2_33',
    },
    'BroadNegativeKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Broad': {
        debugId: '2.34',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING],
        tKey: 'case_2_34',
    },
    'BroadNegativeKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact': {
        debugId: '2.35',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_2_35',
    },
    'BroadNegativeKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad': {
        debugId: '2.36',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_2_36',
    },
};
