<template>
    <button
        id="sa-apple-sign-in-button"
        :disabled="buttonDisabled"
        class="ma-apple-integration-button"
        :class="`${buttonHeigth} ${buttonStateClasses}`"
        @click="goToLink"
    >
        <fa :icon="['fab', 'apple']" class="text-white text-base"/>
        <span class="text-white text-md text-base font-medium ml-2">
            <slot>
                {{ buttonText }}
                <fa v-if="buttonDisabled" icon="spinner" class="ml-2"/>
            </slot>
        </span>
    </button>
</template>

<script>
    import { paramsToQuery } from '@/common/MaUtils.mjs';
    import { getAuthRedirectUriForIntegration } from '@/common/MaUtils.mjs';
    import {
        apiIntegrationClickedEvent,
        onboardingEvent,
        signInWithAppleWallEvent,
    } from '@/plugins/mixpanel/eventDefinitions';

    const SEARCHADS_INTEGRATION_CLIENT_ID = 'SEARCHADS.cc4c5207-003c-48dd-9d00-692588fac2c0';

    export default {
        name: 'ma-apple-integration-button',
        props: {
            isOnboarding: { type: Boolean },
            isGrader: { type: Boolean },
            isAudit: { type: Boolean },
            isAlerts: { type: Boolean },
            isBenchmarks: { type: Boolean },
            isSearchTermEfficiency: { type: Boolean },
            loading: { type: Boolean },
            redirectPath: { type: String, default: '', required: true },
            experiment: { type: String, default: null },
            place: { type: String, default: null },
            buttonText: { type: String, default: 'Sign in with Apple' },
            isFromWall: { type: Boolean, default: false },
            size: {
                type: String,
                default: 'small',
                validator(val) {
                    return ['small', 'middle', 'large'].includes(val);
                },
            },
        },
        data() {
            return {
                waitForMixpanel: false,
            };
        },
        computed: {
            buttonDisabled() {
                return this.waitForMixpanel || this.loading;
            },
            appleAuthUrl() {
                return `https://appleid.apple.com/auth/oauth2/v2/authorize?${paramsToQuery({
                    response_type: 'code',
                    client_id: SEARCHADS_INTEGRATION_CLIENT_ID,
                    redirect_uri: getAuthRedirectUriForIntegration(),
                    state: this.$store.state.loginCsrf,
                    scope: 'searchads',
                })}`;
            },
            buttonHeigth() {
                return {
                    small: 'h-8',
                    middle: 'h-10',
                    large: 'h-12',
                }[this.size];
            },
            buttonStateClasses() {
                return this.buttonDisabled ? 'bg-gray-700 cursor-not-allowed' : 'bg-black';
            },
        },
        methods: {
            goToLink() {
                this.waitForMixpanel = true;
                if (this.isOnboarding) {
                    this.$mixpanel.track(...onboardingEvent.integrateApple.toMixpanel());
                } else if (this.isFromWall) {
                    this.$mixpanel.track(...signInWithAppleWallEvent(this.$route).toMixpanel());
                }
                const mixpanelApiIntegratedEvent = apiIntegrationClickedEvent(this.$route);
                mixpanelApiIntegratedEvent.setPropertyValues({
                    Place: this.place,
                    Experiment: this.experiment,
                });
                this.$mixpanel.track(...mixpanelApiIntegratedEvent.toMixpanel());
                setTimeout(() => {
                    this.waitForMixpanel = false;
                    window.location.href = this.appleAuthUrl;
                }, 1500);
            },

        },
        mounted() {
            // initialize csrf token and store it
            this.$store.dispatch('regenerateLoginCsrf')
                .catch((...args) => this.$log.error('Failed to regenerate login csrf:', ...args));

            //store redirect path for multiple usages
            this.$maLocalStorage.appleAuthRedirectPath = this.redirectPath;
        },
    };
</script>

<style lang="scss" scoped>
    .ma-apple-integration-button {
        @apply flex items-center justify-center px-4 rounded-md;
    }
</style>
