import * as MaCookieStorage from '@/common/MaCookieStorage';
import { parseQuery } from '@/common/MaUtils.mjs';

export const utms = [
    'utm_source',
    'utm_medium',
    'utm_term',
    'utm_content',
    'utm_campaign',
    'gclid',
];

export const trialUtms = utms.slice(0, 5);
export const trialUtmMap = {
    utm_source: 'utmSource',
    utm_medium: 'utmMedium',
    utm_term: 'utmTerm',
    utm_content: 'utmContent',
    utm_campaign: 'utmCampaign',
};
export const setTrialParameters = () => {
    const parsed = parseQuery(window.location.search);
    trialUtms.forEach((key) => {
        const value = parsed[key];
        if (value && value !== '') {
            setTrialUtm(key, value);
        }
    });
};
export const setTrialUtm = (utmKey, value) => {
    MaCookieStorage.set(trialUtmMap[utmKey], value);
};
export const getTrialParameters = () => {
    const utmParameter = {};
    let hasValue = false;
    Object.values(trialUtmMap).forEach((key) => {
        const value = MaCookieStorage.get(key);
        if (value && value !== '') {
            hasValue = true;
            utmParameter[key] = value;
        }
    });
    return hasValue
        ? { utmParameter }
        : {};
};

export const utmMap = {};
utms.forEach((key) => {
    const utmValue = MaCookieStorage.get(key);
    if (utmValue) {
        utmMap[key] = utmValue;
    }
});
