import * as UserPermission from '@/common/MaRequests/UserPermission';
import { validOrgList } from '@/common/FilteringUtils';
import { USER_ACCESS_TYPE } from '@/pages/Settings/composables/useTeamMemberOptions';

export default {
    methods: {
        mx_fetchUserOrganizations() {
            const user = this.user || this.$store.state.user;
            if (!user?.userId) {
                return;
            }
            this.loadingOrgs = true;
            UserPermission.getOrganizationsOfUser()
                .then((data) => {
                    const orgs = validOrgList();
                    if (user.admin || data.userAccessType === USER_ACCESS_TYPE.ALL_ACCESS) {
                        this.$store.dispatch('account/updateUserOrgs', orgs);
                    } else if (data.userAccessType === USER_ACCESS_TYPE.NO_ACCESS) {
                        this.$store.dispatch('account/updateUserOrgs', []);
                    } else { //limited access
                        const filteredData = orgs.filter(o => data.organizationList?.includes(Number(o.orgId)));
                        this.$store.dispatch('account/updateUserOrgs', filteredData);
                    }
                })
                .catch(({ errors }) => {
                    this.$log.error('Failed to fetch user organizations', errors);
                })
                .finally(() => this.loadingOrgs = false);
        },
    },
};
