import { makeRequest, cacheRequest } from '@/common/MaRequestPooler';

export function getApiKey() {
    return makeRequest(`/sa-attrs/account/info`);
}

export function recentEvents() {
    return makeRequest(`/sa-attrs/integrations/tracker-types`);
}

export function attributionAccountInfo(cacheInvalidate = false) {
    return cacheRequest(
        `/sa/attribution-account-info`,
        {},
        { cacheInvalidate }
    );
}

export function getLastEvents({ appId, trackerType }) {
    return makeRequest(`/sa-attrs/app/${appId}/tracker/${trackerType}/events`);
}

export {
    conversions,
    getConversion,
    conversionsByApps,
    addConversion,
    updateConversion,
    deleteConversion,
    updateGoalSelection,
    updateBulkGoalSelection,
    getSpendSummary,
} from '@/common/MaRequests/Goals';
