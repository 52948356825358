<template>
    <ma-popover
        v-model:visible="popoverVisible"
        trigger="click"
        auto-adjust-overflow
        overlay-class-name="ma-selector-popover z-[1000]"
        :get-popup-container="getPopupContainer"
        @visible-change="toggleOrClose"
    >
        <slot>
            <ma-popover-multiple-selector-trigger
                :selected-label="t('selectedLabels')"
                :no-select-label="t('addLabel')"
                :count="triggerCount"
                :is-open="popoverVisible"
                variant="borderless"
                class="bg-white"
            />
        </slot>
        <template #overlay>
            <template v-if="!creationPopupVisible">
                <template v-if="labels?.length">
                    <ma-label-selector-list
                        v-model:selected="selected"
                        :labels="labels"
                        show-actions
                        @edit-label="openEditPopup"
                    />
                    <div class="p-2 mt-1">
                        <ma-link-button
                            color="blue"
                            size="small"
                            icon="add"
                            @click="openCreationPopup"
                        >
                            {{ t('addNewLabel') }}
                        </ma-link-button>
                    </div>
                    <div class="p-2 mt-1">
                        <ma-button
                            size="medium"
                            variant="stroke"
                            class="w-full"
                            @click="applyLabels"
                        >
                            {{ t('buttons.apply') }}
                        </ma-button>
                    </div>
                </template>
                <template v-else>
                    <ma-empty
                        size="small"
                        :title="t('addFirstLabel')"
                        class="my-12"
                    >
                        <template #icon>
                            <ma-icon name="selection" class="!h-16 !w-16 mb-5"/>
                        </template>
                        <ma-link-button
                            color="blue"
                            size="small"
                            @click="openCreationPopup"
                        >
                            {{ t('addNewLabel') }}
                        </ma-link-button>
                    </ma-empty>
                </template>
            </template>
            <template v-else>
                <ma-create-label-popover
                    :selected-label="currentEditLabel"
                    :is-edit-mode="isEditPopup"
                    @back-clicked="closeCreationPopup"
                    @label-created="handleLabelCreated"
                />
            </template>
        </template>
    </ma-popover>
</template>

<script setup>
    import { MaPopover, MaButton, MaLinkButton, MaEmpty, MaIcon } from '@mobileaction/action-kit';
    import MaLabelSelectorList from '../common/MaLabelSelectorList.vue';
    import { computed, ref, watch } from 'vue';
    import i18n from '@/pages/Automations/Components/AutomationComponents.i18n';
    import { useI18n } from 'vue-i18n';
    import MaCreateLabelPopover from '@/pages/Automations/Components/common/MaCreateLabelPopover.vue';
    import MaPopoverMultipleSelectorTrigger
        from '@/pages/Automations/Components/common/MaPopoverMultipleSelectorTrigger.vue';
    import { Form } from 'ant-design-vue';
    import { useLabelsStore } from '@/pinia/Labels';

    const labelsStore = useLabelsStore();
    defineOptions({ i18n });
    const emit = defineEmits([
        'update:value',
        'labels-selected',
        'popup-opened',
        'add-new-label',
        'label-created',
    ]);
    const props = defineProps({
        value: { type: Array, default: () => [] },
        size: { type: String, default: 'small' },
        defaultSelected: { type: Array, default: () => [] },
        getPopupContainer: { type: Function, default: null },
    });
    const { t } = useI18n();
    const formItemContext = Form.useInjectFormItemContext();
    const labels = computed(() => labelsStore.labels);
    const selected = ref(props.defaultSelected);
    watch(selected, v => emit('update:value', v));
    watch(() => props.value, (v) => {
        selected.value = v;
        formItemContext?.onFieldChange();
    }, { immediate: true });
    const triggerCount = computed(() => {
        if (!selected.value?.length) {
            return;
        }
        return selected.value.length;
    });

    const popoverVisible = ref(false);
    const creationPopupVisible = ref(false);
    const isEditPopup = ref(false);
    const currentEditLabel = ref(null);
    const handleLabelCreated = (name) => {
        const createdLabelId = labels.value.find(l => l.name === name)?.id;
        if (createdLabelId) {
            selected.value = [...selected.value, createdLabelId];
        }
        closeCreationPopup();
        emit('label-created');

    };
    const closeCreationPopup = () => {
        creationPopupVisible.value = false;
    };
    const openCreationPopup = () => {
        isEditPopup.value = false;
        creationPopupVisible.value = true;
        emit('add-new-label');
    };
    const openEditPopup = (label) => {
        isEditPopup.value = true;
        currentEditLabel.value = label;
        creationPopupVisible.value = true;
    };
    const toggleOrClose = (visible) => {
        if (creationPopupVisible.value && !visible) {
            creationPopupVisible.value = !creationPopupVisible.value;
            popoverVisible.value = true;
        }
        if (visible) {
            selected.value = props.defaultSelected?.length ? [...props.defaultSelected] : [];
            emit('popup-opened');
        }
    };

    const applyLabels = () => {
        emit('labels-selected', labels.value.filter(l => selected.value.includes(l.id)));
        popoverVisible.value = false;
    };
    watch(popoverVisible, (val) =>{
        if (!val) {
            formItemContext?.onFieldBlur();
        }
    });

    //allow opening popover without clicking on the actual trigger
    const openSelectionPopover = () => {
        creationPopupVisible.value = false;
        popoverVisible.value = true;
        selected.value = [...props.defaultSelected];
        emit('popup-opened');
    };
    defineExpose({
        openSelectionPopover,
    });
</script>
