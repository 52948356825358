import dayjs from '@/plugins/dayjs.mjs';
import { getAllCampaigns, getAllAdGroups } from '@/common/MaRequests/SearchAds';
import { checkError, FETCH_ERROR } from '@/common/ErrorMessages';
import { isMissingAccessSearchads, parseError, safeStringEquals } from '@/common/MaUtils.mjs';
import { addKeywordsToAdgroup, addNegativeKeywordsToCampaign } from '@/common/MaRequests/Management';
import { NEGATIVE_KEYWORD_TYPE } from '@/common/ManagementUtils.mjs';
import { changeActiveOrgId } from '@/common/FilteringUtils';
import vuex from 'vuex';
import { h } from 'vue';
import { assignLabel } from '@/common/MaRequests/MetadataLabel';
import { useLabelsStore } from '@/pinia/Labels';
import { piniaActions, piniaState } from '@/plugins/pinia.mjs';
import { useKeywordAddModalStore } from '@/pinia/KeywordAdd';

export default {
    data() {
        return {
            campaigns: [],
            adGroups: [],
            loadingCampaigns: false,
            loadingAdGroups: false,
            loadingKeywords: false,
            startDate: dayjs().add(14, 'days').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            operationLoading: false,
            item: null,
            operation: { msg: [], item: 0, success: 0, warning: 0, fail: 0 },
        };
    },
    computed: {
        ...vuex.mapGetters('account', ['activeOrg']),
        ...piniaState(useKeywordAddModalStore, ['failedRequests']),
        ...piniaState(useLabelsStore, ['labels']),
    },
    methods: {
        ...piniaActions(useKeywordAddModalStore, ['setTotal', 'addSuccessfulRequest', 'addFailedRequest']),
        checkLoadingOperation(opt, it) {
            //Implement on sub-classes.
            this.operationLoading = opt;
            this.item = it;
        },
        fetchCampaigns() {
            this.loadingCampaigns = true;
            getAllCampaigns()
                .then((data) => {
                    this.campaigns = data.map(c => Object.assign({
                        trackId: c.adamId,
                        app: { adamId: c.adamId },
                        campaignId: c.id,
                        campaignName: c.name,
                    }, c));
                })
                .catch((e) => {
                    const { errors, noData, displayedMessage } = parseError(e);
                    if (noData) {
                        this.campaigns = [];
                        return;
                    }
                    this.$log.error('Failed to fetch campaigns:', errors);
                    if (checkError(errors, 'FETCH_ERROR')) {
                        this.$message.error(FETCH_ERROR.message);
                    } else if (isMissingAccessSearchads(e)) {
                        this.$message.warning(this.$t('messages.warnings.youAreNotAuthorized'));
                    } else {
                        this.$message.error(this.$t('messages.errors.failedToFetchCampaign', { msg: displayedMessage }));
                    }
                })
                .then(() => {
                    this.loadingCampaigns = false;
                });
        },
        fetchAdGroups(campaignId) {
            this.loadingAdGroups = true;
            getAllAdGroups(campaignId)
                .then((data) => {
                    this.adGroups = data.map(c => Object.assign({
                        adGroupId: c.id,
                        adGroupName: c.name,
                    }, c));
                })
                .catch((e) => {
                    const { errors, noData, displayedMessage } = parseError(e);
                    if (noData) {
                        this.adGroups = [];
                        return;
                    }
                    if (errors.includes('FETCH_ERROR')) {
                        this.$message.error(FETCH_ERROR.message);
                    } else if (isMissingAccessSearchads(e)) {
                        this.$message.warning(this.$t('messages.warnings.youAreNotAuthorized'));
                        return;
                    } else {
                        this.$message.error(this.$t('messages.errors.failedAdGroupReport'));
                    }
                    this.$log.error('Failed to load ad groups: ', errors, displayedMessage);
                })
                .then(() => {
                    this.loadingAdGroups = false;
                });
        },
        saveKeywords(options) {
            const { adGroups, campaigns } = options;
            return new Promise((resolve, reject) => {
                this.checkLoadingOperation(true);
                this.operation = {
                    msg: [],
                    item: adGroups.length || campaigns.length,
                    success: 0,
                    warning: 0,
                    fail: 0,
                };
                if (adGroups.length) {
                    this.saveAdGroupKeywords(options)
                        .then(resolve)
                        .catch(reject);
                } else {
                    this.saveCampaignKeywords(options)
                        .then(resolve)
                        .catch(reject);
                }
            });
        },
        async saveAdGroupKeywords({ keywords, addKeywordType, adGroups, selectedNegativeType, useDefaultCPC, excludedKeywords }) {
            this.setTotal(adGroups.length);
            for (const ind in adGroups) {
                const adGroup = adGroups[ind];
                const { campaignId } = adGroup;
                const adGroupId = adGroup.adGroupId || adGroup.id;
                const adGroupName = adGroup.adGroupName || adGroup.name;
                const excludedKws = excludedKeywords
                    .filter(e => e.adGroupId === adGroupId)
                    .map(e => e.text);
                const keywordsToBeAdded = keywords
                    .filter(k => !excludedKws.includes(k.text));
                if (!keywordsToBeAdded.length) {
                    // no keywords to be added, all of them is excluded
                    this.addSuccessfulRequest({ adGroupName, adGroupId, noKeywordsAdded: true });
                }

                try {
                    await this.singleRequest({
                        keywords: keywordsToBeAdded,
                        addKeywordType,
                        orgId: adGroup.orgId,
                        campaignId,
                        adGroupId,
                        adGroupName,
                        defaultCpcBid: adGroup.defaultCpcBid,
                        selectedNegativeType,
                        useDefaultCPC,
                    });
                    this.addSuccessfulRequest({ adGroupName, adGroupId });
                } catch (error) {
                    this.addSuccessfulRequest({ adGroupName, adGroupId, error });
                }
                await this.cooldown();
            }
            return new Promise((resolve, reject) => {
                if (this.failedRequests === adGroups.length) {
                    reject();
                    return;
                }
                resolve();
            });
        },
        async saveCampaignKeywords({ keywords, addKeywordType, campaigns, selectedNegativeType, useDefaultCPC, excludedKeywords }) {
            this.setTotal(campaigns.length);

            for (const ind in campaigns) {
                const campaign = campaigns[ind];
                const campaignId = campaign.id;
                const campaignName = campaign.name || campaign.campaignName;
                const excludedKws = excludedKeywords
                    .filter(e => e.campaignId === campaignId)
                    .map(e => e.text);
                const keywordsToBeAdded = keywords
                    .filter(k => !excludedKws.includes(k.text));
                if (!keywordsToBeAdded.length) {
                    // no keywords to be added, all of them is excluded
                    this.addSuccessfulRequest({ campaignName, campaignId, noKeywordsAdded: true });
                }
                try {
                    await this.singleRequest({
                        keywords: keywordsToBeAdded,
                        addKeywordType,
                        orgId: campaign.orgId,
                        campaignId,
                        adGroupId: null,
                        campaignName,
                        defaultCpcBid: null,
                        selectedNegativeType,
                        useDefaultCPC,
                    });
                    this.addSuccessfulRequest({ campaignName, campaignId });
                } catch (error) {
                    this.addFailedRequest({ campaignName, campaignId, error });
                }

                // we dont wanna spam backend sequentially, so added small cooldown period
                await this.cooldown();
            }
            return new Promise((resolve, reject) => {
                if (this.failedRequests === campaigns.length) {
                    reject();
                    return;
                }
                resolve();
            });
        },
        cooldown() {
            return new Promise (resolve => setTimeout(resolve, 500));
        },
        singleRequest(params) {
            const {
                keywords,
                addKeywordType,
                orgId,
                campaignId,
                adGroupId,
                adGroupName,
                campaignName,
                defaultCpcBid,
                selectedNegativeType,
                useDefaultCPC,
            } = params;

            return new Promise((resolve, reject) => {
                const keywordList = keywords
                    .map((k) => {
                        const rk = Object.assign({}, k);
                        rk.campaignId = campaignId;
                        rk.adGroupId = adGroupId;
                        rk.bidAmount = useDefaultCPC ? defaultCpcBid : rk.bidAmount;
                        return rk;
                    });
                changeActiveOrgId(orgId);
                if (NEGATIVE_KEYWORD_TYPE.NEGATIVE_CAMPAIGN === selectedNegativeType) {
                    this.saveNegativeKeywordsOnCampaign(keywordList, orgId, campaignId, campaignName, addKeywordType)
                        .then(resolve)
                        .catch(reject);
                } else {
                    this.saveKeywordsRequest(keywordList, orgId, campaignId, adGroupId, adGroupName, addKeywordType)
                        .then(resolve)
                        .catch(reject);
                }
            });
        },
        saveLabelsForKeywords(keywordsToLabel, keywordLabelMapping) {
            const keywordLabels = [];
            keywordsToLabel.forEach(succeedKw => keywordLabelMapping
                .find(kw => succeedKw.matchType === kw.matchType && succeedKw.text === kw.text)
                .labels?.forEach(label => keywordLabels.push({ keywordId: succeedKw.id, label })));

            const labelKeywordGrouping = {};
            keywordLabels.forEach((kwLabelPair) => {
                if (kwLabelPair.label in labelKeywordGrouping) {
                    labelKeywordGrouping[kwLabelPair.label].push(kwLabelPair.keywordId);
                } else {
                    labelKeywordGrouping[kwLabelPair.label] = [];
                    labelKeywordGrouping[kwLabelPair.label].push(kwLabelPair.keywordId);
                }
            });

            if (labelKeywordGrouping) {
                Object.keys(labelKeywordGrouping).forEach((labelId) => {
                    const labelObj = this.labels.find(label => safeStringEquals(label.id, labelId));
                    const entityList = labelKeywordGrouping[labelId].map(keywordId => ({
                        entityId: keywordId,
                        entityLevel: 'KEYWORD',
                    }));
                    const data = {
                        ...labelObj,
                        entityList,
                    };
                    assignLabel(data, labelId)
                        .catch(({ errors, errorData }) => {
                            this.$log.error('Failed to assign label:', errors, errorData);
                        });
                });
            }
        },
        saveKeywordsRequest(keywords, orgId, campaignId, adGroupId, adGroupName, type) {
            return new Promise((resolve, reject) => {
                addKeywordsToAdgroup(campaignId, adGroupId, keywords, type)
                    .then(({ succeed }) => {
                        if (succeed.length > 0) {
                            this.saveLabelsForKeywords(succeed, keywords);
                        }
                        this.$maIntercom.trackKeywordEvent('keyword_cart_success', type);
                        this.operation.msg.push(`<span style="color: #67c23a;">${this.$t('messages.success.keywordsAddedSuccessfully', { n: keywords.length, name: adGroupName })}</span>`);
                        this.operation.success += 1;
                        this.item = { prop: 'adGroupId', id: adGroupId, orgId: orgId };
                        resolve();
                    })
                    .catch((error) => {
                        this.handleErrorOfRequest(error, adGroupName);
                        reject(error);
                    })
                    .finally(() => {
                        this.checkCompleteState();
                    });
            });
        },
        saveNegativeKeywordsOnCampaign(keywords, orgId, campaignId, campaignName, type) {
            return new Promise((resolve, reject) => {
                addNegativeKeywordsToCampaign(campaignId, keywords, type)
                    .then(() => {
                        this.operation.msg.push(`<span style="color: #67c23a;">${this.$t('messages.success.keywordsAddedSuccessfully', { n: keywords.length, name: campaignName })}</span>`);
                        this.operation.success += 1;
                        this.item = { prop: 'campaignId', id: campaignId, orgId: orgId };
                        resolve();
                    })
                    .catch((error) => {
                        this.handleErrorOfRequest(error, campaignName);
                        reject(error);
                    })
                    .then(() => {
                        this.checkCompleteState();
                    });
            });
        },
        handleErrorOfRequest(error, name) {
            const { errorMessage, errors, displayedMessage } = parseError(error);
            if (errors.find(e => e === 'BACKEND_REQUEST_FAILED')) {
                this.operation.fail += 1;
                const err = this.$t('messages.serviceError');
                this.operation.msg.push(`<span style="color: #f56c6c;">${this.$t('messages.errors.errorOccurredKeywordAddition', { name: name, error: err })}</span>`);
            } else if (errorMessage.includes('Keyword limit exceeded for ad group')) {
                this.operation.warning += 1;
                const w = this.$t(`messages.errors.keywordLimitExceedForAdGroup`);
                this.operation.msg.push(`<span style="color: #e6a23c;">${this.$t('messages.warnings.warningOccurredKeywordAddition', { name: name, warning: w })}</span>`);
            } else {
                this.operation.fail += 1;
                this.$log.error('Failed to add keywords:', error);
                this.operation.msg.push(`<span style="color: #f56c6c;">${this.$t('messages.errors.errorOccurredKeywordAddition', { name: name, error: displayedMessage })}</span>`);
            }
        },
        checkCompleteState() {
            const { msg, item, success, warning, fail: error } = this.operation;
            if (item === (success + warning + error)) {
                let messageType = 'success';
                if (error === item) {
                    messageType = 'error';
                } else if (error > 0) {
                    messageType = 'info';
                } else if (warning > 0) {
                    messageType = 'warning';
                }
                this.$message[messageType]?.({
                    showClose: true,
                    duration: 12,
                    appContext: this.$.appContext,
                    content: () => h(
                        'div', [
                            h('span', this.$t(`messages.success.operationCompletedWith`, { success, warning, error })),
                            h('br'),
                            ...msg.flatMap(message => [
                                h('div',{ innerHtml: `${message}` }),
                                h('br'),
                            ]),
                        ]
                    ),
                });
                this.loadingKeywords = false;
                this.checkLoadingOperation(false, this.item);
            }
        },
    },
};
