<template>
    <div class="ma-bid-amount">
        <p
            v-if="!row.isUpdate"
            class="ma-cursor-pointer"
            :title="title"
            @click="openUpdate(row)"
        >
            <span v-if="row.bidAmount">
                {{ bidAmountFormatted }} <i v-if="!noUpdate" class="el-icon-edit"/>
            </span>
        </p>
        <div v-else class="flex">
            <div v-if="row.loading" class="ma-left-line-bid">
                <fa icon="spinner"/>
            </div>
            <div class="ma-right-line-bid">
                <a-input
                    :value="newBid"
                    type="number"
                    size="small"
                    :disabled="row.loading"
                    :placeholder="$t('columns.bidAmount')"
                    @keyup.enter="bidAmountChange(row)"
                    @change="e => $emit('update:new-bid', e.target.value)"
                >
                    <template #addonBefore>
                        <div class="append-bid">
                            <fa icon="check" class="ma-save-edit" @click="bidAmountChange(row)"/>
                            <fa icon="times" class="ma-cancel-edit" @click="$emit('update:is-update', null)"/>
                        </div>
                    </template>
                </a-input>
            </div>
        </div>
    </div>
</template>

<script>
    import { changeActiveOrgId } from '@/common/FilteringUtils';
    import { updateKeywordsToAdgroup } from '@/common/MaRequests/Management';
    import Formatter from '@/mixins/Formatter.mjs';
    import messages from '@/components/MaComponentsCommon.i18n';

    export default {
        name: 'ma-bid-amount',
        emits: ['update:new-bid', 'update:is-update', 'update:bid-amount', 'update:loading'],
        mixins: [Formatter],
        props: {
            row: { type: Object, required: false },
            noUpdate: { type: Boolean, default: false },
            isUpdate: { type: Boolean, default: false },
            loading: { type: Boolean, default: false },
            newBid: { type: Number, default: 0 },
            bidAmount: { type: Number, default: 0 },
        },
        computed: {
            title() {
                return this.noUpdate ? null : this.$t('messages.info.clickToUpdate');
            },
            bidAmountFormatted() {
                if (!this.row.bidAmount) {
                    return;
                }
                const { amount, currency } = this.row.bidAmount;
                return this.formatMoney(amount, currency);
            },
        },
        methods: {
            openUpdate(row) {
                if (this.noUpdate) {
                    return;
                }
                row.isUpdate = true;
            },
            bidAmountChange(row) {
                const { newBid, bidAmount } = row;
                if (bidAmount.amount.toFixed(2) === parseFloat(newBid).toFixed(2)) {
                    this.$emit('update:is-update', false);
                } else {
                    const { orgId, campaignId, adGroupId } = row;
                    changeActiveOrgId(orgId);
                    this.$emit('update:loading', true);
                    const keyword = {
                        importAction: 'UPDATE',
                        id: row.keywordId,
                        text: row.keyword,
                        adGroupId,
                        campaignId,
                        matchType: row.matchType,
                        bidAmount: { amount: row.newBid, currency: (row.currency || this.currency) },
                    };
                    updateKeywordsToAdgroup(campaignId, adGroupId, [keyword])
                        .then(() => {
                            this.$emit('update:is-update', false);
                            this.$emit('update:bid-amount', row.newBid);
                            this.$message.success(this.$t('messages.success.keywordBidUpdated'));
                        })
                        .catch((e) => {
                            this.$log.error('Failed to update match type', e);
                            this.$message.error(this.$t('messages.errors.keywordBidUpdated'));
                        })
                        .then(() => {
                            this.$emit('update:loading', false);
                        });
                }
            },
        },
        i18n: { messages },
    };
</script>

<style lang="less" scoped>
    .ma-bid-amount {
        .ma-left-line-bid {
            width: 14%;
            margin-right: 1%;
        }

        .ma-right-line-bid {
            width: 84%;
            margin-left: 1%;
            max-width: 150px;
        }
    }
</style>
