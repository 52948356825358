{
    "buttons": {
        "integrateMMP": "MMPの統合",
        "joinBeta": "ベータ参加",
        "contactSales": "ベータ版に参加する"
    },
    "integrateAttrPartners": "アトリビューションパートナーを統合して、各キャンペーンのROIを追跡し、ゴールやファンネルなどの他の機能から利益を得ましょう。",
    "integrationWallStep": {
        "1": {
            "heading": "オールインワン・プラットフォーム",
            "description": "Apple Search AdsとAttribution Partner (MMP)を統合して、ユーザーのアプリジャーニーを一箇所でトラッキングできます！"
        },
        "2": {
            "heading": "カスタマイズ可能なダッシュボード",
            "description": "30以上の選択肢からキャンペーンに最も重要なKPIを選択し、希望する傾向をチェックすることで、より便利なキャンペーン管理を体験できます！"
        },
        "3": {
            "heading": "一括アクション",
            "description": "時間を節約するためにクリックするだけで様々な一括アクションを実行。キーワードの入札額を一括で増減したり、すべてのキーワードを一時停止したり、オートメーションを作成したりできます。"
        },
        "4": {
            "heading": "自動化と通知",
            "description": "自動化ルールを設定して、入札額の最適化、キャンペーンの凍結、ネガティブキーワードの追加を行いましょう。急な変更を通知"
        }
    },
    "linkMMPPartners": "MMPパートナーと連携し、ユーザー獲得ファネル全体をトラッキング",
    "requestedBeta": "ご要望を承りましたので、すぐにご返信いたします。",
    "sendBetaRequest": "以下のボタンからクライアント・パートナー・チームにリクエストを送信してください。",
    "signInWithAppleSearchAds": "Apple検索広告でサインイン",
    "unlockFullPotential": "Apple Search Adsの可能性をフルに引き出す",
    "upgradePlan": "このページにアクセスするには、プランをアップグレードしてください。",
    "youNeedToIntegrateASA": "ロックを解除するには、Apple Search Adsを統合する必要があります。",
    "youNeedToIntegrateMMP": "そのためには、アトリビューション・パートナーを統合する必要がある。"
}
