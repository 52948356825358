import { makeRequest } from '@/common/MaRequestPooler';
import { paramsToQuery } from '@/common/MaUtils.mjs';

export class KeywordAuctionController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }

    /**
     * Returns the historical visibility score for selected apps
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - countries: country code, string
     * - startDate: start date, string
     * - endDate: end date, string
     * - }
     */
    getVisibilityScoreHistory({ trackId, countries, startDate, endDate }) {
        return this.requestPooler(`/app/${ trackId }/visibility-score/history?${ paramsToQuery({ countries, startDate, endDate }) }`);
    }
    /**
     * Returns mutual keywords for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchACCompetitorKeywords({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/uac/stats/non-mutual-app-stats/${ competitorId }/competitor/${ trackId }/${ countryCode }?${ paramsToQuery(queryParams) }`);
    }
    /**
     * Returns mutual keywords for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchACMainKeywords({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/uac/stats/non-mutual-app-stats/${ trackId }/competitor/${ competitorId }/${ countryCode }?${ paramsToQuery(queryParams) }`);
    }
    /**
     * Returns mutual keywords for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchACMutualKeywords({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/uac/stats/mutual-app-stats/${ trackId }/competitor/${ competitorId }/${ countryCode }?${ paramsToQuery(queryParams) }`);
    }
    /**
     * Returns competitor app keywords count for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchCompetitorKeywordsCount({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/sa/stats/app-non-mutual-count/${ competitorId }/competitor/${ trackId }/${ countryCode }?${ paramsToQuery(queryParams) }`);
    }

    /**
     * Returns main app keywords count for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchMainKeywordsCount({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/sa/stats/app-non-mutual-count/${ trackId }/competitor/${ competitorId }/${ countryCode }?${ paramsToQuery(queryParams)}`);
    }

    /**
     * Returns mutual keywords count for selected app and competitor app
     * @param queryParams
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - competitorId: trackId of selected competitor app, long
     * - countryCode: country code, string
     * - }
     */
    fetchMutualKeywordsCount({ trackId, competitorId, countryCode }, queryParams) {
        return this.requestPooler(`/sa/stats/app-mutual-count/${ trackId }/competitor/${ competitorId }/${ countryCode }?${ paramsToQuery(queryParams) }`);
    }

    /**
     * Returns search ads top advertiser apps for a given country
     * @param urlParams
     * {
     *  countryCode: country code, string
     *  date: date, string
     *
     * @returns [{"trackId":571613512, "score":100.0, "date":"2020-08-09"}, ...]
     * }
     */
    fetchTopAdvertisers({ countryCode, date, category }) {
        return this.requestPooler(`/sa/stats/scores/${ countryCode }?${ paramsToQuery({ date, category }) }`);
    }

    /**
     * Returns search ads top advertiser apps for a given country
     * @param countryCode
     * @param device
     * @param trackIds
     * @param startDate
     * @param endDate
     * @param rankings
     * @returns {unknown}
     */
    fetchTopAdvertisersCategoryRanking({ countryCode, device, trackIds, startDate, endDate, rankings }) {
        let url = `/categoryrankings/bulk/${countryCode}/${device}?rankings=${rankings}&startDate=${startDate}&endDate=${endDate}`;

        return this.requestPooler(url, {
            method: 'POST',
            data: trackIds,
        });
    }

    /**
     * Returns keyword rankings for selected countryCode for given app
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - countryCode: country code, string
     * - device: device of selected app, string
     * - keywords: Array of String keywords
     * - }
     */
    fetchKeywordRankings({ trackId, countryCode, device, keywords }) {
        const query = keywords.map(keyword => `keywords=${ encodeURIComponent(keyword) }`).join('&');
        return this.requestPooler(`/sa/stats/keyword-ranking/app/${ trackId }/${ countryCode }/${ device }?${ query }`);
    }

    /**
     * Returns search ads visibility score and keyword count for selected app and country
     * @param urlParams
     * - {
     * - trackId: trackId of selected app, long
     * - countryCode: country code, string
     * - }
     */
    searchAdsVisibilityScoreAndKwCount({ trackIds, countryCode, date }) {
        return this.requestPooler(`/sa/stats/apps-score-and-kw-count/${ countryCode }?${ paramsToQuery({ trackIds, date }) }`);
    }



    /**
     * Returns active countries for selected app's running search ads
     * @param urlParams
     * -  {
     * - trackId: trackId of selected app, long
     * - }
     */
    fetchActiveCountries({ trackId }) {
        return this.requestPooler(`/sa/stats/scores/${ trackId }/countries`);
    }


    /**
     * Returns the app score of an app for a country
     * @param countryCode
     * @param trackId
     * @returns {*|Promise<any>}
     */
    fetchAppScore({ countryCode, trackId }) {
        return this.requestPooler(`/sa/stats/scores/${ countryCode }/${ trackId }/score`);
    }

    /**
     *
     * @param urlParams
     * - keyword: keyword string
     * - region: default to us
     * - startDate: date in string format YYYY-MM-DD
     * - endDate: date in string format YYYY-MM-DD
     */
    getKeywordStatistic({ keyword, region, startDate, endDate }) {
        return this.requestPooler(`/sa/stats/keyword/${ encodeURIComponent(keyword) }/${ region }?startDate=${
            startDate }&endDate=${ endDate }`);
    }
    /**
     * @param urlParams
     * - trackId: Searched trackId
     * - region: default to us
     * - startDate: date in string format YYYY-MM-DD
     * - endDate: date in string format YYYY-MM-DD
     * @param {{ minPercentage: number, minPercentage: number, minPopularity: number, maxPopularity: number, keywordFilter: string }} optionalParams - Optional Filter Params
     * @param {boolean} deduplicate
     */
    getAppStatistics({ trackId, region, startDate, endDate, pageSize, currentPage, sortColumn, order }, optionalParams = {}, deduplicate = true) {
        let url = `/sa/stats/app/${ trackId }/${ region }?startDate=${ startDate }&endDate=${ endDate }&pageSize=${
            pageSize }&currentPage=${ currentPage }&sortColumn=${ encodeURIComponent(sortColumn) }&order=${ order }`;
        Object.keys(optionalParams)
            .forEach((key) => {
                url += `&${ key }=${ encodeURIComponent(optionalParams[key]) }`;
            });
        return this.requestPooler(url, {}, { deduplicate });
    }

    translateKeywords({ storefront, keywords }) {
        return this.requestPooler(`/translate/ios/keywords/${ storefront }`, { method: 'POST' , data: keywords });
    }
}

export const useKeywordAuctionController = () => new KeywordAuctionController();
