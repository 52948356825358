{
    "PA": "パナマ",
    "PY": "パラグアイ",
    "BR": "ブラジル",
    "DO": "ドミニカ共和国",
    "SV": "エルサルバドル",
    "HN": "ホンジュラス",
    "BO": "ボリビア",
    "GT": "グアテマラ",
    "CR": "コスタリカ",
    "searchCountry": "国を検索...",
    "AE": "アラブ首長国連邦",
    "AL": "アルバニア",
    "AR": "アルゼンチン",
    "AT": "オーストリア",
    "AU": "オーストラリア",
    "AZ": "アゼルバイジャン",
    "BE": "ベルギー",
    "CA": "カナダ",
    "CH": "スイス",
    "CL": "チリ",
    "CN": "中国",
    "CO": "コロンビア",
    "CZ": "チェコ共和国",
    "DE": "ドイツ",
    "DK": "デンマーク",
    "EC": "エクアドル",
    "EG": "エジプト",
    "ES": "スペイン",
    "FI": "フィンランド",
    "FR": "フランス",
    "GB": "イギリス",
    "GR": "ギリシャ",
    "HK": "香港",
    "HR": "クロアチア",
    "HU": "ハンガリー",
    "ID": "インドネシア",
    "IE": "アイルランド",
    "IL": "イスラエル",
    "IN": "インド",
    "IT": "イタリア",
    "JO": "ヨルダン",
    "JP": "日本",
    "KH": "カンボジア",
    "KR": "韓国",
    "KW": "クウェート",
    "KZ": "カザフスタン",
    "LB": "レバノン",
    "MO": "マカオ",
    "MX": "メキシコ",
    "MY": "マレーシア",
    "NL": "オランダ",
    "NO": "ノルウェー",
    "NZ": "ニュージーランド",
    "OM": "オマーン",
    "PE": "ペルー",
    "PH": "フィリピン",
    "PK": "パキスタン",
    "PL": "ポーランド",
    "PT": "ポルトガル",
    "QA": "カタール",
    "RO": "ルーマニア",
    "RU": "ロシア",
    "SA": "サウジアラビア",
    "SE": "スウェーデン",
    "SG": "シンガポール",
    "TH": "タイ",
    "TW": "台湾",
    "UA": "ウクライナ",
    "US": "アメリカ",
    "VN": "ベトナム",
    "ZA": "南アフリカ",
    "active": "有効",
    "disabled": "無効",
    "multiple": "複数",
    "regions": {
        "africaMiddleEast": "アフリカ、中東、インド",
        "asiaPacific": "アジア太平洋",
        "europe": "ヨーロッパ",
        "latinAmerica": "南米およびカリブ地域",
        "northAmerica": "米国、カナダ、プエルトリコ"
    }
}
