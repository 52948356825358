// p.s Add components alphabetically in order
import { message, Spin, Modal, notification } from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import MaSpinIcon from '@/components/MaSpinIcon.vue';

export default {
    install(app) {
        app.config.globalProperties.$message = message;
        app.config.globalProperties.$confirm = Modal.confirm;
        app.config.globalProperties.$notification = notification;
        app.config.globalProperties.$destroyAllModals = Modal.destroyAll;
        app.config.globalProperties.$destroyAllMessages = message.destroy;
        app.config.globalProperties.$destroyAllNotifications = notification.destroy;

        // set default indicator in one place, you can still use indicator slot for setting different size
        Spin.setDefaultIndicator({ indicator: MaSpinIcon });
    },
};
