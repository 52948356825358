import { makeRequestNoAuth } from '@/common/MaRequestPooler';
import { PUBLIC_API_KEY } from '@/common/Config';

export class PublicController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequestNoAuth;
    }

    /**
     * Get searchads-ui project version
     * @returns {unknown}
     */
    getSaVersionInfo() {
        return this.requestPooler(`${ window.origin }/app-info.json?t=${ Date.now() }`);
    }

    /**
     *
     * @param reportId
     * @returns {unknown}
     */
    getGraderReport({ reportId }) {
        return this.requestPooler(
            `/public/sa/grader/generate-report/${reportId}?publicApiKey=${PUBLIC_API_KEY}`,
            {},
            {
                cacheEnabled: false,
                deduplicate: false,
            }
        );
    }
}

export const usePublicController = () => new PublicController();
