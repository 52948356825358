import cn from '@/i18n/components/MaKeywordList/cn.json';
import en from '@/i18n/components/MaKeywordList/en.json';
import ja from '@/i18n/components/MaKeywordList/ja.json';
import ko from '@/i18n/components/MaKeywordList/ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
