import cn from '@/i18n/components/KeywordCart/cn.json';
import en from '@/i18n/components/KeywordCart/en.json';
import ja from '@/i18n/components/KeywordCart/ja.json';
import ko from '@/i18n/components/KeywordCart/ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
