import cn from '@/i18n/components/Sidebar/Sidebar.cn.json';
import en from '@/i18n/components/Sidebar/Sidebar.en.json';
import ja from '@/i18n/components/Sidebar/Sidebar.ja.json';
import ko from '@/i18n/components/Sidebar/Sidebar.ko.json';
export default {
    messages: {
        cn,
        en,
        ja,
        ko,
    },
};
