export const LOG_STATE = {
    COMPLETED: 'COMPLETED',
    PENDING: 'IN_PROGRESS',
};

export const LOG_SUCCESS_STATE = {
    SUCCESSFUL: 'SUCCESSFUL',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
};

export const BULK_ACTIONS = {
    CHANGE_BID: 'CHANGE_BID',
    CHANGE_DEFAULT_CPC_BID: 'CHANGE_DEFAULT_CPC_BID',
    CHANGE_DAILY_CAP: 'CHANGE_DAILY_CAP',
    CHANGE_CPA_GOAL: 'CHANGE_CPA_GOAL',
    ADD_LABEL: 'ADD_LABEL',
};
