{
    "PA": "巴拿马",
    "PY": "巴拉圭",
    "BR": "巴西",
    "DO": "多米尼加共和国",
    "SV": "萨尔瓦多",
    "HN": "洪都拉斯",
    "BO": "玻利维亚",
    "GT": "危地马拉",
    "CR": "哥斯达黎加",
    "searchCountry": "搜索国家...",
    "AE": "阿联酋",
    "AL": "阿尔巴尼亚",
    "AR": "阿根廷",
    "AT": "奥地利",
    "AU": "澳大利亚",
    "AZ": "阿塞拜疆",
    "BE": "比利时",
    "CA": "加拿大",
    "CH": "瑞士",
    "CL": "智利",
    "CN": "中国",
    "CO": "哥伦比亚",
    "CZ": "捷克共和国",
    "DE": "德国",
    "DK": "丹麦",
    "EC": "厄瓜多尔",
    "EG": "埃及",
    "ES": "西班牙",
    "FI": "芬兰",
    "FR": "法国",
    "GB": "英国",
    "GR": "希腊",
    "HK": "香港",
    "HR": "克罗地亚",
    "HU": "匈牙利",
    "ID": "印度尼西亚",
    "IE": "爱尔兰",
    "IL": "以色列",
    "IN": "印度",
    "IT": "意大利",
    "JO": "约旦",
    "JP": "日本",
    "KH": "柬埔寨",
    "KR": "大韩民国",
    "KW": "科威特",
    "KZ": "哈萨克斯坦",
    "LB": "黎巴嫩",
    "MO": "澳门",
    "MX": "墨西哥",
    "MY": "马来西亚",
    "NL": "荷兰",
    "NO": "挪威",
    "NZ": "新西兰",
    "OM": "阿曼",
    "PE": "秘鲁",
    "PH": "菲律宾",
    "PK": "巴基斯坦",
    "PL": "波兰",
    "PT": "葡萄牙",
    "QA": "卡塔尔",
    "RO": "罗马尼亚",
    "RU": "俄罗斯",
    "SA": "沙特阿拉伯",
    "SE": "瑞典",
    "SG": "新加坡",
    "TH": "泰国",
    "TW": "台湾",
    "UA": "乌克兰",
    "US": "美国",
    "VN": "越南",
    "ZA": "南非",
    "active": "有效",
    "disabled": "残疾",
    "multiple": "多个",
    "regions": {
        "africaMiddleEast": "非洲、中东和印度",
        "asiaPacific": "亚太地区",
        "europe": "欧洲",
        "latinAmerica": "拉丁美洲",
        "northAmerica": "美国和加拿大"
    }
}
