<template>
    <div
        class="select-none h-[32px] desktop-xl:max-w-[240px] max-w-[120px]"
        :class="{
            'ma-selector-popover--trigger': true,
            'ma-dropdown': variant === 'dropdown',
            'ma-open': isOpen,
            'font-medium': count > 0,
        }"
    >
        <template v-if="count || selectedLabel">
            <ma-tooltip v-if="selectedLabel" :title="selectedLabel" type="primary">
                <ma-typography class="text-nowrap overflow-ellipsis overflow-hidden" type="body-4" weight="medium">
                    {{ selectedLabel || t('selectedNotifiers') }}
                </ma-typography>
            </ma-tooltip>
            <ma-typography
                v-else class="text-nowrap overflow-ellipsis overflow-hidden"
                type="body-4"
                weight="medium"
            >
                {{ selectedLabel || t('selectedNotifiers') }}
            </ma-typography>

            <ma-badge
                v-if="count"
                variant="dark"
                size="small"
                type="secondary"
                class="ml-1"
            >
                {{ count }}
            </ma-badge>
        </template>
        <template v-else>
            <span class="text-gray-400 truncate">
                {{ noSelectLabel || t('selectNotifiers') }}
            </span>
        </template>
        <template v-if="showArrow">
            <ma-icon
                v-show="isOpen"
                name="chevron-up"
                size="sm"
                class="text-gray-400 flex-shrink-0 block"
            />
            <ma-icon
                v-show="!isOpen"
                name="chevron-down"
                size="sm"
                class="text-gray-400 flex-shrink-0 block"
            />
        </template>
    </div>
</template>

<script setup>
    import { MaBadge, MaIcon, MaTooltip, MaTypography } from '@mobileaction/action-kit';
    import i18n from '@/pages/Automations/Components/AutomationComponents.i18n';
    import { useI18n } from 'vue-i18n';

    defineOptions({ i18n });
    const { t } = useI18n();

    defineProps({
        count: { type: Number, default: 0 },
        noSelectLabel: { type: String, default: null },
        selectedLabel: { type: String, default: null },
        isOpen: { type: Boolean },
        showArrow: { type: Boolean, default: true },
        variant: {
            type: String,
            default: 'borderless',
            validator: val => ['borderless', 'dropdown'].includes(val),
        },
    });
</script>

<style lang="less">
.ma-selector-popover--trigger {
    @apply flex gap-2 cursor-pointer text-gray-900 w-fit ~'p-1.5' pl-3 rounded-lg;
    &:hover {
        @apply bg-gray-50;
    }
    &.ma-dropdown {
        @apply border border-gray-300 transition duration-300 py-1 pl-4 pr-3;
        &:hover {
            @apply !bg-transparent border-gray-400;
        }
        &.ma-open {
            @apply border-jordy-blue;
        }
    }
}
</style>
