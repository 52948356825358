import { makeRequest } from '@/common/MaRequestPooler';
import { getAsArray } from '@/common/MaUtils.mjs';

export function adGroupUpdateBid(campId, adGroupIds, bid) {
    return makeRequest(`/sa/management/campaigns/${ campId }/adgroups/bid-change`, {
        method: 'PUT',
        data: {
            adGroupIds: getAsArray(adGroupIds),
            bid, // budget e.g: { currency: 'USD', amount: 560.5 }
        },
    });
}

export function addKeywordsToAdgroup(campaignId, adGroupId, keywords, type = 'keyword') {
    return makeRequest(`/sa/management/campaign/${ campaignId }/adgroup/${ adGroupId }/${ type }`, {
        method: 'POST',
        data: { keywordInfoList: keywords },
    });
}

export function copyAdGroupKeywordsToAnotherOne({ sourceCampaignId, sourceAdGroupId, targetCampaignId, targetAdGroupId }) {
    return makeRequest(`/sa/management/campaign/${ sourceCampaignId }/adgroup/${ sourceAdGroupId
    }/copy-keywords-to/${ targetCampaignId }/${ targetAdGroupId }`, {
        method: 'POST',
    });
}

export function copyAdGroupNegativeKeywordsToAnotherOne({ sourceCampaignId, sourceAdGroupId, targetCampaignId, targetAdGroupId }) {
    return makeRequest(`/sa/management/campaign/${ sourceCampaignId }/adgroup/${ sourceAdGroupId
    }/copy-negative-keywords-to/${ targetCampaignId }/${ targetAdGroupId }`, {
        method: 'POST',
    });
}

export function updateKeywordsToAdgroup(campaignId, adGroupId, keywords, type = 'keyword') {
    return makeRequest(`/sa/management/campaign/${ campaignId }/adgroup/${ adGroupId }/${ type }`, {
        method: 'PUT',
        data: { keywordInfoList: keywords },
    });
}

export function deleteNegativeKeywords(campaignId, adGroupId, data) {
    return makeRequest(`/sa/management/campaign/${ campaignId }/adgroup/${ adGroupId }/negative-keyword`, {
        method: 'DELETE',
        data,
    });
}

export function createCampaign(data) {
    return makeRequest(`/sa/management/campaign`, {
        method: 'POST',
        data,
    });
}

export function updateCampaign(data, campId) {
    return makeRequest(`/sa/management/campaign/${ campId }`, {
        method: 'PUT',
        data,
    });
}

export function deleteCampaigns(campIds) {
    return makeRequest(`/sa/management/campaign`, {
        method: 'DELETE',
        data: getAsArray(campIds),
    });
}

export function createAdGroup(campId, data) {
    return makeRequest(`/sa/management/campaign/${ campId }/ad-group`, {
        method: 'POST',
        data,
    });
}

export function updateAdGroup(campId, adGroupId, data) {
    return makeRequest(`/sa/management/campaign/${ campId }/adgroups/${ adGroupId }`, {
        method: 'PUT',
        data,
    });
}

export function deleteAdGroups(campId, adGroupIds) {
    return makeRequest(`/sa/management/campaign/${ campId }/ad-group`, {
        method: 'DELETE',
        data: getAsArray(adGroupIds),
    });
}

/**
 * Returns all locations filtered with query
 *
 * @param query: filtering query of location
 * @param countryCode: code of search ads country
 */
export function geoSearch(query, countryCode) {
    return makeRequest(`/sa/management/search/geo?query=${ query }&countryCode=${ countryCode }`);
}

export function getGeoMetadata(data) {
    return makeRequest(`/sa/management/search/geo`, {
        method: 'POST',
        data: getAsArray(data),
    });
}

export function updateCampaignTotalBudget(campIds, budget) {
    return makeRequest(`/sa/management/campaigns/budget-update`, {
        method: 'PUT',
        data: {
            campaignIds: campIds,
            budget, // budget e.g: { currency: 'USD', amount: 560.5 }
        },
    });
}

export function updateCampaignDailyCap(campIds, dailyCap) {
    return makeRequest(`/sa/management/campaigns/daily-cap-update`, {
        method: 'PUT',
        data: {
            campaignIds: campIds,
            dailyCap, // dailyCap e.g: { currency: 'USD', amount: 560.5 }
        },
    });
}

export function duplicateCampaign(data) {
    return makeRequest(`/sa/management/campaigns/duplicate`, {
        method: 'POST',
        data,
    });
}

export function duplicateAdGroups(sourceCampaignId, targetCampaignId, data) {
    return makeRequest(`/sa/management/campaigns/${ sourceCampaignId }/adgroups/to/${ targetCampaignId }/duplicate`, {
        method: 'POST',
        data,
    });
}

export function duplicateAdGroup(data) {
    return makeRequest(`/sa/management/campaigns/adgroups/duplicate`, {
        method: 'POST',
        data,
    });
}

export function duplicateNegativeKeywordsOfCampaign(sourceCampaignId, targetCampaignId) {
    return makeRequest(`/sa/management/campaigns/${ sourceCampaignId }/negativekeywords/to/${ targetCampaignId
    }/duplicate`, {
        method: 'POST',
    });
}

export function duplicateTargetedKeywordsOfAdGroup({ sourceOrgId, sourceCampaignId, sourceAdGroupId, targetCampaignId, targetAdGroupId }) {
    return makeRequest(`/sa/management/campaigns/${ sourceOrgId }/${ sourceCampaignId }/${ sourceAdGroupId
    }/targetedkeywords/to/${ targetCampaignId }/${ targetAdGroupId }/duplicate`, {
        method: 'POST',
    });
}

export function duplicateAdGroupKeywordsOfCampaign(data) {
    return makeRequest('/sa/management/campaigns/adgroups/targetedkeywords/duplicate', {
        method: 'POST',
        data,
    });
}

export function duplicateAdGroupNegativeKeywordsOfCampaign(data) {
    return makeRequest('/sa/management/campaigns/adgroups/negativekeywords/duplicate', {
        method: 'POST',
        data,
    });
}

export function duplicateAdGroupAdsOfCampaign(data) {
    return makeRequest('/sa/management/campaigns/adgroups/ads/duplicate', {
        method: 'POST',
        data,
    });
}

export function duplicateNegativeKeywordsOfAdGroup({ sourceOrgId, sourceCampaignId, sourceAdGroupId, targetCampaignId, targetAdGroupId }) {
    return makeRequest(`/sa/management/campaigns/${ sourceOrgId }/${ sourceCampaignId }/${ sourceAdGroupId
    }/negativekeywords/to/${ targetCampaignId }/${ targetAdGroupId }/duplicate`, {
        method: 'POST',
    });
}

export function addNegativeKeywordsToCampaign(campaignId, keywords, type = 'negative-keyword') {
    return makeRequest(`/sa/management/campaign/${ campaignId }/${ type }`, {
        method: 'POST',
        data: { keywordInfoList: keywords },
    });
}

export function updateNegativeKeywordsToCampaign(campaignId, keywords) {
    return makeRequest(`/sa/management/campaign/${ campaignId }/negative-keyword`, {
        method: 'PUT',
        data: { keywordInfoList: keywords },
    });
}

export function deleteNegativeKeywordsToCampaign(campaignId, data) {
    return makeRequest(`/sa/management/campaign/${ campaignId }/negative-keyword`, {
        method: 'DELETE',
        data,
    });
}

export function duplicateCampaignNegativeKeywords(data) {
    return makeRequest('/sa/management/campaigns/negativekeywords/duplicate', {
        method: 'POST',
        data,
    });
}

/**
 * Example Request:
 const req = {
        appId: 1413104318,
        campId: 203450100,
        keywords: [{ adGroupId: 1, text: 'young', matchType: 'BROAD', bidAmount: { currency: 'USD', amount: 1 } }],
    };
 * Example Response:
 [{
        id: 203490899
        text: "young"
        keywordType: "KEYWORD"
        countryOrRegion: "AU"
        bidAmount: {currency: "USD", amount: 0.15}
        keywordStatus: "ACTIVE"
        matchType: "BROAD"
        campaignId: 203450100
        campaignName: "Australia"
        campaignStatus: "PAUSED"
        adGroupId: 203490768
        adGroupName: "Essential Oils"
        adGroupStatus: "ENABLED"
    }]
 **/
export function checkKeywords({ appId, campId, keywords = [] }) {
    return makeRequest(`/sa/dashboard/report/apps/${appId}/campaigns/${campId}/keywords/check`, {
        method: 'POST',
        data: { keywordInfoList: keywords },
    });
}
export function checkKeywordsWithCountries({ appId, countryCodes, keywords = [] }) {
    return makeRequest(`/sa/dashboard/report/apps/${ appId }/country-codes/${ encodeURIComponent(countryCodes) }/keywords/check`, {
        method: 'POST',
        data: { keywordInfoList: keywords },
    });
}

export function getAllCountriesOfApp({ appId, orgIdList }) {
    return makeRequest(`/sa/dashboard/report/apps/${appId}/countries`, {
        method: 'POST',
        data: getAsArray(orgIdList),
    });
}

export function geoClientOwnApps() {
    return makeRequest(`/sa/management/own/apps`);
}

export function getCppRejectReasons({ appId, productPageId }) {
    return makeRequest(`/sa/management/apps/${ appId }/product-pages/${ productPageId }/reasons`);
}
