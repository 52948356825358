<template>
    <ma-spin :spinning="loading">
        <ma-input
            v-model:value="searchText"
            prefix-icon="search-normal"
            :placeholder="t('filterByKeyword')"
            class="mb-4 ma-borderless"
        />
        <a-table
            :data-source="paginatedKeywords"
            :pagination="false"
            class="ma-keyword-suggestions-table"
        >
            <a-table-column align="center" :width="50">
                <template #title>
                    <ma-button
                        v-if="!allAdded"
                        icon="add"
                        size="small"
                        class="mx-auto"
                        color="blue"
                        variant="stroke"
                        @click="emit('add-all-keywords', paginatedKeywords)"
                    />
                    <ma-button
                        v-else
                        icon="check-line"
                        size="small"
                        class="mx-auto"
                        color="blue"
                        @click="emit('remove-keywords', paginatedKeywords)"
                    />
                </template>
                <template #default="{ record: row }">
                    <ma-button
                        v-if="!addedKeywordsMap[row.keyword]"
                        icon="add"
                        size="small"
                        class="mx-auto"
                        color="blue"
                        variant="stroke"
                        @click="emit('add-keyword', row)"
                    />
                    <ma-button
                        v-else
                        icon="check-line"
                        size="small"
                        class="mx-auto"
                        color="blue"
                        @click="emit('remove-keywords', [row])"
                    />
                </template>
            </a-table-column>
            <a-table-column
                data-index="keyword"
                min-width="150"
                :title="'Keyword'"
            />
            <a-table-column
                data-index="searchVolume"
                min-width="150"
                align="center"
            >
                <template #title>
                    <ma-table-header header-key="searchScore"/>
                </template>
            </a-table-column>
            <a-table-column
                data-index="todaysRank"
                min-width="170"
                align="center"
            >
                <template #title>
                    <ma-table-header header-key="todaysRank"/>
                </template>
                <template #default="{ record: row }">
                    <ma-todays-rank
                        text=""
                        :rank="row.rank || 0"
                        :diff="row.change || 0"
                        :hide-ranking-button="true"
                    />
                </template>
            </a-table-column>

            <template #emptyText>
                <ma-empty
                    v-if="selectedGroupKeywords === null && !searchText"
                    :title="t('noTrackedKeywords')"
                    size="medium"
                    class="my-8"
                >
                    <template #description>
                        <div>
                            {{ t('trackKeywordsForSelected') }}
                        </div>
                    </template>
                    <ma-button type="link" class="mt-4" @click="goToMobileAction">
                        {{ t('goToKeywordTracking') }}
                    </ma-button>
                </ma-empty>
                <ma-empty
                    v-else
                    :title="t('noDataForCurrentFilters')"
                    :description="t('changeOrClearFilters')"
                    size="medium"
                    class="my-8"
                >
                    <ma-button
                        color="blue"
                        variant="ghost"
                        class="mt-4"
                        @click="clearFilters"
                    >
                        {{ t('clearFilters') }}
                    </ma-button>
                </ma-empty>
            </template>
        </a-table>

        <ma-pagination
            v-model:current="currentPage"
            v-model:per-page="pageSize"
            :page-size-options="[5, 10, 20]"
            :total-items="filteredKeywords.length"
            size="small"
            :limits="{ xs: 3, sm: 3, md: 3, lg: 3, default: 3 }"
            show-less-items
            class="m-1 mt-4"
        />
    </ma-spin>
</template>

<script setup>
    import { computed, onMounted, ref, watch } from 'vue';
    import { MaButton, MaEmpty, MaInput, MaPagination, MaSpin } from '@mobileaction/action-kit';
    import MaTableHeader from '@/components/TableHeader.vue';
    import MaTodaysRank from '@/components/TodaysRank.vue';
    import messages from '@/components/KeywordAddModal/KeywordAddModal.i18n';
    import { useI18n } from 'vue-i18n';
    import { INSIGHTS_DOMAIN } from '@/common/Config';
    import { useASOTrackedKeywordsController } from '@/controllers/ASOTrackedKeywordsController';

    defineOptions({
        i18n: { messages },
    });
    const { t } = useI18n();

    const props = defineProps({
        countryCode: { type: String, required: true },
        appId: { type: [Number, String], required: true },
        selectedGroupKeywords: { type: [Array, null], required: true },
        addedKeywordsMap: { type: Object, required: true },
    });

    const keywords = ref([]);
    const loading = ref(false);
    const controller = useASOTrackedKeywordsController();
    const fetchTrackedKeywords = async () => {
        loading.value = true;
        try {
            keywords.value = await controller.fetchASOTrackedKeywords({ trackId: props.appId, countryCode: props.countryCode });
        } catch (e) {
            console.error('Failed to fetch tracked keywords', e);
            keywords.value = [];
        } finally {
            loading.value = false;
        }
    };

    onMounted(() => {
        fetchTrackedKeywords();
    });
    watch(() => [props.appId, props.countryCode], () => {
        fetchTrackedKeywords();
    });

    const searchText = ref('');
    const filteredKeywords = computed(() => {
        const kws = props.selectedGroupKeywords || keywords.value;
        return kws.filter(row => (row.keyword || '').toLowerCase()?.includes(searchText.value.toLowerCase()));
    });

    const currentPage = ref(1);
    const pageSize = ref(10);
    const paginatedKeywords = computed(() => {
        const start = (currentPage.value - 1) * pageSize.value;
        const end = currentPage.value * pageSize.value;
        return filteredKeywords.value.slice(start, end);
    });

    const goToMobileAction = () => {
        const landingUrl = INSIGHTS_DOMAIN;
        window.open(landingUrl + '/aso-intelligence/basic/keyword-tracking', '_blank');
    };

    const emit = defineEmits(['clear-filters', 'add-keyword', 'remove-keywords', 'add-all-keywords']);
    const clearFilters = () => {
        searchText.value = '';
        emit('clear-filters');
    };

    const allAdded = computed(() => {
        return paginatedKeywords.value.every((k) => {
            return props.addedKeywordsMap[k.keyword];
        });
    });
</script>
