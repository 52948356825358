{
    "addedKeywords": "追加されたキーワード",
    "addToKeywords": "キーワードの追加",
    "addToNegativeKeywords": "除外キーワードの追加",
    "dataIsNotReady": "最新のデータを読み込み中です。完了後にリストに追加できます",
    "limitForAdGroupKeywords": "広告グループのキーワード数は5000件が上限です。これ以上追加できません。",
    "limitForAdGroupKeywords2": "広告グループのキーワード数は5000件が上限です。上限を超えたキーワードは追加されません。",
    "noKeywordAddedList": "キーワードがリストに追加されていません",
    "popularity": "検索ボリューム",
    "runAds": "広告に追加",
    "total": "合計:"
}
