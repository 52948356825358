{
    "disabledCustomTimeFrameTooltip": "これらのカスタムタイムフレームは、他のアルゴリズムの誤動作につながるため、編集することはできません。",
    "defaultDateRanges": {
        "CURRENT": "現在の価格",
        "FROM_START": "キャンペーン開始から",
        "LAST_HOUR": "過去1時間",
        "LAST_DAY": "直近1日（本日を含む）",
        "LAST_3_DAY": "直近3日間（本日を含む）",
        "LAST_WEEK": "直近7日間（本日を含む）",
        "DURING_LAST_WEEK": "先週（日～月）",
        "LAST_14_DAY": "直近14日間（本日を含む）",
        "THIS_MONTH": "今月",
        "LAST_MONTH": "過去30日間（本日を含む）",
        "LAST_2_MONTH": "直近60日間（本日を含む）",
        "LAST_3_MONTH": "過去90日間（本日を含む）",
        "CUSTOM_HOUR": "カスタムアワー"
    },
    "add": "追加",
    "addNewNotifier": "新しいノーティファイアを追加する",
    "addNewChannel": "新しいSlackチャンネルを追加する",
    "addLabel": "ラベルの追加",
    "selectAll": "全て選択",
    "selectedLabels": "選択されたラベル",
    "addFirstLabel": "最初のラベルをここに追加",
    "addNewLabel": "新しいラベルの追加",
    "cancel": "いいえ、キャンセル",
    "clickToRemove": "クリックして削除",
    "confirmActivateTitle": "選択したオートメーションを有効にしますか？",
    "confirmActivateButton": "はい。",
    "confirmDeleteTitle": "選択したオートメーションを本当に削除しますか？",
    "confirmDeleteButton": "はい、削除",
    "confirmDuplicateTitle": "選択したオートメーションを本当に複製しますか？",
    "confirmDuplicateButton": "はい、重複",
    "confirmPauseTitle": "選択したオートメーションを本当に一時停止しますか？",
    "confirmPauseButton": "はい、一時停止",
    "createLabel": "ラベル作成",
    "days": "日数",
    "hours": "時間",
    "editRuleName": "ルール名の編集",
    "editLabel": "編集ラベル",
    "allLabels": "すべてのラベル",
    "allUsers": "全てのユーザー",
    "compareOptions": {
        "GREATER_THAN": "より大きい",
        "LESS_THAN": "未満",
        "IN_RANGE": "範囲内",
        "NOT_IN_RANGE": "範囲外",
        "EQUALS": "イコール",
        "NOT_EQUALS": "イコールではない",
        "CONTAINS": "内容",
        "NOT_CONTAINS": "含まれていない",
        "EMPTY": "空",
        "NOT_EMPTY": "空ではない",
        "IN": "で",
        "IS": "は",
        "IS_NOT": "ではない"
    },
    "createCustomTimeframe": "カスタム・タイムフレームの作成",
    "customTimeframes": "カスタム・タイムフレーム",
    "dayRangeText": "から{start} -{end} 日前",
    "defaultTimeframes": "デフォルトの時間枠",
    "delete": "削除",
    "duplicate": "複製する",
    "hourRangeText": "From{start} -{end} hours ago",
    "integrateSlack": "Slackとの統合",
    "label": "ラベル",
    "labelNameMustBeUnique": "ラベル名は一意でなければならない",
    "lastNDays": "直近の{n} 日間（本日を含む）",
    "lastNHours": "直近の{n} 時間（現在の時間を含む）",
    "noEmails": "メールが見つかりません",
    "pause": "停止",
    "searchLabel": "ラベル検索",
    "selected": "選択済",
    "selectNotifiers": "通知者の選択",
    "selectTimeframe": "時間枠の選択",
    "selectedNotifiers": "選択された通知者",
    "setActive": "アクティブに設定する",
    "untitledTimeframe": "無題の時間枠",
    "today": "今日",
    "youDontHaveSlack": "Slackとの統合がない"
}
