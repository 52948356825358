{
    "contactSupport": "サポートへ連絡",
    "freeAccess": "無償期間中",
    "integrations": "接続",
    "logOut": "ログアウト",
    "logs": "過去ログ",
    "pageWillRefreshIn": "ページが更新されます",
    "privacyPolicy": "プライバシーポリシー",
    "requestDemo": "デモリクエスト",
    "settings": "設定",
    "support": "サポート",
    "termsOfService": "利用規約",
    "youHaveSpend": "{startDate} 〜 {endDate}間の合計出稿額は {spend} です。",
    "actions": {
        "CHANGE_BID": "キーワード入札の更新",
        "CHANGE_DEFAULT_CPC_BID": "デフォルトCPTビッドの更新",
        "CHANGE_DAILY_CAP": "日予算を変更",
        "CHANGE_CPA_GOAL": "CPA目標の変更",
        "ADD_LABEL": "ラベルの追加"
    },
    "inProgress": "処理中",
    "completed": "完了",
    "actionsLabel": "行動",
    "seeLogs": "過去ログを見る",
    "noActionsYet": "まだ何もしていない"
}
