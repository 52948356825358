import { MixpanelEvent } from '@/common/Mixpanel';
import { useGlobal } from '@/composables';
import { useRoute } from 'vue-router';

// Keyword Auction Insights Events
export const kaiEvent = {
    runAdsForAllKW: new MixpanelEvent('SA-Keyword Auction Insights-Run Ads for all kws',[{ name: 'Tab', default: '' }]),
    runAdsForSingleKW: new MixpanelEvent('SA-Keyword Auction Insights-Run Ads for kw',[{ name: 'Tab', default: '' }]),
    addToKeywords: new MixpanelEvent('SA-KAI-Add to keywords'),
    addToNegativeKeywords: new MixpanelEvent('SA-KAI-Add to negative keywords'),
    storefrontFilterApplied: new MixpanelEvent('SA-KAI-Storefront Filter Applied',[{ name: 'Tab', default: '' }, { name: 'Storefront', default: '' }]),
    downloadCsv: new MixpanelEvent('SA-KAI-Download Csv'),
    sbaAdsRun: new MixpanelEvent('SA-KAI-Search by App-Ads Run'),
    sbaImpressionFilterApplied: new MixpanelEvent('SA-KAI-Search by App-Impression Share Filter Applied'),
    sbaPopularityFilterApplied: new MixpanelEvent('SA-KAI-Search by App-Popularity Filter Applied'),
    sbaKeywordFilterApplied: new MixpanelEvent('SA-KAI-Search by App-Keyword Filter Applied'),
    taCategoryFilterApplied: new MixpanelEvent('SA-KAI-Top Advertisers-Category Filter Applied',[{ name: 'Category', default: '' }]),
    kgAdsRun: new MixpanelEvent('SA-KAI-Keyword Gap-Ads Run'),
    kgPageView: new MixpanelEvent('SA-KAI-Keyword Gap-Page View'),
    kgMutualKeywords: new MixpanelEvent('SA-KAI-Keyword Gap-Mutual Keywords'),
    kgUniqueKeyword: new MixpanelEvent('SA-KAI-Keyword Gap-Unique Keyword'),
    kgImpressionFilterApplied: new MixpanelEvent('SA-KAI-Keyword Gap-Impression Share Filter Applied'),
    kgPopularityFilterApplied: new MixpanelEvent('SA-KAI-Keyword Gap-Popularity Filter Applied'),
    kgKeywordFilterApplied: new MixpanelEvent('SA-KAI-Keyword Gap-Keyword Filter Applied'),
};

export const useKeywordAuctionInsightEvents = () => {
    const { $mixpanel } = useGlobal();
    const route = useRoute();

    const trackRunAdsForAllKw = (tab) => {
        kaiEvent.runAdsForAllKW.setPropertyValue('Tab', tab);
        $mixpanel.track(...kaiEvent.runAdsForAllKW.toMixpanel());
    };
    const trackRunAdsForSingleKw = (tab) => {
        kaiEvent.runAdsForSingleKW.setPropertyValue('Tab', tab);
        $mixpanel.track(...kaiEvent.runAdsForSingleKW.toMixpanel());
    };
    const trackAddToKeywords = () => $mixpanel.track(...kaiEvent.addToKeywords.toMixpanel());
    const trackAddToNegativeKeywords = () => $mixpanel.track(...kaiEvent.addToNegativeKeywords.toMixpanel());
    const trackStorefrontFilterApplied = (storefront, tab) => {
        kaiEvent.storefrontFilterApplied.setPropertyValue('Storefront', storefront);
        kaiEvent.storefrontFilterApplied.setPropertyValue('Tab', tab);
        $mixpanel.track(...kaiEvent.storefrontFilterApplied.toMixpanel());
    };
    const trackDownloadCsv = () => {
        kaiEvent.downloadCsv.setPropertyValue('Tab', route.name);
        $mixpanel.track(...kaiEvent.downloadCsv.toMixpanel());
    };
    const trackSbaAdsRun = () => $mixpanel.track(...kaiEvent.sbaAdsRun.toMixpanel());
    const trackSbaImpressionFilterApplied = () => $mixpanel.track(...kaiEvent.sbaImpressionFilterApplied.toMixpanel());
    const trackSbaPopularityFilterApplied = () => $mixpanel.track(...kaiEvent.sbaPopularityFilterApplied.toMixpanel());
    const trackSbaKeywordFilterApplied = () => $mixpanel.track(...kaiEvent.sbaKeywordFilterApplied.toMixpanel());
    const trackTaCategoryFilterApplied = (category) => {
        kaiEvent.taCategoryFilterApplied.setPropertyValue('Category', category);
        $mixpanel.track(...kaiEvent.taCategoryFilterApplied.toMixpanel());
    };
    const trackKgAdsRun = () => $mixpanel.track(...kaiEvent.kgAdsRun.toMixpanel());
    const trackKgPageView = () => $mixpanel.track(...kaiEvent.kgPageView.toMixpanel());
    const trackKgMutualKeywords = () => $mixpanel.track(...kaiEvent.kgMutualKeywords.toMixpanel());
    const trackKgUniqueKeyword = () => $mixpanel.track(...kaiEvent.kgUniqueKeyword.toMixpanel());
    const trackKgImpressionFilterApplied = () => $mixpanel.track(...kaiEvent.kgImpressionFilterApplied.toMixpanel());
    const trackKgPopularityFilterApplied = () => $mixpanel.track(...kaiEvent.kgPopularityFilterApplied.toMixpanel());
    const trackKgKeywordFilterApplied = () => $mixpanel.track(...kaiEvent.kgKeywordFilterApplied.toMixpanel());

    return {
        trackRunAdsForAllKw,
        trackRunAdsForSingleKw,
        trackAddToKeywords,
        trackAddToNegativeKeywords,
        trackStorefrontFilterApplied,
        trackDownloadCsv,
        trackSbaAdsRun,
        trackSbaImpressionFilterApplied,
        trackSbaPopularityFilterApplied,
        trackSbaKeywordFilterApplied,
        trackTaCategoryFilterApplied,
        trackKgAdsRun,
        trackKgPageView,
        trackKgMutualKeywords,
        trackKgUniqueKeyword,
        trackKgImpressionFilterApplied,
        trackKgPopularityFilterApplied,
        trackKgKeywordFilterApplied,
    };
};
