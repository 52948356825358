import { makeRequest } from '@/common/MaRequestPooler';

export function filterPresets() {
    return makeRequest(`/sa/filter-presets`);
}

export function addFilterPreset(data) {
    return makeRequest(`/sa/filter-presets`, {
        method: 'POST',
        data,
    });
}

export function deleteFilterPreset(presetId) {
    return makeRequest(`/sa/filter-presets/${ presetId }`, {
        method: 'DELETE',
    });
}
