{
    "contactSupport": "Contact Support",
    "freeAccess": "Free Access",
    "integrations": "Integrations",
    "logOut": "Logout",
    "logs": "Logs",
    "pageWillRefreshIn": "Page will refresh in",
    "privacyPolicy": "Privacy Policy",
    "requestDemo": "Request Demo",
    "settings": "Settings",
    "support": "Support",
    "termsOfService": "Terms of Service",
    "youHaveSpend": "You have spent total of {spend} from {startDate} to {endDate} for Search Ads",
    "actions": {
        "CHANGE_BID": "Update Keyword Bids",
        "CHANGE_DEFAULT_CPC_BID": "Update Default CPT Bid",
        "CHANGE_DAILY_CAP": "Change Daily Budget",
        "CHANGE_CPA_GOAL": "Change CPA Goal",
        "ADD_LABEL": "Add Labels"
    },
    "inProgress": "In Progress",
    "completed": "Completed",
    "actionsLabel": "Actions",
    "seeLogs": "See logs",
    "latestDataCalled": "Request to refresh data is taken successfully. This takes more than 5 minutes...",
    "lastUpdateTimeDisabled": "Last Update Time: {time} UTC <br> Click to call latest data",
    "lastUpdateTimeEnabled": "Last Update Time: {time} UTC <br> There is no data to fetch from Apple Search Ads.",
    "lastUpdateTimeNoData": "Last Update Time: - <br> Click to call latest data",
    "noActionsYet": "No actions taken yet"
}
