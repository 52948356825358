import { makeRequest } from '@/common/MaRequestPooler';
import { paramsToQuery } from '@/common/MaUtils.mjs';

export class HighVolumeBulkEditController {

    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }

    /**
     * Creates bulk edit automation
     * @param data
     * @param entityLevel
     * @returns {Promise<*>}
     */
    createBulkEditAutomation(data, entityLevel) {
        return this.requestPooler(`/sa/bulk-edit-automations/${entityLevel}`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Returns bulk edit automations logs
     * @returns {Promise<*>}
     */
    getBulkEditAutomationsLogs(data) {
        return this.requestPooler(`/sa/bulk-edit-automations/logs/filter`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Returns bulk edit automation log details
     * @param id -> Bulk edit automation id (Returns from bulk edit automation logs)
     * @param size
     * @param page
     * @returns {Promise<*>}
     */
    getDetailedBulkEditAutomationsLogs(id, size, page) {
        return this.requestPooler(`/sa/bulk-edit-automations/${id}/logs/details?${paramsToQuery({ size, page })}`);
    }
}

export const useHighVolumeBulkEditController = () => new HighVolumeBulkEditController();
