import { makeRequest } from '@/common/MaRequestPooler';
import { campaignSearchTabFormatter, adGroupSearchTabFormatter } from '@/pages/Manage/ManageUtils.mjs';

/**
 * Returns all campaign under account without report
 */
export function getAllCampaigns() {
    return makeRequest(`/sa/campaigns/all`);
}

/**
 * Returns campaign with the given id
 *
 * @param campaignId: campaign id
 */
export function getCampaign(campaignId, orgId, integId) {
    let url = `/sa/campaigns/${ campaignId }`;
    return makeRequest(url, {
        formatter: campaignSearchTabFormatter,
        orgId: orgId || null,
        integId: integId || null,
    });
}

/**
 * Returns ad groups under given campaign id without report
 *
 * @param campaignId: campaign id
 */
export function getAllAdGroups(campaignId) {
    return makeRequest(`/sa/campaigns/${ campaignId }/adgroups/all`, {
        formatter: adGroupSearchTabFormatter,
    });
}

/**
 * Returns ad group with the given id
 *
 * @param campaignId: campaign id
 * @param adGroupId: ad group id
 */
export function getAdGroup(campaignId, adGroupId, orgId, integId) {
    return makeRequest(`/sa/campaigns/${ campaignId }/adgroups/${ adGroupId }`, {
        formatter: adGroupSearchTabFormatter,
        orgId: orgId || null,
        integId: integId || null,
    });
}

/**
 * gets app category rankings
 *
 * @param trackId
 * @param region
 * @param device
 * @param startDate
 * @param endDate
 */
export function getAppCategoryRankings({ trackId, region, device, startDate, endDate }) {
    return makeRequest(`/categoryrankings/app/${ trackId }/?countries=${ region }&startDate=${ startDate
    }&endDate=${ endDate }&device=${ device || 'iphone' }`);
}

/**
 * Returns keyword rankings for selected region for given app
 * @param urlParams
 * {
 *  trackId: trackId of selected app, long
 *  region: region code, string
 *  device: device of selected app, string
 *  keywords: Array of String keywords
 * }
 */
export function getKeywordRankings({ trackId, region, device, keywords, startDate, endDate }) {
    let url = `/sa/app/${ trackId }/${ region }/${ device }/keyword-ranking-history/?keywords=${ encodeURIComponent(keywords.join()) }`;
    if (startDate) {
        url += `&startDate=${ startDate }`;
    }
    if (endDate) {
        url += `&endDate=${ endDate }`;
    }
    return makeRequest(url);
}

export function getKeywordRankingsByApp({ trackId, device, keywords, startDate, endDate }) {
    const url = `/sa/app/${ trackId }/${ device }/keyword-ranking-history?startDate=${ startDate }&endDate=${ endDate }`;
    return makeRequest(url, {
        method: 'POST',
        data: keywords,
    });
}

export function apiState() {
    return makeRequest(`/sa/dashboard/report/api/state`);
}

export function createGraderRequest({ trackId }) {
    return makeRequest(`/sa/experiment/grader/create-request/${trackId}`, {
        method: 'POST',
    });
}

export function graderLogs() {
    return makeRequest(`/sa/experiment/grader/get-report`);
}
