import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useGlobal } from '@/composables/index';
import { useI18n } from 'vue-i18n';
import bannerEvents from '@/plugins/mixpanel/definitions/bannerEvents';
import { useRoute } from 'vue-router';
import { useAuthPublicController } from '@/controllers';

export default function useEmailVerifyBanner () {
    const store = useStore();
    const app = useGlobal();
    const submitting = ref(false);
    const user = computed(() => store.state.user);
    const emailNotVerified = computed(() => !user.value?.emailVerified);
    const { t } = useI18n();
    const route = useRoute();
    const authService = useAuthPublicController();
    const resendVerificationEmail = () => {
        if (!user.value?.userId) {
            return;
        }
        submitting.value = true;
        authService.resendEmail(user.value.userId)
            .then(() => {
                app.$notification.success({
                    duration: 5,
                    message: t('common.successful'),
                    description: t('activationEmailResentCompleted'),
                });

                // track mixpanel event
                const event = bannerEvents(route).verifyEmailBannerResend;
                app.$mixpanel.track(...event.toMixpanel());
            })
            .catch(() => {
                app.$notification.error({
                    duration: 3,
                    message: t('common.failed'),
                    description: t('failedToResentTheActivationEmail'),
                });
            })
            .then(() => {
                submitting.value = false;
            });
    };

    return {
        showBanner: emailNotVerified,
        resendVerificationEmail,
        submitting,
    };
}
