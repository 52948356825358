export const HOVER_ACTIONS = {
    EDIT: 'edit',
    DUPLICATE: 'duplicate-data',
    LABEL: 'metadata-label',
    COPY: 'copy-data',
    NEW_WINDOW: 'new-window',
};

export const CAMPAIGN_TYPE_PROP = 'GROUP_BY_CAMPAIGN_TYPE';

export const CAMPAIGN_LABELS = {
    BRAND: '#Brand',
    COMPETITOR: '#Competitor',
    DISCOVERY: '#Discovery',
    GENERIC: '#Generic',
};

export const ATTRIBUTED_TIME = {
    EVENT_TIME: 'EVENT_TIME',
    INSTALL_TIME: 'INSTALL_TIME',
};
