import { computed, ref } from 'vue';
import { useGlobal } from '@/composables';
import { defineStore, storeToRefs } from 'pinia';
import { useStore } from 'vuex';
import { defaultPreset } from '@/common/TableColumns.mjs';
import { useColumnController } from '@/controllers/ColumnController';
import { REPORT_LEVEL, useAdsManagerStore } from '@/pinia/AdsManager/index';

export const types = {
    SET_SELECTED_COLUMNS: 'SET_SELECTED_COLUMNS',
    SET_PRESETS: 'SET_PRESETS',
    SET_CUSTOM_COLUMNS: 'SET_CUSTOM_COLUMNS',
    SET_INITIAL_FETCH: 'SET_INITIAL_FETCH',
};

Object.keys(types).forEach(k => types[k] = k);

const initialTableValue = {
    accounts: defaultPreset('accounts'),
    apps: defaultPreset('apps'),
    campaigns: defaultPreset('campaigns'),
    adGroups: defaultPreset('adGroups'),
    keywords: defaultPreset('keywords'),
    ads: defaultPreset('ads'),
    cpp: defaultPreset('cpp'),
    searchTerms: defaultPreset('searchTerms'),
};

export const useColumnPresetStore = defineStore('columnPreset', () => {
    const loading = ref(false);
    const presets = ref([]);
    const customColumnsData = ref([]);
    const customColumnInitialFetch = ref(false);

    const columnController = useColumnController();
    const { $maIntercom, $log, $maLocalStorage } = useGlobal();
    const store = useStore();
    const selectedColumns = ref($maLocalStorage.selectedTableColumns ?? initialTableValue);

    const attributedTime = computed(() => store.getters['dashboard/attributedTime']);
    const customColumns = computed(() => customColumnsData.value ?? []);
    const customColumnsIdMap = computed(() => {
        const map = {};
        customColumnsData.value?.forEach((col) => {
            map[col.id] = col;
        });
        return map;
    });

    const getPresets = async (cacheInvalidate = false) =>{
        try {
            loading.value = true;
            presets.value = await columnController.fetchTemplates(cacheInvalidate);
        } catch ({ errors }) {
            presets.value = [];
            $log.error('Failed to fetch columns presets', errors);
        } finally {
            loading.value = false;
        }
    };

    const addPreset = async (data) => {
        try {
            loading.value = true;
            await columnController.addTemplate(data);
            $maIntercom.trackCreateEvent('column_preset_success');
            await getPresets();
        } catch (e) {
            const { errors } = e;
            if (errors && errors.includes('NOT_LOGGED_IN')) {
                return;
            }
            $log.error('Failed to add columns preset', e);
        } finally {
            loading.value = false;
        }
    };

    const removePreset = async (id) => {
        try {
            loading.value = true;
            await columnController.deleteTemplate(id);
            await getPresets(true);
        } catch (e) {
            const { errors } = e;
            if (errors && errors.includes('NOT_LOGGED_IN')) {
                return;
            }
            $log.error('Failed to delete columns preset', e);
        } finally {
            loading.value = false;
        }
    };

    const updatePreset = async ({ id, data }) => {
        try {
            loading.value = true;
            const res = await columnController.updateTemplate(id, data);
            await getPresets(true);
            return res;
        } catch (e) {
            const { errors } = e;
            if (errors && errors.includes('NOT_LOGGED_IN')) {
                return;
            }
            $log.error('Failed to update columns preset', e);
        } finally {
            loading.value = false;
        }
    };

    const setSelectedColumns = ({ columns, table }) => {
        let savedColumns = { ...selectedColumns.value };
        savedColumns[table] = columns;
        selectedColumns.value = savedColumns;
        $maLocalStorage.selectedTableColumns = savedColumns;
    };

    const getCustomColumns = async (cacheInvalidate) => {
        try {
            loading.value = true;
            customColumnsData.value = await columnController.fetchCustomColumns(cacheInvalidate, attributedTime.value);
        } catch ({ errors }) {
            customColumnsData.value = [];
            $log.error('Failed to fetch custom columns', errors);
        } finally {
            customColumnInitialFetch.value = true;
            loading.value = false;
        }
    };

    const removeCustomColumn = async (id) => {
        try {
            loading.value = true;
            await columnController.deleteCustomColumn(id);
            await getCustomColumns(true);
        } catch (e) {
            const { errors } = e;
            if (errors && errors.includes('NOT_LOGGED_IN')) {
                return;
            }
            $log.error('Failed to delete custom column', e);
        } finally {
            loading.value = false;
        }
    };

    const CUSTOM_COLUMN_LEVEL_MAPPING = {
        [REPORT_LEVEL.ACCOUNT]: 'ACCOUNT',
        [REPORT_LEVEL.APP]: 'APP',
        [REPORT_LEVEL.CAMPAIGN]: 'CAMPAIGN',
        [REPORT_LEVEL.AD_GROUP]: 'ADGROUP',
        [REPORT_LEVEL.KEYWORD]: 'KEYWORD',
        [REPORT_LEVEL.NEGATIVE]: 'NEGATIVE',
        [REPORT_LEVEL.SEARCH_TERM]: 'SEARCH_TERM',
        [REPORT_LEVEL.AD]: 'AD',
        [REPORT_LEVEL.CPP]: 'CPP',
    };

    const adsManagerStore = useAdsManagerStore();
    const { reportLevel } = storeToRefs(adsManagerStore);
    const availableCustomColumns = computed(() => {
        const currentLevel = CUSTOM_COLUMN_LEVEL_MAPPING[reportLevel.value];
        return customColumns.value.filter((c) => {
            return c.levels.includes(currentLevel);
        });
    });
    const isColumnApplicable = (id) => {
        return customColumnsIdMap.value[id]?.levels?.includes(CUSTOM_COLUMN_LEVEL_MAPPING[reportLevel.value]);
    };

    return {
        loading,
        presets,
        selectedColumns,
        customColumns,
        availableCustomColumns,
        customColumnInitialFetch,
        customColumnsIdMap,

        addPreset,
        getPresets,
        removePreset,
        updatePreset,

        setSelectedColumns,
        getCustomColumns,
        removeCustomColumn,
        isColumnApplicable,
    };
});
