import { makeRequest } from '@/common/MaRequestPooler';

export function labels(cacheInvalidate = false) {
    return makeRequest('/sa/metadata-label', {}, {
        cacheInvalidate,
    });
}

export function labelsOfEntity(entityLevel, entityId) {
    return makeRequest(`/sa/metadata-label/level/${entityLevel}/entity/${entityId}`);
}

export function getLabel(id) {
    return makeRequest(`/sa/metadata-label/${ id }`);
}

export function addLabel(data) {
    return makeRequest(`/sa/metadata-label`, {
        method: 'POST',
        data,
    });
}

/*
*
Only label-data.
data = {
    name: 'Test',
    color: '',
    description: '',
    entityList: [{entityId, entityLevel}]
};
*
* */
export function assignLabel(data, labelId) {
    return makeRequest(`/sa/metadata-label/assign/${ labelId }`, {
        method: 'POST',
        data,
    });
}

/*
*
Only label-data.
data = {
    name: 'Test',
    color: '',
    description: '',
};
*
* */
export function updateLabel(id, data) {
    return makeRequest(`/sa/metadata-label/${ id }`, {
        method: 'PUT',
        data,
    });
}

/*
*
Only label-list.
data = [{
    id: 1,
    name: 'Test',
    color: '',
    description: '',
}];
*
* */
export function updateEntityLabels(entityLevel, entityId, data) {
    return makeRequest(`/sa/metadata-label/level/${entityLevel}/entity/${entityId}`, {
        method: 'PUT',
        data,
    });
}

export function deleteLabel(id) {
    return makeRequest(`/sa/metadata-label/${ id }`, {
        method: 'DELETE',
    });
}

/*
*
Only label-data.
data = {
    name: 'Test',
    color: '',
    description: '',
    entityList: [{entityId, entityLevel}]
};
*
* */
export function removeLabelAssignment(labelId, data) {
    return makeRequest(`/sa/metadata-label/assign/${ labelId }`, {
        method: 'DELETE',
        data,
    });
}
