<template>
    <div :title="$t('components.loadingScreen.loadingSearchAds')" class="ma-loading-screen">
        <div class="ma-logo">
            <img src="@/assets/sa-logo-white.svg">
        </div>
        <div class="ma-page-loading spinner">
            <div class="rect1"/>
            <div class="rect2"/>
            <div class="rect3"/>
            <div class="rect4"/>
            <div class="rect5"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ma-loading-screen',
    };
</script>

<style lang="less">
    .ma-loading-screen {
        padding-top: 30vh;
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        background: #13294c;
        height: 100vh;
        width: 100%;
    }

    .ma-page-loading {
        margin: 100px auto;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;

        > div {
            background-color: #fff;
            height: 100%;
            width: 6px;
            display: inline-block;
            margin-right: 2px;

            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        .rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        .rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        .rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }
        @-webkit-keyframes sk-stretchdelay {
            0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
            20% { -webkit-transform: scaleY(1.0) }
        }

        @keyframes sk-stretchdelay {
            0%, 40%, 100% {
                transform: scaleY(0.4);
                -webkit-transform: scaleY(0.4);
            }  20% {
                   transform: scaleY(1.0);
                   -webkit-transform: scaleY(1.0);
               }
        }
    }
</style>
