<template>
    <a-spin :spinning="loading" :wrapper-class-name="$attrs.class">
        <div :class="`ma-selected-app-info flex items-center ${ empty ? 'empty' : '' }`">
            <ma-app-icon
                :icon="safeApp.iconUrl"
                :size="iconSize"
            />
            <div class="flex flex-col justify-center items-center w-full">
                <p class="ma-app-name m-0" :title="safeApp.name">
                    <b>{{ safeApp.name }}</b>
                </p>
                <p
                    v-if="safeApp.developerName && !hidePublisher"
                    class="ma-developer-name min-w-0 text-left m-0 text-gray-600 truncate w-1/2"
                >
                    {{ safeApp.developerName }}
                </p>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import { getSimpleAppInfo } from '@/common/MaRequests/AppInfo';

    export default {
        name: 'ma-app-info',
        props: {
            app: { type: Object },
            iconSize: { type: String, default: 'large' },
            hidePublisher: { type: Boolean, default: false },
        },
        emits: ['update:app'],
        data() {
            return {
                loading: false,
                data: {},
            };
        },
        computed: {
            safeApp() {
                return Object.assign({
                    trackId: 0,
                    iconUrl: '/img/ma-white-rocket.svg',
                    name: 'App Title',
                    developerName: null,
                }, this.app, this.data);
            },
            empty() {
                return this.app === null;
            },
        },
        methods: {
            getAppInfo() {
                const id = this.app.adamId || this.app.trackId;
                this.loading = true;
                getSimpleAppInfo({ trackIds: [id] })
                    .then((data) => {
                        if (data && data.length) {
                            this.data = data[0];
                            this.$emit('update:app', data[0]);
                        }
                    })
                    .catch((e) => {
                        this.$log.error('Failed to fetch simple app info', e);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
            init() {
                if ((this.app.adamId || this.app.trackId) && !this.app.iconUrl) {
                    this.getAppInfo();
                }
            },
        },
        mounted() {
            this.init();
        },
        watch: {
            app(fr, to) {
                const frTrackId = fr && (fr.trackId || fr.adamId);
                const toTrackId = to && (to.trackId || to.adamId);
                if (frTrackId !== toTrackId) {
                    this.init();
                }
            },
        },
    };
</script>

<style scoped>
    .ma-selected-app-info {
        .ma-app-icon, div {
            @apply ml-2.5 mt-2.5 inline-block align-middle;
            span {
                @apply block;
            }
        }
        &.empty {
            color: rgba(0, 0, 0, 0.5);
        }
    }
</style>
