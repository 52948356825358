<!-- spaces are important let it be ugly-->
<template>
    <div class="flex justify-center items-center space-x-2 w-full">
        <span v-if="showText">{{ text }}</span>
        <div>
            {{ rankStr }}
        </div>
        <ma-badge
            v-if="!isNaN(diff) && icon"
            class="mx-2"
            type="secondary"
            size="small"
            :variant="variant"
            :icon="icon"
        >
            {{ diffStr }}
        </ma-badge>
        <div
            v-if="!hideRankingButton && isRank"
            class="flex justify-center items-center"
        >
            <ma-link-button
                color="blue"
                size="small"
                icon="trend-up-linear"
                class="cursor-pointer"
                @click="detail"
            />
        </div>
    </div>
</template>

<script setup>
    import { MaBadge, MaLinkButton } from '@mobileaction/action-kit';
</script>
<script>
    export default {
        name: 'ma-todays-rank',
        props: {
            rank: { type: [Number, String], required: true },
            diff: { type: Number, required: true },
            text: { type: String },
            hideRankingButton: { type: Boolean },
        },
        emits: ['show-ranking'],
        computed: {
            className() {
                switch (this.diff) {
                    case -Infinity:
                        return 'ma-text-danger';
                    case Infinity:
                        return 'text-success';
                    case 0:
                        return '';
                    default:
                        return this.diff > 0 ? 'text-success' : 'ma-text-danger';
                }
            },
            icon() {
                if (this.diff === 0) {
                    return '';
                }
                return this.diff > 0 ? 'arrow-up' : 'arrow-down';
            },
            variant() {
                if (this.diff === 0) {
                    return 'basic';
                }
                return this.diff > 0 ? 'green' : 'red';
            },
            diffStr() {
                switch (this.diff) {
                    case -Infinity:
                        return 'Out';
                    case Infinity:
                        return 'In';
                    case 0:
                        return '';
                    default:
                        return Math.abs(this.diff);
                }
            },
            rankStr() {
                return this.rank === Infinity || this.rank === 0 ? '–' : this.rank;
            },
            isRank() {
                return this.rank !== Infinity && this.rank !== 0;
            },
            showText() {
                return this.text !== null;
            },
        },
        methods: {
            detail() {
                this.$emit('show-ranking');
            },
        },
    };
</script>

<style scoped>
    .fa-chart-line {
        @apply ml-1 cursor-pointer text-blue-400;
    }
</style>
