<template>
    <ma-tooltip
        v-if="item.routeLink"
        :title="t(item.tooltipKey)"
        type="primary"
        placement="right"
    >
        <a
            :href="item.routeLink"
            target="_blank"
            @click="trackLink(item.mixpanelKey)"
        >
            <ma-side-menu-item
                v-if="!isCollapsed"
                :text="t(item.labelKey)"
                :icon="item.icon"
                class="ma-side-menu-item-link"
                :class="{ collapsed: isCollapsed }"
            >
                <div v-if="!isCollapsed" class="ma-menu-item-icon">
                    <ma-icon name="star-bold" size="xs" class="text-white"/>
                </div>
            </ma-side-menu-item>
            <ma-side-menu-item
                v-else
                :icon="item.icon"
                class="ma-side-menu-item collapsed"
            />
        </a>
    </ma-tooltip>
    <router-link
        v-else
        :to="targetRoute"
    >
        <ma-tooltip
            v-if="isCollapsed"
            placement="right"
            type="primary"
        >
            <template #title>
                <div class="flex items-center gap-2">
                    {{ t(item.labelKey) }}
                    <ma-icon name="lock" size="xs" class="text-gray-500"/>
                </div>
            </template>
            <ma-side-menu-item
                :active="isActive(item.routeNameInURL || item.route.name, item.route.params)"
                :icon="item.icon"
                class="ma-side-menu-item collapsed"
            />
        </ma-tooltip>
        <ma-side-menu-item
            v-else
            :text="t(item.labelKey)"
            :active="isActive(item.routeNameInURL || item.route.name, item.route.params)"
            :icon="item.icon"
            :badge-color="badgeColor"
            :badge-text="badgeText"
            class="ma-side-menu-item"
        >
            <ma-icon
                v-if="userNoAccess"
                name="lock"
                size="xs"
                class="!text-gray-500"
            />
        </ma-side-menu-item>
    </router-link>
</template>

<script>
    import i18n from '@/components/MaSidebar/Sidebar.i18n';
    export default {
        i18n,
    };
</script>
<script setup>
    import { computed } from 'vue';
    import { useRoute } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { MaSideMenuItem, MaTooltip, MaIcon } from '@mobileaction/action-kit';
    import { useStore } from 'vuex';
    import { useMixpanelEvents } from '@/plugins/mixpanel/events';
    import { usePlanStore } from '@/pinia/Plan';
    import { storeToRefs } from 'pinia';
    const planStore = usePlanStore();
    const { validFeatures } = storeToRefs(planStore);

    const props = defineProps({
        item: { type: Object, required: true },
        isCollapsed: { type: Boolean },
    });

    const { t } = useI18n();

    const store = useStore();
    const userNoAccess = computed(() => store.getters['account/isUserNoAccess']);
    const targetRoute = computed(() => {
        if (props.item.routeAlternative && !validFeatures.value.includes(props.item.routeFeature)) {
            return props.item.routeAlternative;
        }
        return props.item.route;
    });

    const badgeColor = computed(() => {
        if (props.item.isUpdated) {
            return 'orange';
        }
        if (props.item.isDashboard) {
            return 'purple';
        }
        return 'green';
    });
    const badgeText = computed(() => {
        if (props.item.isNewFeature) {
            return t('common.new');
        }
        if (props.item.isUpdated) {
            return t('common.updated');
        }
        if (props.item.isDashboard) {
            return t('common.dashboard');
        }
        return null;
    });

    const route = useRoute();
    const isActive = (routeName, params = null) => {
        const routeValues = (route.path || 'dashboard').split('/');
        if (Array.isArray(routeName)) {
            return routeName.some(r => routeValues.includes(r));
        }
        return params
            ? [routeName, params.tab].every(v => routeValues.includes(v))
            : routeValues.includes(routeName);
    };

    const { sidebarCPPMenuItemClicked } = useMixpanelEvents();
    const trackLink = (key) => {
        if (key === 'cpp-intelligence') {
            sidebarCPPMenuItemClicked();
        }
    };
</script>

<style lang="less">
    .ma-side-menu-item {
        @apply w-full min-w-0 box-border;
        &:not(:hover) {
            @apply bg-transparent;
        }
        &.collapsed {
            @apply justify-center mx-auto ~"w-[80%]";
        }
    }
    .ma-side-menu-item-link {
        .ma-menu-item-icon {
            @apply flex items-center justify-center bg-green-500 rounded-full w-4 h-5;
            .ma-icon {
                @apply ~"w-[10px]" ~"h-[10px]" text-white;
            }
        }
        .ma-menu-item-icon {
            @apply absolute right-3;
        }
    }
</style>
