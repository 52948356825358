import { makeRequest } from '@/common/MaRequestPooler';

export class ColumnController {
    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }

    /**
     * Fetch column templates
     * @param cacheInvalidate
     * @returns {unknown}
     */
    fetchTemplates(cacheInvalidate = false) {
        return this.requestPooler(`/sa/column-templates`, {}, { cacheInvalidate });
    }

    /**
     * Add column template
     * @param data
     * @returns {unknown}
     */
    addTemplate(data) {
        return this.requestPooler(`/sa/column-templates`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Update column template
     * @param templateId
     * @param data
     * @returns {unknown}
     */
    updateTemplate(templateId, data) {
        return this.requestPooler(`/sa/column-templates/${ templateId }`, {
            method: 'PUT',
            data,
        });
    }

    /**
     * Delete column template
     * @param templateId
     * @returns {unknown}
     */
    deleteTemplate(templateId) {
        return this.requestPooler(`/sa/column-templates/${ templateId }`, {
            method: 'DELETE',
        });
    }

    /**
     * Fetch custom columns
     * @param cacheInvalidate
     * @param attributedTime
     * @returns {unknown}
     */
    fetchCustomColumns(cacheInvalidate = false, attributedTime) {
        return this.requestPooler(`/sa/custom-columns?attributedTime=${ attributedTime }`, {}, { cacheInvalidate });
    }

    /**
     * Fetch custom column
     * @param columnId
     * @returns {unknown}
     */
    fetchCustomColumn(columnId) {
        return this.requestPooler(`/sa/custom-columns/${ columnId }`, {}, { cacheInvalidate: true });
    }

    /**
     * Add custom column
     * @param data
     * @returns {unknown}
     */
    addCustomColumn(data) {
        return this.requestPooler(`/sa/custom-columns`, {
            method: 'POST',
            data,
        });
    }

    /**
     * Update custom column
     * @param columnId
     * @param data
     * @returns {unknown}
     */
    updateCustomColumn(columnId, data) {
        return this.requestPooler(`/sa/custom-columns/${ columnId }`, {
            method: 'PUT',
            data,
        });
    }

    /**
     * Delete custom column
     * @param columnId
     * @returns {unknown}
     */
    deleteCustomColumn(columnId) {
        return this.requestPooler(`/sa/custom-columns/${ columnId }`, {
            method: 'DELETE',
        });
    }
}

export const useColumnController = () => new ColumnController;


