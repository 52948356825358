import { globalProperties as app } from '@/main';

export const FILTER_LEVELS = {
    ACCOUNT: 'ACCOUNT',
    APP: 'APP',
    CAMPAIGN: 'CAMPAIGN',
    AD_GROUP: 'AD_GROUP',
    ADS: 'AD',
    CPP: 'CPP',
    CREATIVE_SET: 'CREATIVE_SET',
    KEYWORD: 'KEYWORD',
    NEGATIVE: 'NEGATIVE',
    SEARCH_TERM: 'SEARCH_TERM',
    RULE: 'RULE',
    LOG: 'LOG',
};

export const TOTAL_FILTER_COLUMNS = [
    {
        name: 'cpa',
        tKey: 'averageCPA',
        displayName: 'CPA',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
    },
    {
        name: 'totalCpa',
        tKey: 'totalCpa',
        displayName: 'totalCpa',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
    },
    {
        name: 'cpt',
        tKey: 'averageCPT',
        displayName: 'CPT',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
    },
    {
        name: 'spend',
        tKey: 'spend',
        displayName: 'Spend',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
    },
    {
        name: 'taps',
        tKey: 'taps',
        displayName: 'Taps',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'impressions',
        tKey: 'impressions',
        displayName: 'Impressions',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'installs',
        tKey: 'installs',
        displayName: 'Installs',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'viewInstalls',
        tKey: 'viewInstalls',
        displayName: 'viewInstalls',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'totalInstalls',
        tKey: 'totalInstalls',
        displayName: 'totalInstalls',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'redownloads',
        tKey: 'redownloads',
        displayName: 'Re-Downloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'viewRedownloads',
        tKey: 'viewRedownloads',
        displayName: 'viewRedownloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'totalRedownloads',
        tKey: 'totalRedownloads',
        displayName: 'totalRedownloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'newDownloads',
        tKey: 'newDownloads',
        displayName: 'New Downloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'viewNewDownloads',
        tKey: 'viewNewDownloads',
        displayName: 'viewNewDownloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'totalNewDownloads',
        tKey: 'totalNewDownloads',
        displayName: 'totalNewDownloads',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'ttr',
        tKey: 'ttr',
        displayName: 'TTR',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'PERCENTAGE',
    },
    {
        name: 'cr',
        tKey: 'cr',
        displayName: 'CR',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'PERCENTAGE',
    },
    {
        name: 'totalCr',
        tKey: 'totalCr',
        displayName: 'totalCr',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'PERCENTAGE',
    },
];

export const ATTRIBUTION_INSTALL_COLUMNS = [
    {
        name: 'numberOfInstall',
        tKey: 'attrInstalls',
        displayName: 'attrInstalls',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
    },
    {
        name: 'attrInstallRate',
        tKey: 'installRate',
        displayName: 'Attr Install Rate',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'PERCENTAGE',
    },
];

export const ATTRIBUTION_FILTER_COLUMNS = [
    {
        name: 'numberOfGoal',
        tKey: 'numberOfGoals',
        displayName: 'Goals',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        unit: 'NUMBER',
    },
    {
        name: 'costPerGoal',
        tKey: 'costPerGoal',
        displayName: 'Cost Per Goal',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
    },
    {
        name: 'goalsRate',
        tKey: 'goalsRate',
        displayName: 'Goals Rate',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
    },
    {
        name: 'revenuePerGoal',
        tKey: 'revenuePerGoal',
        displayName: 'Revenue Per Gaol',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
    },
    {
        name: 'goalRevenue',
        tKey: 'goalsRevenue',
        displayName: 'Goals Revenue',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
    },
    {
        name: 'goalRoas',
        tKey: 'goalsROAS',
        displayName: 'ROAS',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
    },
    {
        name: 'arpu',
        tKey: 'averageRevenuePerUser',
        displayName: 'Average Revenue Per User',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
    },
    {
        name: 'goalRevenue1',
        tKey: 'goalRevenue1',
        displayName: 'Goal Revenue 1',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRevenue3',
        tKey: 'goalRevenue3',
        displayName: 'Goal Revenue 3',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRevenue7',
        tKey: 'goalRevenue7',
        displayName: 'Goal Revenue 7',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRevenue14',
        tKey: 'goalRevenue14',
        displayName: 'Goal Revenue 14',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRevenue30',
        tKey: 'goalRevenue30',
        displayName: 'Goal Revenue 30',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRevenue60',
        tKey: 'goalRevenue60',
        displayName: 'Goal Revenue 60',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'CURRENCY',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas1',
        tKey: 'goalRoas1',
        displayName: 'Goal ROAS 1',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas3',
        tKey: 'goalRoas3',
        displayName: 'Goal ROAS 3',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas7',
        tKey: 'goalRoas7',
        displayName: 'Goal ROAS 7',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas14',
        tKey: 'goalRoas14',
        displayName: 'Goal ROAS 14',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas30',
        tKey: 'goalRoas30',
        displayName: 'Goal ROAS 30',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalRoas60',
        tKey: 'goalRoas60',
        displayName: 'Goal ROAS 60',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'PERCENTAGE',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount1',
        tKey: 'goalCount1',
        displayName: 'Goals Count 1',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount3',
        tKey: 'goalCount3',
        displayName: 'Goals Count 3',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount7',
        tKey: 'goalCount7',
        displayName: 'Goals Count 7',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount14',
        tKey: 'goalCount14',
        displayName: 'Goals Count 14',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount30',
        tKey: 'goalCount30',
        displayName: 'Goals Count 30',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
    {
        name: 'goalCount60',
        tKey: 'goalCount60',
        displayName: 'Goals Count 60',
        filterType: 'NUMBER',
        category: 'ATTRIBUTION',
        subCategory: 'COHORT',
        unit: 'NUMBER',
        hideOnEventTime: true,
        forProUsersOnly: true,
    },
];

const SCHEDULE_ADS_FILTER_COLUMNS = [
    {
        name: 'dayPart',
        tKey: 'dayPart',
        displayName: 'dayPart',
        filterType: 'ENUM',
        category: 'METADATA',
        subCategory: 'SCHEDULE_ADS',
        unit: 'TEXT',
    },
];

export const LTV_FILTER_COLUMN = [
    {
        name: 'ltv',
        tKey: 'ltv',
        displayName: 'LTV',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
        accessibleBy: ['SA_LTV_UPLOAD'],
    },
];

export const DAILY_BUDGET_FILTER_COLUMN = [
    {
        name: 'dailyBudget',
        tKey: 'dailyBudget',
        displayName: 'dailyBudget',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
    },
];

export const CPI_FILTER_COLUMN = [
    {
        name: 'cpi',
        tKey: 'averageCPI',
        displayName: 'Cost Per Install',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'CURRENCY',
    },
];

const AUDIENCE_FILTER_COLUMNS = [
    {
        name: 'deviceClass',
        tKey: 'deviceClass',
        tKey2: 'common.nDeviceClassSelected',
        displayName: 'deviceClass',
        filterType: 'IN_STRING',
        category: 'METADATA',
        subCategory: 'AUDIENCE',
        unit: 'TEXT',
    },
    {
        name: 'gender',
        tKey: 'gender',
        tKey2: 'common.nGenderSelected',
        displayName: 'gender',
        filterType: 'IN_STRING',
        category: 'METADATA',
        subCategory: 'AUDIENCE',
        unit: 'TEXT',
    },
    {
        name: 'ageRange',
        tKey: 'ageRange',
        displayName: 'ageRange',
        filterType: 'ENUM',
        category: 'METADATA',
        subCategory: 'AUDIENCE',
        unit: 'TEXT',
    },
    {
        name: 'locality',
        tKey: 'locality',
        displayName: 'locality',
        filterType: 'ENUM',
        category: 'METADATA',
        subCategory: 'AUDIENCE',
        unit: 'TEXT',
    },
    {
        name: 'customerType',
        tKey: 'customerType',
        tKey2: 'common.nCustomerTypeSelected',
        displayName: 'customerType',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        subCategory: 'AUDIENCE',
        unit: 'TEXT',
    },
];

const APP_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    ...CPI_FILTER_COLUMN,
];

const CAMPAIGN_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey2: 'common.nAccountsSelected',
        tKey: 'orgName',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'supplySources',
        tKey: 'adPlacement',
        tKey2: 'common.nAdPlacementSelected',
        filterType: 'IN_STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'campaignDisplayStatus',
        tKey: 'campaignStatus',
        tKey2: 'common.nStatusSelected',
        displayName: 'displayStatus',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'totalBudget',
        tKey: 'totalBudget',
        displayName: 'totalBudget',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
    },
    ...DAILY_BUDGET_FILTER_COLUMN,
    {
        level: FILTER_LEVELS.CAMPAIGN,
        name: 'label',
        tKey: 'campaignLabel',
        tKey2: 'common.nCampaignLabelSelected',
        displayName: 'label',
        filterType: 'IN_NUMBER',
        category: 'METADATA',
        unit: 'TEXT',
    },
    ...CPI_FILTER_COLUMN,
];

const KEYWORD_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey2: 'common.nAccountsSelected',
        tKey: 'orgName',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'keywordId',
        tKey: 'keywordId',
        displayName: 'keywordId',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'keyword',
        tKey: 'keyword',
        displayName: 'Keyword',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'keywordMatchType',
        tKey: 'matchType',
        displayName: 'Match Type',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'keywordDisplayStatus',
        tKey: 'keywordStatus',
        tKey2: 'common.nStatusSelected',
        displayName: 'displayStatus',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'bidAmount',
        tKey: 'bidAmount',
        displayName: 'bidAmount',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
    },
    {
        name: 'suggestedBidAmount',
        tKey: 'suggestedBidAmount',
        displayName: 'suggestedBidAmount',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
        subCategory: 'INSIGHTS',
    },
    {
        name: 'bidStrength',
        tKey: 'bidStrength',
        tKey2: 'common.nBidStrengthSelected',
        displayName: 'bidStrength',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
        subCategory: 'INSIGHTS',
    },
    {
        level: FILTER_LEVELS.KEYWORD,
        name: 'label',
        tKey: 'keywordLabel',
        tKey2: 'common.nKeywordLabelSelected',
        displayName: 'label',
        filterType: 'IN_NUMBER',
        category: 'METADATA',
        unit: 'TEXT',
    },
    ...CPI_FILTER_COLUMN,
];

const SEARCH_TERM_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'searchTermKeywordId',
        tKey: 'keywordId',
        displayName: 'keywordId',
        filterType: 'NUMBER',
        category: 'REPORT',
        unit: 'NUMBER',
        subCategory: 'METADATA',
    },
    {
        name: 'adGroupStatus',
        tKey: 'adGroupStatus',
        displayName: 'adGroupStatus',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'campaignStatus',
        tKey: 'campaignStatus',
        displayName: 'campaignStatus',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'searchTermKeywordId',
        tKey: 'keywordId',
        tKey2: 'common.nKeywordsSelected',
        displayName: 'searchTermKeywordId',
        filterType: 'NUMBER',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'NUMBER',
        invisible: true,
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'searchTermText',
        tKey: 'searchTermText',
        displayName: 'Search Term',
        filterType: 'STRING',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'searchTermKeyword',
        tKey: 'searchTermKeyword',
        displayName: 'Keyword',
        filterType: 'STRING',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'addedAsKeyword',
        tKey: 'addedAsKeyword',
        tKey2: 'common.nAddedAsKeywordSelected',
        displayName: 'addedAsKeyword',
        filterType: 'IN_STRING',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'searchTermSource',
        tKey: 'searchTermSource',
        displayName: 'searchTermSource',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'lowVolumeKeyword',
        tKey: 'lowVolumeKeyword',
        displayName: 'lowVolumeKeyword',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
];
export const SHARE_OF_VOICE_COLUMN = {
    name: 'shareOfVoice',
    tKey: 'shareOfVoice',
    displayName: 'shareOfVoice',
    filterType: 'IN_RANGE',
    category: 'REPORT',
    unit: 'PERCENTAGE',
};
const SOV_COLUMN = [
    SHARE_OF_VOICE_COLUMN,
];

const AD_GROUP_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'adGroupName',
        tKey: 'adGroupName',
        displayName: 'adGroupName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupDisplayStatus',
        tKey: 'adGroupStatus',
        tKey2: 'common.nStatusSelected',
        displayName: 'displayStatus',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'searchMatch',
        tKey: 'searchMatch',
        displayName: 'Search Match',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'cpaGoal',
        tKey: 'cpaGoal',
        displayName: 'cpaGoal',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'defaultAmount',
        tKey: 'defaultMaxCPTBid',
        displayName: 'defaultMaxCPTBid',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'CURRENCY',
    },
    {
        level: FILTER_LEVELS.AD_GROUP,
        name: 'label',
        tKey: 'adGroupLabel',
        tKey2: 'common.nAdGroupLabelSelected',
        displayName: 'label',
        filterType: 'IN_NUMBER',
        category: 'METADATA',
        unit: 'TEXT',
    },
    ...CPI_FILTER_COLUMN,
];

const AD_SUB_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'adId',
        tKey: 'adId',
        displayName: 'adId',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'adDisplayStatus',
        tKey: 'adDisplayStatus',
        tKey2: 'common.nStatusSelected',
        displayName: 'adDisplayStatus',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'currency',
        tKey: 'currency',
        displayName: 'currency',
        filterType: 'ENUM',
        category: 'REPORT',
        subCategory: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adName',
        tKey: 'adName',
        displayName: 'Ad',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupStatus',
        tKey: 'adGroupStatus',
        displayName: 'AdGroup Status',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'campaignStatus',
        tKey: 'campaignStatus',
        displayName: 'campaignStatus',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adCreativeType',
        tKey: 'creativeType',
        tKey2: 'common.nCreativeTypeSelected',
        displayName: 'creativeType',
        filterType: 'IN_ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'productPageId',
        tKey: 'productPageId',
        tKey2: 'common.nCPPSelected',
        displayName: 'Product Page',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    ...CPI_FILTER_COLUMN,
];

const CPP_SUB_FILTER_COLUMNS = [
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'cppCreativeType',
        tKey: 'creativeType',
        displayName: 'creativeType',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'productPageName',
        tKey: 'productPageName',
        displayName: 'productPageName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'productPageId',
        tKey: 'productPageId',
        displayName: 'productPageId',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'numberOfAds',
        tKey: 'numberOfAds',
        displayName: 'numberOfAds',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    ...CPI_FILTER_COLUMN,
];

export const NEGATIVE_KEYWORD_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'campaignName',
        tKey: 'campaignName',
        displayName: 'campaignName',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        unit: 'NUMBER',
    },
    {
        name: 'negativeKeyword',
        tKey: 'negativeKeyword',
        displayName: 'Keyword',
        filterType: 'STRING',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'negativeKeywordMatchType',
        tKey: 'matchType',
        displayName: 'Match Type',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
];

export const SEARCH_TERM_KEYWORD_ID_COLUMN = {
    name: 'searchTermKeywordId',
    tKey: 'searchTermKeyword',
    tKey2: 'common.nKeywordsSelected',
    displayName: 'Search Term',
    filterType: 'NUMBER',
    category: 'REPORT',
};

export const ADS_PRODUCT_PAGE_ID_COLUMN = {
    name: 'productPageId',
    tKey: 'productPageId',
    tKey2: 'common.nCPPSelected',
    displayName: 'Product Page',
    filterType: 'STRING',
    category: 'REPORT',
};

export const SEARCH_TERM_TEXT_COLUMN = {
    name: 'searchTermText',
    tKey: 'searchTermText',
    displayName: 'Search Term',
    filterType: 'STRING',
    category: 'REPORT',
    subCategory: 'METADATA',
    unit: 'TEXT',
};

export const SEARCH_TERM_KEYWORD_COLUMN = {
    name: 'searchTermKeyword',
    tKey: 'searchTermKeyword',
    displayName: 'Keyword',
    filterType: 'STRING',
    category: 'REPORT',
    subCategory: 'METADATA',
    unit: 'TEXT',
};

export const KEYWORD_ID_COLUMN = {
    name: 'keywordId',
    tKey: 'keywordName',
    tKey2: 'common.nKeywordsSelected',
    displayName: 'Keyword',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const AD_ID_COLUMN = {
    name: 'adId',
    tKey: 'adId',
    displayName: 'Ad ID',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const CPP_ID_COLUMN = {
    name: 'productPageId',
    tKey: 'productPageId',
    tKey2: 'common.nCPPSelected',
    displayName: 'CPP',
    filterType: 'STRING',
    category: 'METADATA',
};

export const AD_GROUP_ID_COLUMN = {
    name: 'adGroupId',
    tKey: 'adGroup',
    tKey2: 'common.nAdGroupsSelected',
    displayName: 'Ad Group',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const CREATIVE_SET_ID_COLUMN = {
    name: 'creativeSetId',
    tKey: 'creativeSetId',
    displayName: 'creativeSetId',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const AD_FORMAT_COLUMN = {
    name: 'adFormat',
    tKey: 'adFormat',
    displayName: 'adFormat',
    filterType: 'STRING',
    category: 'METADATA',
};

export const CAMPAIGN_ID_COLUMN = {
    name: 'campaignId',
    tKey: 'campaign',
    tKey2: 'common.nCampaignsSelected',
    displayName: 'Campaign',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const APP_ID_COLUMN = {
    name: 'appId',
    tKey: 'appName',
    tKey2: 'common.nAppsSelected',
    displayName: 'App',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const ORG_ID_COLUMN = {
    name: 'orgId',
    tKey: 'orgName',
    tKey2: 'common.nAccountsSelected',
    displayName: 'Account',
    filterType: 'NUMBER',
    category: 'METADATA',
};

export const STORE_FRONT_COLUMN = {
    name: 'country',
    tKey: 'storefront',
    tKey2: 'common.nCountriesSelected',
    displayName: 'Store Front',
    filterType: 'STRING',
    category: 'METADATA',
};

export const FILTER_TYPE_IN = {
    type: 'NUMBER',
    inputType: 'text',
    word: 'IN',
    tKey: 'common.in',
    enum: 'IN',
    symbol: 'IN',
};

export const FILTER_TYPE_IN_STR = {
    type: 'STRING',
    inputType: 'text',
    word: 'IN',
    tKey: 'common.in',
    enum: 'IN',
    symbol: 'IN',
};

export const FILTER_TYPE_EQUAL = {
    type: 'STRING',
    inputType: 'text',
    word: 'Equals',
    tKey: 'common.equals',
    enum: 'EQUALS_STR',
    symbol: '=',
};

export const FILTER_TYPE_CONTAIN = {
    type: 'STRING',
    inputType: 'text',
    word: 'Contains',
    tKey: 'common.contains',
    enum: 'CONTAINS',
    symbol: '%',
};

export const RULE_NAME_COLUMN = {
    name: 'ruleName',
    tKey: 'ruleName',
    displayName: 'Rule',
    filterType: 'STRING',
    category: 'RULE',
};

export const RULE_ID_COLUMN = {
    name: 'ruleId',
    tKey: 'ruleId',
    tKey2: 'common.nRulesSelected',
    displayName: 'Rule',
    filterType: 'NUMBER',
    category: 'RULE',
};

export const RULE_RESTRICTION_TYPE_COLUMN = {
    name: 'restrictionEntityType',
    tKey: 'restrictionEntityType',
    displayName: 'restrictionEntityType',
    filterType: 'STRING',
    category: 'RULE',
};

export const RULE_ACTION_FIELD_COLUMN = {
    name: 'actionField',
    tKey: 'actionField',
    displayName: 'actionField',
    filterType: 'STRING',
    category: 'RULE_ACTION',
};

export const FILTER_TYPES = {
    STRING: [
        { type: 'STRING', inputType: 'text', word: 'Contains', tKey: 'common.contains', enum: 'CONTAINS', symbol: '%' },
        {
            type: 'STRING',
            inputType: 'text',
            word: 'Not Contains',
            tKey: 'common.notContains',
            enum: 'NOT_CONTAINS',
            symbol: '!%',
        },
        { type: 'STRING', inputType: 'text', word: 'Equals', tKey: 'common.equals', enum: 'EQUALS_STR', symbol: '=' },
        {
            type: 'STRING',
            inputType: 'text',
            word: 'Not Equals',
            tKey: 'common.notEquals',
            enum: 'NOT_EQUALS_STR',
            symbol: '!=',
        },
    ],
    INT_ENUM: [
        { type: 'ENUM', inputType: 'text', word: 'Is', tKey: 'common.is', enum: 'EQUALS', symbol: ':' },
        { type: 'ENUM', inputType: 'text', word: 'Is Not', tKey: 'common.isNot', enum: 'NOT_EQUALS', symbol: '!:' },
    ],
    ENUM: [
        { type: 'ENUM', inputType: 'text', word: 'Is', tKey: 'common.is', enum: 'EQUALS_STR', symbol: ':' },
        { type: 'ENUM', inputType: 'text', word: 'Is Not', tKey: 'common.isNot', enum: 'NOT_EQUALS_STR', symbol: '!:' },
    ],
    IN: [
        { type: 'NUMBER', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
        { type: 'STRING', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
    ],
    IN_STRING: [
        { type: 'STRING', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
    ],
    IN_NUMBER: [
        { type: 'NUMBER', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
    ],
    IN_ENUM: [
        { type: 'ENUM', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
    ],
    IN_RANGE: [
        { type: 'NUMBER', inputType: 'text', word: 'In Range', tKey: 'common.IN_RANGE', enum: 'BETWEEN', symbol: 'In range' },
        { type: 'ENUM', inputType: 'text', word: 'Is', tKey: 'common.is', enum: 'EMPTY', symbol: ':' },
    ],
    NUMBER: [
        {
            type: 'NUMBER',
            inputType: 'number',
            word: 'Greater Than',
            tKey: 'common.greaterThan',
            enum: 'GREATER_THAN',
            symbol: '>',
        },
        {
            type: 'NUMBER',
            inputType: 'number',
            word: 'Less Than',
            tKey: 'common.lessThan',
            enum: 'LESS_THAN',
            symbol: '<',
        },
        { type: 'NUMBER', inputType: 'number', word: 'Equals', tKey: 'common.equals', enum: 'EQUALS', symbol: '=' },
        { type: 'NUMBER', inputType: 'number', word: 'BETWEEN', tKey: 'common.between', enum: 'BETWEEN', symbol: ':' },
        {
            type: 'NUMBER',
            inputType: 'number',
            word: 'NOT_BETWEEN',
            tKey: 'common.notBetween',
            enum: 'NOT_BETWEEN',
            symbol: '!:',
        },
        { type: 'NUMBER', inputType: 'text', word: 'IN', tKey: 'common.in', enum: 'IN', symbol: 'IN' },
    ],
};

const common_columns = [
    {
        name: 'orgId',
        tKey: 'orgName',
        tKey2: 'common.nAccountsSelected',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'METADATA',
        table: ['all'],
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'METADATA',
        table: ['APP', 'CAMPAIGN', 'AD_GROUP', 'KEYWORD', 'SEARCH_TERM', 'CREATIVE_SET', 'NEGATIVE'],
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'METADATA',
        table: ['CAMPAIGN', 'AD_GROUP', 'KEYWORD', 'SEARCH_TERM', 'CREATIVE_SET', 'NEGATIVE'],
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'METADATA',
        table: ['AD_GROUP', 'KEYWORD', 'SEARCH_TERM', 'CREATIVE_SET', 'NEGATIVE'],
    },
    {
        name: 'country',
        tKey: 'storefront',
        tKey2: 'common.nCountriesSelected',
        displayName: 'Store Front',
        filterType: 'STRING',
        category: 'REPORT',
        table: ['CAMPAIGN', 'AD_GROUP', 'KEYWORD', 'SEARCH_TERM', 'CREATIVE_SET'],
    },
    {
        name: 'keywordId',
        tKey: 'keywordId',
        tKey2: 'common.nKeywordsSelected',
        displayName: 'Keyword',
        filterType: 'NUMBER',
        category: 'METADATA',
        table: ['KEYWORD'],
    },
    {
        name: 'searchTermKeywordId',
        tKey: 'searchTermKeywordId',
        displayName: 'Keyword',
        filterType: 'NUMBER',
        category: 'REPORT',
        table: ['SEARCH_TERM'],
    },
];

export const RULE_FILTER_COLUMNS = [
    {
        name: 'ruleName',
        tKey: 'ruleName',
        displayName: 'ruleName',
        filterType: 'STRING',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'ruleType',
        tKey: 'ruleType',
        displayName: 'ruleType',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'ruleId',
        tKey: 'ruleId',
        tKey2: 'common.nRulesSelected',
        displayName: 'Rule',
        filterType: 'NUMBER',
        category: 'RULE',
        invisible: true,
    },
    {
        name: 'ruleState',
        tKey: 'ruleState',
        displayName: 'ruleState',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'stateReason',
        tKey: 'stateReason',
        displayName: 'stateReason',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'ruleSupplySources',
        tKey: 'adChannel',
        filterType: 'ENUM',
        category: 'METADATA',
        unit: 'TEXT',
    },
    {
        name: 'ruleActionFrequency',
        tKey: 'ruleActionFrequency',
        displayName: 'ruleActionFrequency',
        filterType: 'INT_ENUM',
        category: 'RULE',
        unit: 'NUMBER',
    },
    {
        name: 'ruleControlFrequency',
        tKey: 'ruleControlFrequency',
        displayName: 'ruleControlFrequency',
        filterType: 'INT_ENUM',
        category: 'RULE',
        unit: 'NUMBER',
    },
    {
        name: 'ruleLogState',
        tKey: 'ruleLogState',
        displayName: 'ruleLogState',
        filterType: 'ENUM',
        category: 'RULE_LOG',
        unit: 'TEXT',
    },
    {
        name: 'actionType',
        tKey: 'actionType',
        displayName: 'actionType',
        filterType: 'ENUM',
        category: 'RULE_ACTION',
        unit: 'TEXT',
    },
    {
        name: 'restrictionEntityType',
        tKey: 'restrictionLevel',
        displayName: 'restrictionEntityType',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'queryingEntityType',
        tKey: 'automationLevel',
        displayName: 'automationLevel',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'emailNotifier',
        tKey: 'emailNotifier',
        displayName: 'emailNotifier',
        filterType: 'STRING',
        category: 'RULE_ACTION',
        unit: 'TEXT',
    },
    {
        name: 'ruleLabel',
        tKey: 'label',
        displayName: 'label',
        filterType: 'INT_ENUM',
        category: 'RULE',
        unit: 'NUMBER',
    },
];

export const RULE_ADS_FILTER_COLUMNS = [
    {
        name: 'orgId',
        tKey2: 'common.nAccountsSelected',
        tKey: 'orgName',
        displayName: 'Account',
        filterType: 'NUMBER',
        category: 'RULE_RESTRICTION',
        unit: 'NUMBER',
    },
    {
        name: 'appId',
        tKey: 'appName',
        tKey2: 'common.nAppsSelected',
        displayName: 'App',
        filterType: 'NUMBER',
        category: 'RULE_RESTRICTION',
        unit: 'NUMBER',
    },
    {
        name: 'campaignId',
        tKey: 'campaign',
        tKey2: 'common.nCampaignsSelected',
        displayName: 'Campaign',
        filterType: 'NUMBER',
        category: 'RULE_RESTRICTION',
        unit: 'NUMBER',
    },
    {
        name: 'adGroupId',
        tKey: 'adGroup',
        tKey2: 'common.nAdGroupsSelected',
        displayName: 'Ad Group',
        filterType: 'NUMBER',
        category: 'RULE_RESTRICTION',
        unit: 'NUMBER',
    },
];

export const RULE_ATTR_FILTER_COLUMNS = [
    {
        name: 'goalId',
        tKey: 'goal',
        displayName: 'goal',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
    {
        name: 'reAttrType',
        tKey: 'reAttrType',
        displayName: 'reAttrType',
        filterType: 'ENUM',
        category: 'RULE',
        unit: 'TEXT',
    },
];

export const DASHBOARD_TABS = {
    ACCOUNTS: 'accounts',
    APPS: 'apps',
    CAMPAIGNS: 'campaigns',
    AD_GROUPS: 'ad-groups',
    KEYWORDS: 'keywords',
    SEARCH_TERMS: 'search-terms',
    ADS: 'ads',
    CPP: 'cpp',
    NEGATIVE_KEYWORDS: 'negative-keywords',
};

export const CUSTOM_COLUMN_LEVEL = {
    ACCOUNT: 'accounts',
    APP: 'apps',
    CAMPAIGN: 'campaigns',
    AD_GROUP: 'adGroups',
    KEYWORD: 'keywords',
    SEARCH_TERM: 'searchTerms',
    AD: 'ads',
    CPP: 'cpp',
    NEGATIVE: 'negativeKeywords',
};

export const DASHBOARD_TABLES = {
    ACCOUNTS: 'accounts',
    APPS: 'apps',
    CAMPAIGNS: 'campaigns',
    AD_GROUPS: 'adGroups',
    ADS: 'ads',
    CPP: 'cpp',
    KEYWORD: 'keyword',
    KEYWORDS: 'keywords',
    SEARCH_TERMS: 'searchTerms',
    CREATIVE_SETS: 'creativeSets',
    NEGATIVE_KEYWORDS: 'negativeKeywords',
};

export const RESPONSE_TYPES = {
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    BLOCKED: 'BLOCKED',
};

export function getCommonColumns(tableName) {
    return !tableName ? common_columns : common_columns.filter(c => (c.table.includes('all') || c.table.includes(tableName)));
}

export const APP_FILTER_COLUMNS = APP_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS
);
export const CAMPAIGN_FILTER_COLUMNS = CAMPAIGN_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS,
    LTV_FILTER_COLUMN
);
export const KEYWORD_FILTER_COLUMNS = KEYWORD_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS,
    LTV_FILTER_COLUMN,
    SOV_COLUMN
);
export const SEARCH_TERM_FILTER_COLUMNS = SEARCH_TERM_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    SOV_COLUMN
);
export const AD_GROUP_FILTER_COLUMNS = AD_GROUP_SUB_FILTER_COLUMNS.concat(
    AUDIENCE_FILTER_COLUMNS,
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS,
    SCHEDULE_ADS_FILTER_COLUMNS,
    LTV_FILTER_COLUMN
);
export const AD_FILTER_COLUMNS = AD_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS
);
export const CPP_FILTER_COLUMNS = CPP_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS
);
export const CAMPAIGN_GROUP_COLUMNS = CPP_SUB_FILTER_COLUMNS.concat(
    TOTAL_FILTER_COLUMNS,
    ATTRIBUTION_FILTER_COLUMNS,
    ATTRIBUTION_INSTALL_COLUMNS
);

export const MATCH_TYPES = ['EXACT', 'BROAD'];
export const CAMPAIGN_STATUS_TYPES = ['ENABLED', 'PAUSED'];
export const KEYWORD_STATUS_TYPES = ['ACTIVE', 'PAUSED'];
export const CREATIVE_SET_STATUS_TYPES = ['ELIGIBLE', 'INVALID', 'PAUSED', 'DELETED'];
export const RULE_STATUS_TYPES = ['ACTIVE', 'PASSIVE'];
export const RULE_STATE_REASONS = ['PAUSED_BY_USER', 'PAUSED_BY_CP', 'PAUSED_BY_SYSTEM'];
export const RULE_TYPES = ['AUTOMATION', 'AUTOMATION_CLIENT_PARTNER', 'SMART_BID', 'SMART_NOTIFICATION'];
export const RULE_AD_CHANNEL_TYPES = ['SEARCH', 'DISPLAY'];
export const ADDED_AS_KEYWORD = ['ADDED', 'NOT_ADDED', 'EXCLUDED', 'ADDED_AND_EXCLUDED'];
export const RULE_LOG_STATUS_TYPES = [
    'SUCCESSFUL',
    'WARNING',
    'ERROR',
    'TECHNICAL_ERROR'];
export const RE_ATTR_TYPES = ['WITHOUT', 'ONLY', 'WITH'];
export const ACTION_FREQUENCY_TYPES = [
    'ONCE_HOURLY',
    'ONCE_THREE_HOURS',
    'ONCE_SIX_HOURS',
    'ONCE_TWELVE_HOURS',
    'ONCE_A_DAY',
    'ONLY_ONCE',
    'ONCE_IN_TWO_DAY',
    'ONCE_IN_THREE_DAY',
    'ONCE_IN_SEVEN_DAY',
];
export const CONTROL_FREQUENCY_TYPES = [
    'HOURLY',
    'THREE_HOURLY',
    'TWELVE_HOURLY',
    'DAILY'];
export const DATE_CHECK_RANGE_TYPES = [
    'FROM_START',
    'LAST_HOUR',
    'LAST_DAY',
    'LAST_WEEK',
    'LAST_MONTH',
    'THIS_MONTH'];

export const SEARCH_MATCH_TYPES = ['SEARCH_MATCH_ON', 'SEARCH_MATCH_OFF'];
export const SEARCH_TERM_SOURCE_TYPES = ['AUTO', 'TARGETED'];
export const LOW_VOLUME_KEYWORD_TYPES = ['EXCLUDED', 'INCLUDED'];
export const AGE_RANGE_TYPES = ['SELECTED'];
export const GENDER_TYPES = ['MALE', 'FEMALE', 'MALE_FEMALE'];
export const DEVICE_TYPES = ['IPHONE', 'IPAD', 'IPHONE_IPAD'];
export const DAY_PART_TYPES = ['SELECTED'];
export const LOCALITY_TYPES = ['SELECTED'];
export const CUSTOMER_TYPES = ['ALL_USERS', 'NEW_USERS', 'RETURNING_USERS', 'USERS_OF_MY_OTHER_APPS'];
export const DISPLAY_STATUS_TYPES = ['RUNNING', 'PAUSED', 'ON_HOLD'];
export const ADGROUP_DISPLAY_STATUS_TYPES = ['RUNNING', 'PAUSED', 'ON_HOLD', 'CAMPAIGN_ON_HOLD'];
export const KEYWORD_DISPLAY_STATUS_TYPES = ['RUNNING', 'PAUSED', 'CAMPAIGN_ON_HOLD', 'AD_GROUP_ON_HOLD'];
export const AD_DISPLAY_STATUS_TYPES = ['ACTIVE', 'PAUSED', 'INVALID', 'ON_HOLD', 'REMOVED'];
export const CREATIVE_TYPES = ['CUSTOM_PRODUCT_PAGE', 'CREATIVE_SET', 'DEFAULT_PRODUCT_PAGE'];
export const EMPTY_TYPE = ['EMPTY'];
export const SUPPLY_SOURCE_TYPES = [
    'APPSTORE_TODAY_TAB',
    'APPSTORE_SEARCH_TAB',
    'APPSTORE_SEARCH_RESULTS',
    'APPSTORE_PRODUCT_PAGES_BROWSE',
];
export const BID_STRENGTH_TYPES = [
    { tKey: 'WEAK', key: 'WEAK', html: 'BID_STRENGTH_WEAK' },
    { tKey: 'MODERATE', key: 'MODERATE', html: 'BID_STRENGTH_MODERATE' },
    { tKey: 'STRONG', key: 'STRONG', html: 'BID_STRENGTH_STRONG' },
    { tKey: 'UNAVAILABLE', key: 'UNAVAILABLE', html: 'BID_STRENGTH_UNAVAILABLE' },
];

export function getFilterTypeSymbolMapping() {
    const mapIt = (res, d) => {
        res[d.enum] = d.symbol;
        return res;
    };
    const stringTypes = FILTER_TYPES.STRING.reduce(mapIt, {});
    const numberTypes = FILTER_TYPES.NUMBER.reduce(mapIt, {});
    return Object.assign({}, stringTypes, numberTypes);
}

export function validIntegrationOrgIdList() {
    const integrations = app.$store.getters['account/integrations'];
    return integrations && Object.keys(integrations).flatMap(k => integrations[k])
        .filter(k => k.valid).map(i => i.orgId);
}

export function validOrgList() {
    const integrations = app.$store.getters['account/integrations'];
    const validOrgs = integrations && Object.keys(integrations).flatMap(k => integrations[k])
        .filter(k => k.valid);
    const orgs = [];
    const preOrgs = [];
    for (const org of validOrgs) {
        if (!preOrgs.includes(org.orgId)) {
            orgs.push(org);
            preOrgs.push(org.orgId);
        }
    }
    return orgs;
}

export function isValidOrgIdMatch(integration, orgId) {
    return integration?.valid && `${ integration?.orgId }` === `${ orgId }`;
}

export function changeActiveOrgId(orgId) {
    const integrations = app.$store.getters['account/integrations'];
    if (!Object.keys(integrations || {}).length) {
        return;
    }
    const row = Object.keys(integrations)
        .flatMap(k => integrations[k])
        .filter(integration => isValidOrgIdMatch(integration, orgId));
    if (row.length) {
        app.$store.dispatch('account/changeOrg', row[0]).catch(e => this.$log.error('failed to change org:', e));
        app.$log.warn('Active org changed to : ' + orgId);
    }
}

export function findOrgById(orgId) {
    const integrations = app.$store.getters['account/integrations'];
    if (!Object.keys(integrations || {}).length) {
        return;
    }
    const rows = Object.keys(integrations)
        .flatMap(k => integrations[k])
        .filter(integration => isValidOrgIdMatch(integration, orgId));
    return rows?.[0];
}

export const LEVEL_ENTITY_MAP = {
    [FILTER_LEVELS.APP]: {
        entityField: 'appName',
        keyField: 'appId',
    },
    [FILTER_LEVELS.CAMPAIGN]: {
        entityField: 'campaignName',
        tKey: 'campaign',
        keyField: 'campaignId',
    },
    [FILTER_LEVELS.AD_GROUP]: {
        entityField: 'adGroupName',
        tKey: 'adGroupName',
        keyField: 'adGroupId',
    },
    [FILTER_LEVELS.KEYWORD]: {
        entityField: 'keyword',
        keyField: 'keywordId',
    },
    [FILTER_LEVELS.SEARCH_TERM]: {
        entityField: 'searchTermText',
        category: 'REPORT',
    },
    [FILTER_LEVELS.NEGATIVE]: {
        entityField: 'negativeKeyword',
    },
};

export const ACCESS_TYPES = {
    DEFAULT: 'DEFAULT',
    LIMITED: 'LIMITED',
};

export const CUSTOM_COLUMN_TABLES = {
    ACCOUNT: DASHBOARD_TABLES.ACCOUNTS,
    APP: DASHBOARD_TABLES.APPS,
    CAMPAIGN: DASHBOARD_TABLES.CAMPAIGNS,
    ADGROUP: DASHBOARD_TABLES.AD_GROUPS,
    KEYWORD: DASHBOARD_TABLES.KEYWORDS,
    AD: DASHBOARD_TABLES.ADS,
    CPP: DASHBOARD_TABLES.CPP,
    SEARCH_TERM: DASHBOARD_TABLES.SEARCH_TERMS,
};
