{
    "expired": "Expired",
    "expiredInfo": "No valid integration found to reach Apple Search Ads API.",
    "live": "Live",
    "liveInfo": "Apple Search Ads API is available. You can manage your operations comfortably.",
    "noIntegration": "No Integration",
    "noIntegrationInfo": "There is no Apple Search Ads API integration to use the platform.",
    "noValidIntegration": "No Valid Integration",
    "noValidIntegrationInfo": "There is no valid Apple Search Ads API integration to use the platform.",
    "notLive": "Unavailable",
    "notLiveInfo": "Problems reaching Apple Search Ads API. You might encounter some problems during your operations."
}
