import { IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT,
    REMOVE_NEGATIVE_KEYWORD, PAUSE_KEYWORD } from './actions.mjs';

// key structure            ?:optional
// <ADDs What>-<Insertion Level>-<Duplicate Keyword Position>-<Duplicate Keyword Type>-<Bid Value>?
// debugId: is used for matching case ono excel sheet, displaying case id on tooltip for debug purposes
// see detailed cases: https://docs.google.com/spreadsheets/d/1ZxYhMT9wv34rr3EtMBASFMfkuM4RCu2WjwsJfYTTRvY/edit#gid=630018202

export default {
    'ExactKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Exact': {
        debugId: '1.1',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_1',
    },
    'ExactKeyword-AdGroupLevel-SameAdGroup-NegativeKeyword-Broad': {
        debugId: '1.2',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_2',
    },
    'ExactKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact-SameBid': {
        debugId: '1.3',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING],
        tKey: 'case_1_3',
    },
    'ExactKeyword-AdGroupLevel-SameAdGroup-Keyword-Exact-DifferentBid': {
        debugId: '1.4',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT],
        tKey: 'case_1_4',
    },
    'ExactKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad-SameBid': {
        debugId: '1.5',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_5',
    },
    'ExactKeyword-AdGroupLevel-SameAdGroup-Keyword-Broad-DifferentBid': {
        debugId: '1.6',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, UPDATE_BID_AMOUNT, PAUSE_KEYWORD],
        tKey: '',
    },
    'ExactKeyword-AdGroupLevel-DifferentAdGroup-Keyword-Exact-DifferentBid': {
        debugId: '1.10',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_10',
    },
    'ExactKeyword-AdGroupLevel-DifferentAdGroup-Keyword-Broad-DifferentBid': {
        debugId: '1.12',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_12',
    },
    'ExactKeyword-AdGroupLevel-OtherCampaign-Keyword-Exact-DifferentBid': {
        debugId: '1.16',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_16',
    },

    'ExactKeyword-AdGroupLevel-OtherCampaign-Keyword-Broad-DifferentBid': {
        debugId: '1.18',
        type: 'warning',
        actions: [IGNORE, CANCEL_ADDING, PAUSE_KEYWORD],
        tKey: 'case_1_18',
    },
    'ExactKeyword-CampaignLevel-SameCampaign-Keyword-Exact': {
        debugId: '1.19',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_19',
    },
    'ExactKeyword-CampaignLevel-SameCampaign-Keyword-Broad': {
        debugId: '1.20',
        type: 'danger',
        actions: [IGNORE, CANCEL_ADDING, REMOVE_NEGATIVE_KEYWORD],
        tKey: 'case_1_20',
    },
};
