import { makeRequest } from '@/common/MaRequestPooler';

/**
 * Sends a slack notification for users that wants to join beta programs
 * betaType
 * @param { 'JOIN_KEYWORD_ADVISOR' | 'JOIN_SMART_BIDDING' | 'JOIN_CAMPAIGN_FORECASTING' } betaType This is defined as BETA_TYPE enum in this project.
 * @param {string} message
 * @returns {Promise}
 */
export function joinBetaProgram(betaType, message) {
    return makeRequest(
        `/sa/experiment/beta-program/notify/${betaType}`,
        {
            method: 'POST',
            data: message,
        });
}
