import cn from '@/i18n/components/KeywordAddModal/MaKeywordSuggestions.cn.json';
import en from '@/i18n/components/KeywordAddModal/MaKeywordSuggestions.en.json';
import ja from '@/i18n/components/KeywordAddModal/MaKeywordSuggestions.ja.json';
import ko from '@/i18n/components/KeywordAddModal/MaKeywordSuggestions.ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
