import dayjs from 'dayjs/esm/index';

import utc from 'dayjs/esm/plugin/utc';
import localizedFormat from 'dayjs/esm/plugin/localizedFormat';
import advancedFormat from 'dayjs/esm/plugin/advancedFormat';
import customParseFormat from 'dayjs/esm/plugin/customParseFormat';
import weekday from 'dayjs/esm/plugin/weekday';
import isoWeek from 'dayjs/esm/plugin/isoWeek';
import duration from 'dayjs/esm/plugin/duration';
import minMax from 'dayjs/esm/plugin/minMax';
import updateLocale from 'dayjs/esm/plugin/updateLocale';
import quarterOfYear from 'dayjs/esm/plugin/quarterOfYear';
import localeData from 'dayjs/esm/plugin/localeData';
import weekYear from 'dayjs/esm/plugin/weekYear';
import weekOfYear from 'dayjs/esm/plugin/weekOfYear';

dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(weekYear);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale);
dayjs.extend(quarterOfYear);
dayjs.extend(localeData);

export default dayjs;
