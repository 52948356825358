export { AutomationController, useAutomationController } from '@/controllers/AutomationController';
export { AttributionController, useAttributionController } from '@/controllers/AttributionController';
export { AuthController, useAuthController } from '@/controllers/AuthController';
export { AuthPublicController, useAuthPublicController } from '@/controllers/AuthPublicController';
export { OverviewController, useOverviewController } from '@/controllers/OverviewController';
export { ManagementController, useManagementController } from '@/controllers/ManagementController';
export { ShareOfVoiceController, useShareOfVoiceController } from '@/controllers/ShareOfVoiceController';
export { BudgetAllocationController, useBudgetAllocationController } from '@/controllers/BudgetAllocationController';
export { DashboardController, useDashboardController } from '@/controllers/DashboardController';
export { ColumnController, useColumnController } from '@/controllers/ColumnController';
export { ReportController , useReportController } from '@/controllers/ReportController';
export { KeywordAuctionController, useKeywordAuctionController } from '@/controllers/KeywordAuctionController';
export { MetadataController, useMetadataController } from '@/controllers/MetadataController';


export const PLATFORM = {
    SEARCHADS: 'SEARCHADS_COM',
};


