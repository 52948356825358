<template>
    <div class="flex gap-2 items-center p-2">
        <ma-icon
            name="arrow-left"
            size="sm"
            class="cursor-pointer text-gray-600"
            @click="emit('back-clicked')"
        />
        <span class="font-medium text-gray-900">
            {{ isEditMode ? t('editLabel') : t('createLabel') }}
        </span>
    </div>
    <div class="px-2 my-1">
        <ma-input
            v-model:value="labelName"
            placeholder="Popular"
        />
        <div v-if="showUniqueWarning" class="text-sm text-red-500">
            {{ t('labelNameMustBeUnique') }}
        </div>
    </div>
    <div class="grid grid-cols-7 gap-[6px] py-2 px-1">
        <div
            v-for="color in Object.keys(PRESET_COLORS)"
            :key="color"
            :class="{
                'ma-color-button border rounded-lg p-2 cursor-pointer': true,
                [PRESET_COLORS[color]]: selectedColor === color,
            }"
            @click="selectedColor = color"
        >
            <ma-icon
                name="dot"
                :color="color"
                size="xs"
            />
        </div>
    </div>
    <div class="p-2 mt-1">
        <ma-button
            size="medium"
            class="w-full"
            :disabled="!labelName || !selectedColor"
            :loading="loading"
            @click="createLabel"
        >
            {{ isEditMode ? t('buttons.apply') : t('createLabel') }}
        </ma-button>
    </div>
</template>

<script setup>
    import { MaIcon, MaInput, MaButton } from '@mobileaction/action-kit';
    import { addLabel, updateLabel } from '@/common/MaRequests/MetadataLabel';
    import i18n from '@/pages/Automations/Components/AutomationComponents.i18n';
    import { useI18n } from 'vue-i18n';
    import { computed, onMounted, ref } from 'vue';
    import { useLabelsStore } from '@/pinia/Labels';

    defineOptions({ i18n });
    const { t } = useI18n();

    const emit = defineEmits(['back-clicked', 'label-created']);

    const props = defineProps({
        selectedLabel: { type: Object, default: () => ({}) },
        isEditMode: { type: Boolean },
    });
    const labelName = ref('');
    const selectedColor = ref(null);
    onMounted(() => {
        if (props.isEditMode) {
            labelName.value = props.selectedLabel.name;
            selectedColor.value = props.selectedLabel.color.toUpperCase();
        }
    });

    //these border classes need to exist as strings for tailwind to recognize them
    const PRESET_COLORS = {
        '#060B27': 'border-[#060B27]',
        '#392DCA': 'border-[#392DCA]',
        '#6FDDA6': 'border-[#6FDDA6]',
        '#7C5CFC': 'border-[#7C5CFC]',
        '#92BAFB': 'border-[#92BAFB]',
        '#F29E61': 'border-[#F29E61]',
        '#F97066': 'border-[#F97066]',
        '#4478FF': 'border-[#4478FF]',
        '#36BFFA': 'border-[#36BFFA]',
        '#98A2B3': 'border-[#98A2B3]',
        '#BDB4FE': 'border-[#BDB4FE]',
        '#B54708': 'border-[#B54708]',
        '#027A48': 'border-[#027A48]',
        '#026AA2': 'border-[#026AA2]',
    };

    const labelsStore = useLabelsStore();
    const labels = computed(() => labelsStore.labels);
    const loading = ref(false);

    const findLabelByName = (name) => {
        return labels.value.find(l => l.name === name);
    };

    const createLabel = async () => {
        const existingLabel = findLabelByName(labelName.value);
        const showWarning = !props.isEditMode && !!existingLabel;
        if (showWarning) {
            showUniqueWarning.value = true;
            return;
        }
        showUniqueWarning.value = false;
        loading.value = true;
        try {
            if (props.isEditMode) {
                await updateLabel(props.selectedLabel.id, {
                    name: labelName.value,
                    color: selectedColor.value,
                    description: '',
                });
            } else {
                await addLabel({
                    name: labelName.value,
                    color: selectedColor.value,
                    description: '',
                });
            }
            await labelsStore.getLabels( { cacheInvalidate: true });
            emit('label-created', labelName.value);
        } catch (e) {
            console.error('Failed to update label', e);
        } finally {
            loading.value = false;
        }
    };

    const showUniqueWarning = ref(false);
</script>
