{
    "chooseAccount": "Choose Campaign Group",
    "chooseAdGroup": "Choose Ad Group",
    "chooseApp": "Choose App",
    "chooseCampaign": "Choose Campaign",
    "chooseCountry": "Choose Country",
    "chooseCreativeSet": "Choose Creative Set",
    "chooseGoal": "Choose Goal",
    "chooseKeyword": "Choose Keyword",
    "confirm": {
        "bulkOperation": "Max. number of 20 {type} is allowed to go on with bulk operation",
        "filterRemove": "This will clear your unrelated goal filters. Continue?",
        "goalRemove": "This will clear your selected goal. Continue?",
        "selectionCanceled": "Selection canceled.",
        "singleCurrency": "You can go on with single currency. Please choose items that have same currency.",
        "singleItem": "Please choose a single {type} to go on."
    },
    "emptyTextSubList": "Select a Campaign from left to see its Ad Groups",
    "selectAccountText": "Select a Campaign Group from left",
    "selectAdGroupText": "Select an Ad Group from left mid table to add them to the list",
    "selectAppText": "Select an App from left",
    "selectCampaignText": "Select a Campaign from left",
    "selectCountryText": "Select a Country from left",
    "selectCreativeSetText": "Select a Creative Set from left",
    "selectGoalText": "Select a Goal from left",
    "selectKeywordText": "Select a Keyword from left",
    "selectedAccounts": "No Selected Campaign Group | {n} Selected Campaign Group | {n} Campaign Groups",
    "selectedAdGroups": "No Selected Ad Group | {n} Selected Ad Group | {n} Selected Ad Groups",
    "selectedApps": "No Selected App | {n} Selected App | {n} Selected Apps",
    "selectedCampaigns": "No Selected Campaign | {n} Selected Campaign | {n} Selected Campaigns",
    "selectedCountries": "No Selected Country | {n} Selected Country | {n} Selected Countries",
    "selectedCreativeSets": "No Selected Creative Set | {n} Selected Creative Set | {n} Selected Creative Sets",
    "selectedGoals": "No Selected Goal | {n} Selected Goal | {n} Selected Goals",
    "selectedKeywords": "No Selected Keyword | {n} Selected Keyword | {n} Selected Keywords",
    "summaryOfYourList": "Summary of your list",
    "showingActiveCampaign": "Showing Only Active Campaigns",
    "showingEligibleItems": "Showing Only Eligible Items",
    "types": {
        "ACCOUNT": "Campaign Group",
        "AD_GROUP": "Ad Group",
        "APP": "App",
        "CAMPAIGN": "Campaign",
        "COUNTRY": "Country",
        "GOAL": "Goal",
        "KEYWORD": "Keyword"
    },
    "youCanChangeThem": "You can change them whenever you want"
}
