{
    "chooseAccount": "キャンペーングループを選択",
    "chooseApp": "アプリを選択",
    "chooseCountry": "国を選択する",
    "chooseCreativeSet": "Creative Setを選択",
    "chooseGoal": "目標イベントを選択",
    "chooseKeyword": "キーワードを選択",
    "confirm": {
        "bulkOperation": "{type} 、最大20台までバルク・オペレーションを行うことができる。",
        "filterRemove": "フィルタ選択がクリアされますがよろしいですか？",
        "goalRemove": "目標イベント選択がクリアされますがよろしいですか？",
        "selectionCanceled": "選択がキャンセルされました",
        "singleCurrency": "通貨は同じ項目のもののみを選択ください",
        "singleItem": "キーワードを追加するには単一の{type}を選択してください"
    },
    "emptyTextSubList": "広告グループを表示するためにキャンペーンを選択してください",
    "selectAccountText": "左からアカウントを選択",
    "selectAdGroupText": "リストに追加するために広告グループを選択してください",
    "selectAppText": "左からアプリを選択",
    "selectCampaignText": "キャンペーンを選択してください",
    "selectCountryText": "左から国を選択",
    "selectCreativeSetText": "左からCreative Setを選択してください",
    "selectGoalText": "左から目標イベントを選択してください",
    "selectKeywordText": "左からキーワードを選択してください",
    "selectedAccounts": "アカウントが選択されていません | {n}個のアカウント | {n}個のアカウント",
    "selectedApps": "アプリが選択されていません | {n}個のアプリ | {n}個のアプリ",
    "selectedCountries": "国が選択されていません | {n}個の国 | {n}個の国",
    "selectedCreativeSets": "Creative Setが選択されておりません | {n}Creative Setを選択してください | {n}選択済みCreative Sets",
    "selectedGoals": "目標イベントが選択されていません | {n}個の目標イベント | {n}個の目標イベント",
    "summaryOfYourList": "リストの概要",
    "showingActiveCampaign": "アクティブなキャンペーンのみを表示",
    "showingEligibleItems": "対象商品のみ表示",
    "types": {
        "ACCOUNT": "キャンペーングループ",
        "AD_GROUP": "広告グループ",
        "APP": "アプリ",
        "CAMPAIGN": "キャンペーン",
        "COUNTRY": "国",
        "GOAL": "目標イベント",
        "KEYWORD": "キーワード"
    },
    "youCanChangeThem": "いつでも好きなときに変更できる",
    "chooseAdGroup": "広告グループを選択",
    "chooseCampaign": "キャンペーンを選択",
    "selectedAdGroups": "厳選された広告グループ",
    "selectedCampaigns": "キャンペーン",
    "selectedKeywords": "キーワードが選択されていません | {n}個のキーワード | {n}個のキーワード"
}
