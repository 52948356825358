{
    "asoTracked": "ASOトラッキング",
    "keywordSuggestionsFrom": "<b>{type}<\/b> Keyword Suggestions from   on<b>{app}<\/b> <b>{country}<\/b>",
    "organic": "オーガニック",
    "proFeature": "プロ特集",
    "talkToUs": "ご相談ください",
    "upgradeToPremium": "プレミアムにアップグレードすると、フルアクセスとさらに多くの機能をご利用いただけます。",
    "addAllKeywords": "ページ上の全てのキーワードを追加",
    "addKeywords": "キーワードを追加",
    "addNegativeKeywords": "広告グループに除外キーワードを追加",
    "added": "追加",
    "organicKeywords": "オーガニック検索キーワード",
    "paid": "支払い済",
    "paidKeywords": "入札されているキーワード"
}
