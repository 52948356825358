import Cookies from 'js-cookie';

export const c = {
    MA: 'MA',
    MISSING_INFO_CLOSE: 'missingInfoClose',
};

// set storage value
export function set(key, value, expires, options) {
    Cookies.remove(key, { domain: '.searchads.com' });

    Cookies.set(key, JSON.stringify(value), {
        ...options,
        expires: expires ? expires : 30,
    });
}

export function get(key) {
    const val = Cookies.get(key);
    try {
        return JSON.parse(val);
    } catch (e) {
        return val;
    }
}

export function remove(key) {
    Cookies.remove(key);
}

export function reduce(key, value) {
    if (typeof value !== 'undefined') {
        set(key, value);
    }
    return get(key, value);
}

export const AUTH_COOKIE_OPTIONS = {
    path: '/',
    secure: true,
    sameSite: 'Strict',
    domain: window.location.hostname.search('mobileaction.co') === -1? undefined: `.mobileaction.co`,
};

// Global Cookie Names
export const COOKIES = Object.freeze({
    AUTH: 'MA_userAuth',
});
