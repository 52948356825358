import { globalProperties as app } from '@/main';

// todo this one is used too many places,
// once its usages cleaned we can remove this as well
export const FETCH_ERROR = {
    title: 'Failed to get response',
    message: 'Server returned an error please try again later and if it persists let us know!',
};

const ErrorCodes = [
    'UNAUTHORIZED',
    'NOT_LOGGED_IN',
    'ACCOUNT_NOT_FOUND',
    'AUTHENTICATION_FAILED',
    'AUTH_MULTIPLE_LOGIN',
    'SMART_BIDDING_NOT_AVAILABLE',
    'SMART_NOTIFICATION_DATA_NOT_AVAILABLE',

    // ui generated enums
    'MISSING_SA_HEADER',
    'AUTH_CHECK_FAILED',
    'TOKEN_EXPIRED',
    'NO_TOKEN',
    'REQUEST_CANCELED',
    'NETWORK_ERROR',
];

const ErrorMessages = {
    FETCH_ERROR,
};
ErrorCodes.forEach(code => ErrorMessages[code] = code);

export function checkError(errors, key) {
    if (!errors || Number.isNaN(errors.length)) {
        app.$log.warn('Invalid keys sent');
        return false;
    }
    for (let i = 0; i < errors.length; i += 1) {
        if (errors[i] === key) {
            return ErrorMessages[key] || true;
        }
    }
    return false;
}

export default ErrorMessages;
