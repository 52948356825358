<template>
    <ma-dropdown
        :trigger="['click']"
        :get-popup-container="trigger => trigger.parentElement"
    >
        <div>
            <ma-avatar
                :src="avatar"
                size="small"
                class="cursor-pointer"
            />
        </div>
        <template #overlay>
            <div class="flex items-center gap-3 p-2 border-gray-200 border-b">
                <ma-avatar
                    :src="avatar"
                    size="small"
                    class="cursor-pointer"
                />
                <ma-typography
                    type="caption-1"
                    weight="regular"
                    tag="span"
                    class="text-gray-500"
                >
                    {{ user?.username }}
                </ma-typography>
                <ma-badge v-if="showFreeAccessBadge" type="secondary" variant="green">
                    {{ t('freeAccess') }}
                </ma-badge>
            </div>
            <template v-if="!isUserNoAccess">
                <router-link :to="{ name: 'settings' }">
                    <div class="ma-dropdown-item">
                        <ma-icon
                            name="settings"
                            class="text-gray-600"
                            size="sm"
                        />
                        <ma-typography type="body-4" class="text-gray-900">
                            {{ t('settings') }}
                        </ma-typography>
                    </div>
                </router-link>
                <router-link :to="{ name: 'integration' }">
                    <div class="ma-dropdown-item">
                        <ma-icon
                            name="plug"
                            class="text-gray-600"
                            size="sm"
                        />
                        <ma-typography type="body-4" class="text-gray-900">
                            {{ t('integrations') }}
                        </ma-typography>
                    </div>
                </router-link>
                <router-link :to="{ name: 'logs' }">
                    <div class="ma-dropdown-item">
                        <ma-icon
                            name="clock"
                            class="text-gray-600"
                            size="sm"
                        />
                        <ma-typography type="body-4" class="text-gray-900">
                            {{ t('logs') }}
                        </ma-typography>
                    </div>
                </router-link>
            </template>
            <ma-typography
                type="caption-1"
                weight="medium"
                class="text-gray-400 uppercase px-2 py-1"
            >
                {{ t('support') }}
            </ma-typography>
            <div class="ma-dropdown-item" @click="requestDemoPage">
                <ma-icon
                    name="calendar"
                    class="text-gray-600"
                    size="sm"
                />
                <ma-typography type="body-4" class="text-gray-900">
                    {{ t('requestDemo') }}
                </ma-typography>
            </div>
            <div class="ma-dropdown-item" @click="showIntecom">
                <ma-icon
                    name="messages"
                    class="text-gray-600"
                    size="sm"
                />
                <ma-typography type="body-4" class="text-gray-900">
                    {{ t('contactSupport') }}
                </ma-typography>
            </div>
            <div class="ma-dropdown-item" @click="emit('logout')">
                <ma-icon
                    name="logout"
                    class="text-gray-600"
                    size="sm"
                />
                <ma-typography type="body-4" class="text-gray-900">
                    {{ t('logOut') }}
                </ma-typography>
            </div>
            <div class="p-2">
                <a href="https://www.mobileaction.co/privacy-policy">
                    <ma-link-button
                        color="gray"
                        size="medium"
                    >
                        <ma-typography type="body-4">{{ t('privacyPolicy') }}</ma-typography>
                    </ma-link-button>
                </a>
                <ma-typography tag="span" type="body-4" class="text-gray-400">
                    ·
                </ma-typography>
                <a href="https://www.mobileaction.co/terms-of-service">
                    <ma-link-button
                        color="gray"
                        size="medium"
                    >
                        <ma-typography type="body-4"> {{ t('termsOfService') }}</ma-typography>
                    </ma-link-button>
                </a>
            </div>
        </template>
    </ma-dropdown>
</template>

<script setup>
    import {
        MaAvatar,
        MaBadge,
        MaDropdown,
        MaIcon,
        MaLinkButton,
        MaTypography,
    } from '@mobileaction/action-kit';
    import { useStore } from 'vuex';
    import { computed } from 'vue';
    import { getGravatarUrl } from '@/common/MaUtils.mjs';
    import { useI18n } from 'vue-i18n';
    import messages from '@/components/Topbar/Topbar.i18n';
    import { useGlobal } from '@/composables/index';
    import { REQUEST_DEMO_URL } from '@/common/Config/index';
    import { usePlanStore } from '@/pinia/Plan';

    defineOptions({
        i18n: { messages },
    });
    const { t } = useI18n();
    const planStore = usePlanStore();

    const store = useStore();
    const user = computed(() => store.state['user']);
    const isUserNoAccess = computed(() => store.getters['account/isUserNoAccess']);
    const avatar = computed(() => getGravatarUrl(user.value?.username));

    const isPlanFetched = computed(() => planStore.isPlanFetched);
    const isSearchAdsFreeUser = computed(() => planStore.isSearchAdsFreeUser);
    const showFreeAccessBadge = computed(() => { // show badge after fething user features
        return isPlanFetched.value && isSearchAdsFreeUser.value;
    });

    const app = useGlobal();
    const showIntecom = () => app.$maIntercom.show();
    const requestDemoPage = () => window.open(REQUEST_DEMO_URL, '_blank');
    const emit = defineEmits(['logout']);
</script>

<style lang="less">
    .ma-dropdown-item {
        @apply p-2 hover:bg-gray-50 cursor-pointer flex items-center rounded-lg gap-2;
    }
</style>
