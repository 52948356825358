{
    "CN": "中国话",
    "EN": "English",
    "JA": "日本語",
    "KO": "한국어",
    "chinese": "中国话",
    "english": "English",
    "japanese": "日本語",
    "korean": "한국어"
}
