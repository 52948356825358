{
    "addAllKeywords": "Add All Keywords on this page",
    "addKeywords": "Add Keywords",
    "addNegativeKeywords": "Add Negative Keywords to Ad Group",
    "added": "Added",
    "asoTracked": "ASO Tracked",
    "keywordSuggestionsFrom": "<b>{type}</b> Keyword Suggestions from <b>{app}</b> on <b>{country}</b>",
    "organic": "Organic",
    "organicKeywords": "Organic Keywords",
    "paid": "Paid",
    "paidKeywords": "Paid Keywords",
    "proFeature": "PRO FEATURE",
    "talkToUs": "Talk to us",
    "upgradeToPremium": "Upgrade to Premium for Full Access and More Features"
}
