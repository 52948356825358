import { cacheRequest, makeRequest } from '@/common/MaRequestPooler';

/**
 * Gets detailed app info for a single app
 *
 * @param trackId
 * @param countryCode
 */
export function getDetailedAppInfo({ trackId, countryCode }) {
    return cacheRequest(`/app/${ trackId }/info/detailed/${ countryCode }`);
}

/**
 * Returns simplified app info of given track ids
 * @param urlParams
 *  trackIds: comma separated trackIds
 */
export function getSimpleAppInfo({ storeName = 'ios', trackIds }) {
    const APP_INFO_REQUEST_SIZE = 100;

    let requests = [];
    for (let i = 0; i < trackIds.length; i += APP_INFO_REQUEST_SIZE) {
        //take APP_INFO_REQUEST_SIZE ids at a time
        let trackIdsSlice = trackIds.slice(i, i + APP_INFO_REQUEST_SIZE);
        requests.push(makeRequest(`/app/info/simple/${ storeName }`, {
            method: 'POST',
            data: trackIdsSlice,
        }));
    }
    return Promise.all(requests).then( (responses) => {
        if (responses && responses.length) {
            return responses.reduce((res, data) => res.concat(data || []), []);
        }
        return [];
    });
}

export function appSearch({ storeName, query, region }) {
    return cacheRequest(`/app/search/store/${ storeName }?query=${ query }&countryCode=${ region }`);
}
