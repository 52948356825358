import { makeRequest } from '@/common/MaRequestPooler';
import { getAsArray } from '@/common/MaUtils.mjs';
import { searchTabFormatter } from '@/pages/Manage/ManageUtils.mjs';
import { FIELDS } from '@/pages/CampaignForecasting/composables/useCampaignForecastForm';

export function findAllMetadata({ data, requestLevel, isExperiment }) {
    const experimentUrl = {
        [FIELDS.ACCOUNT]: '/sa/experiment/campaign-forecast/eligible-campaign-groups',
        [FIELDS.CAMPAIGN]: '/sa/experiment/campaign-forecast/eligible-campaigns',
    };

    let requestUrl = `/sa/dashboard/report/metadata/${ requestLevel }`;
    if (isExperiment && experimentUrl[requestLevel]) { // check if level url is available
        requestUrl = experimentUrl[requestLevel];
    }

    return makeRequest(requestUrl, {
        method: 'POST',
        data: getAsArray(data),
        formatter: searchTabFormatter(requestLevel),
    });
}

export function findCampaign({ data, campaignId }) {
    return makeRequest(`/sa/dashboard/report/metadata/campaigns/${campaignId}/find`, {
        method: 'POST',
        data: getAsArray(data),
    });
}
