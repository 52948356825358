{
    "audit": "审计",
    "optimize": "优化",
    "campaignWizard": "活动向导",
    "coPilot": "副领航员",
    "coPilotAbb": "CP.",
    "hideTheMenu": "",
    "adsManager": "广告管理",
    "aso": "KP.",
    "attribution": "归因",
    "automations": "自动化规则",
    "benchmarks": "基准",
    "budgetAllocation": "预算分配",
    "collapseMenu": "折叠菜单",
    "campaignForecasting": "活动预测",
    "competitionAnalysis": "竞争分析",
    "competitionAnalysisAbb": "CA.",
    "competitors": "竞品",
    "contactSupport": "服务支持",
    "credit": "学分",
    "expandMenu": "展开菜单",
    "funnels": "漏斗",
    "goals": "目标设置",
    "ic": "IC.",
    "insightCenter": "洞察中心",
    "integrations": "集成",
    "keywordAdvisor": "关键词顾问",
    "keywordAuctionInsights": "关键词拍卖分析",
    "cppIntelligence": "CPP 情报",
    "cppIntelligenceTooltip": "探索我们的数据驱动解决方案，了解竞争对手的自定义产品页面战略。",
    "keywordPlanner": "关键词规划器",
    "ma": "马萨诸塞州",
    "mmp": "MMP",
    "mobileMeasurementPartner": "移动衡量服务提供商",
    "optimization": "优化",
    "organicKeywordsHunt": "自然关键词搜索",
    "overview": "概述",
    "quickActions": "快速行动",
    "recommendations": "相关建议",
    "report": "报告",
    "requestDemo": "体验Demo",
    "settings": "设置",
    "setup": "设置",
    "setupAbb": "设置",
    "setupCompleteness": "设置完成度",
    "smartBidding": "智能投标",
    "spotlight": "聚焦",
    "switchTo": "切换到",
    "tooltips": {
        "adsManager": "广告系列管理控制面板，您可以在此创建广告系列、跟踪效果、跟踪 Apple Search Ads 漏斗、添加自动化规则并扩展广告系列。",
        "automations": "设置自动化规则，确保您的营销活动始终处于优化状态。",
        "spotlight": "利用智能工具自动提高性能",
        "campaignWizard": "只需 3 个步骤，即可创建 Apple 搜索广告系列！",
        "keywordAdvisor": "自动查找高意向关键词",
        "smartBidding": "通过对正确的关键字进行正确的竞价，消除广告费用的浪费。提高 UA 和成本预测的准确性。",
        "audit": "使用我们的审核功能详细分析你的 Apple Search Ads 账户，发现浪费你的预算和时间的任何低效之处。",
        "campaignForecasting": "增加每日预算可使您的广告在广告中显示更多。尝试不同的每日预算，预测搜索结果广告系列的印象、点击和安装量。",
        "organicKeywordsHunt": "探索App排名的所有自然关键词，并根据App Store的指标选择最佳关键词，然后立即开始在这些关键词上投放广告。",
        "goals": "配置自定义目标，跟踪精确的 ROAS。设置归因整合并开始跟踪。"
    },
    "grader": "",
    "integration": "",
    "quickAutomation": "",
    "smartToolsAbb": ""
}
