{
    "chooseAdGroup": "选择广告组",
    "chooseApp": "选择应用程序",
    "chooseCampaign": "选择广告系列",
    "chooseCountry": "选择国家",
    "chooseCreativeSet": "选择Creative Set",
    "chooseGoal": "选择目标",
    "chooseKeyword": "选择关键词",
    "confirm": {
        "filterRemove": "这将清除你无关的目标筛选器。确定要继续？",
        "goalRemove": "这将清除你已选的目标。确定要继续？",
        "selectionCanceled": "已取消选择。",
        "singleCurrency": "你可以使用单一货币继续操作。请选择具有相同货币的项目。",
        "singleItem": "请选择单个{type}继续。"
    },
    "emptyTextSubList": "从左侧选择一个广告系列查看其广告组",
    "selectAccountText": "从左侧选择一个帐户",
    "selectAdGroupText": "从左侧中央表选择一个广告组添加到列表中",
    "selectAppText": "从左选择一个应用程序",
    "selectCampaignText": "从左侧选择一个广告系列",
    "selectCountryText": "从左边选择一个国家",
    "selectCreativeSetText": "从左边选择一个Creative Set",
    "selectGoalText": "从左边选择一个目标",
    "selectKeywordText": "从左侧选择一个关键词",
    "selectedAccounts": "没有选择帐户 | 已选择 {n} 个帐户 | 已选择 {n} 个帐户",
    "selectedAdGroups": "没有选择广告组 | 已选择 {n} 个广告组 | 已选择 {n} 个广告组",
    "selectedApps": "没有选择应用程序 | 已选择 {n} 个应用程序 | 已选择 {n} 个应用程序",
    "selectedCampaigns": "没有选择广告系列 | 已选择 {n} 个广告系列 | 已选择 {n} 个广告系列",
    "selectedCountries": "没有选择国家 | 已选择 {n} 个国家 | 已选择 {n} 个国家",
    "selectedCreativeSets": "没有选择Creative Set | 已选择 {n} 个Creative Set | 已选择 {n} 个Creative Set",
    "selectedGoals": "没有选择目标 | 已选择 {n} 个目标 | 已选择 {n} 个目标",
    "selectedKeywords": "没有选择关键词 | 已选择 {n} 个关键词 | 已选择 {n} 个关键词",
    "types": {
        "AD_GROUP": "广告组",
        "APP": "应用程序",
        "CAMPAIGN": "广告系列",
        "COUNTRY": "国家",
        "GOAL": "目标",
        "KEYWORD": "关键词"
    }
}
