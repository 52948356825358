{
    "addedKeywords": {
        "keyword": "添加的关键词",
        "negative-keyword": "添加否定关键词"
    },
    "bidAmountMustBeNumber": "投标金额必须为数字。小数分隔符为点（00.0）",
    "bidAmountRequired": "投标金额为必填项，且必须大于 0 (0)",
    "buttons": {
        "changeAll": "全部更改",
        "changeSelected": "更改所选",
        "removeAll": "全部删除",
        "removeSelected": "移除选定的"
    },
    "createKeywordGroup": "在此创建关键词组",
    "createLabel": "创建标签",
    "enterKeywords": "输入或粘贴{type} 关键字。您可以用逗号分隔每个关键词，也可以每行输入一个关键词。",
    "goToKeywordTracking": "转到关键字跟踪",
    "keywordBid": "关键词竞价",
    "keywordGroup": "关键词组",
    "limitForAdGroup": "广告组关键词上限为 5000，超过上限的关键词不会添加到列表中",
    "noNegativeKeywords": "尚未添加否定关键字",
    "none": "没有数值",
    "pleaseInputCorrectBidAmount": "请输入正确的投标金额",
    "selectAll": "全部选择",
    "specialCharacters": "不允许使用特殊字符 ({chars})。请删除这些字符并重试。",
    "youCanEitherAddKeywords": "您可以在此手动添加关键字，也可以从左侧进行选择",
    "addLabelsToSelected": "将标签应用到选定的",
    "hideSelection": "",
    "editKeywords": "编辑{count} 关键字",
    "select": ""
}
