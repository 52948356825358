{
    "expired": "期限切れ",
    "expiredInfo": "Apple Search Adsの有効なAPIが見つかりませんでした",
    "live": "API稼働中",
    "liveInfo": "Apple Search AdsのAPIは正常に稼働しています",
    "noIntegration": "接続がありません",
    "noIntegrationInfo": "Apple Search AdsのAPI証明が確認されませんでした",
    "noValidIntegration": "有効な接続がありません",
    "noValidIntegrationInfo": "プラットフォームを利用するための有効なApple Search Ads API接続がありません",
    "notLive": "無効",
    "notLiveInfo": "現在、Apple Search AdsのAPI接続に問題があります。最新のデータ表示が遅延される可能性があります。"
}
