{
    "contactTheTeam": "Contact the Team",
    "featureList": {
        "1": "Have a premium Ads Manager experience",
        "2": "Set automation rules to automatize your campaign strategies",
        "3": "Start Smart Bidding to optimize your campaign management",
        "4": "Learn the most recent Apple Search Ads industry benchmarks with Benchmarks Report"
    },
    "upgradeNow": "Upgrade Now",
    "upgradePlan": "Upgrade your plan to access this page",
    "upgradeToStart": "Upgrade your plan to get started.",
    "withUpgradedPlan": "With the upgraded plan, you can:"
}
