import { makeRequest } from '@/common/MaRequestPooler';

export function fetchAppsWithCompetitors(cacheInvalidate = false) {
    return makeRequest(`/sa/account/apps/competitors`, {}, {
        cacheEnabled: false,
        cacheInvalidate,
    });
}

export function addCompetitor(appId, competitorId) {
    return makeRequest(`/sa/account/apps/${ appId }/competitors/${ competitorId }`, {
        method: 'POST',
    });
}

export function deleteCompetitor(appId, competitorId) {
    return makeRequest(`/sa/account/apps/${ appId }/competitors/${ competitorId }`, {
        method: 'DELETE',
    });
}

/**
 * Returns exclusive competitor list for connected accounts
 * @param trackId app track id
 * @param countryCode country code
 * @returns {Promise<any>}
 */
export function getExclusiveCompetitors(trackId, countryCode) {
    return makeRequest(`/datascience/competitor/app/${trackId}?country=${countryCode}`, {
        method: 'GET',
    });
}
