<template>
    <div
        id="trigger"
        :data-clipboard-text="content"
        class="inline-block cursor-pointer"
        @click="copy"
    >
        <template v-if="!hideContent && !$slots.default">
            {{ content || '-' }}
        </template>
        <slot v-else/>
        <fa v-show="!hideIcon && content" :icon="['far', 'copy']"/>
    </div>
</template>

<script>
    import Clipboard from 'clipboard';

    export default {
        name: 'ma-clip-text',
        props: {
            content: { type: [String, Number], default: null },
            hideContent: { type: Boolean, default: false },
            hideIcon: { type: Boolean, default: false },
            successMessage: { type: [String, Number], required: true },
        },
        methods: {
            copy() {
                this.clipboard = new Clipboard('#trigger');
                this.clipboard.on('success', (e) => {
                    this.$message.success(this.successMessage);
                    this.$log.debug('Clipboard success:', e);
                    e.clearSelection();
                    this.clipboard.destroy();
                });
                this.clipboard.on('error', (e) => {
                    this.$message.error('Please use CTRL + C to copy');
                    this.$log.error('Clipboard failed:', e);
                    this.clipboard.destroy();
                });
            },
        },
    };
</script>

<style scoped>

</style>
