<template>
    <div class="ma-app-search">
        <a-tooltip placement="top">
            <template v-if="disableTooltip" #title>
                {{ $t('components.appSearch.searchForAnyAppByName') }}
            </template>
            <a-auto-complete
                ref="appSearchInput"
                class="ma-app-search-autocomplete"
                :dropdown-match-select-width="false"
                :options="options"
                @select="handleSelect"
                @search="handleSuggestions"
                @blur="blur"
                @focus="focus"
            >
                <template #default>
                    <a-input
                        :size="size"
                        :placeholder="placeholder || $t('components.appSearch.searchForAnyAppByName')"
                        :allow-clear="true"
                        @blur="blur"
                    >
                        <template #prefix>
                            <slot name="prefix">
                                <search-outlined/>
                            </slot>
                        </template>
                    </a-input>
                </template>
                <template #option="app">
                    <div class="flex justify-between items-center">
                        <div class="flex items-center">
                            <div
                                class="flex items-center p-1"
                                :class="iconSize"
                            >
                                <ma-app-icon
                                    :icon="app.iconUrl"
                                    class="ma-brand-icon-md app-icon object-contain"
                                    size="small"
                                />
                            </div>
                            <div class="ma-text-overflow w-72 font-medium text-md leading-4">
                                {{ app.name }}
                            </div>
                        </div>
                        <div
                            v-if="!hidePublisher"
                            class="ma-text-overflow w-72 leading-4 text-md text-right"
                        >
                            {{ app.developerName }}
                        </div>
                        <div v-if="showNotificationState && notificationStates[app.trackId]">
                            <a-tag :color="notificationStates[app.trackId].color">
                                <a-tooltip :title="notificationStates[app.trackId].title">
                                    {{ notificationStates[app.trackId].label }}
                                </a-tooltip>
                            </a-tag>
                        </div>
                    </div>
                </template>
            </a-auto-complete>
        </a-tooltip>
    </div>
</template>

<script setup>
    import { AutoComplete as AAutoComplete } from 'ant-design-vue';
</script>
<script>
    import { checkError } from '@/common/ErrorMessages';
    import { appSearch, getSimpleAppInfo } from '@/common/MaRequests/AppInfo';
    import { mapGetters } from 'vuex';
    import { mapState } from 'pinia';
    import { useAccountStore } from '@/pinia/Account';
    import { Form } from 'ant-design-vue';
    import { SearchOutlined } from '@ant-design/icons-vue';

    export default {
        name: 'ma-app-search',
        components: {
            SearchOutlined,
        },
        props: {
            size: { type: String, default: 'middle' },
            iconPlacement: { type: String, default: 'prefix' },
            countryCode: { type: String, default: 'US' },
            disableTooltip: { type: Boolean, default: false },
            hidePublisher: { type: Boolean, default: false },
            showNotificationState: { type: Boolean, default: false },
            excludeCompetitors: { type: Boolean, default: false },
            clearAfterSelection: { type: Boolean, default: false },
            excludeIds: { type: Array, default: () => [] }, // array of app ids to be excluded
            notificationStates: { type: Object },
            placeholder: { type: String, default: () => null },
        },
        emits: ['select', 'blur', 'change'],
        data() {
            return {
                appList: [],
                selectedApp: null,
            };
        },
        computed: {
            ...mapGetters('account', ['appCompetitors']),
            ...mapState(useAccountStore, {
                authorizedApps: 'appIdList',
            }),
            options() {
                return this.appList.map(app => ({
                    ...app,
                    value: app.name,
                }));
            },
            allUserCompetitors() {
                const authCompetitors = this.appCompetitors
                    .filter(a => this.authorizedApps?.includes(Number(a.trackId)));
                return authCompetitors
                    .flatMap(x => x.competitors)
                    .map(k => Number(k.trackId));
            },
            iconSize() {
                return {
                    small: 'h-6 w-6',
                    default: 'h-8 w-8',
                    large: 'h-10 w-10',
                }[this.size];
            },
        },
        setup() {
            const formItemContext = Form.useInjectFormItemContext();
            return {
                formItemContext,
            };
        },
        methods: {
            fetchUserApps() {
                return new Promise((resolve, reject) => {
                    let appIds =  this.authorizedApps;
                    let competitors = this.allUserCompetitors;
                    if (!this.excludeCompetitors) {
                        appIds = appIds.concat(competitors);
                    }
                    if (this.excludeIds.length) {
                        appIds = appIds
                            .map(id => Number(id))
                            .filter(id => !this.excludeIds.map(id => Number(id)).includes(id));
                    }
                    if (!appIds.length) {
                        resolve([]);
                        return;
                    }
                    const trackIds = [...new Set(appIds)].slice(0, 30);
                    if (!trackIds.length) {
                        resolve([]);
                        return;
                    }
                    getSimpleAppInfo({ trackIds })
                        .then((data) => {
                            const response = data
                                .map(s => ({
                                    trackId: s.trackId,
                                    iconUrl: s.iconUrl || s.artworkUrl60,
                                    name: s.name || s.appName,
                                    developerName: s.developerName,
                                    rating: s.rating,
                                    ratingCount: s.ratingCount,
                                }))
                                .sort((a, b) => {
                                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                                        return 1;
                                    }
                                    return -1;
                                }); //sort by name
                            resolve(response);
                        })
                        .catch(({ errors }) => {
                            this.$log.error(`Failed to get app infos for trackIds:${ trackIds } error: ${ errors }`);
                            reject([]);
                        });

                });
            },
            handleSuggestions(queryString) {
                if (!queryString) {
                    this.fetchUserApps()
                        .then(data => this.appList = data)
                        .catch(() => this.appList = []);
                    return;
                }
                if (!queryString || queryString.length < 2) {
                    this.appList = [];
                    return;
                }
                appSearch({
                    storeName: 'ios',
                    query: queryString,
                    region: this.countryCode,
                }).then((data) => {
                    this.appList = data;
                }).catch((e) => {
                    this.$log.error('App search failed for query:', queryString, this.countryCode, e);
                    const { errors } = e;
                    if (checkError(errors, 'REQUEST_CANCELED')) {
                        this.$message.error('components.appSearch.searchFailedWithErrorCode' + errors.join('/'));
                    }
                    this.appList = [];
                });
            },
            handleSelect(_, option) {
                this.selectedApp = this.appList.find(app => app.trackId === option?.trackId);
                this.$emit('change');
                this.$emit('select', this.selectedApp);
                this.formItemContext?.onFieldChange();

                if (this.clearAfterSelection) {
                    this.clear();
                }
            },
            clear() {
                this.search = '';
            },
            focus() {
                this.handleSuggestions();
                setTimeout(() => {
                    this.$refs.appSearchInput?.focus();
                }, 300);
            },
            blur() {
                this.$emit('blur');
                this.formItemContext?.onFieldBlur();
                setTimeout(() => {
                    this.$refs.appSearchInput?.blur();
                }, 300);
            },
        },
        mounted() {
            this.$store.dispatch('account/getAppsCompetitors');
        },
    };
</script>
<style>
    .ma-app-search {
        @apply w-full;
        .ma-app-search-autocomplete {
            @apply w-full;
        }
    }
</style>
