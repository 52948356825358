<template>
    <div class="ma-currency-symbol" @click.stop="toggle">
        <span v-if="showSwitch">
            <span>{{ $t('common.currencyView') }}</span>
            :&nbsp;
        </span>

        <a-switch
            :checked="this.currencyView !== 'code'"
            :checked-children="checkedChildren"
            :un-checked-children="unCheckedChildren"
        />
    </div>
</template>

<script>
    import CurrencySymbolMap from 'currency-symbol-map';
    import { currencyViewSwitchEvent } from '@/plugins/mixpanel/eventDefinitions';
    import vuex from 'vuex';

    export default {
        name: 'ma-currency-symbol',
        props: {
            currency: { type: String },
            showSwitch: { type: Boolean, default: false },
        },
        computed: {
            ...vuex.mapState('account', ['currencyView']),
            ...vuex.mapGetters('account', ['activeOrg']),
            orgCurrency() {
                return this.activeOrg && this.activeOrg.currency;
            },
            unCheckedChildren() {
                const currency = this.currency || this.orgCurrency || 'USD';
                return this.$t('common.currencyCode', { code: currency });
            },
            checkedChildren() {
                const currency = this.currency || this.orgCurrency || 'USD';
                return this.$t('common.currencySymbol', { currency: CurrencySymbolMap(currency) });
            },
        },
        methods: {
            ...vuex.mapActions('account', ['toggleCurrencyView']),
            toggle() {
                if (!this.showSwitch) {
                    return;
                }
                this.$mixpanel.track(...currencyViewSwitchEvent.toMixpanel());
                this.toggleCurrencyView();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .ma-currency-symbol {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-size: 13px;
        line-height: 27px;
    }
</style>
