import { makeRequest } from '@/common/MaRequestPooler';

/*
[
  {orgId, account, userId}
]
 */
export function getUserPermissions(userId) {
    return makeRequest(`/sa/user-permission/${userId}`);
}

/*
basePermissionLevel,
userAccessType,
organizationList: [Long]
 */
export function getOrganizationsOfUser() {
    return makeRequest(`/sa/user-permission/organizations`);
}

/*
data = {
  accountId,
  userId,
  userAccessType,
  organizationList: [Long]
}
 */
export function saveUserPermissions(userId, data) {
    return makeRequest(`/sa/user-permission/${userId}`, {
        method: 'PUT',
        data,
    });
}

/*
data = {
  userIdList: [],
  userAccessType,
  orgIdList: []
}
 */
export function saveNewInvitedUserPermissions(data) {
    return makeRequest(`/sa/user-permission`, {
        method: 'POST',
        data,
    });
}

export function deleteUserPermissions(userId) {
    return makeRequest(`/sa/user-permission/${userId}`, {
        method: 'DELETE',
    });
}
