import { cacheRequest } from '@/common/MaRequestPooler';

/**
 * Gets extended aso report for app and country
 *
 * @param trackId
 * @param countryCode
 * @param device
 */
export function getASOReport({ trackId, countryCode, device }) {
    return cacheRequest(`/sa/app/${ trackId }/${ countryCode }/aso-report-extended?device=${ device }`);
}
