import cn from '@/i18n/components/MaProFeatureIndicators/MaProFeatureIndicators.cn.json';
import en from '@/i18n/components/MaProFeatureIndicators/MaProFeatureIndicators.en.json';
import ja from '@/i18n/components/MaProFeatureIndicators/MaProFeatureIndicators.ja.json';
import ko from '@/i18n/components/MaProFeatureIndicators/MaProFeatureIndicators.ko.json';

export default {
    messages: {
        cn,
        en,
        ja,
        ko,
    },
};
