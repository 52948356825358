<template>
    <ma-popover
        v-model:visible="popoverVisible"
        trigger="click"
        placement="bottomLeft"
        overlay-class-name="[&_.antd-popover-inner]:!p-2 min-w-[400px]"
        auto-adjust-overflow
        :z-index="1000"
    >
        <ma-button
            size="small"
            variant="plain"
            type="primary"
            :class="{
                '!border !border-gray-200 !bg-white': true,
                'outline-offset-[3px] outline outline-2 outline-gray-100': popoverVisible,
            }"
        >
            <ma-icon name="smart-notification" size="sm" class="text-gray-500"/>
            {{ t('actionsLabel') }}
        </ma-button>
        <template #overlay>
            <div class="flex items-center justify-between p-2">
                <ma-typography weight="medium" type="body-4" class="text-gray-900">
                    {{ t('actionsLabel') }}
                </ma-typography>
                <ma-link-button color="blue" icon="refresh-2" @click="fetchLogs">
                    {{ t('buttons.refresh') }}
                </ma-link-button>
            </div>
            <ma-spin :spinning="loadingLogs" class="overflow-y-auto max-h-[230px]">
                <ma-typography
                    v-if="!bulkEditLogs.length"
                    type="body-4"
                    weight="medium"
                    class="h-[230px] flex items-center justify-center text-gray-500"
                >
                    {{ t('noActionsYet') }}
                </ma-typography>
                <div
                    v-for="log in bulkEditLogs"
                    v-else
                    :key="log.id"
                    class="p-2 my-1 flex items-center gap-1.5 hover:bg-indigo-25 rounded-lg"
                >
                    <ma-badge shape="dot" :variant="log.state === LOG_STATE.PENDING ? 'blue' : 'dark'" size="small"/>
                    <ma-typography type="body-4" class="text-gray-900 flex-grow">
                        {{ getBulkActionText(log.actionType) }}
                    </ma-typography>
                    <ma-badge :variant="log.state === LOG_STATE.PENDING ? 'blue' : 'green'" type="secondary">
                        {{ log.state === LOG_STATE.PENDING ? t('inProgress') : t('completed') }}
                    </ma-badge>
                </div>
            </ma-spin>
            <router-link :to="{ name: 'ads-manager-logs' }">
                <ma-button
                    variant="stroke"
                    size="small"
                    class="w-full p-2 text-sm"
                    @click="popoverVisible = false"
                >
                    {{ t('seeLogs') }}
                </ma-button>
            </router-link>
        </template>
    </ma-popover>
</template>

<script setup>
    import { MaBadge, MaButton, MaIcon, MaLinkButton, MaPopover, MaSpin, MaTypography } from '@mobileaction/action-kit';
    import { onMounted, ref } from 'vue';
    import messages from '@/components/Topbar/Topbar.i18n';
    import { useI18n } from 'vue-i18n';
    import { LOG_STATE } from '@/common/LogsUtils';
    import { storeToRefs } from 'pinia';
    import { useHighVolumeLogsStore } from '@/pinia/HighVolumeLogs';

    defineOptions({
        i18n: { messages },
    });

    const { t, te } = useI18n();
    const popoverVisible = ref(false);
    const highVolumeLogsStore = useHighVolumeLogsStore();
    const { bulkEditLogs, loadingLogs } = storeToRefs(highVolumeLogsStore);

    const getBulkActionText = (actionType) => {
        const translationKey = `common.bulkActions.${actionType}`;
        const fallback = 'common.bulkActions.NEW_ACTION';
        return te(translationKey)
            ? t(translationKey)
            : t(fallback);
    };

    const fetchLogs = () => {
        highVolumeLogsStore.fetchRecentLogs();
    };
    onMounted(() => {
        highVolumeLogsStore.fetchRecentLogs();
    });
</script>
