{
    "cryptoCurrencies": {
        "BITCOIN": {
            "label": "ビットコイン",
            "code": "BTC"
        },
        "ETHEREUM": {
            "label": "イーサリアム",
            "code": "東北大学"
        },
        "RIPPLE": {
            "label": "XRP",
            "code": "XRP"
        },
        "TETHER": {
            "label": "テザー",
            "code": "USDT"
        },
        "DOGECOIN": {
            "label": "ドージェコイン",
            "code": "DOGE"
        },
        "BONK": {
            "label": "ボンク",
            "code": "ボンク"
        },
        "SOLANA": {
            "label": "ソラナ",
            "code": "ソル"
        },
        "LITECOIN": {
            "label": "ライトコイン",
            "code": "長信銀"
        },
        "PEPE": {
            "label": "ペペ",
            "code": "ペペ"
        },
        "CARDANO": {
            "label": "カルダノ",
            "code": "自動データ収集"
        },
        "WORLDCOIN": {
            "label": "ワールドコイン",
            "code": "世界保健機関"
        },
        "TRON": {
            "label": "トロン",
            "code": "TRX"
        },
        "IOTA": {
            "label": "IOTA",
            "code": "IOTA"
        }
    },
    "headers": {
        "adGroupLabel": {
            "title": "広告グループラベル",
            "tooltip": "任意で設定したラベル"
        },
        "application": {
            "title": "アプリ"
        },
        "apps": {
            "title": "アプリ"
        },
        "suggestedBidAmount": {
            "title": "提案入札額"
        },
        "campaignLabel": {
            "title": "キャンペーンラベル",
            "tooltip": "任意で設定したラベル"
        },
        "displayStatus": {
            "title": "ステータス",
            "tooltip": "ステータス"
        },
        "keywordLabel": {
            "title": "キーワードラベル",
            "tooltip": "任意で設定したラベル"
        },
        "optimization": {
            "title": "最適化タイプ"
        },
        "rank": {
            "title": "順位",
            "tooltip": "選択したアプリの各キーワードでのランキング。"
        },
        "rankVsRank": {
            "title": "ランク対ランク",
            "tooltip": "選択したアプリの各キーワードでのランキング。"
        },
        "smartBidding": {
            "title": "全自動入札調整"
        },
        "targetCPA": {
            "title": "平均\/目標CPI(Apple)"
        },
        "targetCPI": {
            "title": "平均\/目標CPI(MMP)"
        },
        "targetCPG": {
            "title": "平均\/目標イベントCPA"
        },
        "targetROAS": {
            "title": "平均\/目標ROAS"
        },
        "totalCpa": {
            "title": "平均CPA（合計）",
            "tooltip": "総キャンペーン費用を報告期間内の総インストール数で割ったもの。"
        },
        "totalCr": {
            "title": "コンバージョン率（合計）",
            "tooltip": "総設置数を報告期間内の総タップ数で割ったもの。"
        },
        "totalInstalls": {
            "title": "インストール（合計）",
            "tooltip": "報告期間内のタップスルーおよびビュースルーによる新規ダウンロードおよび再ダウンロードの総数。"
        },
        "totalNewDownloads": {
            "title": "新規ダウンロード数（合計）",
            "tooltip": "報告期間内のタップスルーおよびビュースルーによる新規ダウンロードの総数。"
        },
        "totalRedownloads": {
            "title": "再ダウンロード（合計）",
            "tooltip": "報告期間内のタップスルーおよびビュースルーによる再ダウンロードの総数。"
        },
        "viewInstalls": {
            "title": "インストール（ビュースルー）",
            "tooltip": "1日のアトリビューションウィンドウ内に広告を閲覧したがタップしなかったユーザーによる新規ダウンロードと再ダウンロードの総数。"
        },
        "viewNewDownloads": {
            "title": "新規ダウンロード（ビュースルー）",
            "tooltip": "広告を見たがタップせず、アプリをダウンロードしたことのないユーザーからの新規ダウンロード。これらは1日のアトリビューションウィンドウ内でカウントされます。"
        },
        "viewRedownloads": {
            "title": "再ダウンロード（ビュースルー）",
            "tooltip": "アトリビューションウィンドウ1日以内に広告を閲覧したがタップしなかったユーザーからの再ダウンロード。ユーザーがアプリをダウンロードし、削除した後、広告閲覧後に同じデバイスまたは別のデバイスで再度ダウンロードした場合にカウントされます。"
        },
        "GROUP_BY_CAMPAIGN_TYPE": {
            "title": "キャンペーンタイプ",
            "tooltip": "キャンペーングループ内のキャンペーンをどのように構成するかは、パフォーマンス、レポート、そして規模拡大に影響します。キャンペーンを成功に導くには、キーワードのテーマや戦略ごとに分けることをお勧めします。<br><strong> ブランドキャンペーン<\/strong> 貴社のアプリまたは会社名を検索する顧客に焦点を当てる。<br><strong> ジェネリックキャンペーン<\/strong> 貴社のアプリのカテゴリーとアプリの機能を説明する非ブランド用語を検索する顧客に焦点を当てる。<br><strong> コンペティターキャンペーン<\/strong> 同じアプリまたは関連するアプリカテゴリー内で貴社と同様のアプリを検索する顧客に焦点を当てる。<br><strong> ディスカバリーキャンペーン<\/strong> 貴社のアプリに関連する新しい検索用語を見つけることに焦点を当てる。"
        },
        "actionFrequency": {
            "title": "アクション頻度",
            "tooltip": "指定された期間サイクル内で、ルール実行が複数起こらないように設定できます。"
        },
        "actions": {
            "title": "アクション",
            "tooltip": "アクション"
        },
        "addedAsKeyword": {
            "title": "キーワードとして追加"
        },
        "adChannelType": {
            "title": "広告チャネル"
        },
        "adGroupId": {
            "title": "広告グループID",
            "tooltip": "広告グループに設定された固有の識別ID"
        },
        "adGroup": {
            "title": "広告グループ",
            "tooltip": "広告グループ"
        },
        "adGroupName": {
            "title": "広告グループ",
            "tooltip": "広告グループ"
        },
        "adGroupDisplayStatus": {
            "title": "広告グループのステータス",
            "tooltip": "広告グループのステータス"
        },
        "adGroupStatus": {
            "title": "広告グループのステータス",
            "tooltip": "広告グループのステータス"
        },
        "adId": {
            "title": "広告ID",
            "tooltip": "Apple Search Ads 広告の識別子。"
        },
        "adName": {
            "title": "広告名",
            "tooltip": "広告名"
        },
        "adPlacement": {
            "title": "広告表示箇所",
            "tooltip": "広告表示箇所"
        },
        "adStatus": {
            "title": "広告ステータス",
            "tooltip": "広告ステータス"
        },
        "age": {
            "title": "年齢の範囲",
            "tooltip": "年齢の範囲"
        },
        "ageRange": {
            "title": "年齢の範囲",
            "tooltip": "年齢の範囲"
        },
        "allConversionsRate": {
            "title": "全コンバージョン率",
            "tooltip": "全てのインストール数 (計測パートナー基準) に対し、目標イベント設定されたものとそれ以外を含む、カスタムコンバージョンの回数の比率を表示しています"
        },
        "app": {
            "title": "アプリ",
            "tooltip": "アプリ"
        },
        "appId": {
            "title": "アプリID",
            "tooltip": "アプリに設定された固有の識別ID"
        },
        "shareOfVoice": {
            "title": "アップルのシェア・オブ・ボイス",
            "tooltip": "同じ国や地域で、同じ検索語句やキーワードで配信されたインプレッション総数のうち、あなたの広告が獲得したインプレッションの割合です。アップルのSOVは先週のデータです。"
        },
        "appleShareOfVoiceDaily": {
            "title": "アップルのシェア（日次）"
        },
        "appleShareOfVoiceWeekly": {
            "title": "アップルのシェア・オブ・ボイス（週間）"
        },
        "appName": {
            "title": "アプリ",
            "tooltip": "アプリ"
        },
        "appShare": {
            "title": "インプレッション比率",
            "tooltip": "表示しているアプリのインプレッション比率"
        },
        "appliedData": {
            "title": "対象",
            "tooltip": "自動化ルールが実行される対象"
        },
        "arpu": {
            "title": "@:headers.averageRevenuePerUser.title",
            "tooltip": "@:headers.averageRevenuePerUser.tooltip"
        },
        "assets": {
            "title": "資産",
            "tooltip": "アセットは、アプリの主要言語とiPhone 6.5''ディスプレイに応じて表示されます。"
        },
        "attrInstallRate": {
            "title": "@:headers.installRate.title",
            "tooltip": "@:headers.installRate.tooltip"
        },
        "attrInstalls": {
            "title": "インストール(計測パートナー)",
            "tooltip": "計測パートナーで計測されたインストール数。ダウンロード後に初回起動された数値。<br> N\/Aと表示されている場合は計測パートナーとSearchAds.comの接続を確認してください"
        },
        "automationActions": {
            "title": "アクション",
            "tooltip": "自動化ルールの条件が満たされた場合に実行されるアクション"
        },
        "automationConditions": {
            "title": "条件",
            "tooltip": "自動化ルールが実行される条件"
        },
        "automationCreatedAt": {
            "title": "作成日時",
            "tooltip": "自動化ルールが作成された日付"
        },
        "automationName": {
            "title": "ルール名",
            "tooltip": "自動化ルールの名前"
        },
        "automationState": {
            "title": "稼働中",
            "tooltip": "自動化ルールのステータス"
        },
        "automationUpdatedAt": {
            "title": "更新日時",
            "tooltip": "自動化ルールの更新日。"
        },
        "averageCPA": {
            "shortTitle": "平均CPA (Apple)",
            "title": "平均CPA (Apple)",
            "tooltip": "<b>平均顧客獲得単価（CPA）<\/b> <br>：特定期間内の合計支出を獲得インストール数（つまりダウンロード数）で割った数値。"
        },
        "averageCPI": {
            "title": "CPI",
            "tooltip": "<b>平均CPI(Cost per Install):<\/b> <br>CPAがApple基準のCPIであるのに対し、この指標では、計測パートナー経由のインストール数に基づいたCPIを表示しています。<br>N\/Aが表示されている場合は計測パートナーの接続を確認してください"
        },
        "averageCPT": {
            "shortTitle": "平均CPT",
            "title": "平均CPT",
            "tooltip": "CPTの平均値。 <br>出稿額をタップ数で割った数値。"
        },
        "averageRevenuePerUser": {
            "title": "ARPU",
            "tooltip": "<b>ARPU (Average Revenue Per User) :<\/b> <br>ユーザー(計測パートナー基準のインストール数)ごとの収益平均値を表示しています"
        },
        "bidAmount": {
            "title": "入札額"
        },
        "budgetChangeSource": {
            "title": "ソース",
            "tooltip": "この欄はキャンペーンの日次予算変更のソースを示す。<br><ul> <li> <b> ダッシュボード：<\/b> キャンペーンの日次予算は広告マネージャのダッシュボードから更新されます。<\/li><li> <b> 外部：<\/b> キャンペーンの日次予算はApple Nativeから更新されます。<\/li><li> <b> Automation：<\/b><\/li><li> <b> 予算配分：<\/b><\/li><li> <b> クイックオートメーション：<\/b> キャンペーンの日次予算はクイックオートメーションを使用して更新されます。<\/li><\/ul>"
        },
        "defaultBidAmount": {
            "title": "デフォルト入札額"
        },
        "bidStrength": {
            "title": "入札効力",
            "tooltip": "<b>入札の強度が弱い（赤）<\/b> 、他の広告主の入札に対して競争力がないことを意味します。あなたの広告が表示される可能性は相対的に低くなります。<br> <b>入札の強さが中程度（黄色）<\/b> は、入札の競争力を向上させ、広告が表示される可能性を高める機会があることを示します。<br><b> 入札の強さが強い（緑色）<\/b> は、市場における他の広告主の中で、入札が適切な位置にあることを示します。<br><b> 表示なし<\/b> は、入札範囲を提案したり入札の強さを測定したりするためのデータが不十分な場合、キーワードが一時停止されている場合、広告グループまたはキャンペーンが保留されている場合、または現在の入札がすでに弊社の推奨と一致している場合に表示されます。"
        },
        "campaign": {
            "title": "キャンペーン",
            "tooltip": "接続されているキャンペーン名"
        },
        "campaignDailyBudget": {
            "title": "1日の上限",
            "tooltip": "1日に使用できる合計金額。生涯予算が一杯でなければ、毎日リセットされます。"
        },
        "campaignId": {
            "title": "キャンペーンID",
            "tooltip": "キャンペーンに付与された固有のID"
        },
        "campaignStatus": {
            "title": "ステータス",
            "tooltip": "キャンペーン状況"
        },
        "campaignTotalBudget": {
            "title": "生涯予算",
            "tooltip": "キャンペーンに割り当てられた生涯予算。"
        },
        "categoryName": {
            "title": "カテゴリ名",
            "tooltip": "アプリの所属するカテゴリ"
        },
        "categoryRank": {
            "title": "カテゴリ内ランク",
            "tooltip": "アプリのランキングをカテゴリ別に表示します"
        },
        "categoryRanking": {
            "title": "カテゴリ内ランク",
            "tooltip": "カテゴリ内ランク"
        },
        "chance": {
            "title": "ASO難易度",
            "tooltip": "ASO難易度はオーガニック検索結果でトップ10に入れる可能性を表示しています。<br>0〜100の間で、高いほどトップ10に入りやすいことを示します。"
        },
        "competitors": {
            "title": "競合アプリ",
            "tooltip": "競合アプリを追加して推奨キーワードやインサイトを入手しましょう"
        },
        "conditions": {
            "title": "条件",
            "tooltip": "自動化ルールが実行される条件"
        },
        "conversionRate": {
            "title": "CR (CR)",
            "tooltip": "<b>コンバージョン率 (CR)<\/b> <br>特定期間中に獲得した合計インストール数を、同じ期間中の合計タップ数で割った数値。"
        },
        "costPerConversion": {
            "title": "全コンバージョンあたり支出",
            "tooltip": "目標イベント設定されたものとそれ以外を含む、カスタムコンバージョンの回数ごとの支出(コスト)を表示しています"
        },
        "costPerGoal": {
            "title": "Cost Per 目標イベント",
            "tooltip": "目標イベントとして設定されたアプリ内イベント1回あたりの支出(コスト)を表示しています。"
        },
        "country": {
            "title": "国"
        },
        "cpaGoal": {
            "title": "目標CPA",
            "tooltip": "<b>CPA目標<\/b> <br>Cost Per Acquisitionの目標値は<b>任意設定<\/b>となっています。現実感のない過少な値を入力した場合、インプレッション数が減り機会損失が発生することが想定されます。"
        },
        "cpi": {
            "title": "@:headers.averageCPI.title",
            "tooltip": "@:headers.averageCPI.tooltip"
        },
        "cr": {
            "title": "CR (CR)",
            "tooltip": "<b>コンバージョン率 (CR)<\/b> <br>特定期間中に獲得した合計インストール数を、同じ期間中の合計タップ数で割った数値。"
        },
        "creativeSetName": {
            "title": "Creative Set",
            "tooltip": "Creative Set"
        },
        "creativeSetStatus": {
            "title": "Creative Set ステータス",
            "tooltip": "Creative Set ステータス"
        },
        "creativeType": {
            "title": "クリエイティブ・タイプ",
            "tooltip": "クリエイティブ・タイプ"
        },
        "csvReport": {
            "title": "CSVレポート",
            "tooltip": "適用されたルールのデータをCSV出力することができます。<br>非稼働のルールに関してはレポートが作成されません。"
        },
        "currency": {
            "title": "通貨"
        },
        "currentBid": {
            "title": "現在の入札額",
            "tooltip": "現在の入札額"
        },
        "currentDailyBudget": {
            "title": "現在の日予算",
            "tooltip": "現在の日予算"
        },
        "currentLifetimeBudget": {
            "title": "現在の総予算",
            "tooltip": "現在の総予算"
        },
        "customColumn": {
            "new": "新規カスタムカラム",
            "title": "カスタムカラム"
        },
        "customerType": {
            "title": "お客様のタイプ",
            "tooltip": "顧客タイプ"
        },
        "dailyBudget": {
            "title": "1日の上限",
            "tooltip": "1日に出稿できる上限額。上限に満たない場合も毎日リセットされます。"
        },
        "dayPart": {
            "title": "時間帯区分",
            "tooltip": "時間帯区分"
        },
        "defaultAmount": {
            "title": "@:headers.defaultMaxCPTBid.title",
            "tooltip": "@:headers.defaultMaxCPTBid.tooltip"
        },
        "defaultCPTAmount": {
            "title": "デフォルトのCPT入札額",
            "tooltip": "<b>最大タップ単価（最大CPT）入札額<\/b> <br>広告に対する1回のタップにお支払い可能な最大額。<br>デフォルトの最大CPT入札額は、最大CPT入札額をキーワード単位で指定しない限り、すべてのキーワードに適用されます。"
        },
        "defaultMaxCPMBid": {
            "title": "デフォルトCPM入札額",
            "tooltip": "<b>デフォルトCPM入札額<\/b> <br>1,000インプレッションあたりの入札単価です"
        },
        "defaultMaxCPTBid": {
            "title": "デフォルトのCPT入札額",
            "tooltip": "<b>最大タップ単価（最大CPT）入札額<\/b> <br>広告に対する1回のタップにお支払い可能な最大額。<br>デフォルトの最大CPT入札額は、最大CPT入札額をキーワード単位で指定しない限り、すべてのキーワードに適用されます。"
        },
        "deviceClass": {
            "title": "デバイス",
            "tooltip": "デバイス"
        },
        "difficultyRank": {
            "title": "難易度",
            "tooltip": "難易度とは、アプリがキーワードでオーガニックに上位表示されることがどれだけ難しいかを測定する指標です。詳しくは<a href=\"https:\/\/help.mobileaction.co\/en\/articles\/4201403-what-is-difficulty-score\" target=\"_blank\" style=\"color: #1c8de0\">難易度スコア<\/a> をご覧ください。"
        },
        "dimensions": {
            "title": "ディメンション",
            "tooltip": "ディメンションとは、カスタムレポートに含めることができるApple Search Adsキャンペーンの要素のことです。たとえば、キャンペーンIDやCPT入札額などです。ディメンションは、カスタムレポートの行として表示されます。"
        },
        "adDisplayStatus": {
            "title": "広告表示状況",
            "tooltip": "これは、ネイティブ・プラットフォームで表示される読み取り専用のステータスである。"
        },
        "downloadPercentage": {
            "title": "オーガニックダウンロード比率",
            "tooltip": "オーガニックダウンロードを構成している上位キーワード"
        },
        "endDate": {
            "title": "終了日",
            "tooltip": "終了日"
        },
        "engagements": {
            "title": "エンゲージメント",
            "tooltip": "Engagementゴール・カテゴリーに付随するイベントの数。"
        },
        "frequency": {
            "title": "ルール稼働周期",
            "tooltip": "自動化ルールの条件判定に用いる、過去実績を参照する期間範囲"
        },
        "gameEvents": {
            "title": "ゲームイベント",
            "tooltip": "ゲームイベントゴールカテゴリーに付属するイベント数。"
        },
        "gender": {
            "title": "性別",
            "tooltip": "性別"
        },
        "goalCount1": {
            "title": "目標イベント数1",
            "tooltip": "インストール後1日間で発生した目標イベントの件数"
        },
        "goalCount14": {
            "title": "目標イベント数14",
            "tooltip": "インストール後14日間で発生した目標イベントの件数"
        },
        "goalCount3": {
            "title": "目標イベント数3",
            "tooltip": "インストール後3日間で発生した目標イベントの件数"
        },
        "goalCount30": {
            "title": "目標イベント数30",
            "tooltip": "インストール後30日間で発生した目標イベントの件数"
        },
        "goalCount60": {
            "title": "ゴール数 60",
            "tooltip": "あなたのアプリで60日間に発生したゴールの合計数。"
        },
        "goalCount7": {
            "title": "目標イベント数7",
            "tooltip": "インストール後7日間で発生した目標イベントの件数"
        },
        "goalRevenue": {
            "title": "@:headers.goalsRevenue.title",
            "tooltip": "@:headers.goalsRevenue.tooltip"
        },
        "goalRevenue1": {
            "title": "収益 1",
            "tooltip": "インストール後1日間の収益をコホート集計した数値です。"
        },
        "goalRevenue14": {
            "title": "収益 14",
            "tooltip": "インストール後14日間の収益をコホート集計した数値です。"
        },
        "goalRevenue3": {
            "title": "収益 3",
            "tooltip": "インストール後3日間の収益をコホート集計した数値です。"
        },
        "goalRevenue30": {
            "title": "収益 30",
            "tooltip": "インストール後30日間の収益をコホート集計した数値です。"
        },
        "goalRevenue60": {
            "title": "目標 収益 60",
            "tooltip": "広告キャンペーンが60日間にアプリにもたらした収益の合計。"
        },
        "goalRevenue7": {
            "title": "収益 7",
            "tooltip": "インストール後7日間の収益をコホート集計した数値です。"
        },
        "goalRoas": {
            "title": "@:headers.goalsROAS.title",
            "tooltip": "@:headers.goalsROAS.tooltip"
        },
        "goalRoas1": {
            "title": "目標イベントROAS 1",
            "tooltip": "インストール後1日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 1\/支出 * 100<\/b>."
        },
        "goalRoas14": {
            "title": "目標イベントROAS 14",
            "tooltip": "インストール後14日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 14\/支出 * 100<\/b>."
        },
        "goalRoas3": {
            "title": "目標イベントROAS 3",
            "tooltip": "インストール後3日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 3\/支出 * 100<\/b>."
        },
        "goalRoas30": {
            "title": "目標イベントROAS 30",
            "tooltip": "インストール後30日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 30\/支出 * 100<\/b>."
        },
        "goalRoas60": {
            "title": "目標 ROAS 60",
            "tooltip": "60日間の総広告費から生み出された収益の割合。<br>この指標は、カスタムコンバージョンの下で特定された各目標からの収益を考慮します。<br><b> 目標 収益 60\/支出 * 100%<\/b>."
        },
        "goalRoas7": {
            "title": "目標イベントROAS 7",
            "tooltip": "インストール後7日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 7\/支出 * 100<\/b>."
        },
        "goalsROAS": {
            "title": "目標イベントROAS",
            "tooltip": "目標イベントとして設定されたカスタムコンバージョンのROAS(%)を表示しています。この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益\/支出 * 100<\/b>."
        },
        "goalsRate": {
            "title": "目標イベント率",
            "tooltip": "計測パートナー準拠のインストール数に対し、目標イベントとして設定されたカスタムコンバージョンの比率(%)を表示しています。<br><b>目標イベント 率 = 目標イベント \/ Installs * 100<\/b>"
        },
        "goalsRevenue": {
            "title": "目標イベント収益",
            "tooltip": "目標イベントとして設定されたカスタムコンバージョンの収益合計額を表示しています。この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。"
        },
        "goalsRoasDays": {
            "title": "目標イベントROAS 1\/3\/7\/14\/30",
            "tooltip": "インストール後1\/3\/7\/14\/30日間の収益をコホート集計したROAS(%)です。 <br>この指標ではカスタムコンバージョンから「目標イベント」として設定されたアプリ内イベントに紐づいた数値が集計されています。<br><b>目標イベント 収益 日数\/支出 * 100<\/b>."
        },
        "goalValue": {
            "title": "目標イベント値",
            "tooltip": "収益を集計するのに用いられる数値です。ここで設定した値が集計に反映されます。"
        },

        "impressions": {
            "title": "インプレッション数",
            "tooltip": "レポート期間中、App Storeの検索結果に広告が表示された回数。"
        },
        "installRate": {
            "title": "計測パートナー率",
            "tooltip": "Apple基準の全体のインストールに対し、計測パートナー経由のインストールの比率を表示しています。<br> <b>インストール(計測パートナー) \/ インストール(新規ダウンロード + 再ダウンロード) * 100<\/b>"
        },
        "installs": {
            "title": "インストール数",
            "tooltip": "レポート期間中に広告によって発生した新規ダウンロードおよび再ダウンロードからの合計コンバージョン数。Apple Search Adsのインストール数は、30日のタップ期間に基づいています。"
        },
        "keyword": {
            "title": "キーワード",
            "tooltip": "このアプリが入札しているキーワード"
        },
        "negativeKeyword": {
            "title": "除外キーワード",
            "tooltip": "除外キーワード"
        },
        "keywordDisplayStatus": {
            "title": "キーワード稼働状況",
            "tooltip": "キーワード稼働状況"
        },
        "keywordMatchType": {
            "title": "@:headers.matchType.title",
        },
        "keywordStatus": {
            "title": "キーワード稼働状況",
        },
        "label": {
            "title": "ラベル",
            "tooltip": "任意で設定したラベル"
        },
        "localSpend": {
            "title": "支出"
        },
        "locality": {
            "title": "場所",
            "tooltip": "場所"
        },
        "lowVolume": {
            "title": "少量",
            "tooltip": "少量と表示されている場合、インプレッション数がアップルの表示閾値を下回っています。検索語句は最低でも10インプレッションを上回らなければならず、年代、性別、地域レポートを表示するには最低でも100インプレッションを上回らなければなりません。"
        },
        "lowVolumeKeyword": {
            "title": "Low Volume Term",
            "tooltip": "Low Volume Term"
        },
        "ltv": {
            "title": "LTV",
            "tooltip": "キャンペーンのライフタイムバリューを表示します。"
        },
        "matchSource": {
            "title": "マッチ元",
            "tooltip": "マッチ元のカラムでは、インプレッションが発生したソースが検索マッチか個別に入札されたキーワードかを確認できます。"
        },
        "matchType": {
            "title": "一致タイプ"
        },
        "message": {
            "title": "メッセージ"
        },
        "newDownloads": {
            "title": "新規ダウンロード",
            "tooltip": "これまでにアプリケーションをインストールしたことがない新規ユーザーによるアプリケーションのダウンロード数。"
        },
        "notification": {
            "title": "通知"
        },
        "numberOfAds": {
            "title": "広告数",
            "tooltip": "この商品ページが使用されている広告の数。"
        },
        "numberOfApps": {
            "title": "アプリ数",
            "tooltip": "各キーワードでの検索ランキング全アプリ数"
        },
        "numberOfGoal": {
            "title": "@:headers.numberOfGoals.title",
            "tooltip": "@:headers.numberOfGoals.tooltip"
        },
        "numberOfGoals": {
            "title": "目標イベント設定",
            "tooltip": "カスタムコンバージョンで設定したアプリ内イベントの中から、特定のものを「目標イベント」として抜き出して設定されたものを表示しています。"
        },
        "numberOfInstall": {
            "title": "@:headers.attrInstalls.title",
            "tooltip": "@:headers.attrInstalls.tooltip"
        },
        "orgName": {
            "title": "キャンペーングループ",
            "tooltip": "キャンペーン・グループ名"
        },
        "organicRank": {
            "title": "オーガニック順位",
            "tooltip": "選択されたアプリのオーガニックランキング"
        },
        "otherEvents": {
            "title": "他広告主",
            "tooltip": "その他の目標イベントカテゴリで発生した件数"
        },
        "others": {
            "title": "他広告主",
            "tooltip": "このキーワードでインプレッションが計測された他のアプリ"
        },
        "overallRanking": {
            "title": "ランキング",
            "tooltip": "ランキング"
        },
        "policy": {
            "title": "集計方式",
            "tooltip": "目標イベントの集計方法を選んでください。設定された値が収益の集計値に反映されます。"
        },
        "popularityRank": {
            "title": "検索ボリューム",
            "tooltip": "Apple Search Adsによって提供されている国別の検索流行度の指標です"
        },
        "productPageName": {
            "title": "製品ページ名",
            "tooltip": "製品ページ名"
        },
        "productPageId": {
            "title": "製品ページID",
            "tooltip": "製品ページID"
        },
        "purchases": {
            "title": "購入",
            "tooltip": "購入の目標イベントカテゴリで発生した件数"
        },
        "keywordId": {
            "title": "キーワードID",
            "tooltip": "キーワードに付与された固有のID"
        },
        "reAttrType": {
            "title": "リアトリビューション設定",
            "tooltip": "自動化ルール設定時のリアトリビューション設定を表示しています"
        },
        "recommendedAction": {
            "title": "推奨される措置",
            "tooltip": "キーワードのコンバージョン率が著しく低いため、推奨される"
        },
        "recommendedActions": {
            "title": "アクション",
            "tooltip": "私たちの推奨を個別に適用することも、却下することもできます。市場価格、消費者の嗜好、その他の要因は動的なものであるため、推奨を利用したからといってパフォーマンスの向上が保証されるわけではありません。"
        },
        "recommendedBid": {
            "title": "推奨の入札額",
            "tooltip": "競合他社に対抗するには、キーワードのインプレッションが不十分であるため。"
        },
        "recommendedDailyBudget": {
            "title": "1日の推奨予算",
            "tooltip": "このキャンペーンはコンバージョン率が非常に高く、1日の予算を数回達成することができる。"
        },
        "recommendedLifetimeBudget": {
            "title": "推奨生涯予算",
            "tooltip": "推奨生涯予算"
        },
        "redownloads": {
            "title": "再ダウンロード",
            "tooltip": "再ダウンロードとは、ユーザーがアプリケーションをダウンロードし、いったん削除してから、App Storeで広告をタップして、同じアプリケーションを再度ダウンロードするか、同じアプリケーションを別のデバイスにダウンロードすること。"
        },
        "reportDate": {
            "title": "レポート日時",
            "tooltip": "Apple Search Adsのレポーティング日時"
        },
        "returnOnAdvertisingSpend": {
            "title": "ROAS",
            "tooltip": "<b>ROAS　(Return on Advertising Spend)　:<\/b> <br>支出に対する収益比率を%表示しています。 <br>計算式: <br><b>ROAS=収益\/支出 * 100<\/b>."
        },
        "revenue": {
            "title": "収益",
            "tooltip": "キャンペーン中の収益を表示しています"
        },
        "revenuePerConversion": {
            "title": "全コンバージョンあたり収益",
            "tooltip": "全てのインストール数 (計測パートナー基準) に対し、目標イベント設定されたものとそれ以外を含む、カスタムコンバージョン1回あたりの収益を表示しています"
        },
        "revenuePerGoal": {
            "title": "目標イベントあたり収益",
            "tooltip": "目標イベントとして設定されたカスタムコンバージョン1回あたりの収益平均値を表示しています。"
        },
        "roas1": {
            "title": "ROAS 1",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後1日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 1\/支出 * 100<\/b>."
        },
        "roas14": {
            "title": "ROAS 14",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後14日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 14\/支出 * 100<\/b>."
        },
        "roas3": {
            "title": "ROAS 3",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後3日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 3\/支出 * 100<\/b>."
        },
        "roas30": {
            "title": "ROAS 30",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後30日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 30\/支出 * 100<\/b>."
        },
        "roas7": {
            "title": "ROAS 7",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後7日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 7\/支出 * 100<\/b>."
        },
        "roasDays": {
            "title": "ROAS 1\/3\/7\/14\/30日",
            "tooltip": "<b>ROAS(Return on Advertising Spend):<\/b> <br>インストール後1\/3\/7\/14\/30日間の収益をコホート集計したROAS(%)です。 <br>計算式: <br><b>ROAS=収益 1\/支出 * 100<\/b>."
        },
        "ruleActions": {
            "title": "アクション",
            "tooltip": "自動化ルールの条件が満たされた場合に実行されるアクション"
        },
        "ruleChanges": {
            "title": "変更された件数",
            "tooltip": "変更された件数を表示しています。\n\n<br><br>\n<strong>変更なし<\/strong>-ルール発動したが値の変更なし<br><br>\n<strong>N件 : 成功<\/strong>-ルールによって変更された件数<br><br>\n<strong>N件 : 警告付きで実行<\/strong>-ルールによって警告と共に変更された件数 (予算上限等)<br><br>\n<strong>N件 : エラー<\/strong>-ルール判定にマッチしたものの変更されなかった件数 (エラーではなく、日予算や全体予算等)"
        },
        "ruleDateChecked": {
            "title": "ルール判定日時",
            "tooltip": "ルール設定に基づいてアクションされた日時です"
        },
        "ruleDescription": {
            "title": "ルール内容",
            "tooltip": "アクション、条件、等、実行されたルール設定内容のサマリです。"
        },
        "ruleLogState": {
            "title": "ステータス",
            "tooltip": "ステータスはエラー有無を含むアクション状況を表示しています。<br>\n<strong>成功<\/strong>-エラー無しでアクション実行完了<br>\n<strong>Apple: 注意<\/strong>-Apple側の制限内でアクション実行完了<br>\n<strong>ユーザー: 注意<\/strong>-ユーザー側制限内でアクション実行完了<br>\n<strong>Apple: 警告<\/strong>-Apple制限によりアクション未実行<br>\n<strong>ユーザー: 警告<\/strong>-ユーザー側制限によりアクション未実行<br>\n<strong>エラー<\/strong>-エラーによりアクション未実行"
        },
        "ruleName": {
            "title": "ルール名",
            "tooltip": "自動化ルールを複数管理する前提での分かりやすい命名を推奨します"
        },
        "ruleRunState": {
            "title": "ステータス",
            "tooltip": "<br><br> <strong> Ran Successfully<\/strong>-ルールがエラーなしで実行されました。<br><br> <strong> Ran with warnings<\/strong>-ルールが予算制限などの事前定義された制約で実行されました。<br><br> <strong> Ran with errors<\/strong>-ルールが日予算超過などの非技術的エラーで実行されました。<br><br> <strong> Technical errors<\/strong>-ルールが技術的エラーで実行されました。"
        },
        "ruleType": {
            "title": "ルールの種類",
            "tooltip": "クライアントパートナーの自動化」のルールタイプは、クライアントパートナーがアカウントになりすましている場合にのみ表示されます。"
        },
        "rulesFor": {
            "title": "ルール稼働範囲",
            "tooltip": "自動化ルールは個別のキャンペーン、広告グループ、キーワードに対して設定可能です"
        },
        "searchAdsVisibilityScore": {
            "title": "検索スコア",
            "tooltip": "App Store内でのアプリの検索表示を0〜100で相対化しています。"
        },
        "searchMatch": {
            "title": "検索マッチ",
            "tooltip": "<b>検索マッチ<\/b> App Storeの掲載ページのメタデータ、同じジャンルの類似アプリケーションの情報、その他の検索データなど、複数のリソースを活用して、App Storeで行われた関連性の高い検索と広告を一致させます。"
        },
        "searchScore": {
            "title": "検索ボリューム",
            "tooltip": "キーワードの人気指標。このスコアが高いほど被検索ボリュームが高いことを示します。"
        },
        "searchTerm": {
            "title": "検索語句",
            "tooltip": "検索語句は、実際にユーザーが検索時に使用したキーワード、もしくはフレーズです。"
        },
        "searchTermKeyword": {
            "title": "キーワード",
            "tooltip": "検索語句テキスト"
        },
        "searchTermKeywordId": {
            "title": "キーワードID",
            "tooltip": "キーワードに付与された固有のID"
        },
        "searchTermSource": {
            "title": "検索語句：参照元",
            "tooltip": "検索語句：参照元"
        },
        "searchTermText": {
            "title": "検索語句テキスト",
            "tooltip": "検索語句テキスト"
        },
        "source": {
            "title": "ソース",
            "tooltip": "この列はキーワード入札の変更元を示す。<br><ul> <li> <b> ダッシュボード：<\/b><\/li><li> <b> External：<\/b><\/li><li> <b> オートメーション：<\/b><\/li><li> <b> スマート入札：<\/b> キーワード入札はスマート入札を使用して更新されます。<\/li><\/ul>"
        },
        "spend": {
            "title": "支出",
            "tooltip": "キャンペーンで使用された期間中の総額"
        },
        "startDate": {
            "title": "開始日",
            "tooltip": "開始日"
        },
        "stateReason": {
            "title": "一時停止の理由",
            "tooltip": "オートメーションが一時停止された理由"
        },
        "status": {
            "title": "ステータス",
            "tooltip": "ステータス"
        },
        "storefront": {
            "title": "国または地域",
            "tooltip": "キャンペーンが有効になっている国または地域"
        },
        "strategies": {
            "title": "戦略",
            "tooltip": "この欄には、現在の日付に割り当てられたオートメーションが表示されます。"
        },
        "strategiesWithSb": {
            "title": "戦略",
            "tooltip": "この欄には、現在の日付に割り当てられたスマート入札と自動化が表示されます。"
        },
        "subscriptions": {
            "title": "サブスクリプション",
            "tooltip": "Subscriptionゴールカテゴリーに付属するイベントの数。"
        },
        "taps": {
            "title": "タップ数",
            "tooltip": "広告がタップ(クリック)された回数"
        },
        "todaysRank": {
            "title": "順位（今日）",
            "tooltip": "選択されたアプリのオーガニックランキング"
        },
        "totalBudget": {
            "title": "生涯予算",
            "tooltip": "キャンペーンに割り当てられた生涯予算。"
        },
        "totalPaidKeywords": {
            "title": "出稿されているキーワード数",
            "tooltip": "アプリに対して広告稼働中のキーワード数"
        },
        "trials": {
            "title": "トライアル",
            "tooltip": "トライアルゴールのカテゴリーに付属するイベントの数。"
        },
        "ttr": {
            "title": "TTR",
            "tooltip": "<b>タップスルーレート(TTR)<\/b> <br>インプレッション数でタップ数を割ったもの"
        },
        "yesterdaysRank": {
            "title": "順位（前日）",
            "tooltip": "選択されたアプリのオーガニックランキング"
        },
        "campaignStartTime": {
            "title": "キャンペーン開始時間",
            "tooltip": "キャンペーン開始時間"
        }
    },
    "conditions": {
        "SPEND": "@:headers.spend.title",
        "CPA": "@:headers.averageCPA.title",
        "TOTAL_CPA": "@:headers.totalCpa.title",
        "CPT": "@:headers.averageCPT.title",
        "TTR": "@:headers.ttr.title",
        "CR": "@:headers.conversionRate.title",
        "TOTAL_CR": "@:headers.totalCr.title",
        "INSTALLS": "@:headers.installs.title",
        "VIEW_INSTALLS": "@:headers.viewInstalls.title",
        "TOTAL_INSTALLS": "@:headers.totalInstalls.title",
        "TAPS": "@:headers.taps.title",
        "IMPRESSIONS": "@:headers.impressions.title",
        "TOTAL_BUDGET": "@:headers.totalBudget.title",
        "DAILY_BUDGET": "@:headers.dailyBudget.title",
        "NEW_DOWNLOADS": "@:headers.newDownloads.title",
        "VIEW_NEW_DOWNLOADS": "@:headers.viewNewDownloads.title",
        "TOTAL_NEW_DOWNLOADS": "@:headers.totalNewDownloads.title",
        "REDOWNLOADS": "@:headers.redownloads.title",
        "VIEW_REDOWNLOADS": "@:headers.viewRedownloads.title",
        "TOTAL_REDOWNLOADS": "@:headers.totalRedownloads.title",
        "ATTR_INSTALLS": "@:headers.attrInstalls.title",
        "GOALS": "@:headers.numberOfGoals.title",
        "GOALS_REVENUE": "@:headers.goalsRevenue.title",
        "INSTALL_RATE": "@:headers.installRate.title",
        "CPI": "@:headers.averageCPI.title",
        "COST_PER_GOAL": "@:headers.costPerGoal.title",
        "GOALS_RATE": "@:headers.goalsRate.title",
        "REVENUE_PER_GOAL": "@:headers.revenuePerGoal.title",
        "GOALS_ROAS": "@:headers.goalsROAS.title",
        "ARPU": "@:headers.averageRevenuePerUser.title",
        "GOALS_REVENUE_1": "@:headers.goalRevenue1.title",
        "GOALS_REVENUE_3": "@:headers.goalRevenue3.title",
        "GOALS_REVENUE_7": "@:headers.goalRevenue7.title",
        "GOALS_REVENUE_14": "@:headers.goalRevenue14.title",
        "GOALS_REVENUE_30": "@:headers.goalRevenue30.title",
        "GOALS_REVENUE_60": "@:headers.goalRevenue60.title",
        "GOALS_COUNT_1": "@:headers.goalCount1.title",
        "GOALS_COUNT_3": "@:headers.goalCount3.title",
        "GOALS_COUNT_7": "@:headers.goalCount7.title",
        "GOALS_COUNT_14": "@:headers.goalCount14.title",
        "GOALS_COUNT_30": "@:headers.goalCount30.title",
        "GOALS_COUNT_60": "@:headers.goalCount60.title",
        "GOALS_ROAS_1": "@:headers.goalRoas1.title",
        "GOALS_ROAS_3": "@:headers.goalRoas3.title",
        "GOALS_ROAS_7": "@:headers.goalRoas7.title",
        "GOALS_ROAS_14": "@:headers.goalRoas14.title",
        "GOALS_ROAS_30": "@:headers.goalRoas30.title",
        "GOALS_ROAS_60": "@:headers.goalRoas60.title",
        "CUSTOM_COLUMN": "@:headers.CUSTOM_COLUMN.title",
        "LABEL": "@:headers.label.title",
        "APPLE_SHARE_OF_VOICE_DAILY": "@:headers.appleShareOfVoiceDaily.title",
        "APPLE_SHARE_OF_VOICE_WEEKLY": "@:headers.appleShareOfVoiceWeekly.title",
        "CPA_GOAL": "@:headers.cpaGoal.title",
        "LTV": "@:headers.ltv.title",
        "BID_AMOUNT": "@:headers.bidAmount.title",
        "DEFAULT_BID_AMOUNT": "@:headers.defaultBidAmount.title",
        "ORGANIC_RANK": "@:headers.organicRank.title",
        "STOREFRONT": "@:headers.storefront.title",
        "CAMPAIGN_NAME": "@:headers.campaign.title",
        "CAMPAIGN_GROUP_NAME": "@:headers.orgName.title",
        "AD_GROUP_NAME": "@:headers.adGroupName.title",
        "SEARCH_MATCH": "@:headers.searchMatch.title",
        "DEVICE": "@:headers.deviceClass.title",
        "GENDER": "@:headers.gender.title",
        "CUSTOMER_TYPE": "@:headers.customerType.title",
        "LOCATION": "@:headers.locality.title",
        "AGE_RANGE": "@:headers.age.title",
        "KEYWORD": "@:headers.keyword.title",
        "MATCH_TYPE": "@:headers.matchType.title",
        "SUGGESTED_BID_AMOUNT": "@:headers.suggestedBidAmount.title",
        "BID_STRENGTH": "@:headers.bidStrength.title",
        "ADDED_AS_KEYWORD": "@:headers.addedAsKeyword.title",
        "SEARCH_TERM_SOURCE": "@:headers.searchTermSource.title",
        "SEARCH_TERM_TEXT": "@:headers.searchTermText.title",
        "LOW_VOLUME_TERM": "@:headers.lowVolumeKeyword.title",
        "POPULARITY": "@:headers.popularityRank.title"
    }
}
