import { loadLinkAsync, loadScriptAsync } from '@/common/MaUtils.mjs';

(async() => {
    // attach flag-icon library
    await loadLinkAsync('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/css/flag-icon.min.css', 'head', {
        defer: true,
    });

    // attach google tag manager script
    await loadGoogleTagManager();

    // load beamer script and set initial config
    await loadBeamer();
})();

async function loadGoogleTagManager() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    await loadScriptAsync('https://www.googletagmanager.com/gtm.js?id=GTM-5CHKNV8', 'head', {
        defer: true,
        async: true,
    });
}

async function loadBeamer() {
    await loadScriptAsync('https://app.getbeamer.com/js/beamer-embed.js', 'head', {
        defer: true,
        async: true,
    });
    window.beamer_config = {
        selector: '#ma-beamer-container',
        product_id: 'nbVTyums51472',
        top: 1,
        right: 1,
    };
}
