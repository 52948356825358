export let _Vue = null;

export function install(Vue) {
    if (install.installed && _Vue === Vue) {
        return;
    }
    install.installed = true;
    _Vue = Vue;

    Vue.config.globalProperties.$error = {
        failedRequest(...args) {
            Vue.config.globalProperties.$log.error('Request failed:', ...args);
        },
        suppressDuplicateNavigation(...args) {
            if (!args.length || ![args[0]._name, args[0].name].includes('NavigationDuplicated')) {
                console.error('Unknown navigation error:', ...args);
                throw args[0];
            }
        },
        suppressCancelledNavigation(...args) {
            if (!args.length || args[0].type !== 8) {
                console.error('Unknown navigation error:', ...args);
                throw args[0];
            }
        },
        suppressAll(err) {
            const suppressErrorKeys = Object.keys(this || {})
                .filter(errorKey => errorKey.includes('suppress'));

            suppressErrorKeys.forEach(errorKey => this[errorKey](err));
        },
    };
}

export default {
    install,
};
