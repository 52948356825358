{
    "ALL_USERS": "All users",
    "FEMALE": "여성",
    "IPAD": "iPad Only",
    "IPHONE": "iPhone Only",
    "IPHONE_IPAD": "iPad and iPhone",
    "MALE": "남성",
    "MALE_FEMALE": "전체",
    "NEW_USERS": "New Users",
    "ONLY_RE_ATTR_TYPE": "재설치만",
    "PAUSED_BY_USER": "사용자에 의한 일시 정지",
    "WITHOUT_RE_ATTR_TYPE": "신규만",
    "WITH_RE_ATTR_TYPE": "신규+재설치",
    "actions": "액션",
    "active": "활성화",
    "adGroupStatus": "Ad Group 상태",
    "allCampaigns": "모든 캠페인",
    "appIcon": "앱 아이콘",
    "automationRule": "Automation 규칙",
    "buttons": {
        "automationRule": "Automation 규칙",
        "cancel": "취소",
        "createNewCampaign": "신규 캠페인 생성",
        "update": "업데이트"
    },
    "callLatestData": "최근 데이터 업데이트",
    "campaignStatus": "캠페인 상태",
    "copyAllKeywords": "키워드 복사하기",
    "copyAllKeywordsToOther": "해당 Ad group의 키워드를 다른 Ad group으로 복사하기",
    "copyAllNegativeKeywords": "모든 Negative Keyword를 복사하기",
    "copyAllNegativeKeywordsToOther": "다른 Ad group에 복사한 모든 Negative Keyword를 보내기",
    "copyKeywordsToOther": "선택된 키워드를 다른 Ad Group으로 복사",
    "copyNegativeKeywordsToOther": "선택된 Negative Keyword를 다른 Ad group으로 복사하기",
    "copySelectedCount": "데이터 없음 | 선택한 검색어 {n} | 선택한 검색어 {n}을(를) 복사합니다",
    "copySelectedKeywords": "선택된 키워드 복사하기",
    "copySelectedNegativeKeywords": "선택된 Negative Keyword를 복사하기",
    "copySelectedSearchTerms": "선택된 Search term을 다른 Ad Group에 키워드로 복사하기",
    "createAutomationRule": "Automation 규칙 만들기",
    "creativeSetStatus": "Creative Set 상태",
    "creativeTypes": {
        "CREATIVE_SET": "Creative Set"
    },
    "dailyBudget": "일일예산",
    "downloadDailyData": "일일 데이터 다운로드",
    "downloadData": "데이터 다운로드",
    "downloadKeywords": "키워드 다운로드",
    "duplicateAdGroup": "Ad group 복제하기",
    "duplicateCampaign": "캠페인 복제하기",
    "editSelectedAdGroup": "선택한 ad group을 수정하기",
    "editSelectedCampaign": "선택된 캠페인 수정하기",
    "editSelectedCreativeSet": "선택된 Creative Set을 수정하기",
    "goalInfo": {
        "trackId": "App"
    },
    "goalSelection": "목표(Goal) 설정",
    "keywordMatchTypeUpdated": "키워드의 Match type이 업데이트 되었어요.",
    "lastUpdateTime": "최종 업데이트 시각",
    "messages": {
        "copyMain": {
            "keyword": "키워드",
            "negative": "Negative Keyword",
            "term": "Search Term"
        },
        "copySuccess": {
            "keyword": "키워드가 새로운 ad group으로 복사되었습니다.",
            "negative": "Negative Keyword가 새로운 ad group으로 복사되었습니다.",
            "term": "Search term이 새로운 ad group으로 복사되었습니다."
        },
        "duplicatedKeyword": {
            "keyword": "이미 캠페인과 ad group에 추가된 키워드가 해당리스트에 존재합니다.",
            "negative": "이미 캠페인과 ad group에 추가된 Negative keyword가 해당리스트에 존재합니다.",
            "term": "이미 캠페인과 ad group에 추가된 Search term이 해당리스트에 존재합니다."
        },
        "errors": {
            "checkPermission": "사용 권한을 확인하세요!",
            "errorType": {
                "adGroupCampaign": "캠페인의 ad group",
                "campaign": "캠페인",
                "creativeSet": "Creative Set",
                "negagativeKeywordsCampaign": "캠페인의 negative keyword",
                "negativeKeywords": "Negative keyword",
                "targetedKeyword": "타겟팅 키워드"
            },
            "failedCampaign": "캠페인 상태를 변경하지 못했습니다. {msg}",
            "failedChangeState": "{level}변경에 실패했습니다. {msg}",
            "failedDuplicate": "복제하지 못했습니다. {msg}, 지속적으로 발생한다면 저희에게 연락해 주십시오.",
            "failedDuplicatedError": "복제하지 못했습니다. {0}, 오류 {1}",
            "failedToFetchChart": "데이터를 가져오는 동안 시간이 초과되었습니다. 다시 시도하려면 아래 버튼을 클릭하세요.",
            "failedToFetchSummary": "데이터를 가져오는 동안 시간이 초과되었습니다. 다시 시도하려면 아래 버튼을 클릭하세요.",
            "failedToFetchTable": "데이터를 가져오는 동안 시간이 초과되었습니다. 다시 시도하려면 아래 버튼을 클릭하세요.",
            "keywordBidUpdated": "업데이트 과정에서 예기치 않은 오류가 발생했습니다.",
            "noReportData": "앗! 보여드릴 데이터를 찾을 수 없습니다."
        },
        "info": {
            "addGoalTabInfo": "이 탭을 선택하면 MMP 어트리뷰션 데이터에 대한 새 목표(Goal)를 설정할 수 있습니다.",
            "clickToFilter": "이 필드에 따라 필터링하려면 클릭하세요",
            "dataIsFetching": "최근 데이터를 업데이트 중이니 기다려주세요..",
            "deleteAdGroup": "선택한 ad group을 삭제하는 것이 확실합니까?",
            "deleteCampaign": "선택된 캠페인을 정말 삭제할까요?",
            "deleteCreativeSet": "선택한 Creatiec set을 정말 삭제할까요?",
            "deleteNegativeKeyword": "선택된 Negative keyword를 삭제할까요?",
            "enterNewBidAmount": "최대입찰가 새로 입력하기",
            "goalTabInfo": "이 탭을 선택하면 미리 정의된 사용자 정의에 따라 MMP 어트리뷰션 툴의 데이터가 포함되어 표시됩니다.",
            "invalidKeywordBid": "키워드 비딩금액이 잘못되었습니다.",
            "noGoalTabInfo": "어트리뷰션 툴의 데이터가 없거나 사용자가 정의한 컨버전에 대한 설명이 없습니다.",
            "oneAdGroup": "(한번에 한그룹)",
            "oneCampaign": "(한 번에 하나의 캠페인만 가능합니다)",
            "refreshDataRestriction": "Apple로 부터 가져올 데이터가 없습니다.",
            "updateKeywordBids": "선택된 캠페인에 대한 키워드 비딩상황 업데이트 하기"
        },
        "success": {
            "adGroupDuplicated": "Ad Group({msg})이 정상적으로 복제되었습니다.",
            "campaignDuplicated": "캠페인({msg})이 정상적으로 복제되었습니다.",
            "completedChangeState": "상태 변경 작업이 정상적으로 완료되었습니다.",
            "completedChangeStateLater": "변경 상태 요청을 받았습니다. 시간이 소요될 수 있습니다.끝나는 대로 알려드리겠습니다.",
            "completedDeleteItem": "작업이 정상적으로 완료되었습니다.",
            "completedDeleteItemLater": "삭제요청을 받았습니다. 시간이 소요될 수 있습니다. 끝나는 대로 알려드리겠습니다.",
            "deleteItem": "삭제된 캠페인이 없습니다. | 삭제된 {n} 캠페인 | 삭제된 {n} 캠페인",
            "keywordBidUpdated": "키워드 비딩가가 정상적으로 업데이트 되었습니다.",
            "keywordBids": "선택한 캠페인의 키워드 비딩단가가 정상적으로 업데이트 되었습니다.",
            "latestDataCalled": "데이터 새로 고침 요청이 정상적으로 접수되었습니다. 이 작업은 5분 이상 소요됩니다."
        },
        "warning": {
            "defaultCreativeSetNotEditable": "Creative set기본값은 수정할 수 없습니다.",
            "onlyOneSingleAdGroup": "하나의 ad group만 편집할 수 있습니다. 하나의 ad group을 선택하십시오.",
            "onlyOneSingleCampaign": "하나의 캠페인만 편집할 수 있습니다. 캠페인을 하나만 선택하십시오.",
            "onlyOneSingleCreativeSet": "하나의 Creative set만 편집할 수 있습니다. 하나만 선택해 주세요",
            "thereIsAlreadyACampaignName": "이름이 {name}인 캠페인이 이미 있습니다. 계속하기 전에 캠페인 이름을 변경하십시오."
        }
    },
    "searchCampaign": "캠페인 검색하기",
    "servingReasons": {
        "ADGROUP_END_DATE_REACHED": "종료일이 되었습니다.",
        "AD_GROUP_MISSING": "Ad Group이 없습니다.",
        "AD_GROUP_PAUSED_BY_USER": "사용자에 의한 일시 정지",
        "APP_NOT_ELIGIBLE": "앱이 지원되지 않습니다.",
        "APP_NOT_ELIGIBLE_SEARCHADS": "앱이 광고를 지원하지 않습니다.",
        "APP_NOT_PUBLISHED_YET": "앱이 아직 출시되지 않았습니다.",
        "APP_NOT_SUPPORT": "앱이 지원되지 않습니다.",
        "AUDIENCE_BELOW_THRESHOLD": "대상이 임계값 미만입니다.",
        "BO_END_DATE_REACHED": "예산 종료일이 되었습니다.",
        "BO_EXHAUSTED": "예산이 모두 소진되었습니다.",
        "BO_START_DATE_IN_FUTURE": "예산 종료일이 되지 않았습니다.",
        "CAMPAIGN_END_DATE_REACHED": "종료일이 되었습니다.",
        "CAMPAIGN_NOT_RUNNING": "캠페인이 중단된 상태입니다.",
        "CAMPAIGN_START_DATE_IN_FUTURE": "시작일이 되지 않았습니다.",
        "CREDIT_CARD_DECLINED": "신용 카드가 거절되었습니다.",
        "DELETED_BY_USER": "사용자에 의해 삭제",
        "LOC_EXHAUSTED": "Loc 소진",
        "MISSING_BO_OR_INVOICING_FIELDS": "인보이스 내용이 부족합니다.",
        "NO_ELIGIBLE_COUNTRIES": "가능한 국가(마켓)이 없습니다.",
        "NO_PAYMENT_METHOD_ON_FILE": "지불방법이 없습니다.",
        "ORG_CHARGE_BACK_DISPUTED": "Org charge back disputed",
        "ORG_PAYMENT_TYPE_CHANGED": "지불방법이 변경되었습니다.",
        "ORG_SUSPENDED_FRAUD": "Org suspended for fraud",
        "ORG_SUSPENDED_POLICY_VIOLATION": "Org suspended for policy violation",
        "PAUSED_BY_SYSTEM": "시스템에 의한 중지",
        "PAUSED_BY_USER": "사용자에 의한 중지",
        "PENDING_AUDIENCE_VERIFICATION": "해당유저군을 확인 중입니다.",
        "SAPIN_LAW_AGENT_UNKNOWN": "Unknown Law agent",
        "SAPIN_LAW_FRENCH_BIZ": "Law French BIZ",
        "SAPIN_LAW_FRENCH_BIZ_UNKNOWN": "Unknown Law French BIZ",
        "START_DATE_IN_THE_FUTURE": "아직 시작일이 되지 않았습니다.",
        "TAX_VERIFICATION_PENDING": "세금관련 확인 중"
    },
    "status": {
        "onHold": "보류",
        "paused": "캠페인 중지",
        "startDateNotReached": "아직 시작일이 되지 않았습니다."
    },
    "tableColumn": {
        "NA": "N/A",
        "appName": "App Name",
        "campaign": "Campaign",
        "campaignId": "Campaign ID",
        "editColumn": "Edit Columns",
        "enabled": "캠페인 활성"
    },
    "totalOfAllAdGroups": "모든 Ad Groups의 총합",
    "totalOfAllApp": "모든 앱의 총합",
    "totalOfAllCampaign": "전체 캠페인 요약",
    "updateKeywordsBids": "키워드 비딩단가 업데이트",
    "updateKeywordsBidsSelected": "선택된 캠페인에 대한 키워드 비딩상황 업데이트 하기",
    "uploadKeywords": "키워드 업로드",
    "uploadNegativeKeywords": "Negative Keyword 업로드"
}
