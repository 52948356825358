<template>
    <fa
        icon="circle-notch"
        :size="size || DEFAULT_SIZE"
        spin
    />
</template>

<script>
    const DEFAULT_SIZE = 'xl';

    export default {
        name: 'ma-spin-icon',
        props: {
            size: {
                type: String,
                default: DEFAULT_SIZE,
                validator(value) {
                    return [
                        '2xs',
                        'xs',
                        'sm',
                        '1x',
                        'lg',
                        'xl',
                        '2x',
                        '3x',
                        '4x',
                        '5x',
                        '6x',
                        '7x',
                    ].includes(value);
                },
            },
        },
        data() {
            return {
                DEFAULT_SIZE,
            };
        },
    };
</script>
