<template>
    <div
        :class="{
            'ma-list-action-icon': true,
            'ma-list-action-icon--loading': loading,
        }"
        @click.prevent="handleDelete"
    >
        <ma-icon
            v-if="loading"
            name="spin-round"
            spin
            size="xs"
            class="text-gray-600"
        />
        <ma-icon
            v-else
            name="trash"
            size="xs"
            class="text-gray-600"
        />
    </div>
</template>

<script setup>
    import { MaIcon } from '@mobileaction/action-kit';
    import { ref } from 'vue';
    import { deleteLabel } from '@/common/MaRequests/MetadataLabel';
    import { useLabelsStore } from '@/pinia/Labels';

    const labelsStore = useLabelsStore();
    const props = defineProps({
        labelId: { type: Number, required: true },
    });

    const loading = ref(false);
    const handleDelete = async () => {
        if (loading.value) {
            return;
        }
        loading.value = true;
        try {
            await deleteLabel(props.labelId);
            await labelsStore.getLabels({ cacheInvalidate: true });
        } catch (e) {
            console.error('Failed to delete label', e);
        } finally {
            loading.value = false;
        }
    };
</script>
