<template>
    <ma-tooltip type="primary" :title="text" :disabled="!showTooltip">
        <ma-icon
            v-if="onHold"
            name="dot"
            class="text-yellow-400 cursor-pointer focus:outline-none flex-shrink-0"
            size="xs"
        />
        <ma-icon
            v-else-if="enabled"
            name="dot"
            class="text-green-400 cursor-pointer focus:outline-none flex-shrink-0"
            size="xs"
        />
        <ma-icon
            v-else
            name="dot"
            class="text-gray-200 cursor-pointer focus:outline-none flex-shrink-0"
            size="xs"
        />
        <span v-if="showText">&nbsp;&nbsp;{{ text }}</span>
    </ma-tooltip>
</template>

<script>
    import { MaIcon, MaTooltip } from '@mobileaction/action-kit';

    export default {
        name: 'ma-status-icon',
        components: { MaIcon, MaTooltip },
        props: {
            enabled: { type: Boolean, required: false },
            onHold: { type: Boolean, required: false },
            showText: { type: Boolean, default: false },
            holdText: { type: String, default: 'columns.active' },
            enabledText: { type: String, default: 'columns.active' },
            pausedText: { type: String, default: 'common.paused' },
            toBeAddedText: { type: String, default: 'columns.toBeAdded' },
            toBeAdded: { type: Boolean, default: false },
            showTooltip: { type: Boolean, default: false },
        },
        computed: {
            text() {
                let key = this.pausedText;
                if (this.enabled) {
                    key = this.enabledText;
                } else if (this.onHold) {
                    key = this.holdText;
                } else if (this.toBeAdded) {
                    key = this.toBeAddedText;
                }
                return this.$te(key) ? this.$t(key) : key;
            },
        },
    };
</script>

