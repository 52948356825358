{
    "addNewLabel": "Add new label",
    "areYouSureDeleteLabel": "Are you sure you want to delete the selected label?",
    "cancel": "Cancel",
    "color": "Color",
    "createLabel": "Create Label",
    "description": "Description",
    "description_optional": "Description (Optional)",
    "editLabel": "Edit Label",
    "enterAColor": "Color is required",
    "enterALabelName": "Label name is required",
    "failedToCreateLabel": "Failed to create label {msg}",
    "failedToFetchLabel": "Failed to fetch label {msg}",
    "failedToUpdateLabel": "Failed to update label {msg}",
    "label": "Label",
    "labelNameCannotBeStartedWithAt": "Label name cannot be started with {'@'} symbol.",
    "labelNameShouldBeUnique": "Label name should be unique",
    "mainCategoryCanBeOnlyOne": "Main category (which starts with #) cannot be more than one for each entity",
    "name": "Name",
    "newLabel": "New Label",
    "nFreeLabelsLeft": "You have {n} free label(s) left",
    "noLabelFound": "No Label Found",
    "preDefinedLabelCannotBeDeleted": "Pre-defined labels cannot be deleted.",
    "preDefinedLabelCannotBeEdited": "Pre-defined labels cannot be edited.",
    "searchByName": "Search by name",
    "succeedToCreateLabel": "The label {label} was created successfully.",
    "succeedToUpdateLabel": "The label {label} was updated successfully.",
    "upgradeForMoreLabels": "Upgrade to add new labels"
}
