{
    "buttons": {
        "OK": "OK",
        "actions": "Actions",
        "adScheduling": "Ad Scheduling",
        "add": "Add",
        "addAsKeyword": "Add As Keywords",
        "addAsNegativeKeyword": "Add As Negative Keywords",
        "addKeywords": "Add Keywords",
        "addKeywordsToAdGroup": "Add keywords to Ad Group",
        "addNegativeKeywords": "Add Negative Keywords",
        "addNew": "Add new",
        "addSlackChannel": "Add Slack Channel",
        "adjust": "Adjust",
        "apply": "Apply",
        "assignCategory": "Assign Category",
        "audience": "Audience",
        "automationCart": "Automation Cart",
        "automationRule": "Automation Rule",
        "back": "Back",
        "cancel": "Cancel",
        "change": "Change",
        "chart": "Chart",
        "checkStatus": "Check Status",
        "choose": "Choose",
        "clear": "Clear",
        "clearAll": "Clear All",
        "clearFilters": "Clear filters",
        "close": "Close",
        "collapse": "Collapse",
        "collapseAll": "Collapse All",
        "confirm": "Confirm",
        "connectNow": "Connect now",
        "contactSales": "Contact Sales 🚀",
        "continueToPreview": "Continue to preview",
        "copy": "Copy",
        "copyUrl": "Copy URL",
        "create": "Create",
        "createAd": "Create Ad",
        "createAdGroup": "Create Ad Group",
        "createAnother": "Create Another",
        "createAutomationRule": "Create Automation Rule",
        "createCampaign": "Create Campaign",
        "createConversion": "Create Conversion",
        "createCreativeSet": "Create Creative Set",
        "createGoal": "Create Goal",
        "createIntegration": "Create Integration",
        "createKeyword": "Create Keyword",
        "createNegativeKeyword": "Create Negative Keyword",
        "createSearchTabAutomation": "Create Search Tab Automation",
        "delete": "Delete",
        "discard": "Discard",
        "discardDraft": "Discard Draft",
        "dismiss": "Dismiss",
        "duplicate": "Duplicate",
        "done": "Done",
        "edit": "Edit",
        "expand": "Expand",
        "find": "Find",
        "goalSelection": "Goal Selection",
        "hide": "Hide",
        "joinBeta": "Join Beta",
        "label": "Label",
        "learn": "Learn",
        "learnMore": "Learn More",
        "linkItLater": "Link It Later",
        "loadMore": "Load more",
        "logs": "Logs",
        "newTab": "New Tab",
        "next": "Next",
        "openAutomationCart": "Open Automation Cart",
        "openInNewTab": "Open In A New Browser Tab",
        "refresh": "Refresh",
        "reloadPage": "Reload now",
        "remove": "Remove",
        "replay": "Replay",
        "save": "Save",
        "saveAndClose": "Save & Close",
        "saveChanges": "Save Changes",
        "seeExample": "See Example",
        "seeAll": "See All",
        "selectAll": "Select All",
        "send": "Send",
        "shareOn": "Share on",
        "start": "Start",
        "show": "Show",
        "templates": "Templates",
        "tryAgain": "Try Again",
        "update": "Update",
        "reset": "Reset"
    },
    "columns": {
        "CR": "Conversion Rate (CR)",
        "TTR": "TTR",
        "action": "Action",
        "actionType": "Action Type",
        "active": "Active",
        "adChannel": "Ad Channel",
        "adGroupId": "Ad Group Id",
        "adGroupName": "Ad Group Name",
        "allAdGroups": "All Ad Groups",
        "allCampaigns": "All Campaigns",
        "allKeywords": "All Keywords",
        "appliedData": "Applied Data",
        "automationLevel": "Automation Level",
        "averageCPA": "Average CPA",
        "averageCPT": "Average CPT",
        "avgCPA": "Avg CPA",
        "avgCPT": "Avg CPT",
        "bidAmount": "Bid Amount",
        "broadMatch": "Broad Match",
        "budget": "Budget",
        "campaignID": "Campaign Id",
        "campaignName": "Campaign Name",
        "campaignType": "Campaign Type",
        "category": "Category",
        "chance": "Chance",
        "change": "Change",
        "clicks": "Clicks",
        "conditions": "Conditions",
        "conversionRates": "Conversion Rates",
        "conversions": "Conversions",
        "cpa": "CPA",
        "cpaGoal": "CPA Cap",
        "cpm": "CPM",
        "cpt": "CPT",
        "cptBid": "CPT Bid",
        "cr": "Conversion Rate (CR)",
        "currency": "Currency",
        "creativeSetId": "Creative Set Id",
        "dailyCap": "Daily Budget",
        "dataCheckRange": "Data Check Range",
        "dataComparisonRange": "Data Comp. Range",
        "dateOccured": "Date Occurred",
        "defaultCPMBid": "Default CPM Bid",
        "defaultCPTBid": "Default CPT Bid",
        "defaultMaxCpmBid": "Default Max CPM Bid",
        "defaultMaxCptBid": "Default Max CPT Bid",
        "difficultyRank": "Organic Difficulty",
        "email": "Email",
        "emailNotEditable": "Email (Not Editable)",
        "emailNotifier": "Email Notifier",
        "exactMatch": "Exact Match",
        "excludeReAttribution": "Exclude Re-Attributions",
        "goal": "Goal",
        "goals": "Goals",
        "impressions": "Impressions",
        "includeReAttribution": "Include Re-Attributions",
        "includedInGoal": "Included in Goal",
        "install": "Installs",
        "installs": "Installs",
        "invoicingOption": "Invoicing Option",
        "iphoneChance": "Iphone Chance",
        "keyword": "Keyword",
        "keywordId": "Keyword Id",
        "keywords": "Keywords",
        "label": "Label",
        "lifetimeBudget": "Lifetime Budget",
        "ltv": "LTV",
        "matchSource": "Match Source",
        "matchType": "Match Type",
        "maxCPTBid": "Max CPT Bid",
        "maxCpmBid": "Max CPM Bid",
        "name": "Name",
        "negativeKeywords": "Negative Keywords",
        "newBid": "New Bid",
        "newBudget": "New Budget",
        "numberOfApps": "Number of Apps",
        "numberOfIphoneApps": "Number of IPhone Apps",
        "oldBid": "Old Bid",
        "oldBudget": "Old Budget",
        "onlyReAttribution": "Only Re-Attribution",
        "organicRank": "Organic Rank",
        "passive": "Passive",
        "password": "Password",
        "percentage": "Percentage",
        "popularity": "Popularity",
        "popularityRank": "Popularity",
        "queryType": "Query Type",
        "reAttrType": "Re-Attribution Type",
        "reAttribution": "Re-Attribution",
        "reSend": "Re-Send",
        "recover": "Recover",
        "redownloadsShare": "Redownloads Share",
        "restrictionEntityType": "Query Type",
        "restrictionLevel": "Restriction Level",
        "roas": "ROAS",
        "ruleActionFrequency": "Action Frequency",
        "ruleControlFrequency": "Control Frequency",
        "ruleId": "Rule",
        "ruleLogState": "Run Status",
        "ruleName": "Rule Name",
        "ruleState": "Rule State",
        "searchMatch": "Search Match",
        "searchTerm": "Search Terms",
        "source": "Source",
        "spend": "Spend",
        "state": "State",
        "status": "Status",
        "storefront": "Storefront",
        "taps": "Taps",
        "toBeAdded": "To Be Added",
        "ttr": "TTR",
        "updateTime": "Update Time",
        "withOutReAttribution": "Without Re-Attribution",
        "withReAttribution": "With Re-Attribution",
        "columnGroups": {
            "METADATA": 'Settings',
            "REPORT": 'Ad Performance',
            "INSIGHTS": 'Insights',
            "AUDIENCE": 'Audience',
            "ATTRIBUTION": 'In-App Conversions',
            "COHORT": 'Cohort Analysis'
        }
    },
    "device" : {
        "IPHONE": "iPhone",
        "IPAD": "iPad",
        "IPHONE_AND_IPAD": "iPhone and iPad"
    },
    "common": {
        "ADD_AS_KEYWORD": "Add as Keyword",
        "ADD_AS_NEGATIVE_KEYWORD": "Add as Negative Keyword",
        "ADD_LABEL": "Add Label",
        "ALL": "ALL",
        "AND": "AND",
        "APPLY_SMART_NOTIFICATION": "Apply Smart Notification",
        "APPLY_SMART_BID_SWEET_SPOT": "Apply Smart Bid Sweet Spot",
        "BETWEEN_SENTENCE": "{n} in range of",
        "BLANK_SENTENCE": "is Included",
        "CHANGE_BID": "Change Bid",
        "CHANGE_BUDGET": "Change Budget",
        "CHANGE_CPA_GOAL": "Change CPA Cap",
        "CHANGE_DAILY_CAP": "Change Daily Budget",
        "CHANGE_TOTAL_BUDGET": "Change Lifetime Budget",
        "CONTAINS": "Contains",
        "DECREASE": "Decrease by",
        "EMAIL_NOTIFICATION": "Email Notification",
        "EMPTY": "is empty",
        "EMPTY_SENTENCE": "is empty",
        "GREATER_THAN": "Greater Than",
        "GREATER_THAN_SENTENCE": "{n} greater than",
        "INCREASE": "Increase by",
        "IN_RANGE": "In Range",
        "LESS_THAN": "Less Than",
        "LESS_THAN_SENTENCE": "{n} less than",
        "LIMIT": "Limit: {limit}",
        "MALE": "Male",
        "FEMALE": "Female",
        "MALE_FEMALE": "Male and Female",
        "NOT_CONTAINS": "Not contains",
        "NOT_EMPTY_SENTENCE": "is not empty",
        "NOT_IN_RANGE": "Not in Range",
        "NOT_BETWEEN": "is not in range between",
        "NOT_EQUALS_STR": "is not equal to",
        "EQUALS_STR": "Equals",
        "EQUALS": "Equals",
        "NOT_IN_RANGE_SENTENCE": "{n} not in range of",
        "NOT_BETWEEN_SENTENCE": "{n}  not in range of",
        "NOT_BLANK_SENTENCE": "is Excluded",
        "NOT_EQUALS_STR_SENTENCE": "{n} not equal to",
        "EQUALS_STR_SENTENCE": "{n} equal to",
        "EQUALS_SENTENCE": "{n} equal to",
        "NOT_EQUALS_SENTENCE": "{n} not equal to",
        "CONTAINS_SENTENCE": "contains",
        "NOT_CONTAINS_SENTENCE": "does not contain",
        "PAUSE": "Pause",
        "SET": "Set to",
        "SET_TO_CPA": "Set to average CPA",
        "SET_TO_CPT": "Set to average CPT",
        "SLACK_NOTIFICATION": "Slack Notification",
        "UTC": "UTC",
        "accessRequired": "Access Required",
        "accountIntegrationsExpired": "You've not valid integration for {orgName}, please provide a valid integration for the account or contact us. Click this warning to integrate now!",
        "accounts": "Campaign Groups",
        "active": "Active",
        "add": "Add",
        "actionFrequency": "Action Frequency",
        "activate": "Activate",
        "activeOrganization": "Active Organization: {org}",
        "addNewFilter": "Add New Filter",
        "adGroup": "Ad Group",
        "adGroupSettings": "Ad Group Settings",
        "adGroupStatus": "Ad Group Status",
        "adGroups": "Ad Groups",
        "adPerformance": "Ad Performance",
        "adPlacement": "Ad Placement",
        "wellBeBackSoon": "We’ll be back soon!",
        "sorryForInconvenience1": "Sorry for the inconvenience but we’re performing maintenance at the moment. If you need to you can always ",
        "sorryForInconvenience2": ", otherwise we’ll be back online shortly!",
        "adPlacements": {
            "APPSTORE_PRODUCT_PAGES_BROWSE": "Product Pages--While Browsing",
            "APPSTORE_SEARCH_RESULTS": "Search Results",
            "APPSTORE_SEARCH_TAB": "Search Tab",
            "APPSTORE_TODAY_TAB": "Today Tab"
        },
        "ads": "Ads",
        "adsStructure": "Ads Structure",
        "adsPerformances": "Ads Performances",
        "adsPerformanceFor": "Ads Performance for {adGroup}",
        "adsTopFive": "The chart shows the top 5 performing Ads based on Conversion Rate. You can click",
        "adsToNavigate": "to see the performance of all ads attached to this Ad Group.",
        "addLabelsFor": "Add Labels for",
        "ageRange": "Age Range",
        "all": "All",
        "allData": "All Data",
        "action": "Action",
        "alwaysTranslate": "Always show English translation of keywords",
        "alreadyInBudgetAllocationGroup": "This campaign cannot be selected because it is already in a budget allocation group.",
        "alreadyInBudgetAllocationGroupAll": "All of the campaigns under the {appName} are already included in budget allocation.",
        "allUsers": "All Users",
        "amount": "Amount",
        "and": "and",
        "apiKey": "Api Key",
        "app": "App",
        "appName": "App Name",
        "apps": "Apps",
        "areaChart": "Area Chart",
        "attribution": "Attribution",
        "audience": "Audience",
        "automationEntityList": "Automation Entity List",
        "available": "Available",
        "basic": "Basic",
        "beta": "BETA",
        "between": "Between",
        "bidAmountHistory": "Bid History",
        "bidSources": {
            "DASHBOARD": "Dashboard",
            "RULE": "Automation",
            "EXTERNAL": "External",
            "SMART_BID": "Smart Bidding",
            "BUDGET_ALLOCATION": "Budget Allocation",
            "QUICK_AUTOMATION": "Optimize",
            "AI_KEYWORD_OPTIMIZER": "AI-powered Keyword Optimizer"
        },
        "budgetOrder": "Budget Order",
        "bulkActions": {
            "CHANGE_BID": "Update Keyword Bids",
            "CHANGE_DEFAULT_CPC_BID": "Update Default CPT Bid",
            "CHANGE_DAILY_CAP": "Change Daily Budget",
            "CHANGE_CPA_GOAL": "Change CPA Goal",
            "ADD_LABEL": "Add Labels",
            "NEW_ACTION": "New Action",
            "PAUSE": "Pause"
        },
        "bulkUpdate": "Bulk Update",
        "campaign": "Campaign",
        "campaignSetting": "Campaign Settings",
        "campaignStatus": "Campaign Status",
        "campaigns": "Campaigns",
        "categories": {
            "BRAND": "#Brand",
            "CATEGORY": "#Category",
            "COMPETITOR": "#Competitor",
            "DISCOVERY": "#Discovery",
            "UNASSIGNED": "#Unassigned",
            "description": {
                "AGE": "When you target a specific gender or age group, Apple doesn't show your ads to users who have a \"Limit Ad Tracking\" (LAT) option \"On\".",
                "BRAND": "Brand campaign focused on customers searching for your app or company name.",
                "BROAD": "Broad match ensures your ad will run on relevant close variants of a keyword, such as singular, plurals, misspellings, synonyms, related searches, and phrases that include that term, fully or partially.",
                "COMPETITOR": "Competitor campaign focused on customers searching for apps similar to yours, within the same or a related app category.",
                "DISCOVERY": "Discovery campaign focused on finding new search terms relevant to your app.",
                "EXACT": "For the tightest control over searches where your ad may appear, use exact match.",
                "GENDER": "When you target a specific gender or age group, Apple doesn't show your ads to users who have a \"Limit Ad Tracking\" (LAT) option \"On\".",
                "GENERIC": "Generic campaign focused on customers searching for non-branded terms that describe your app’s category and what your app does.",
                "LAT": "When you target a specific gender or age group, Apple doesn't show your ads to users who have a \"Limit Ad Tracking\" (LAT) option \"On\"."
            }
        },
        "changeContentFromSidebar": "You can change content from left side bar.",
        "changeOrClearFilters": "Please change or clear filters",
        "chart": "Chart",
        "chartType": "Chart Type",
        "chartFilters": {
            "customColumnNotApplicable": "Custom columns can only applicable in the daily chart view",
            "monthlyChartNotAvailable": "Monthly chart option is not available once the custom columns field is selected",
            "weeklyChartNotAvailable": "Weekly chart option is not available once the custom columns field is selected"
        },
        "cohortAnalysis": "Cohort Analysis",
        "columnChart": "Column Chart",
        "columns": "Columns",
        "comingSoon": "Coming Soon",
        "computed": "Computed",
        "confirm": "Confirm",
        "confirmDescription": "I confirm that this bulk action will be applied to the {number} items and this action is irreversible",
        "contactTeamAdministrator": "Please contact your team administrator.",
        "contactUsTerm1": "Contact Us",
        "contactUsTerm2": "anytime if you need help!",
        "contains": "Contains",
        "copy": "Copy",
        "countriesAndRegions": "Countries and Regions",
        "country": "Country",
        "countries": "Countries",
        "countryCodes": "Country Codes",
        "cpp": "CPP",
        "createdAt": "Created At",
        "creativeSetSettings": "Creative Set Settings",
        "creativeSets": "Creative Sets",
        "cryptoCurrencies": "Crypto Currencies",
        "csv": "CSV",
        "currency": "Currency",
        "currencyCode": "Code ({code})",
        "currencySymbol": "Symbol ({currency})",
        "currencyView": "Currency View",
        "currentView": "Current View",
        "customColumns": "Custom Columns",
        "customConversion": "Custom Conversion",
        "customerType": "Customer Type",
        "daily": "Daily",
        "dailyBudget": "Daily Budget",
        "dailyBudgetHistory": "Daily Budget History",
        "dashboard": "Dashboard",
        "dataRange": "Data Range",
        "defaultColumns": "Default Columns",
        "delete": "Delete",
        "devices": "Devices",
        "displayStatus": "Display Status",
        "download": "Download",
        "downloadCsv": "Download data as CSV",
        "dontTranslate": "Don't show English translation of keywords",
        "duplicate": "Duplicate",
        "editColumns": "Edit Columns",
        "emailPreview": "Email Preview",
        "enable": "Enable",
        "enabled": "Enabled",
        "endDate": "End Date",
        "enterNewBindAmount": "Enter new bid amount",
        "entitySearchPlaceholder": {
            "AD_GROUP": "Search for Ad groups",
            "CAMPAIGN": "Search for Campaigns",
            "KEYWORD": "Search for Keywords",
            "NEGATIVE": "Search for Negative Keywords",
            "SEARCH_TERM": "Search for Search Terms"
        },
        "equals": "Equals",
        "error": "Error",
        "events": "Events",
        "failed": "Failed",
        "filter": "Filter",
        "allFilters": "All Filters",
        "filters": "Filters",
        "for": "for",
        "formula": "Formula",
        "frequency": "Frequency",
        "from": "from",
        "gender": "Gender",
        "goal": "Goal",
        "goalCreatedSuccessfully" : "Goal created successfully",
        "greaterThan": "Greater Than",
        "here": "here",
        "hideChart": "Hide Chart",
        "hourly": "Hourly",
        "in": "In",
        "inAppConversions": "In-App Conversions",
        "inAppEventsSettings": "In-app events settings",
        "inQueue": "In queue",
        "info": "Info",
        "insights": "Insights",
        "integrated": "Integrated",
        "integratedOrgs": "Integrated Campaign Groups",
        "integration": "Integration",
        "integrationId": "Integration ID:",
        "integrationStatus": "Integration Status",
        "integrations": {
            "partners": {
                "adjust": "Adjust",
                "airbridge": "Airbridge",
                "appsflyer": "AppsFlyer",
                "branch": "Branch",
                "custom": "In-House",
                "kochava": "Kochava",
                "singular": "Singular",
                "tenjin": "Tenjin"
            }
        },
        "are": "are",
        "is": "is",
        "isNot": "is not",
        "keywordStatus": "Keyword Status",
        "keywords": "Keywords",
        "language": "Language",
        "lessThan": "Less Than",
        "splineChart": "Spline Chart",
        "lineChart": "Line Chart",
        "link": "Link",
        "loading": "Loading...",
        "logs": "Logs",
        "makeSelection": "Please make selection",
        "message": "Message",
        "monthly": "Monthly",
        "showCumulative": "Show Cumulative",
        "showValues": "Show Values",
        "more": "More",
        "myApps": "My Apps",
        "multiLoginDetected": "Login activity detected from another browser, you will be redirected to the login page",
        "multiple": "Multiple",
        "numberMetadataSelected": "{count} Selected {type}(s)",
        "noApplicableFilterCannotEdit": "This filter item is not editable/applicable on this level.",
        "noDataForFilter": "There is no data to match with your filters!",
        "nAccount": "No Campaign Group | {n} Campaign Group | {n} Campaign Groups",
        "nAccountsSelected": "No Campaign Group Selected | {n} Campaign Group Selected | {n} Campaign Groups Selected",
        "nAdGroup": "No Ad Group | {n} Ad Group | {n} Ad Groups",
        "nAdGroupsSelected": "No Ad Group Selected | {n} Ad Group Selected | {n} Ad Groups Selected",
        "nAdGroupLabelSelected": "No Ad Group Label Selected | {n} Ad Group Label Selected | {n} Ad Group Labels Selected",
        "nApp": "No App | {n} App | {n} Apps",
        "nAppsSelected": "No App Selected | {n} App Selected | {n} Apps Selected",
        "nAdPlacementSelected": "No Ad Placement Selected | {n} Ad Placement Selected | {n} Ad Placements Selected",
        "nLabelSelected": "No Label Selected | {n} Label Selected | {n} Labels Selected",
        "nStatusSelected": "No Status Selected | {n} Status Selected | {n} Statuses Selected",
        "nAddedAsKeywordSelected": "No Added As Keyword Selected | {n} Added As Keyword Selected | {n} Added As Keywords Selected",
        "nDeviceClassSelected": "No Device | {n} Device | {n} Devices",
        "nGenderSelected": "No Gender | {n} Gender | {n} Genders",
        "nCustomerTypeSelected": "No Customer Type | {n} Customer Type | {n} Customer Types",
        "nBidStrengthSelected": "No Bid Strength | {n} Bid Strength | {n} Bid Strengths",
        "nCreativeTypeSelected": "No Creative Type | {n} Creative Type | {n} Creative Types",
        "nCampaign": "No Campaign | {n} Campaign | {n} Campaigns",
        "nCampaignLabelSelected": "No Campaign Label Selected | {n} Campaign Label Selected | {n} Campaign Labels Selected",
        "nCampaignsSelected": "No Campaign Selected | {n} Campaign Selected | {n} Campaigns Selected",
        "nCountriesSelected": "No Country Selected | {n} Country Selected | {n} Countries Selected",
        "nCountry": "No Country | {n} Country | {n} Countries",
        "nCreativeSet": "No Creative Set | {n} Creative Set | {n} Creative Sets",
        "nGoal": "No Goal | {n} Goal | {n} Goals",
        "nItemsSelected": "No items selected | {n} item selected | {n} items selected",
        "nKeyword": "No Keyword | {n} Keyword | {n} Keywords",
        "nKeywordsSelected": "No Keyword Selected | {n} Keyword Selected | {n} Keywords Selected",
        "nKeywordLabelSelected": "No Keyword Label Selected | {n} Keyword Label Selected | {n} Keyword Labels Selected",
        "nCPPSelected": "No CPPs Selected | {n} CPP Selected | {n} CPPs Selected",
        "nRulesSelected": "No Rule Selected | {n} Rule Selected | {n} Rules Selected",
        "nSelected": "None selected | {n} selected | {n} selected",
        "negativeKeywords": "Negative Keywords",
        "new": "New",
        "newFeature": "New feature available!",
        "newUsers": "New Users",
        "no": "No",
        "noResultsFound": "No results found.",
        "noActionLogDetails": "No details for this action log",
        "noBidChangeInRange": "No changes in bid in the selected time range",
        "noBudgetChangeInRange": "No changes in daily budget in the selected time range",
        "noAdsPerformace":  "No ads performance in the selected time range",
        "noData": "No Data",
        "noDataWithCurrentFilters": "No data found with current filters",
        "metadata": {
            "nCampaignGroups": "Campaign Groups | 1 Campaign Group | {n} Campaign Groups",
            "nApps": "Apps | 1 App | {n} Apps",
            "nCampaigns": "Campaigns | 1 Campaign | {n} Campaigns",
            "nAdGroups": "Ad Groups | 1 Ad Group | {n} Ad Groups",
            "summary": "Summary",
            "selectACampaignGroupFromLeft": "Select a campaign group from the left",
            "selectAnAppFromLeft": "Select an app from the left",
            "selectACampaignFromLeft": "Select a campaign from the left",
            "selectAnAdGroupFromLeft": "Select an ad group from the left",
            "noSelectedCampaignGroups": "No selected campaign groups",
            "noSelectedApps": "No selected apps",
            "noSelectedCampaigns": "No selected campaigns",
            "noSelectedAdGroups": "No selected ad groups"
        },
        "noFiltersApplied": "No filters applied yet",
        "noGoalSelection": "No Goal Selection",
        "noIntegration": "No Integration",
        "noIntegrationAdded": "No integration added yet!",
        "noSearchAdsAuthorization": "You don’t have authorization to view SearchAds.com by MobileAction.",
        "notBetween": "Not Between",
        "notContains": "Not Contains",
        "notEquals": "Not Equals",
        "notScheduled": "Not scheduled",
        "onHold": "On hold",
        "onlySelectedData": "Only Selected Data",
        "operation": "Operation",
        "optional": "Optional",
        "or": "or",
        "organization": "Campaign Group",
        "organizations": "Campaign Groups",
        "passed": "Passed",
        "pause": "Pause",
        "paused": "Paused",
        "preparing": "Preparing",
        "preview": "Preview",
        "processing": "Processing",
        "processingLevel": "Processing {level}...",
        "promoText": "Promo Text",
        "promotedApp": "Promoted App",
        "ranking": "Ranking",
        "reAttrType": "Re-Attributions Data Type",
        "reason": "Reason",
        "recentlyViewed": "Recently Viewed",
        "report": "Report",
        "reportingNote": "Reporting is not real time. Data received within the last 24 hours may not be reflected in reporting",
        "resolved": "Resolved",
        "results": "Results",
        "requestTime": "Request Time",
        "retry": "Retry",
        "returningUsers": "Returning Users",
        "rules": "Rules",
        "running": "Running",
        "savedFilters": "Saved Filters",
        "scheduleAds": "Schedule Ads",
        "scheduled": "Scheduled",
        "search": "Search",
        "searchNew": "Search...",
        "searchApp": "Search App",
        "searchByMetric": "Search By Metric",
        "searchByName": "Search by name",
        "searchByNameOrId": "Search by name or ID",
        "searchKeyword": "Search keyword",
        "searchMatch": {
            "false": "Off",
            "true": "On"
        },
        "seeMore": "See more",
        "searchTerms": "Search Terms",
        "select": "Select",
        "selectACampaign": "Select a campaign",
        "selectAnAdGroup": "Select an Ad Group",
        "selectCampaign": "Select Campaign",
        "selectCategory": "Select Category",
        "selectGoalInfo": "Click here to see your attribution data.",
        "selectLabels": "Select labels",
        "selectMetadata": "Select {type}(s)",
        "sessionHasExpired": "Your session has expired, you will be redirected to the login page",
        "settings": "Settings",
        "showChart": "Show Chart",
        "showSteps": "Show Steps",
        "smartBidding": "Smart Bidding",
        "standardInvoice": "Standard Invoice",
        "startDate": "Start Date",
        "status": "Status",
        "statuses": {
            "pending": "Pending"
        },
        "stop": "Stop",
        "stopped": "Stopped",
        "successful": "Successful",
        "suggested": "Suggested",
        "exclusive": "Exclusive",
        "switchCurrencyView": "Click to switch currency view",
        "noDataTopApps": "There is no data for this keyword",
        "templates": "Templates",
        "timeZone": "Time Zone",
        "timezoneNote": "Time zone for all dates and times",
        "topRunningAdsForKeyword": "Top {n} Apps Running Ads for {keyword}",
        "toSeeMMP": "To see and use it, please integrate your MMP.",
        "toSeeHere": "To see here, please create it.",
        "total": "Total",
        "totalCount": "Total {total}",
        "totalSummary": "Total Summary",
        "type": "Type",
        "translationOn": "Translation On",
        "translationOff": "Translation Off",
        "update": "Update",
        "updated": "Updated",
        "updateKeywordBidAmount": "Update Keyword Bid Amount",
        "upload": "Upload",
        "uploadedIntegrations": "Uploaded Integrations",
        "users": "Users",
        "usersOfMyOtherApps": "Users of my other apps",
        "value": "Value",
        "view": "View",
        "warning": "Warning",
        "weekly": "Weekly",
        "yes": "Yes",
        "youWillBeRedirected": "You will be redirected in {autoRedirectTimer} seconds.",
        "youDontHaveAnyGoal": "You don’t have any goal",
        "selectedOption": "This option will apply changes just for the items you have selected.",
        "currentOption": "This option will apply changes  just for the items on the displayed page.",
        "allOption": "This option will apply changes for all the items according to the current level and filters. Please, be aware that this operation might take some time."
    },
    "components": {
        "appSearch": {
            "searchFailedWithErrorCode": "Search failed with error code:",
            "searchForAnyAppByName": "Search for any app by name or id"
        },
        "bidHistory": {
            "viewsNotAvailable": "Those views(Weekly, Monthly) are not applicable for Daily Apple SOV metric."
        },
        "campaignGroup": {
            "chooseCampaignGroup": "Choose Campaign Group",
            "selectAnIntegration": "Select an integration"
        },
        "common": {
            "apply": "Apply",
            "columnsToHide": "Columns to hide",
            "columnsToShow": "Columns to show",
            "resetToDefaults": "Reset to defaults",
            "saveAsPreset": "Save as preset"
        },
        "countrySelector": {
            "selectAStoreFront": "Select a storefront"
        },
        "dataLevels": {
            "ACCOUNT": "Campaign Group",
            "AD": "Ads",
            "AD_GROUP": "Ad Group",
            "APP": "Application",
            "CAMPAIGN": "Campaign",
            "CPP": "CPP",
            "KEYWORD": "Keyword",
            "NEGATIVE": "Negative Keyword",
            "SEARCH_TERM": "Search Term"
        },
        "dateRangeSelector": {
            "last2Weeks": "Last 2 Weeks",
            "last30Days": "Last 30 Days",
            "last3Mths": "Last 3 Months",
            "last6Mths": "Last 6 Months",
            "last7Days": "Last 7 Days",
            "last14Days": "Last 14 Days",
            "lastMonth": "Last Month",
            "lastWeek": "Last Week",
            "lastYear": "Last Year",
            "pickARange": "Pick a range",
            "thisMonth": "This Month",
            "thisWeek": "This week",
            "today": "Today",
            "week": "Week",
            "yesterday": "Yesterday",
            "custom": "Custom"
        },
        "goals": {
            "categories": {
                "ENGAGEMENT": "Engagement",
                "GAME_EVENT": "Game Event",
                "OTHER": "Other",
                "PURCHASE": "Purchase",
                "SUBSCRIPTION": "Subscription",
                "TRIAL": "Trial"
            }
        },
        "keywordAddition": {
            "BROAD": "Broad",
            "EXACT": "Exact",
            "keywordType": "Keyword Type",
            "negative": "Negative"
        },
        "loadingScreen": {
            "loadingSearchAds": "Search Ads by Mobile Action | Loading..."
        },
        "maColumnsManager": {
            "clearAll": "Clear All",
            "dragDrop": "Drag and drop to reorder",
            "duplicatePresetName": "Preset name already used, please change it!",
            "editColumnsFor": "Edit Columns For  {table}",
            "makePrivate": "Make private",
            "cannotMakePrivate": "You cannot make private preset that you do not own",
            "makePublic": "Make public",
            "nColumnsSelected": "{n} columns selected",
            "privatePreset": "Private Preset",
            "privatePresets": "Private Presets",
            "publicPreset": "Public Preset",
            "publicPresets": "Public Presets",
            "saveAsPreset": "Save as preset",
            "searchByMetric": "Search by Metric",
            "selectAll": "Select All",
            "ugradeForCustomColumns": "You need to upgrade to create more custom columns",
            "ugradeForCohortColumns": "You need to upgrade to apply Cohort Metrics"
        },
        "saAuthHeader": {
            "adBlockersAreKnowToBlock": "Ad Blockers are known to block anything with the word ads in it. You might come across some unexpected problems. In order to prevent these problems please disable it on our product.",
            "ifYouAreUsingAdBlock": "If you are using ad block, then please disable it for a better experience"
        },
        "updateChecker": {
            "importantUpdateReleased": "An important update is released",
            "newVersionAvailable": "New Version is Available!",
            "newVersionAvailableRefresh": "New version of SearchAds.com dashboard is available. Once you click on the badge, the dashboard will refresh with the latest features.",
            "newVersionAvailableRefreshMajor": "New version of SearchAds.com Dashboard is available. You can either refresh now with the following button or wait for it to refresh in 60 seconds.",
            "pageWillRefreshIn": "Page will refresh to upgrade in:",
            "refreshNow": "Refresh Now",
            "refreshLater": "I will refresh later",
            "newVersionAvailable": "New version available",
            "willRefreshIn": "Will Refresh In: "
        }
    },
    "messages": {
        "errors": {
            "accountHasReadOnlyAccess": "You don't have the required permissions, please make sure that your integrations have Admin access.",
            "anErrorOccurredFetchingRules": "An error occurred fetching rules: {msg}",
            "apiIntegration": "Apple Search Ads account is not valid, you can check it on",
            "checkPermission": "Please check your permissions!",
            "dataIsTooBigToSort": "Data is too big to sort. Please filter your data to sort it.",
            "dataNotFound": "Data is not found. Please synchronize data from Dashboard",
            "decimalFormatIsNotValid": "Number format is not valid. Decimal separator is dot (0.00)",
            "duplicateInputValidatorMessage": "Value needs to be between {min} and {max}",
            "duplicatedKeywords": "Duplicated keyword",
            "errorOccurredKeywordAddition": "Error occurred when trying to add keywords to <strong>({name})</strong>. Error is <i>{error}</i>",
            "failedAdGroupReport": "Failed to load Ad Groups Report",
            "failedChangeState": "Failed to change state of {level}: {msg}",
            "failedKeywordReport": "Failed to get Keywords Report",
            "failedToAdd": "Failed to add {type}: {msg}",
            "failedToAddKeywords": "Failed to add keywords",
            "failedToCreateAdGroup": "Failed to create ad group with error \"{msg}\"",
            "failedToCreateAdGroupPlease": "Failed to create ad group, please check your inputs and if it persists let us know!",
            "failedToFetchAdGroup": "Failed to fetch ad groups",
            "failedToFetchCampaign": "Failed to fetch campaigns {msg}",
            "failedToFetchSummary": "Time out was occurred when we try to fetch your summary data. Please click below button to try again.",
            "failedToFetchChart": "Time out was occurred when we try to fetch your chart data. Please click below button to try again.",
            "fieldIsRequired": "This field is required",
            "fieldMustBeGreaterThan0": "This field must be greater than 0",
            "fixTheError": "Fix the error",
            "invalidNumberFormat": "Invalid number format",
            "keywordLimitExceedForAdGroup": "Keyword limit exceeded for ad group, Limit for Ad Group keywords is 1000. Try again after make sure that total number of keywords in the list and ad group does not exceed the limit",
            "noActiveOrgSelected": "Active organization could not be found. Please refresh page.",
            "noAnyOrgOnAccount": "We could not find any campaign group on your account. Please refresh your page or contact with admin.",
            "servicesError": "There was an error with our services. Please check your inputs",
            "somethingWentWrong": "Ups, Something went wrong.",
            "timeoutMessage": "Looks like the server is taking to long to respond, this can be caused by either poor connectivity or an error with our servers. Please try again in a while",
            "thereAreKeywordsOnTheList": "There are keywords on the list that were already added to campaign and ad group",
            "unexpectedError": "Unexpected error occurred, if it persists please contact us. {msg}",
            "youAreNotAuthorizedToThisOperation": "You don't have authorization to this operation(s)"
        },
        "info": {
            "actionTypeInfo": "Implemented action via pre defined rule.",
            "adBlockersIsNotEnabled": "Please make sure that ad blockers is not enabled for this site. If this issue persists contact us",
            "addGoalTabInfo": "If you select this tab, you can describe new goal for your MMP Attribution data",
            "addingKeywordsToAdGroup": "Adding keywords to ad group...",
            "addingNegativeKeywordsToAdGroup": "Adding negative keywords to ad group...",
            "addingNegativeKeywordsToCampaign": "Adding negative keywords to campaign...",
            "almostThere": "Almost There!",
            "amountMustBeGreaterThan0": "Amount must be greater than 0",
            "areYouSureDeleted": "Are you sure to delete selected item(s)?",
            "automationLevelInfo": "It is selected Automation level when a rule is described.<br><br>E.g: Keywords in Apps; For this example, Automation level is Keywords",
            "budgetCanNotBeEmpty": "Budget cannot be empty",
            "budgetValueIsNotValid": "Budget value is not valid",
            "campaignNameCanNotBeEmpty": "Campaign name cannot be empty",
            "campaignNameShouldBeUniqueForOrg": "Campaign name should be unique for organization.",
            "chooseOneOrMoreCountries": "Choose one or more countries or regions",
            "clickToDetail": "Click to see detail...",
            "clickToUpdate": "Click to update",
            "continueWithSSO": "Continue with SSO",
            "copyReportLink": "Copy report link",
            "createAccount": "Create campaign group",
            "creatingAdCreative": "Creating ad creative...",
            "creatingAdGroup": "Creating ad group...",
            "creatingCampaign": "Creating campaign...",
            "creatingCreativeSet": "Creating creative set...",
            "dailyBudgetCanNotBeEmpty": "Daily Budget cannot be empty",
            "dailyBudgetExplanation": "Daily budget is the average amount you want to spend on your campaign each day over the course of a month. Your monthly spend won’t be more than your daily budget times the average number of days in a calendar month. On days with opportunities to get more downloads, your spend may exceed your daily budget.<br><br>Your campaign will continue to spend every month based on your daily budget amount, unless you pause it, remove it, or set an end date. If you set an end date, you won’t spend more than the total number of days in the campaign times your daily budget.<br><br>You can adjust your daily budget at any time.",
            "dataIsFetching": "Fetching data...",
            "dataProvidedBy": "Data provided by",
            "doNotHaveAnAccountYet": "Don't have an account yet?",
            "enterABillingContactEmail": "Please enter a Billing Contact Email",
            "enterABuyerEmail": "Please enter a Buyer Email",
            "enterABuyerName": "Please enter a Buyer Name",
            "enterAClientName": "Please enter a Client Name",
            "enterAOrderNumber": "Please enter an Order Number",
            "enterAValidBuyerEmail": "Please enter a valid buyer email",
            "enterAValidContactEmail": "Please enter a valid contact email",
            "enterAValidEmail": "Please enter a valid email",
            "goalTabInfo": "If you select this tab, you will see reporting data with the MMP Attribution data according to your predefined custom conversion.",
            "haveToReloadPage": "While loading this page your contents have been updated. Either refresh this page or it will be reloaded in 10 seconds.",
            "inputValidNumber": "Please input valid number",
            "lifetimeBudgetExplanation": "Lifetime budget is the total amount you want to spend on your campaign. It can’t be decreased once your campaign starts running, and it's not available for new or duplicated campaigns.<br><br>You can switch to daily budget only anytime if you'd like to have your campaigns run continuously and optimize your spending to take advantage of high-opportunity times. Once you switch, you won't be able to use lifetime budget again for the same campaign.",
            "loadingDataFromServer": "Loading data from server...",
            "max500Row": "*Only first 500 rows were displayed.",
            "noAuthorizedForAnyOrg": "No authorized Campaign Group found. Please contact your administrator",
            "noGoalTabInfo": "You do not have attribution data or you have not describe custom conversion yet.",
            "passwordTooShort": "Password is too short, must be at least 8 characters",
            "pleaseEnterAnEmail": "Please enter an email",
            "pleaseEnterYourPassword": "Please enter your password",
            "pleaseFillTheForm": "Please fill the form, we will contact you ASAP!",
            "restrictionLevelInfo": "It is selected Restriction level when a rule is described.<br><br>E.g: Keywords in Apps; For this example, Restriction level is Apps",
            "ruleStateInfo": "Current rule state.",
            "selectCountryOrRegion": "Please select a Country or Region",
            "selectMetrics": "Select metrics to view in chart",
            "signInWithApple": "Sign in with Apple",
            "signInWithAppleSearchAds": "Sign in with Apple Search Ads",
            "signInWithGoogle": "Sign in with Google",
            "signInWithLinkedIn": "Sign in with LinkedIn",
            "signUpWithYourEmail": "Or, sign up with your email",
            "somethingHasGoneWrong": "Uhoh, something has gone wrong! Please try again later.",
            "toScrollUseShift": "Use \"Shift + Mouse Scroll\" to scroll to the right of the table.",
            "unsupportedDomain": "You can't register with this domain. Please use a business email address to register.",
            "thePageYouHaveRequested": "The page you have requested not found.",
            "youCanAdjustYourBudget": "The amount you want to spend on a campaign, and one of the mandatory steps during your initial campaign setup.You can adjust your budget at any time.",
            "youCanSafelyCloseThisWindow": "You can safely close this window and continue using the link in your email.",
            "youCanSearchAndSelectAnApp": "You can search and select an app to see active ads keyword impression share.",
            "youNeedTheCompleteOneMoreStep": "You need to complete one more step to continue.",
            "youWillNotBeChargedMoreThanYourCampaign": "The most you want to spend per day on your campaign. Once the daily budget is reached, your ads will stop appearing for that day but will restart on the next day assuming there is still budget remaining for your campaign. It is possible that the amount spent on a given day will slightly exceed the daily budget. However you will not be charged more than your campaign budget.",
            "yourAppWillBePromotedOnTheAppStore": "Your app will be promoted on the App Store in the eligible countries and regions you choose from the list."
        },
        "integrateAccountView": {
            "accessDailyRunning": "Access daily running ads by app/keyword easily.",
            "automateYourSearchAdsCampaign": "Automate your search ads campaigns",
            "connectYourSearchAds": "Connect Your Search Ads Account",
            "connectYourSearchAdsAccount": "Connect Your Search Ads Account to Get Started.",
            "createSearchAdsCampaigns": "Create Search Ads campaigns, ad groups",
            "getAccessToDetailed": "Get access to detailed summary of all campaigns",
            "getAccessToDetailedInsight": "Get access to detailed insights of different countries",
            "getAheadOfYourCompetitors": "Get ahead of your competitors with the smartest Search Ads Tool in the market.",
            "getAnalyticsAbout": "Get analytics about how your campaigns affect your rankings",
            "searchDetailedInsight": "Search detailed insight of ranking keywords",
            "setAutomationsAndAlerts": "Set automations and alerts for your Search Ads campaigns",
            "setYourConditions": "Set your conditions, pause campaigns, edit your bids and manage all your campaign specifications",
            "withAutomationsYouCan": "With Automations, you can:",
            "withKeywordHunt": "With Keyword Hunt, you can:",
            "withSearchAdsYouCan": "With Search Ads, you can:",
            "withYourConnectedAppleSearchAdsAccount": "With your connected Apple Search Ads account, you can:",
            "youGetAccessToOtherFeatures": "You'll get access to other features such as Keyword Auction Insights, Automations, Organic Keyword Hunt.",
            "youWillGetAccessToOther": "You'll get access to other features such as Keyword Auction Insights, Ads Manager.",
            "youWillGetAccessToOthers": "You'll get access to other features such as Keyword Auction Insights, Dashboard, Automations",
            "integrate": "Integrate",
            "noValidAccountFound": "Your Integrations Are Expired!",
            "noValidAccountFoundInfo": "It looks like your Apple Search Ads integrations are <span style=\"text-decoration: underline;\">expired</span>. To continue to use our platform, please connect a valid Apple Search Ads account to SearchAds.com."
        },
        "success": {
            "adCreativeUpdated": "Ad updated successfully",
            "adGroupBidUpdated": "Max CPT bid updated successfully",
            "apiKeyCopied": "Api Key copied!",
            "cppLinkCopied": "Custom product page link copied",
            "dailyBudgetUpdated": "Daily budget updated successfully",
            "keywordBidUpdated": "Keyword bid updated successfully",
            "keywordsAddedSuccessfully": "{n}-Keywords were added to <strong>({name})</strong> successfully.",
            "linkCopied": "Link copied",
            "operationCompleted": "Operation is completed successfully.",
            "operationCompletedWith": "Operation is completed with {success}-Successful, {warning}-Warning and {error}-Error.",
            "totalBudgetUpdated": "Lifetime Budget updated successfully"
        },
        "warnings": {
            "adGroupCustomError": "Failed to create ad group with error, {msg}",
            "adGroupUnrelatedGoalField": "You have unrelated ad group filter with your goal.",
            "campaignDailyCapCanNotExceed": "Campaign Daily Budget cannot exceed Campaign Budget, correct it before continue",
            "campaignIsUnderBudgetAllocation": "This campaign is under Budget Allocation.",
            "campaignUnrelatedGoalField": "You have unrelated campaign filter with your goal.",
            "dataIsNotFoundToSort": "Data is not found to sort. Data can be loading or not available.",
            "deviceTypeIPadCanNotBeSelected": "Device type IPad cannot be selected for this, choose IPhone only",
            "discardDraftSure": "If you discard this draft, you will not be able to undo this.",
            "duplicateAdGroupName": "Ad Group name {msg} is not unique in the campaign.",
            "maxDataRange": "Max date range for {level} can be 180 days so date range is automatically handled and fetched for {startDate} and {endDate}",
            "metricNotEligible": "This metric is not eligible for Compare Option.",
            "mobileScreenWarning": "You might encounter with compatibility problems on mobile view. We strongly suggest to use more comfortable screens.",
            "noInAppEventsYet": "No in-app events yet",
            "noIntegratedApp": "No Integrated App",
            "noKeywordRankingHistory": "No Keyword Ranking History For Keyword {selectedKeywordString}",
            "notAvailableLocations": "Not available if your campaign is set for multiple countries or regions",
            "pleaseFixValidationErrors": "Please fix all validation errors on the page before continue",
            "pleaseSelectAGoalForFilter": "Please select a goal to apply this filter",
            "pleaseSelectAdPlacement": "Please select ad placement",
            "pleaseSelectAnApp": "Please select an app",
            "pleaseSelectAnAttributonPartner": "Please select an attribution partner",
            "pleaseSelectAnGoalForFilter": "Please select an goal to implement goal filter",
            "promoteOthersAppDisallowed": "Your Apple Search Ads Account is not allowed to promote other's app!",
            "singleCurrencyWarning": "Your selection includes multiple currencies. You can create automation for single currency",
            "requestFailedDueTo": "The request failed because of: {reason}",
            "usernameExistsGoLogin": "Username already exists. Click this message to go login page!",
            "warningOccurredKeywordAddition": "Warning occurred when trying to add keywords to <strong>({name})</strong>. Warning is <i>{warning}</i>",
            "withoutRelatedGoalAndRemoved": "Goal was removed from filters due to current filters.",
            "singleCurrencyAutomation": "Automation rules is only allowed for single currency",
            "singleCurrencyAutomationDesc": "Please remove entities with secondary currencies to proceed with creating Automation rule",
            "youAreNotAuthorized": "You are not authorized to access Search Ads. If this issue persists please contact us"
        },
        "noData": {
            "noKeywordAddedToCart": "No keyword added to cart yet",
            "previewNotAvailable": "Preview not available",
            "noDataAvailable": "No Data Available",
            "nothingToShow": "We have no data to show"
        }
    },
    "page": {
        "adsManager": "Ads Manager",
        "automations": "Automations",
        "overview": "Overview",
        "recommendations": "Recommendations"
    },
    "actionKit": {
        "competitorAddedSuccessfully": "Competitor added successfully",
        "competitorRemovedSuccessfully": "Competitor added successfully",
        "noMatchWithQuery": "No match is found with your query",
        "showRecentlyViewed": "Show @:common.recentlyViewed",
        "showSuggested": "Show @:common.suggested",
        "typeAnAppName": "Type an app name or publisher name"
    },
    "tooltips": {
        "maxCPMBid": "<h3>Max CPM Bid</h3>This is the maximum amount you’re willing to pay per one thousand ad impressions on the App Store. An impression is counted each time at least 50 percent of your ad is visible for one second."
    }
}
