import ExactKeyword from './ExactKeyword.mjs';
import BroadKeyword from './BroadKeyword.mjs';
import ExactNegativeKeyword from './ExactNegativeKeyword.mjs';
import BroadNegativeKeyword from './BroadNegativeKeyword.mjs';

export const addType = {
    broadKeyword: 'BroadKeyword',
    exactKeyword: 'ExactKeyword',
    broadNegativeKeyword: 'BroadNegativeKeyword',
    exactNegativeKeyword: 'ExactNegativeKeyword',
};
export const insertionLevel = {
    campaignLevel: 'CampaignLevel',
    adGroupLevel: 'AdGroupLevel',
};
export const keywordPosition = {
    sameCampaign: 'SameCampaign',
    otherCampaign: 'OtherCampaign',
    sameAdGroup: 'SameAdGroup',
    differentAdGroup: 'DifferentAdGroup',
};
export const keywordType = {
    keyword: 'Keyword',
    negativeKeyword: 'NegativeKeyword',
};
export const matchType = {
    broad: 'Broad',
    exact: 'Exact',
};
export const bidType = {
    sameBid: 'SameBid',
    differentBid: 'DifferentBid',
};

export const buildKey = (kwType, kwToBeAdded, kwPresent) => {
    const kTypes = { keyword: 'KEYWORD', negative: 'NEGATIVE' };
    const mTypes = { broad: 'BROAD', exact: 'EXACT' };

    //  Keyword Add Type
    let _addType = '';
    if (kwToBeAdded.matchType === mTypes.broad) {
        _addType = kwType === kTypes.keyword
            ? addType.broadKeyword
            : addType.broadNegativeKeyword;
    } else {
        _addType = kwType === kTypes.keyword
            ? addType.exactKeyword
            : addType.exactNegativeKeyword;
    }

    //  Keyword Insertion Level
    let _insertionLevel = insertionLevel.campaignLevel;
    if (kwToBeAdded.adGroupId && kwPresent.adGroupId) {
        _insertionLevel = insertionLevel.adGroupLevel;
    }

    //  Keyword Position
    let _keywordPosition = '';
    if (_insertionLevel === insertionLevel.adGroupLevel) {
        _keywordPosition = kwToBeAdded.adGroupId === kwPresent.adGroupId
            ? keywordPosition.sameAdGroup
            : keywordPosition.differentAdGroup;
    } else {
        _keywordPosition = kwToBeAdded.campaignId === kwPresent.campaignId
            ? keywordPosition.sameCampaign
            : keywordPosition.otherCampaign;
    }

    //  Keyword Type
    let _keywordType = kwPresent.keywordType === kTypes.keyword
        ? keywordType.keyword
        : keywordType.negativeKeyword;

    // Match Type
    let _matchType = kwPresent.matchType === mTypes.broad ? matchType.broad : matchType.exact;

    // Bid Type
    let _bidType = '';
    if (kwType === kTypes.keyword && kwPresent.keywordType === kTypes.keyword) {
        _bidType = `-${bidType.sameBid}`;
        if (Number(kwToBeAdded.bidAmount.amount) !== Number(kwPresent.bidAmount.amount)) {
            _bidType = `-${bidType.differentBid}`;
        }
    }

    return `${_addType}-${_insertionLevel}-${_keywordPosition}-${_keywordType}-${_matchType}${_bidType}`;
};

export const cases = Object.assign({}, ExactKeyword, BroadKeyword, ExactNegativeKeyword, BroadNegativeKeyword);
