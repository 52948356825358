import { makeRequest } from '@/common/MaRequestPooler';

export class ASOTrackedKeywordsController {

    constructor(pooler) {
        this.requestPooler = pooler || makeRequest;
    }

    /**
     * Returns keywords tracked on MA side
     * @param trackId
     * @param countryCode
     */
    fetchASOTrackedKeywords({ trackId, countryCode }) {
        return this.requestPooler(`/account/app/${trackId}/keywords/ranking/${countryCode}?device=iphone`, {
            method: 'POST',
        });
    }

    /**
     * Returns keyword groups created on MA side for provided app & country
     * @param trackId trackId of main app
     * @param countryCode
     */
    fetchASOTrackedKeywordGroups({ trackId, countryCode }) {
        return this.requestPooler(`/user-keyword-group/v2/app/${trackId}/${countryCode}`);
    }
}

export const useASOTrackedKeywordsController = () => new ASOTrackedKeywordsController();
