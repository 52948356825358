import cn from '@/i18n/components/TableHeader/cn.json';
import en from '@/i18n/components/TableHeader/en.json';
import ja from '@/i18n/components/TableHeader/ja.json';
import ko from '@/i18n/components/TableHeader/ko.json';

export default {
    cn,
    en,
    ja,
    ko,
};
