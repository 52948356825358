{
    "addAllKeywords": "ページ上の全てのキーワードを追加",
    "addKeywords": "キーワードを追加",
    "added": "追加されました",
    "allKeywordsExcluded": "全てのキーワードが除外されました。追加するキーワードはありません。",
    "areYouSureYouWantContinue": "キーワードが重複しています。このまま進めますか？",
    "bidAmountFormatIsNotValid": "入札金額の書式が無効です。小数点以下の区切り文字がドット（0.00）になっています。",
    "checkKeywordIssues": "キーワードの重複を確認",
    "chooseNegativeKeywordTarget": "除外キーワードを追加するには、キャンペーンもしくは広告グループを選択してください",
    "considerGivingCampaign": "キーワードのパフォーマンスを分析する前に、キャンペーンが十分な期間で出稿されているかご確認ください。除外キーワードを過度に増やすと広告露出が限定されます。",
    "filterByKeyword": "キーワードで絞り込む",
    "failedToGetStatistics": "エラーのため詳細を取得できませんでした:",
    "makeSureBidAmountFilled": "全ての入札額の値が入力されているかご確認ください",
    "maxCPTBid": "最大CPT入札額",
    "negativeKwLevel": "除外キーワード階層：",
    "negativeKwsToAdGroup": "除外キーワードを広告グループへ",
    "negativeKwsToCampaign": "除外キーワードをキャンペーンへ",
    "noDataForCurrentFilters": "現在のフィルターではデータが見つからない。",
    "noTrackedKeywords": "このアプリと国では、キーワードは追跡されない。",
    "organicKeywords": "オーガニック検索キーワード",
    "paidKeywords": "入札されているキーワード",
    "select": "選択する",
    "selectTarget": "追加先を選択",
    "setCustomValuesOnTable": "個別に値を変更する",
    "singleTypeToUpload": "キーワードを追加するには単一の{type}を選択してください",
    "theseKeywordsWillNotBeShown": "これらのキーワードは表示されません",
    "thisWillProcessConflictingKeywords": "このプロセスでは重複キーワードを処理します。",
    "tips": "ヒント",
    "trackKeywordsForSelected": "トラッキングしたキーワードのリストを表示するには、選択したアプリと国のキーワードをトラッキングしてください。",
    "typeToUploadKws": "どちらの {type} でキーワードを追加しますか？",
    "useDefaultMaxCPT": "デフォルトの最大CPT入札額を使用する",
    "youreHere": "あなたはここにいる",
    "adGroupNegativeKeywords": "広告グループ内の除外キーワードは、この広告グループ内でのみ適用されます。全ての広告グループで除外したい場合、キャンペーンの階層で設定してください。",
    "addNegativeKeywords": "広告グループに除外キーワードを追加",
    "changeOrClearFilters": "フィルターを変更またはクリアしてください",
    "chooseACampaignGroup": "キャンペーングループを選択",
    "chooseAnApp": "アプリを選択してください",
    "clearFilters": "クリアフィトラー",
    "goToKeywordTracking": "キーワードトラッキングへ",
    "noKeywordAddedList": "キーワードがリストに追加されていません"
}
