import {
    GOOGLE_RECAPTCHA_SITE_KEY,
    isProduction } from '@/common/Config';
import DefaultSelectedApp from '@/common/DefaultSelectedApp.mjs';
import { dateToStr } from '@/common/MaUtils.mjs';
import { defaultIndexes, defaultPreset } from '@/common/TableColumns.mjs';
import {
    MaLoggerPlugin,
    MaLocalStorage,
    MaSessionStorage,
    MaGoogleRecaptcha,
} from '@mobileaction/ui-modules/dist/plugins.cjs';
import jwtDecode from 'jwt-decode';
import { h } from 'vue';
import dayjs from '@/plugins/dayjs.mjs';
import { getDefaultColumns } from '@/pages/Automations/utils/AutomationsTable';

const dateSetter = d => d && d.getFullYear ? dateToStr(d) : d;

window.j = jwtDecode;

export default {
    install(app) {
        app.use(MaLoggerPlugin, {
            isDebug: !isProduction,
        });

        app.use(MaLocalStorage, [
            {
                name: 'userAuth',
                getter: auth => auth && Object.assign({}, auth, {
                    jwtData: auth.jwt && jwtDecode(auth.jwt),
                    rftData: auth.refreshToken && jwtDecode(auth.refreshToken),
                }),
            },
            {
                name: 'isSummaryCollapsed',
                getter: v => v === true,
                setter: v => v === true,

            },
            {
                name: 'lastSearchedApp',
                default: DefaultSelectedApp,
            },
            {
                name: 'startDate',
                setter: dateSetter,
            },
            {
                name: 'endDate',
                setter: dateSetter,
            },
            {
                name: 'automationsTableColumns',
                default: [...getDefaultColumns()],
            },
            {
                name: 'campaignColumns',
                default: defaultIndexes('campaign'),
            },
            {
                name: 'adGroupColumns',
                default: defaultIndexes('adgroup'),
            },
            {
                name: 'keywordColumns',
                default: defaultIndexes('keyword'),
            },
            {
                name: 'selectedTableColumns',
                default: {
                    accounts: defaultPreset('accounts'),
                    apps: defaultPreset('apps'),
                    campaigns: defaultPreset('campaigns'),
                    adGroups: defaultPreset('adGroups'),
                    keywords: defaultPreset('keywords'),
                    ads: defaultPreset('ads'),
                    cpp: defaultPreset('cpp'),
                    searchTerms: defaultPreset('searchTerms'),
                },
            },
            {
                name: 'dashboardChartMetrics',
                default: [],
            },
            {
                name: 'bidHistoryChartMetrics',
                default: [],
            },
            {
                name: 'keywordPerformanceChartMetrics',
                default: [],
            },
            {
                name: 'selections',
            },
            {
                name: 'campaignPerformanceChartMetrics',
                default: [],
            },
            {
                name: 'budgetHistoryChartMetrics',
                default: [],
            },
            {
                name: 'lastUpdateTime',
            },
            {
                name: 'refreshIntegrationTime',
            },
            {
                name: 'utmVars',
                default: {},
            },
            {
                name: 'countryCode',
                default: 'US',
            },
            {
                name: 'reAttrType',
                default: 'WITHOUT',
            },
            {
                name: 'overviewFields',
                default: {
                    userId: null,
                    activeDate: null,
                    selectedApp: null,
                    selectedAppFilter: null,
                    initialBenchmarkItems: [],
                    initialCountryItems: [],
                    initialDeviceItems: [],
                    initialGenderItems: [],
                    keywordSortField: null,
                    adGroupSortField: null,
                },
            },
            {
                name: 'funnelFields',
                default: {
                    onboarded: 'NO',
                    allLevels: [],
                    selectedLevels: [],
                },
            },
            'integrationOnBoardingCompleted',
            'newsletterOptIn',
            'friendReferrer',
            'initialReferrer',
            'landingPage',
            'loginCsrf',
            'forwardTo',
            'appleAuthRedirectPath',
            {
                name: 'locale',
                default: 'en',
            },
            'activeOrg',
            'activeCurrency',
            'integrations',
            'currentFilter',
            {
                name: 'activePresets',
                default: {},
            },
            'userInfo',
            {
                name: 'currencyView',
                default: 'symbol',
            },
            {
                name: 'ruleOptionDialog',
                default: 'YES',
            },
            'experimentApp',
            'appleSignInUtms',
            {
                name: 'bannerHidden',
                getter: v => v === true,
                setter: v => v === true,
            },
            'pkceConfig',
            {
                name: 'sidebarCollapsed',
                default: false,
            },
        ]);
        app.use(MaSessionStorage, [
            'campaigns',
            'ad-groups',
            'keywordCart',
            'menuCollapsed',
            'landingTime',
            {
                name: 'unauthorized',
                getter: v => v === true,
                setter: v => v === true,
            },
            {
                name: 'slackRedirectUri',
                default: '/',
            },
            {
                name: 'automationSelectedEntities',
                default: {
                    entityType: null,
                    entityList: [],
                },
            },
            {
                name: 'remainingBannerCloseTime',
                setter: v => v || dayjs(),
                getter: v => v || 0,
            },
            {
                name: 'validFeatures',
                default: [],
            },
        ]);

        // todo - VUE3 - enable MaGoogleRecaptcha after updating ui-modules
        app.use(MaGoogleRecaptcha, {
            h,
            siteKey: GOOGLE_RECAPTCHA_SITE_KEY,
        });
    },
};
