{
    "adsManager": "Ads Manager",
    "aso": "KP.",
    "attribution": "Attribution",
    "automations": "Automations",
    "benchmarks": "Benchmarks",
    "budgetAllocation": "Budget Allocation",
    "collapseMenu": "Collapse menu",
    "campaignForecasting": "Campaign Forecasting",
    "competitionAnalysis": "COMPETITION ANALYSIS",
    "competitionAnalysisAbb": "CA.",
    "competitors": "Competitors",
    "contactSupport": "Contact Support",
    "credit": "Credit",
    "expandMenu": "Expand menu",
    "funnels": "Funnels",
    "goals": "Goals",
    "audit": "Audit",
    "ic": "IC.",
    "insightCenter": "INSIGHT CENTER",
    "integrations": "Integrations",
    "keywordAdvisor": "Keyword Advisor",
    "keywordAuctionInsights": "Keyword Auction Insights",
    "cppIntelligence": "CPP Intelligence",
    "cppIntelligenceTooltip": "Explore our data-driven solution to uncover your competitors’ Custom Product Pages strategies.",
    "keywordPlanner": "KEYWORD PLANNER",
    "ma": "MA",
    "mmp": "MMP",
    "mobileMeasurementPartner": "MOBILE MEASUREMENT PARTNER",
    "optimization": "Optimization",
    "organicKeywordsHunt": "Organic Keyword Hunt",
    "overview": "Overview",
    "quickActions": "Quick Actions",
    "optimize": "Optimize",
    "campaignWizard": "Campaign Wizard",
    "recommendations": "Recommendations",
    "report": "Report",
    "requestDemo": "Request Demo",
    "settings": "Settings",
    "setup": "SETUP",
    "setupAbb": "SET.",
    "setupCompleteness": "Setup Completeness",
    "smartBidding": "Smart Bidding",
    "spotlight": "Spotlight",
    "coPilot": "CO-PILOT",
    "coPilotAbb": "CP.",
    "switchTo": "Switch to",
    "tooltips": {
        "adsManager": "The Campaign management dashboard where you can create campaigns, track your performance, track your Apple Search Ads funnel, add automation rules and scale your campaigns.",
        "automations": "Setup automation rules to make sure your campaigns are always optimized.",
        "spotlight": "Increase your performance automatically with Smart Tools.",
        "campaignWizard": "Build Apple Search Ads Campaigns just in 3 steps!",
        "keywordAdvisor": "Automatically find high-intent keywords.",
        "smartBidding": "Eliminate wasted ad spend by bidding right amount for right keywords. Improve your UA and cost forecast accuracy.",
        "audit": "Get a detailed analysis of your Apple Search Ads account with our Audit and discover any inefficiencies that are wasting your budget and time.",
        "campaignForecasting": "Increasing your Daily Budget enables your ads to be shown more in ads. Try different Daily Budgets and forecast your Impressions, Taps and Installs for your Search Result Campaigns.",
        "organicKeywordsHunt": "Discover all of the organic keywords an app is ranking for and choose the best possible ones by relying on App Store metrics and instantly start running ads on those keywords.",
        "goals": "Configure custom goals to track your precise ROAS. Set up your attribution integration and start tracking."
    }
}
