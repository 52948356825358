import { defineStore } from 'pinia';
import { ref } from 'vue';
import { filterPresets } from '@/common/MaRequests/FilterPreset';

export const useFilterPresetStore = defineStore('filterPreset', () => {
    const filterPresetsList = ref([]);
    const loadingPresets = ref(false);

    const getPresets = async () => {
        loadingPresets.value = true;
        try {
            filterPresetsList.value = await filterPresets();
        } catch (errors) {
            filterPresetsList.value = [];
            console.error('Failed to fetch filter presets', errors);
        } finally {
            loadingPresets.value = false;
        }
    };

    return {
        filterPresetsList,
        getPresets,
        loadingPresets,
    };
});
