<template>
    <div>
        <template v-if="type === 'full'">
            <a :href="REQUEST_DEMO_URL" target="_blank">
                <ma-badge
                    :variant="limitReached ? 'green' : 'orange'"
                    type="secondary"
                    shape="square"
                    class="py-2 px-6 text-md gap-2"
                    size="large"
                >
                    <ma-icon name="cup-bold" size="xl" class="!h-6 !w-6"/>
                    <div>
                        {{ text }}
                    </div>
                </ma-badge>
            </a>
        </template>
        <template v-else-if="type === 'badge'">
            <a
                @click="handleClick"
            >
                <ma-badge
                    icon="cup-bold"
                    size="small"
                    :variant="limitReached ? 'green' : 'orange'"
                    type="secondary"
                    class="cursor-pointer"
                >
                    {{
                        limitReached
                            ? (fullText ? t('proFeature') : t('pro'))
                            : t('remainingQuota', { remaining: remainingQuota, total: totalQuota })
                    }}
                </ma-badge>
            </a>
        </template>
    </div>
</template>

<script setup>
    import { MaBadge, MaIcon } from '@mobileaction/action-kit';
    import { REQUEST_DEMO_URL } from '@/common/Config/index';
    import i18n from '@/components/MaProFeatureIndicators/MaProFeatureIndicators.i18n';
    import { useI18n } from 'vue-i18n';

    defineOptions({
        i18n,
    });
    const { t } = useI18n();

    const props = defineProps({
        type: {
            type: String,
            validator: val => ['full', 'badge', 'badge-full'].includes(val),
            default: 'badge',
        },
        limitReached: { type: Boolean },
        text: { type: String, default: '' },
        totalQuota: { type: Number, default: 3 },
        remainingQuota: { type: Number, default: 3 },
        fullText: { type: Boolean },
        disableLink: { type: Boolean },
    });

    const emit = defineEmits(['track-click']);

    const handleClick = (e) => {
        if (!props.disableLink) {
            e.stopPropagation();
            emit('track-click');
            window.open(REQUEST_DEMO_URL, '_blank');
        }
    };
</script>
