import { authEvent, sidebarEvent } from '@/plugins/mixpanel/eventDefinitions';
import { useGlobal } from '@/composables';

export default {
    login: authEvent.login,
    signup: authEvent.signup,
    pageView: authEvent.pageView,
};


export const useMixpanelEvents = () => {
    const { $mixpanel } = useGlobal();

    const sidebarCPPMenuItemClicked = () => {
        $mixpanel.track(...sidebarEvent.cppIntelligenceClicked.toMixpanel());
    };

    return { sidebarCPPMenuItemClicked };
};
