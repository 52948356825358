import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useHighVolumeBulkEditController } from '@/controllers/HighVolumeBulkEditController';
import { validOrgList } from '@/common/FilteringUtils';

export const useHighVolumeLogsStore = defineStore('highVolumeLogs', () => {
    const pageSize = ref(10);
    const currentPage = ref(1);
    const totalResults = ref(0);

    const bulkEditLogs = ref([]);
    const loadingLogs = ref(false);

    const statusFilter = ref([]);
    const actionsFilter = ref([]);
    const usersFilter = ref([]);

    const isFiltered = computed(() => {
        return !!statusFilter.value.length
            || !!actionsFilter.value.length
            || !!usersFilter.value.length;
    });
    const clearFilters = () => {
        statusFilter.value = [];
        actionsFilter.value = [];
        usersFilter.value = [];
    };

    const statusFilterObj = computed(() => {
        if (!statusFilter.value.length) {
            return null;
        }
        return {
            column: 'ruleLogState',
            value: statusFilter.value.join(','),
            type: 'STRING',
            compareType: 'IN',
        };
    });
    const actionsFilterObj = computed(() => {
        if (!actionsFilter.value.length) {
            return null;
        }
        return {
            column: 'actionType',
            value: actionsFilter.value.join(','),
            type: 'STRING',
            compareType: 'IN',
        };
    });

    const controller = useHighVolumeBulkEditController();
    const params = computed(() => {
        const filters = [
            actionsFilterObj.value,
            statusFilterObj.value,
        ].filter(f => f);
        return {
            filterFields: filters,
            sortField: 'bulkEditAutomationCreationDate',
            sortOrder: 'DESC',
            limit: pageSize.value,
            orgIdList: validOrgList().map(o => o.orgId),
            offset: (currentPage.value - 1) * pageSize.value,
        };
    });
    const fetchBulkEditLogs = async () => {
        loadingLogs.value = true;
        try {
            const result = await controller.getBulkEditAutomationsLogs(params.value);
            totalResults.value = result?.pagination?.totalRowCount || 0;
            bulkEditLogs.value = result?.tableData || [];
        } catch (e) {
            console.error('Failed to fetch Bulk Edit logs', e);
        } finally {
            loadingLogs.value = false;
        }
    };

    const fetchRecentLogs = async () => {
        loadingLogs.value = true;
        const params = {
            filterFields: [],
            sortField: 'bulkEditAutomationCreationDate',
            sortOrder: 'DESC',
            limit: 5,
            orgIdList: validOrgList().map(o => o.orgId),
            offset: 0,
        };
        try {
            const res = await controller.getBulkEditAutomationsLogs(params);
            bulkEditLogs.value = res?.tableData;
        } catch (e) {
            console.error('Failed to fetch Bulk Edit logs', e);
        } finally {
            loadingLogs.value = false;
        }
    };

    watch([statusFilterObj, actionsFilterObj, pageSize], () => {
        currentPage.value = 1;
        fetchBulkEditLogs();
    });

    watch([currentPage], () => {
        fetchBulkEditLogs();
    });

    return {
        pageSize,
        currentPage,
        totalResults,
        fetchBulkEditLogs,
        fetchRecentLogs,
        loadingLogs,
        bulkEditLogs,
        statusFilter,
        actionsFilter,
        usersFilter,
        isFiltered,
        clearFilters,
    };
});
