export const TABS = {
    CPA_OPTIMIZATION: 'cpa-optimization',
    CPI_OPTIMIZATION: 'cpi-optimization',
    CPG_OPTIMIZATION: 'cpg-optimization',
    ROAS_OPTIMIZATION: 'roas-optimization',
};

export const REQUIRED_FEATURES = {
    [TABS.CPA_OPTIMIZATION]: 'SA_SMART_BIDDING_CPA',
    [TABS.CPI_OPTIMIZATION]: 'SA_SMART_BIDDING_CPI',
    [TABS.CPG_OPTIMIZATION]: 'SA_SMART_BIDDING_CPG',
    [TABS.ROAS_OPTIMIZATION]: 'SA_SMART_BIDDING_ROAS',
};

export const STATES = {
    ACTIVE: 'ACTIVE',
    PASSIVE: 'PASSIVE',
    STOPPED: 'STOPPED',
};

export const SMART_BIDDING_TYPE = {
    CPA: 'CPA',
    CPI: 'CPI',
    CPG: 'CPG',
    ROAS: 'ROAS',
};

export const CPA_GOALS = {
    CPA_OPTIMIZATION: 'CPA_OPTIMIZATION',
    SCALE_UP_CPA: 'SCALE_UP_CPA',
    SCALE_UP_AND_CPA_OPTIMIZATION: 'SCALE_UP_AND_CPA_OPTIMIZATION',
};

export const CPI_GOALS = {
    CPI_OPTIMIZATION: 'CPI_OPTIMIZATION',
    SCALE_UP_CPI: 'SCALE_UP_CPI',
    SCALE_UP_AND_CPI_OPTIMIZATION: 'SCALE_UP_AND_CPI_OPTIMIZATION',
};

export const CPG_GOALS = {
    CPG_OPTIMIZATION: 'CPG_OPTIMIZATION',
    SCALE_UP_CPG: 'SCALE_UP_CPG',
    SCALE_UP_AND_CPG_OPTIMIZATION: 'SCALE_UP_AND_CPG_OPTIMIZATION',
};

export const ROAS_GOALS = {
    ROAS_OPTIMIZATION: 'ROAS_OPTIMIZATION',
    SCALE_UP_AND_ROAS_OPTIMIZATION: 'SCALE_UP_AND_ROAS_OPTIMIZATION',
};

export const GOALS = {
    ...CPA_GOALS,
    ...CPI_GOALS,
    ...CPG_GOALS,
    ...ROAS_GOALS,
};

export const STRATEGIES_FILTER = {
    SCALE_UP: 'SCALE_UP',
    OPTIMIZATION: 'OPTIMIZATION',
};

export const STRATEGIES_FILTER_VALUES = {
    [SMART_BIDDING_TYPE.CPA]: {
        [STRATEGIES_FILTER.SCALE_UP]: CPA_GOALS.SCALE_UP_CPA,
        [STRATEGIES_FILTER.OPTIMIZATION]: CPA_GOALS.CPA_OPTIMIZATION,
    },
    [SMART_BIDDING_TYPE.CPI]: {
        [STRATEGIES_FILTER.SCALE_UP]: CPI_GOALS.SCALE_UP_CPI,
        [STRATEGIES_FILTER.OPTIMIZATION]: CPI_GOALS.CPI_OPTIMIZATION,
    },
    [SMART_BIDDING_TYPE.CPG]: {
        [STRATEGIES_FILTER.SCALE_UP]: CPG_GOALS.SCALE_UP_CPG,
        [STRATEGIES_FILTER.OPTIMIZATION]: CPG_GOALS.CPG_OPTIMIZATION,
    },
};

export const GOALS_EVENT_LABELS = {
    [CPA_GOALS.SCALE_UP_CPA]: 'Scale Up',
    [CPA_GOALS.CPA_OPTIMIZATION]: 'Optimization',
    [CPI_GOALS.SCALE_UP_CPI]: 'Scale Up',
    [CPI_GOALS.CPI_OPTIMIZATION]: 'Optimization',
    [CPG_GOALS.SCALE_UP_CPG]: 'Scale Up',
    [CPG_GOALS.CPG_OPTIMIZATION]: 'Optimization',
    [ROAS_GOALS.ROAS_OPTIMIZATION]: 'Optimization',
};

export const RULE_TYPE_EVENT_LABELS = {
    CPA: 'CPA',
    CPI: 'CPI',
    COST_PER_GOAL: 'CPG',
    ROAS: 'ROAS',
};

export const ENTITY_TYPES = {
    CAMPAIGN: 'CAMPAIGN',
    ADGROUP: 'ADGROUP',
};

export const GOALS_ROAS = {
    GOALS_ROAS_1: 'GOALS_ROAS_1',
    GOALS_ROAS_7: 'GOALS_ROAS_7',
    GOALS_ROAS_14: 'GOALS_ROAS_14',
};

export const isRoasSmartBidding = (goal) => {
    return Object.values(ROAS_GOALS).includes(goal);
};

export const AVAILABLE_CHART_COLUMNS = [
    { prop: 'ttr', title: 'headers.ttr.title', isPercentageFormat: true },
    { prop: 'cr', title: 'headers.cr.title', isPercentageFormat: true },
    { prop: 'impressions', title: 'headers.impressions.title', isNumberFormat: true },
    { prop: 'cpt', title: 'headers.averageCPT.title' },
    { prop: 'installs', title: 'columns.installs', isNumberFormat: true },
    { prop: 'redownloads', title: `headers.redownloads.title`, isNumberFormat: true },
    { prop: 'newDownloads', title: `headers.newDownloads.title`, isNumberFormat: true },
    { prop: 'spend', title: 'columns.spend' },
    { prop: 'taps', title: 'columns.taps', isNumberFormat: true },
    { prop: 'numberOfInstall', title: 'headers.attrInstalls.title', isNumberFormat: true, isAttributionColumn: true },
    { prop: 'cpi', title: 'headers.averageCPI.title', isAttributionColumn: true },
    { prop: 'numberOfGoal', title: 'headers.numberOfGoals.title', isNumberFormat: true, isAttributionColumn: true },
    { prop: 'goalRevenue', title: 'headers.goalsRevenue.title', isAttributionColumn: true },
    { prop: 'goalsRate', title: 'headers.goalsRate.title', isPercentageFormat: true, isAttributionColumn: true },
    { prop: 'costPerGoal', title: 'headers.costPerGoal.title', isAttributionColumn: true },
];
