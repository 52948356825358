{
    "AE": "United Arab Emirates",
    "AL": "Albania",
    "AR": "Argentina",
    "AT": "Austria",
    "AU": "Australia",
    "AZ": "Azerbaijan",
    "BE": "Belgium",
    "CA": "Canada",
    "CH": "Switzerland",
    "CL": "Chile",
    "CN": "China",
    "CO": "Colombia",
    "CZ": "Czech Republic",
    "DE": "Germany",
    "DK": "Denmark",
    "EC": "Ecuador",
    "EG": "Egypt",
    "ES": "Spain",
    "FI": "Finland",
    "FR": "France",
    "GB": "United Kingdom",
    "GR": "Greece",
    "HK": "Hong Kong",
    "HR": "Croatia",
    "HU": "Hungary",
    "ID": "Indonesia",
    "IE": "Ireland",
    "IL": "Israel",
    "IN": "India",
    "IT": "Italy",
    "JO": "Jordan",
    "JP": "Japan",
    "KH": "Cambodia",
    "KR": "Korea, Republic Of",
    "KW": "Kuwait",
    "KZ": "Kazakhstan",
    "LB": "Lebanon",
    "MO": "Macao",
    "MX": "Mexico",
    "MY": "Malaysia",
    "NL": "Netherlands",
    "NO": "Norway",
    "NZ": "New Zealand",
    "OM": "Oman",
    "PE": "Peru",
    "PH": "Philippines",
    "PK": "Pakistan",
    "PL": "Poland",
    "PT": "Portugal",
    "QA": "Qatar",
    "RO": "Romania",
    "RU": "Russia",
    "SA": "Saudi Arabia",
    "SE": "Sweden",
    "SG": "Singapore",
    "TH": "Thailand",
    "TW": "Taiwan",
    "PA": "Panama",
    "PY": "Paraguay",
    "BR": "Brazil",
    "DO": "Dominican Republic",
    "SV": "El Salvador",
    "HN": "Honduras",
    "BO": "Bolivia",
    "GT": "Guatemala",
    "CR": "Costa Rica",
    "UA": "Ukraine",
    "US": "United States",
    "VN": "Viet Nam",
    "ZA": "South Africa",
    "AM": "Armenia",
    "BH": "Bahrain",
    "BG": "Bulgaria",
    "CY": "Cyprus",
    "DZ": "Algeria",
    "EE": "Estonia",
    "GH": "Ghana",
    "IQ": "Iraq",
    "IS": "Iceland",
    "KE": "Kenya",
    "KG": "Kyrgyzstan",
    "LK": "Sri Lanka",
    "LU": "Luxembourg",
    "LV": "Latvia",
    "MA": "Morocco",
    "MN": "Mongolia",
    "NP": "Nepal",
    "SI": "Slovenia",
    "SK": "Slovakia",
    "TR": "Türkiye",
    "UZ": "Uzbekistan",
    "active": "Active",
    "disabled": "Disabled",
    "multiple": "Multiple",
    "regions": {
        "africaMiddleEast": "Africa, The Middle East, and India",
        "asiaPacific": "Asia Pacific",
        "europe": "Europe",
        "latinAmerica": "Latin America",
        "northAmerica": "The United States and Canada"
    },
    "searchCountry": "Search for country",
    "selectCountries": "Select countries",
}
