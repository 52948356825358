{
    "buttons": {
        "OK": "OK",
        "actions": "액션",
        "adScheduling": "Ad Scheduling",
        "add": "추가하기",
        "addAsKeyword": "Keyword로 추가",
        "addAsNegativeKeyword": "Negative Keywords로 추가",
        "addKeywords": "Keywords 추가하기",
        "addKeywordsToAdGroup": "Keyword를 Ad Group에 추가하기",
        "addNegativeKeywords": "제외 키워드 추가",
        "addSlackChannel": "Slack Channel 추가",
        "apply": "적용하기",
        "assignCategory": "카테고리 지정",
        "audience": "Audience",
        "automationCart": "자동화 카트",
        "automationRule": "Automation Rule",
        "back": "Back",
        "cancel": "취소",
        "change": "변경",
        "chart": "차트",
        "choose": "선택",
        "clear": "지우기",
        "clearAll": "모두 지우기",
        "close": "닫기",
        "collapseAll": "모두 축소",
        "confirm": "확인하기",
        "connectNow": "지금 연결하기",
        "copy": "복사하기",
        "create": "새로 만들기",
        "createAdGroup": "Ad group 만들기",
        "createAnother": "추가적으로 만들기",
        "createAutomationRule": "Automation규칙 만들기",
        "createCampaign": "캠페인 만들기",
        "createConversion": "Conversion 만들기",
        "createCreativeSet": "크리에이티브 세트 만들기",
        "createGoal": "목표 만들기",
        "createIntegration": "통합 만들기",
        "createKeyword": "키워드 만들기",
        "createNegativeKeyword": "제외 키워드 만들기",
        "createSearchTabAutomation": "검색 탭 자동화 생성",
        "delete": "삭제",
        "discard": "폐기",
        "discardDraft": "초안 삭제",
        "dismiss": "해지",
        "duplicate": "중복",
        "edit": "수정하기",
        "goalSelection": "Goal 설정",
        "label": "라벨",
        "learn": "학습",
        "linkItLater": "나중에 연결합니다.",
        "logs": "로그조회",
        "next": "Next",
        "openAutomationCart": "자동화 카트 열기",
        "refresh": "새로 고침",
        "remove": "제거하기",
        "save": "저장하기",
        "saveAndClose": "저장 및 닫기",
        "templates": "템플릿",
        "tryAgain": "다시 시도",
        "update": "업데이트",
        "addNew": "새 항목 추가",
        "adjust": "조정",
        "collapse": "접기",
        "createAd": "광고 만들기",
        "done": "완료",
        "expand": "확장",
        "find": "찾기",
        "joinBeta": "베타 참여",
        "learnMore": "자세히 알아보기",
        "loadMore": "더 보기",
        "newTab": "새 탭",
        "openInNewTab": "새 브라우저 탭에서 열기",
        "replay": "재생",
        "seeExample": "예제 보기",
        "seeAll": "모두 보기",
        "send": "보내기",
        "shareOn": "공유",
        "start": "시작",
        "checkStatus": "상태 확인",
        "contactSales": "영업팀에 문의 🚀",
        "continueToPreview": "미리 보기 계속하기",
        "copyUrl": "URL 복사",
        "reloadPage": "지금 다시 로드"
    },
    "columns": {
        "CR": "Conversion Rate (CR)",
        "TTR": "TTR",
        "action": "Actions(실행내용)",
        "actionType": "작업 유형",
        "active": "Active",
        "adChannel": "광고 채널",
        "adGroupId": "Ad Group Id",
        "adGroupName": "ad group name",
        "allCampaigns": "All Campaigns",
        "allKeywords": "All Keywords",
        "appliedData": "적용대상",
        "automationLevel": "자동화 수준",
        "averageCPA": "Average CPA",
        "averageCPT": "Average CPT",
        "avgCPA": "Avg CPA",
        "avgCPT": "Avg CPT",
        "bidAmount": "비딩가격",
        "broadMatch": "Broad Match",
        "budget": "예산",
        "campaignID": "Campaign ID",
        "campaignName": "Campaign Name",
        "campaignType": "캠페인 유형",
        "category": "Category",
        "chance": "Chance",
        "change": "변경",
        "conditions": "조건",
        "conversionRates": "Conversion Rates",
        "conversions": "Conversions",
        "cpa": "CPA",
        "cpaGoal": "CPA Goal",
        "cpm": "CPM",
        "cpt": "CPT",
        "cptBid": "CPT Bid",
        "cr": "Conversion Rate (CR)",
        "creativeSetId": "Creative Set ID",
        "dataCheckRange": "데이터확인 기간 선택",
        "dataComparisonRange": "Data Comp. 범위",
        "dateOccured": "생성일자",
        "defaultCPMBid": "기본 CPM 입찰가",
        "defaultCPTBid": "Default CPT Bid",
        "defaultMaxCpmBid": "기본 최대 CPM 입찰가",
        "defaultMaxCptBid": "Default Max CPT Bid",
        "difficultyRank": "유기적 난이도",
        "email": "Email",
        "emailNotifier": "이메일 알림",
        "exactMatch": "Exact Match",
        "excludeReAttribution": "재어트리뷰션 제외",
        "goal": "목표",
        "goals": "목표달성건수",
        "impressions": "Impressions",
        "includeReAttribution": "다시 어트리뷰션 포함",
        "includedInGoal": "Included in Goal",
        "install": "Installs",
        "installs": "총 다운로드 수",
        "invoicingOption": "Invoicing Option",
        "iphoneChance": "iPhone Chance",
        "keyword": "키워드",
        "keywordId": "Keyword Id",
        "keywords": "키워드",
        "matchSource": "Match Source",
        "matchType": "Match Type",
        "maxCPTBid": "Max CPT Bid",
        "maxCpmBid": "최대 CPM 입찰가",
        "name": "이름",
        "negativeKeywords": "제외 키워드",
        "newDownloadsShare": "새 다운로드 공유",
        "numberOfApps": "앱 수",
        "numberOfIphoneApps": "Number of IPhone Apps",
        "onlyReAttribution": "재설치만",
        "organicRank": "Organic Rank",
        "passive": "일시정지",
        "password": "Password",
        "popularity": "Popularity",
        "popularityRank": "Popularity",
        "queryType": "쿼리 유형",
        "reAttrType": "재 어트리뷰션 유형",
        "reAttribution": "재 어트리뷰션",
        "reSend": "재발송",
        "recover": "복원하기",
        "redownloadsShare": "재다운로드 비중(%)",
        "restrictionEntityType": "쿼리 유형",
        "restrictionLevel": "제한 수준",
        "ruleActionFrequency": "작업 빈도",
        "ruleControlFrequency": "제어 빈도",
        "ruleId": "규칙",
        "ruleLogState": "실행 상태",
        "ruleName": "규칙이름",
        "ruleState": "규칙 상태",
        "searchMatch": "Search Match",
        "searchTerm": "Search Terms",
        "spend": "실집행금액",
        "state": "규칙적용상태",
        "status": "연동상태",
        "storefront": "국가(마켓)",
        "taps": "Taps",
        "toBeAdded": "추가 예정",
        "ttr": "TTR",
        "withOutReAttribution": "신규만",
        "withReAttribution": "신규+재설치",
        "allAdGroups": "모든 광고 그룹",
        "clicks": "클릭 수",
        "dailyCap": "일일 예산",
        "label": "라벨",
        "lifetimeBudget": "평생 예산",
        "ltv": "LTV",
        "newBid": "신규 입찰",
        "oldBid": "이전 입찰",
        "percentage": "백분율",
        "roas": "ROAS",
        "source": "출처",
        "updateTime": "업데이트 시간",
        "newBudget": "새 예산",
        "oldBudget": "이전 예산"
    },
    "common": {
        "ADD_AS_KEYWORD": "Keyword로 추가",
        "ADD_AS_NEGATIVE_KEYWORD": "Negative Keyword로 추가",
        "ALL": "ALL",
        "AND": "AND",
        "CHANGE_BID": "비딩가 변경",
        "CHANGE_BUDGET": "예산 변경",
        "CHANGE_TOTAL_BUDGET": "평생 예산 변경",
        "DECREASE": "다음만큼 감소",
        "EMAIL_NOTIFICATION": "이메일 알림",
        "GREATER_THAN": "보다 큰",
        "INCREASE": "다음만큼 증가",
        "IN_RANGE": "범위 내",
        "LESS_THAN": "미만",
        "LIMIT": "제한: {limit}",
        "NOT_IN_RANGE": "범위 내 없음",
        "PAUSE": "캠페인 중지",
        "SET": "로 설정",
        "SET_TO_CPA": "평균 CPA로 설정",
        "SET_TO_CPT": "평균 CPT로 설정",
        "SLACK_NOTIFICATION": "Slack 알림",
        "UTC": "UTC",
        "actionFrequency": "작업 빈도",
        "activate": "Activate",
        "adGroup": "Ad Group",
        "adGroupSettings": "Ad Group Settings",
        "adGroupStatus": "Ad Group 상태",
        "adGroups": "광고 그룹",
        "adPerformance": "광고 성과",
        "adsStructure": "광고 구조",
        "ageRange": "나이 범위",
        "all": "ALL",
        "allUsers": "All users",
        "and": "및",
        "apiKey": "Api Key",
        "app": "App",
        "appName": "App Name",
        "apps": "App(앱)",
        "areaChart": "영역 차트",
        "attribution": "어트리뷰션 파트너",
        "audience": "Audience",
        "automationEntityList": "자동화 엔티티 목록",
        "available": "사용 가능",
        "between": "사이",
        "bidAmountHistory": "입찰 내역",
        "budgetOrder": "Budget Order",
        "campaign": "Campaign",
        "campaignSetting": "캠페인 설정",
        "campaignStatus": "캠페인 상태",
        "campaigns": "캠페인",
        "categories": {
            "BRAND": "#브랜드",
            "CATEGORY": "#카테고리",
            "COMPETITOR": "#경쟁자",
            "DISCOVERY": "발견 #디스커버리",
            "UNASSIGNED": "#할당되지 않음",
            "description": {
                "AGE": "특정 성별 또는 연령대를 타겟팅하는 경우, Apple은 '광고 추적 제한'(LAT) 옵션이 '켜짐'으로 설정된 사용자에게는 광고를 표시하지 않습니다.",
                "BRAND": "앱 또는 회사 이름을 검색하는 고객에 초점을 맞춘 브랜드 캠페인입니다.",
                "BROAD": "확장 검색은 단수, 복수, 맞춤법 오류, 동의어, 관련 검색, 해당 용어가 전체 또는 일부 포함된 구문 등 키워드의 관련성 높은 유사 검색어에 광고가 게재되도록 합니다.",
                "COMPETITOR": "경쟁사 캠페인은 동일 또는 관련 앱 카테고리 내에서 귀하의 앱과 유사한 앱을 검색하는 고객에 초점을 맞춥니다.",
                "DISCOVERY": "발견 캠페인은 앱과 관련된 새로운 검색어를 찾는 데 중점을 둡니다.",
                "EXACT": "광고가 게재될 수 있는 검색을 가장 엄격하게 관리하려면 일치 검색을 사용하세요.",
                "GENDER": "특정 성별 또는 연령대를 타겟팅하는 경우, Apple은 '광고 추적 제한'(LAT) 옵션이 '켜짐'으로 설정된 사용자에게는 광고를 표시하지 않습니다.",
                "GENERIC": "일반 캠페인은 앱의 카테고리와 앱의 기능을 설명하는 브랜드가 아닌 용어를 검색하는 고객에 초점을 맞춥니다.",
                "LAT": "특정 성별 또는 연령대를 타겟팅하는 경우, Apple은 '광고 추적 제한'(LAT) 옵션이 '켜짐'으로 설정된 사용자에게는 광고를 표시하지 않습니다."
            }
        },
        "chart": "차트",
        "cohortAnalysis": "코호트 분석",
        "columnChart": "열 차트",
        "comingSoon": "Coming Soon",
        "computed": "계산",
        "confirm": "확인하기",
        "contactUsTerm1": "Contact Us",
        "contactUsTerm2": "도움이 필요하면 언제든지 연락주세요",
        "contains": "포함 사항",
        "copy": "복사하기",
        "countriesAndRegions": "국가와 지역",
        "country": "국가",
        "countryCodes": "Country Codes",
        "createdAt": "규칙생성일자",
        "creativeSetSettings": "크리에이티브 세트 설정",
        "creativeSets": "Creative Sets",
        "currency": "화폐종류",
        "currencySymbol": "기호 ({currency})",
        "customColumns": "사용자 지정 열",
        "customConversion": "사용자 정의 Conversion",
        "customerType": "Customer Type",
        "daily": "Daily(24시간 마다)",
        "dashboard": "대시보드",
        "dataRange": "데이터 범위",
        "defaultColumns": "기본 열",
        "delete": "삭제",
        "devices": "디바이스",
        "displayStatus": "디스플레이 상태",
        "download": "다운로드",
        "duplicate": "중복",
        "editColumns": "Edit Columns",
        "enable": "캠페인 활성",
        "enabled": "캠페인 활성",
        "endDate": "종료일",
        "enterNewBindAmount": "새로운 최대입찰가 입력하기",
        "equals": "같음",
        "events": "Events",
        "failed": "실패",
        "filter": "필터",
        "filters": "필터",
        "formula": "공식",
        "frequency": "빈도",
        "goal": "목표",
        "greaterThan": "보다 큰",
        "hourly": "Hourly(1시간 마다)",
        "in": "In",
        "inAppConversions": "인앱 전환",
        "inAppEventsSettings": "in-app event 설정하기",
        "inQueue": "대기열에 있음",
        "info": "정보",
        "insights": "인사이트",
        "integratedOrgs": "통합 캠페인 그룹",
        "integration": "Integration",
        "integrationStatus": "통합 상태",
        "keywordStatus": "키워드 상태",
        "keywords": "키워드",
        "lessThan": "미만",
        "lineChart": "라인 차트",
        "loading": "Loading...",
        "logs": "로그조회",
        "message": "메시지",
        "monthly": "Monthly",
        "nAdGroup": "광고 그룹 없음 | {n} 광고 그룹 | {n} 광고 그룹",
        "nAdGroupsSelected": "선택된 광고 그룹 없음 | {n} 광고 그룹 선택됨 | {n} 광고 그룹 선택됨",
        "nApp": "앱 없음 | {n} 앱 | {n} 앱",
        "nAppsSelected": "선택된 앱 없음 | {n} 앱 선택됨 | {n} 앱 선택됨",
        "nCampaign": "캠페인 없음 | {n} 캠페인 | {n} 캠페인",
        "nCampaignsSelected": "선택된 캠페인 없음 | {n} 캠페인 선택됨 | {n} 캠페인 선택됨",
        "nCountriesSelected": "국가 선택 안 함 | {n} 국가 선택됨 | {n} 국가 선택됨",
        "nCountry": "국가 없음 | {n} 국가 | {n} 국가",
        "nCreativeSet": "크리에이티브 세트 없음 | {n} 크리에이티브 세트 | {n} 크리에이티브 세트",
        "nGoal": "목표 없음 | {n} 목표 | {n} 목표",
        "nKeyword": "키워드 없음 | {n} 키워드 | {n} 키워드",
        "nKeywordsSelected": "선택된 키워드 없음 | {n} 키워드 선택됨 | {n} 키워드 선택됨",
        "nRulesSelected": "선택된 규칙 없음 | {n} 규칙 선택됨 | {n} 규칙 선택됨",
        "negativeKeywords": "제외 키워드",
        "newUsers": "New Users",
        "no": "No",
        "noData": "데이터 없음",
        "noFiltersApplied": "아직 적용된 필터가 없습니다.",
        "noGoalSelection": "목표 선택 없음",
        "noIntegration": "통합 없음",
        "noIntegrationAdded": "아직 통합이 추가되지 않았습니다!",
        "notBetween": "Not Between",
        "notContains": "포함되지 않음",
        "notEquals": "Not Equals",
        "operation": "운영",
        "optional": "선택 사항",
        "or": "또는",
        "pause": "캠페인 중지",
        "paused": "캠페인 중지",
        "processing": "처리",
        "ranking": "Ranking",
        "reAttrType": "다시 어트리뷰션 데이터 유형",
        "report": "Report",
        "reportingNote": "보고는 실시간이 아닙니다. 지난 24시간 이내에 수신된 데이터는 보고에 반영되지 않을 수 있습니다.",
        "retry": "다시 시도",
        "returningUsers": "재방문 사용자",
        "rules": "규칙",
        "running": "운영중",
        "savedFilters": "저장된 필터",
        "scheduleAds": "광고 예약",
        "search": "검색",
        "searchApp": "검색 앱",
        "searchByMetric": "검색항목 입력",
        "searchByName": "이름으로 검색",
        "searchByNameOrId": "이름 또는 ID로 검색",
        "searchKeyword": "Search Keyword",
        "searchTerms": "Search Terms(검색어)",
        "select": "선택",
        "selectACampaign": "캠페인 선택하기",
        "selectAnAdGroup": "Ad group 선택하기",
        "selectCampaign": "Campaign 선택하기",
        "selectCategory": "카테고리 선택",
        "sessionHasExpired": "세션이 만료된 경우 로그인 페이지로 리디렉션됩니다.",
        "settings": "설정",
        "showSteps": "연동절차 보기",
        "standardInvoice": "Standard Invoice",
        "startDate": "시작일",
        "status": "연동상태",
        "successful": "성공",
        "switchCurrencyView": "클릭하여 통화 보기 전환",
        "templates": "템플릿",
        "timeZone": "시간대",
        "timezoneNote": "모든 날짜 및 시간의 표준 시간대",
        "total": "Total",
        "totalSummary": "총 요약",
        "update": "업데이트",
        "updateKeywordBidAmount": "Keyword 비딩단가 업데이트",
        "upload": "Upload",
        "uploadedIntegrations": "업로드된 통합",
        "usersOfMyOtherApps": "나의 다른 앱을 사용한 유저",
        "view": "보기",
        "warning": "경고",
        "weekly": "Weekly",
        "yes": "Yes",
        "youWillBeRedirected": "{autoRedirectTimer} 으로 리디렉션됩니다.",
        "ADD_LABEL": "레이블 추가",
        "APPLY_SMART_NOTIFICATION": "스마트 알림 적용",
        "APPLY_SMART_BID_SWEET_SPOT": "스마트 입찰 스위트 스팟 적용",
        "CHANGE_CPA_GOAL": "CPA 목표 변경",
        "CHANGE_DAILY_CAP": "일일 예산 변경",
        "CONTAINS": "포함 사항",
        "NOT_CONTAINS": "포함되지 않음",
        "accessRequired": "액세스 필요",
        "accountIntegrationsExpired": "{orgName} 에 대한 유효한 통합 정보가 없습니다. 계정에 대한 유효한 통합 정보를 제공하거나 문의해 주세요. 지금 통합하려면 이 경고를 클릭하세요!",
        "accounts": "캠페인 그룹",
        "activeOrganization": "활성 조직: {org}",
        "adPlacement": "광고 게재 위치",
        "adPlacements": {
            "APPSTORE_PRODUCT_PAGES_BROWSE": "제품 페이지--검색 중",
            "APPSTORE_SEARCH_RESULTS": "검색 결과",
            "APPSTORE_SEARCH_TAB": "검색 탭",
            "APPSTORE_TODAY_TAB": "오늘 탭"
        },
        "ads": "광고",
        "basic": "기본",
        "bidSources": {
            "DASHBOARD": "대시보드",
            "RULE": "자동화",
            "EXTERNAL": "외부",
            "SMART_BID": "스마트 입찰",
            "BUDGET_ALLOCATION": "예산 할당",
            "QUICK_AUTOMATION": "빠른 자동화"
        },
        "bulkUpdate": "대량 업데이트",
        "chartType": "차트 유형",
        "currencyCode": "코드 ({code})",
        "currencyView": "통화 보기",
        "dailyBudget": "일일 예산",
        "downloadCsv": "데이터를 CSV로 다운로드",
        "for": "에 대한",
        "from": "에서",
        "gender": "성별",
        "here": "여기",
        "hideChart": "차트 숨기기",
        "integrationId": "통합 ID:",
        "integrations": {
            "partners": {
                "adjust": "조정",
                "airbridge": "에어브릿지",
                "appsflyer": "앱스플라이어",
                "branch": "Branch",
                "custom": "사내",
                "kochava": "Kochava",
                "singular": "단수",
                "tenjin": "Tenjin"
            }
        },
        "is": "는",
        "isNot": "는",
        "more": "자세히 보기",
        "multiLoginDetected": "다른 브라우저에서 로그인 활동이 감지되면 로그인 페이지로 리디렉션됩니다.",
        "nAccount": "캠페인 그룹 없음 | {n} 캠페인 그룹 | {n} 캠페인 그룹",
        "nAccountsSelected": "선택된 캠페인 그룹 없음 | {n} 캠페인 그룹 선택됨 | {n} 캠페인 그룹 선택됨",
        "new": "신규",
        "noBidChangeInRange": "선택한 시간대에 입찰가 변동 없음",
        "notScheduled": "예약되지 않음",
        "onHold": "보류 중",
        "organization": "Campaign Group",
        "organizations": "캠페인 그룹",
        "passed": "통과",
        "preparing": "준비",
        "processingLevel": "처리 중 {level}...",
        "promotedApp": "프로모션 앱",
        "reason": "이유",
        "requestTime": "요청 시간",
        "resolved": "해결됨",
        "scheduled": "예약됨",
        "showChart": "차트 보기",
        "smartBidding": "스마트 입찰",
        "stop": "중지",
        "stopped": "중지됨",
        "totalCount": "합계 {total}",
        "type": "유형",
        "EMPTY": "해당 없음",
        "add": "추가",
        "amount": "금액",
        "beta": "베타",
        "chartFilters": {
            "customColumnNotApplicable": "사용자 지정 열은 일별 차트 보기에서만 적용할 수 있습니다.",
            "monthlyChartNotAvailable": "사용자 지정 열 필드를 선택하면 월별 차트 옵션을 사용할 수 없습니다.",
            "weeklyChartNotAvailable": "사용자 지정 열 필드를 선택하면 주별 차트 옵션을 사용할 수 없습니다."
        },
        "cpp": "CPP",
        "dailyBudgetHistory": "일일 예산 내역",
        "language": "언어",
        "splineChart": "스플라인 차트",
        "makeSelection": "선택해 주세요",
        "multiple": "여러",
        "nAdPlacementSelected": "광고 게재 위치 선택됨 없음 | {n} 광고 게재 위치 선택됨 | {n} 광고 게재 위치 선택됨",
        "nLabelSelected": "선택된 레이블 없음 | {n} 레이블 선택됨 | {n} 레이블 선택됨",
        "nStatusSelected": "선택된 상태 없음 | {n} 상태 선택됨 | {n} 상태 선택됨",
        "nAddedAsKeywordSelected": "키워드가 선택됨으로 추가되지 않음 | {n} 키워드가 선택됨으로 추가됨 | {n} 키워드가 선택됨으로 추가됨",
        "nDeviceClassSelected": "장치 없음 | {n} 장치 | {n} 장치",
        "nGenderSelected": "성별 없음 | {n} 성별 | {n} 성별",
        "nCustomerTypeSelected": "고객 유형 없음 | {n} 고객 유형 | {n} 고객 유형",
        "nBidStrengthSelected": "입찰 강점 없음 | {n} 입찰 강점 | {n} 입찰 강점",
        "nCreativeTypeSelected": "크리에이티브 유형 없음 | {n} 크리에이티브 유형 | {n} 크리에이티브 유형",
        "nCPPSelected": "선택된 CPP 없음 | {n} CPP 선택됨 | {n} CPP 선택됨",
        "newFeature": "새로운 기능을 사용할 수 있습니다!",
        "noBudgetChangeInRange": "선택한 시간 범위에서 일일 예산이 변경되지 않았습니다.",
        "promoText": "프로모션 텍스트",
        "updated": "업데이트"
    },
    "components": {
        "appSearch": {
            "searchFailedWithErrorCode": "다음 오류 코드와 함께 검색하지 못했습니다:",
            "searchForAnyAppByName": "앱이름 또는 앱 ID 검색"
        },
        "campaignGroup": {
            "chooseCampaignGroup": "캠페인 그룹 선택",
            "selectAnIntegration": "Integration 선택하기"
        },
        "common": {
            "apply": "적용하기",
            "columnsToHide": "항목 숨김",
            "columnsToShow": "항목 표시",
            "resetToDefaults": "초기값으로 리셋하기",
            "saveAsPreset": "프리셋으로 저장"
        },
        "countrySelector": {
            "selectAStoreFront": "국가마켓 선택하기"
        },
        "dataLevels": {
            "AD_GROUP": "Ad Group",
            "APP": "App(앱)",
            "CAMPAIGN": "캠페인",
            "CREATIVE_SET": "Creative Set",
            "KEYWORD": "키워드",
            "NEGATIVE": "Negative keyword",
            "SEARCH_TERM": "Search Term",
            "ACCOUNT": "캠페인 그룹",
            "AD": "광고",
            "CPP": "CPP"
        },
        "dateRangeSelector": {
            "last2Weeks": "최근 2주",
            "last3Mths": "최근 3개월",
            "last6Mths": "최근 6개월",
            "last7Days": "Last Week",
            "lastMonth": "지난 달",
            "lastWeek": "지난 주",
            "lastYear": "작년",
            "pickARange": "범위를 선택하세요",
            "thisMonth": "이번 달",
            "thisWeek": "이번 주",
            "today": "오늘",
            "week": "주",
            "yesterday": "어제",
            "last30Days": "지난 30일"
        },
        "goals": {
            "categories": {
                "ENGAGEMENT": "참여",
                "GAME_EVENT": "게임 이벤트",
                "OTHER": "기타",
                "PURCHASE": "구매",
                "SUBSCRIPTION": "구독",
                "TRIAL": "평가판"
            }
        },
        "keywordAddition": {
            "BROAD": "Broad Match",
            "EXACT": "정확한",
            "keywordType": "키워드 유형",
            "negative": "부정적"
        },
        "loadingScreen": {
            "loadingSearchAds": "Search Ads by Mobile Action | Loading..."
        },
        "maColumnsManager": {
            "clearAll": "모두 지우기",
            "dragDrop": "드래그 앤 드롭으로 주문 변경",
            "duplicatePresetName": "이미 사용 중인 프리셋 이름을 변경해 주세요!",
            "editColumnsFor": "열 편집 대상 {table}",
            "nColumnsSelected": "{n} columns selected",
            "saveAsPreset": "프리셋으로 저장",
            "searchByMetric": "검색항목 입력",
            "selectAll": "모두 선택",
            "makePrivate": "비공개로 설정",
            "makePublic": "공개하기",
            "privatePreset": "비공개 프리셋",
            "privatePresets": "개인 프리셋",
            "publicPreset": "공개 프리셋",
            "publicPresets": "공개 프리셋"
        },
        "saAuthHeader": {
            "adBlockersAreKnowToBlock": "Ad blocker는 단어 광고가 포함된 모든 것을 차단하는 것으로 알려져 있습니다. 따라서 예상치 못한 문제가 발생할 수 있습니다. 원활한 사용을 위해 서비스 이용시 해당기능을 비활성화해 주세요.",
            "ifYouAreUsingAdBlock": "Ad block을 사용하는 경우 더 나은 환경을 위해 Ad block을 사용하지 않도록 설정해 주십시오."
        }
    },
    "messages": {
        "errors": {
            "accountHasReadOnlyAccess": "필요한 권한이 없는 경우 통합에 관리자 액세스 권한이 있는지 확인하세요.",
            "anErrorOccurredFetchingRules": "데이터를 가져오는 동안 오류가 발생했습니다. {msg}",
            "checkPermission": "사용 권한을 확인하세요!",
            "dataIsTooBigToSort": "데이터가 너무 커서 정렬할 수 없습니다. 데이터를 필터링하여 정렬하세요.",
            "dataNotFound": "데이터를 찾을 수 없습니다. 대시보드에서 데이터를 동기화하세요.",
            "decimalFormatIsNotValid": "숫자 형식이 올바르지 않습니다. 소수점 구분 기호는 점(0.00)입니다.",
            "duplicateInputValidatorMessage": "값은 {min} 와 {max}",
            "duplicatedKeywords": "중복된 Keyword입니다.",
            "errorOccurredKeywordAddition": "<strong>({name})<\/strong> 에 키워드를 추가하는 동안 오류가 발생했습니다. 오류는 <i>{error}<\/i>",
            "failedAdGroupReport": "Ad Groups Report를 로딩하는데 실패했습니다.",
            "failedChangeState": "{level}변경에 실패했습니다. {msg}",
            "failedKeywordReport": "Keyword Report를 가져오는데 실패했습니다.",
            "failedToAdd": "{type} 을 추가하지 못했습니다: {msg}",
            "failedToAddKeywords": "Keyword를 추가하는데 실패했습니다.",
            "failedToCreateAdGroup": "Ad group을 생성에 실패했습니다.",
            "failedToCreateAdGroupPlease": "Ad group을 만들지 못했습니다. 입력한 내용을 다시 한번 확인하고, 문제가 지속되면 알려주십시오.",
            "failedToFetchAdGroup": "Ad group을 불러오는데 실패했습니다.",
            "failedToFetchCampaign": "캠페인을 불러오는데 실패했습니다.{msg}",
            "fieldIsRequired": "이 필드는 필수 입력 사항입니다.",
            "fieldMustBeGreaterThan0": "이 필드는 0보다 커야 합니다.",
            "fixTheError": "오류를 수정합니다.",
            "invalidNumberFormat": "유효하지않은 숫자형식입니다.",
            "keywordLimitExceedForAdGroup": "Ad group의 Keyword 제한을 초과하였습니다. Ad group Keyword 제한은 1000개입니다. 목록 및 Ad group의 총 Keyword 수가 제한을 초과하지 않는지 확인한 후 다시 시도해 주시기 바랍니다.",
            "noActiveOrgSelected": "활성 조직을 찾을 수 없습니다. 페이지를 새로 고쳐주세요.",
            "servicesError": "서비스에 오류가 발생했습니다. 입력 내용을 확인하시기 바랍니다.",
            "somethingWentWrong": "무언가 잘못 되었습니다.",
            "thereAreKeywordsOnTheList": "목록에 있는 Keyword 중 캠페인 및 Ad group에 이미 추가된 Keyword가 있습니다.",
            "unexpectedError": "예기치 않은 오류가 발생했습니다. 오류가 지속되면 문의해 주세요. {msg}",
            "youAreNotAuthorizedToThisOperation": "이 작업에 대한 권한이 없습니다.",
            "apiIntegration": "Apple 검색 광고 계정이 유효하지 않은 경우 다음에서 확인할 수 있습니다.",
            "noAnyOrgOnAccount": "계정에서 캠페인 그룹을 찾을 수 없습니다. 페이지를 새로고침하거나 관리자에게 문의하세요.",
            "timeoutMessage": "서버가 응답하는 데 시간이 오래 걸리는 것 같습니다. 이는 연결 상태가 좋지 않거나 서버에 오류가 발생했기 때문일 수 있습니다. 잠시 후 다시 시도해 주세요."
        },
        "info": {
            "actionTypeInfo": "사전 정의된 규칙을 통해 조치를 구현합니다.",
            "adBlockersIsNotEnabled": "이 사이트에 대해 ad blocker가 설정되어 있는지 확인해 주세요. 해당문제가 지속되면 연락 주십시오.",
            "addGoalTabInfo": "이 탭을 선택하면 MMP 어트리뷰션 데이터에 대한 새 목표를 설명할 수 있습니다.",
            "addingKeywordsToAdGroup": "Ad group에 Keyword를 추가중..",
            "addingNegativeKeywordsToAdGroup": "Ad group에 Negative Keyword를 추가중..",
            "addingNegativeKeywordsToCampaign": "캠페인에 제외 키워드 추가하기...",
            "almostThere": "거의 다 되었어요!",
            "amountMustBeGreaterThan0": "금액은 0보다 커야 합니다.",
            "areYouSureDeleted": "선택한 항목을 삭제하시겠습니까?",
            "automationLevelInfo": "규칙을 설명할 때 자동화 수준이 선택됩니다.<br><br> 예시 앱의 키워드; 이 예의 경우 자동화 수준은 키워드입니다.",
            "budgetCanNotBeEmpty": "예산은 비워 둘 수 없습니다.",
            "budgetValueIsNotValid": "예산 값이 유효하지 않습니다.",
            "campaignNameCanNotBeEmpty": "캠페인 이름은 비워 둘 수 없습니다.",
            "campaignNameShouldBeUniqueForOrg": "캠페인 이름은 조직별로 고유해야 합니다.",
            "chooseOneOrMoreCountries": "하나 이상의 국가 또는 지역을 선택하세요",
            "clickToDetail": "자세히 보려면 클릭하세요...",
            "clickToUpdate": "업데이트하려면 클릭",
            "creatingAdGroup": "ad group 생성 중...",
            "creatingCampaign": "캠페인 만들기...",
            "creatingCreativeSet": "크리에이티브 세트 만들기...",
            "dataIsFetching": "데이터 가져오기...",
            "dataProvidedBy": "Data provided by",
            "doNotHaveAnAccountYet": "아직 계정이 없으신가요?",
            "enterABillingContactEmail": "청구시 사용될 이메일 주소을 입력하세요.",
            "enterABuyerEmail": "담당자 이메일을 입력하세요.",
            "enterABuyerName": "담당자 이름을 입력하세요.",
            "enterAClientName": "회사(사업주체) 이름을 입력하세요.",
            "enterAOrderNumber": "Order number를 입력하세요.",
            "enterAValidBuyerEmail": "유효한 구매자 이메일을 입력하세요.",
            "enterAValidContactEmail": "유효한 연락처 이메일을 입력하세요.",
            "enterAValidEmail": "유효한 이메일 주소를 입력하세요",
            "goalTabInfo": "이 탭을 선택하면 미리 정의된 사용자 지정 전환에 따라 MMP 어트리뷰션 데이터가 포함된 리포팅 데이터가 표시됩니다.",
            "inputValidNumber": "유효한 숫자를 넣어주세요",
            "loadingDataFromServer": "Loading data from server...",
            "max500Row": "*처음 500개 행만 표시되었습니다.",
            "noAuthorizedForAnyOrg": "승인된 캠페인 그룹을 찾을 수 없습니다. 관리자에게 문의하세요.",
            "noGoalTabInfo": "어트리뷰션 데이터가 없거나 사용자 지정 전환을 아직 설명하지 않았습니다.",
            "pleaseEnterAnEmail": "이메일을 입력하세요",
            "pleaseEnterYourPassword": "패스워드를 입력하세요",
            "pleaseFillTheForm": "양식을 작성해 주십시오. 빠르게 연락드리겠습니다!",
            "restrictionLevelInfo": "규칙을 설명할 때 제한 수준이 선택됩니다.<br><br> 예시 앱의 키워드; 이 예의 경우 제한 수준은 앱입니다.",
            "ruleStateInfo": "현재 규칙 상태입니다.",
            "selectCountryOrRegion": "국가 또는 지역을 선택하세요.",
            "signInWithGoogle": "Sign in with Google",
            "signInWithLinkedIn": "Sign in with LinkedIn",
            "somethingHasGoneWrong": "오류가 발생했습니다. 나중에 다시 시도해 주세요.",
            "thePageYouHaveRequested": "요청한 페이지를 찾을 수 없습니다.",
            "youCanAdjustYourBudget": "예산은 캠페인에 집행할 금액을 의미하며, 첫 캠페인 설정에서 필수항목입니다.예산은 언제든지 변경할 수 있습니다.",
            "youCanSafelyCloseThisWindow": "이 창을 안전하게 닫고 메일의 링크를 계속 사용할 수 있습니다.",
            "youCanSearchAndSelectAnApp": "앱을 검색하고 선택하면 사용중인 비딩 키워드의 impression 점유율을 볼 수 있습니다.",
            "youNeedTheCompleteOneMoreStep": "완성하기 위해서는 한 단계를 더 진행해야 합니다.",
            "yourAppWillBePromotedOnTheAppStore": "선택한 해당 국가 및 지역의 앱 스토어에서 캠페인을 시작할 수 있습니다.",
            "continueWithSSO": "SSO로 계속 진행",
            "copyReportLink": "보고서 링크 복사",
            "createAccount": "캠페인 그룹 만들기",
            "creatingAdCreative": "광고 크리에이티브 만들기...",
            "dailyBudgetCanNotBeEmpty": "일일 예산은 비워둘 수 없습니다.",
            "dailyBudgetExplanation": "일일 예산은 한 달 동안 매일 캠페인에 지출할 수 있는 평균 금액입니다. 월별 지출은 일일 예산에서 한 달의 평균 일수를 곱한 금액을 초과할 수 없습니다. 다운로드 수가 많은 날에는 일일 예산액을 초과하여 지출할 수 있습니다.<br><br> 캠페인을 일시 중지하거나 제거하거나 종료 날짜를 설정하지 않는 한 캠페인은 일일 예산액을 기준으로 매월 계속 지출됩니다. 종료 날짜를 설정하면 캠페인의 총 일수에 일일 예산을 더한 금액을 초과하여 지출하지 않습니다.<br><br> 언제든지 일일 예산을 조정할 수 있습니다.",
            "haveToReloadPage": "이 페이지를 로드하는 동안 콘텐츠가 업데이트되었습니다. 이 페이지를 새로 고치거나 10초 후에 다시 로드됩니다.",
            "lifetimeBudgetExplanation": "평생 예산은 캠페인에 지출하고자 하는 총 금액입니다. 캠페인이 실행되기 시작하면 줄일 수 없으며 신규 또는 중복 캠페인에는 사용할 수 없습니다.<br><br> 캠페인을 지속적으로 실행하고 지출을 최적화하여 기회가 많은 시간을 활용하려는 경우 언제든지 일일 예산으로만 전환할 수 있습니다. 전환한 후에는 동일한 캠페인에 대해 평생 예산을 다시 사용할 수 없습니다.",
            "passwordTooShort": "비밀번호가 너무 짧습니다. 8자 이상이어야 합니다.",
            "selectMetrics": "차트에서 볼 메트릭 선택",
            "signInWithApple": "Apple로 로그인",
            "signUpWithYourEmail": "또는 이메일로 가입하세요.",
            "unsupportedDomain": "이 도메인으로 등록할 수 없습니다. 비즈니스 이메일 주소를 사용하여 등록하세요.",
            "youWillNotBeChargedMoreThanYourCampaign": "캠페인에 하루에 지출할 수 있는 최대 금액입니다. 일일 예산에 도달하면 해당 날짜에 광고 게재가 중지되지만 캠페인에 예산이 남아 있는 경우 다음 날 다시 시작됩니다. 특정 날짜에 지출한 금액이 일일 예산을 약간 초과할 수 있습니다. 그러나 캠페인 예산을 초과하는 금액은 청구되지 않습니다."
        },
        "integrateAccountView": {
            "accessDailyRunning": "실행 중인 키워드 광고에 쉽게 접속할 수 있습니다.",
            "automateYourSearchAdsCampaign": "페인을 자동화하세요",
            "connectYourSearchAds": "Search Ads Account과 연결하기",
            "connectYourSearchAdsAccount": "캠페인 시작을 위해 Search Ads Account과 연결하기",
            "createSearchAdsCampaigns": "Search Ads campaigns과 ad groups을 만드세요",
            "getAccessToDetailed": "모든 캠페인에 대한 세부 요약에 접속하세요",
            "getAccessToDetailedInsight": "다양한 국가의 세부 정보를 얻으세요.",
            "getAheadOfYourCompetitors": "업계에서 가장 스마트한 Search Ads 도구를 사용하여 경쟁자보다 앞서 나가세요.",
            "getAnalyticsAbout": "캠페인이 당신의 앱 랭킹에 미치는 영향을 분석하세요",
            "searchDetailedInsight": "순위 키워드에 대한 세부정보를 찾으세요",
            "setAutomationsAndAlerts": "automation규칙과 캠패인에 대한 alert을 설정하세요.",
            "setYourConditions": "당신의 모든 캠페인을 상세히 관리하세요\n자동조건 설정, 캠페인 중지, 비딩값 변경이 모두 가능합니다",
            "withAutomationsYouCan": "Automation 기능을 통해서 다음 작업을 할 수 있습니다.",
            "withKeywordHunt": "Keyword hunt기능을 통해서 다음 작업을 할 수 있습니다.",
            "withSearchAdsYouCan": "Search Ads 기능을 통해서 다음 작업을 할 수 있습니다.",
            "withYourConnectedAppleSearchAdsAccount": "연결된 Apple Search Ads 계정을 사용하여 다음 작업을 수행할 수 있습니다.",
            "youGetAccessToOtherFeatures": "당신은 keyword auction insights, automation rule, keyword hunt와 같은\n다른 메뉴에 접근할 수 있습니다.",
            "youWillGetAccessToOther": "Keyword Auction Insights, Ads Manager와 같은 메뉴에 접속할 수 있습니다.",
            "youWillGetAccessToOthers": "Keyword Auction Insights, Dashboard, Automations와 같은 메뉴에 접속할 수 있습니다."
        },
        "success": {
            "adGroupBidUpdated": "최대 CPT 입찰가 업데이트 성공",
            "apiKeyCopied": "Api Key가 복사되었습니다",
            "keywordBidUpdated": "키워드 입찰가 업데이트 성공",
            "keywordsAddedSuccessfully": "{n}-키워드가 <strong>({name})<\/strong> 에 성공적으로 추가되었습니다.",
            "operationCompleted": "작업이 성공적으로 완료되었습니다.",
            "adCreativeUpdated": "광고 업데이트 성공",
            "cppLinkCopied": "사용자 지정 제품 페이지 링크 복사",
            "dailyBudgetUpdated": "일일 예산 업데이트 성공",
            "linkCopied": "링크 복사됨",
            "operationCompletedWith": "{success}-성공, {warning}-경고 및 {error}-오류로 작업이 완료됩니다.",
            "totalBudgetUpdated": "평생 예산 업데이트 성공"
        },
        "warnings": {
            "adGroupUnrelatedGoalField": "목표와 관련이 없는 광고 그룹 필터가 있습니다.",
            "campaignUnrelatedGoalField": "목표와 관련이 없는 캠페인 필터가 있습니다.",
            "dataIsNotFoundToSort": "정렬할 데이터를 찾을 수 없습니다. 데이터가 로드 중이거나 사용할 수 없습니다.",
            "deviceTypeIPadCanNotBeSelected": "디바이스 유형 iPad를 이 항목에 대해 선택할 수 없습니다. iPhone만 선택하십시오.",
            "discardDraftSure": "이 초안을 삭제하면 되돌릴 수 없습니다.",
            "duplicateAdGroupName": "광고 그룹 이름 {msg} 은 캠페인에서 고유하지 않습니다.",
            "maxDataRange": "{level}의 날짜범위는 최대 180일입니다.현재  데이터는 자동으로 {startDate} ~{endDate}로 설정되었습니다.",
            "mobileScreenWarning": "모바일 보기에서 호환성 문제가 발생할 수 있습니다. 보다 편안한 화면을 사용하는 것이 좋습니다.",
            "noInAppEventsYet": "in-app event연동 미완료",
            "noIntegratedApp": "연동된 앱이 없습니다",
            "noKeywordRankingHistory": "키워드에 대한 키워드 랭킹 기록이 없습니다. {selectedKeywordString}",
            "notAvailableLocations": "여러 국가 또는 지역에 대해 캠페인을 설정한 경우 사용할 수 없습니다.",
            "pleaseSelectAdPlacement": "광고 게재 위치를 선택하세요.",
            "pleaseSelectAnApp": "앱을 선택해 주세요",
            "pleaseSelectAnGoalForFilter": "목표 필터를 구현할 목표를 선택하세요.",
            "promoteOthersAppDisallowed": "Apple 검색 광고 계정으로는 다른 사람의 앱을 홍보할 수 없습니다!",
            "singleCurrencyWarning": "선택한 통화에는 여러 통화가 포함됩니다. 단일 통화에 대한 자동화를 만들 수 있습니다.",
            "usernameExistsGoLogin": "사용자 아이디가 이미 존재합니다. 이 메시지를 클릭하면 로그인 페이지로 이동합니다!",
            "warningOccurredKeywordAddition": "<strong>({name})<\/strong> 에 키워드를 추가하려고 할 때 경고가 발생했습니다. 경고는 <i>{warning}<\/i>",
            "withoutRelatedGoalAndRemoved": "현재 필터로 인해 목표가 필터에서 제거되었습니다.",
            "youAreNotAuthorized": "액세스할 수 있는 권한이 없습니다. 이 문제가 지속되면 연락 주시기 바랍니다.",
            "adGroupCustomError": "오류로 광고 그룹을 만들지 못했습니다, {msg}",
            "campaignDailyCapCanNotExceed": "캠페인 일일 예산은 캠페인 예산을 초과할 수 없습니다. 계속하기 전에 수정하십시오.",
            "pleaseSelectAGoalForFilter": "이 필터를 적용하려면 목표를 선택하세요.",
            "pleaseSelectAnAttributonPartner": "어트리뷰션 파트너를 선택하세요.",
            "requestFailedDueTo": "요청이 실패한 이유는 다음과 같습니다: {reason}",
            "campaignIsUnderBudgetAllocation": "이 캠페인은 예산 할당에 따라 진행됩니다.",
            "pleaseFixValidationErrors": "계속하기 전에 페이지의 모든 유효성 검사 오류를 수정하세요."
        },
        "noData": {
            "noKeywordAddedToCart": "아직 장바구니에 추가한 키워드가 없습니다."
        }
    },
    "tooltips": {
        "maxCPMBid": "<h3>최대 CPM 입찰가<\/h3>앱 스토어에서 광고 노출 1,000회당 지불할 수 있는 최대 금액입니다. 광고의 50% 이상이 1초 동안 노출될 때마다 노출 수가 계산됩니다."
    },
    "page": {
        "adsManager": "애드 매니저",
        "automations": "Automation 관리",
        "overview": "개요",
        "recommendations": "권장 사항"
    }
}
