import { useSetupGuideStore } from '@/pinia/SetupGuide';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { validIntegrationOrgIdList } from '@/common/FilteringUtils';
import { useRoute, useRouter } from 'vue-router';
import bannerEvents from '@/plugins/mixpanel/definitions/bannerEvents';
import { useGlobal } from '@/composables/index';

export default function useExpiredIntegrationsBanner() {
    const setupStore = useSetupGuideStore();
    const { onboardingStatus } = storeToRefs(setupStore);
    const hasIntegration = computed(() => onboardingStatus.value?.sections?.integration?.state);
    const noValidOrgs = computed(() => {
        const validOrgs = validIntegrationOrgIdList();
        return !validOrgs?.length;
    });
    const showBanner = computed(() => hasIntegration.value && noValidOrgs.value);

    const router = useRouter();
    const route = useRoute();
    const app = useGlobal();
    const goToIntegrations = () => {
        const event = bannerEvents(route).expiredIntegrationsBannerConnect;
        app.$mixpanel.track(...event.toMixpanel());
        router.push({ name: 'integration' });
    };
    return { showBanner, goToIntegrations };
}
