{
    "buttons": {
        "integrateMMP": "整合您的 MMP",
        "joinBeta": "申请加入测试版计划",
        "contactSales": "联系销售"
    },
    "integrateAttrPartners": "整合您的归因合作伙伴，跟踪每个营销活动的投资回报率，并从目标和隧道等其他功能中获益。",
    "integrationWallStep": {
        "1": {
            "heading": "一体化平台",
            "description": "合并 Apple Search Ads 和 Attribution Partner (MMP)，在一个地方跟踪用户的应用历程！"
        },
        "2": {
            "heading": "可定制的仪表板",
            "description": "从 30 多个备选指标中为您的营销活动挑选最重要的关键绩效指标，并检查所需的趋势，从而获得更便捷的营销活动管理体验！"
        },
        "3": {
            "heading": "批量行动",
            "description": "只需点击一下，即可执行各种批量操作，节省时间。提高\/降低批量关键字的出价，暂停所有关键字或创建自动操作。"
        },
        "4": {
            "heading": "自动化和通知",
            "description": "设置自动化规则，以优化出价金额、冻结营销活动或添加否定关键字。获得突发变化通知！"
        }
    },
    "linkMMPPartners": "连接您的 MMP 合作伙伴，跟踪整个用户获取漏斗",
    "requestedBeta": "我们已收到您的请求，将尽快回复您",
    "sendBetaRequest": "通过以下按钮向我们的客户合作伙伴团队发送请求",
    "signInWithAppleSearchAds": "使用 Apple 搜索广告登录",
    "unlockFullPotential": "释放 Apple 搜索广告的全部潜能",
    "upgradePlan": "升级您的计划以访问此页面",
    "youNeedToIntegrateASA": "您需要整合苹果搜索广告才能解锁",
    "youNeedToIntegrateMMP": "您需要整合您的归属合作伙伴，以开启"
}
