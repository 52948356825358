{
    "expired": "已到期",
    "expiredInfo": "没有找到有效的 Apple Search Ads API连接",
    "live": "API运作中",
    "liveInfo": "Apple Search ADS API已生效。你可以舒适地管理你的操作。",
    "noIntegration": "没有连接",
    "noIntegrationInfo": "使用平台需要Apple Search Ads连接",
    "noValidIntegration": "没有有效连接",
    "noValidIntegrationInfo": "使用平台需要Apple Search Ads连接",
    "notLive": "无效",
    "notLiveInfo": "目前，Apple Search Ads API连接有问题发生。可能会产生一些操作上的故障。"
}
