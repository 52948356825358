import { createPinia, mapActions, mapState } from 'pinia';

const pinia = createPinia();

export const piniaActions = mapActions;
export const piniaState = mapState;
export default {
    install(app) {
        app.use(pinia);
    },
};
