import dayjs from '@/plugins/dayjs.mjs';
import { getSpendSummary, recentEvents, conversions } from '@/common/MaRequests/Attribution';
import { isNoData, ISO_DATE_FORMAT } from '@/common/MaUtils.mjs';
import { fetchAppsWithCompetitors } from '@/common/MaRequests/AppCompetitor';
import { ACCESS_TYPES, validOrgList } from '@/common/FilteringUtils';
import CurrencySymbolMap from 'currency-symbol-map';
import { USER_ACCESS_TYPE } from '@/pages/Settings/composables/useTeamMemberOptions';

export const types = {
    UPDATE_INTEGRATIONS: 'UPDATE_INTEGRATIONS',
    CHANGE_ORG: 'CHANGE_ORG',
    CHANGE_ACTIVE_CURRENCY: 'CHANGE_ACTIVE_CURRENCY',
    CLEAR_INTEGRATIONS: 'CLEAR_INTEGRATIONS',
    SET_TOTAL_SPEND: 'SET_TOTAL_SPEND',
    TOGGLE_CURRENCY_VIEW: 'TOGGLE_CURRENCY_VIEW',
    UPDATE_GOALS: 'UPDATE_GOALS',
    UPDATE_RULE_TEMPLATES: 'UPDATE_RULE_TEMPLATES',
    UPDATE_ATTRIBUTIONS: 'UPDATE_ATTRIBUTIONS',
    UPDATE_APPS_COMPETITORS: 'UPDATE_APPS_COMPETITORS',
    UPDATE_CANDIDATE_ATTR: 'UPDATE_CANDIDATE_ATTR',
    UPDATE_MENU_COLLAPSE: 'UPDATE_MENU_COLLAPSE',
    UPDATE_USER_ORGS: 'UPDATE_USER_ORGS',
    UPDATE_USER_ACCESS_TYPE: 'UPDATE_USER_ORGS',
};

Object.keys(types).forEach(k => types[k] = k);

const state = app => ({
    integrations: app.config.globalProperties.$maLocalStorage.integrations,
    userOrgs: [],
    goals: [],
    allGoals: [],
    ruleTemplates: [],
    attributions: {},
    activeOrg: app.config.globalProperties.$maLocalStorage.activeOrg,
    accountTotalSpend: [],
    currencyView: app.config.globalProperties.$maLocalStorage.currencyView, // symbol, code
    attrCandidates: [],
    activeCurrency: app.config.globalProperties.$maLocalStorage.activeCurrency,
    menuCollapsed: app.config.globalProperties.$maSessionStorage.menuCollapsed,
    fetched: { goals: false, orgs: false },
    appCompetitors: [],
    appCompetitorsLoading: false,
    appInfoFetched: false,
    userAccessType: null,
});

function fetchAttributionEvents(commit, retry) {
    recentEvents()
        .then((data) => { //Map<Id, TrackerType>
            commit(types.UPDATE_ATTRIBUTIONS, data[0]);
        })
        .catch((e) => {
            commit(types.UPDATE_ATTRIBUTIONS, {});
            if (isNoData(e)) {
                return;
            }
            if (retry) {//Timeout problem try again.
                setTimeout(() => {
                    fetchAttributionEvents(commit,false);
                }, 30000);
            }
            console.error('Failed fetch event list from account-store', e);
        });
}

function currenciesOfValidOrgs(s, g) {
    const currencySet = new Set();
    if (s.integrations) {
        Object.keys(g.integrations).flatMap(k => g.integrations[k])
            .filter(k => k.valid).forEach((o) => {
                currencySet.add(o.currency);
            });
    }
    return [...currencySet].map(c => ({ code: c, symbol: CurrencySymbolMap(c) }));
}

const getters = {
    fetched: s => s.fetched || {},
    integrations: s => s.integrations || {},
    userOrgs: s => s.userOrgs || [],
    goals: s => s.goals || [],
    allGoals: s => s.allGoals || [],
    goalsIdMap: (s) => {
        const map = {};
        s.goals.forEach(g => map[g.id] = { ...g });
        return map;
    },
    appGoalsMap: (s) => {
        const map = {};
        s.goals.forEach((g) => {
            if (!map[g.trackId]) {
                map[g.trackId] = [g];
            } else {
                map[g.trackId].push(g);
            }
        });
        return map;
    },
    ruleTemplates: s => s.ruleTemplates || [],
    attributions: s => s.attributions || {},
    appCompetitors: s => s.appCompetitors || {},
    appCompetitorsLoading: s => s.appCompetitorsLoading,
    currencyView: s => s.currencyView,
    activeOrg: s => s.activeOrg || {},
    currency: (s, g) => g.activeOrg.currency || 'USD',
    currencySymbol: (s, g) => {
        if (s.currencyView === 'code') {
            return g.activeCurrency;
        }
        return CurrencySymbolMap(g.activeCurrency);
    },
    attrCandidates: s => s.attrCandidates || [],
    currencies: (s, g) => currenciesOfValidOrgs(s,g),
    activeCurrency: (s, g) => s.activeCurrency || g.activeOrg.currency,
    menuCollapsed: s => s.menuCollapsed || false,
    isUserAccessLimited: s => s.userAccessType === ACCESS_TYPES.LIMITED,
    isUserNoAccess: s => s.userAccessType === USER_ACCESS_TYPE.NO_ACCESS,
    accountTotalSpend: s => s.accountTotalSpend,
    noValidIntegrations: s => Object.values(s.integrations || {}).every(orgs => orgs.every(org => !org.valid)),
};

const actions = app => ({
    updateIntegrations({ commit, state }, integrations) {
        if (!integrations || !Object.keys(integrations).length) {
            commit(types.CLEAR_INTEGRATIONS);
            commit(types.CHANGE_ORG, null);
        } else {
            commit(types.UPDATE_INTEGRATIONS, integrations);
            const [validOrg] = validOrgList() || [];
            if (validOrg && (!state.activeOrg || !integrations[state.activeOrg.integId]
                || !integrations[validOrg.integId].some(acc => acc.orgId === state.activeOrg.orgId))) {
                commit(types.CHANGE_ORG, validOrg);
            }
        }
    },
    updateUserOrgs({ commit }, orgs) {
        commit(types.UPDATE_USER_ORGS, orgs);
    },
    updateUserAccessType({ commit }, accessType) {
        commit(types.UPDATE_USER_ACCESS_TYPE, accessType);
    },
    updateGoals({ commit }, goals) {
        commit(types.UPDATE_GOALS, goals);
    },
    updateAppCompetitors({ commit }, competitors) {
        commit(types.UPDATE_APPS_COMPETITORS, { data: competitors });
    },
    updateAttributions({ commit }, attributions) {
        commit(types.UPDATE_ATTRIBUTIONS, attributions);
    },
    updateCandidateAttr({ commit }, candidates) {
        commit(types.UPDATE_CANDIDATE_ATTR, candidates);
    },
    changeOrg({ commit }, org) {
        commit(types.CHANGE_ORG, org);
    },
    changeActiveCurrency({ commit }, currency) {
        commit(types.CHANGE_ACTIVE_CURRENCY, currency);
    },
    updateMenuCollapse({ commit }, isCollapse) {
        commit(types.UPDATE_MENU_COLLAPSE, isCollapse);
    },
    fetchAccountTotalSpend({ commit, state, getters }) {
        const startDate = dayjs().add(-30, 'days').format(ISO_DATE_FORMAT);
        const endDate = dayjs().format(ISO_DATE_FORMAT);
        let orgList = [];
        Object.keys(state.integrations || {}).forEach((k) => {
            state.integrations[k].forEach((i) => {
                orgList.push(i.orgId);
            });
        });
        if (getters.isUserAccessLimited) {
            orgList = getters.userOrgs.map(org => org.orgId);
        }
        getSpendSummary(startDate, endDate, orgList)
            .then((data) => {
                let totals = [];
                if (data && data.length) {
                    totals = data[0].totalExpense;
                }
                commit(types.SET_TOTAL_SPEND, totals);
            })
            .catch((e) => {
                commit(types.SET_TOTAL_SPEND, []);
                const { errors } = e;
                if (errors && errors.includes('NOT_LOGGED_IN')) {
                    return;
                }
                app.config.globalProperties.$log.error('Failed to fetch account spend summary', e);
            });
    },
    getAppsCompetitors({ commit }) {
        commit(types.UPDATE_APPS_COMPETITORS, { loading: true });
        fetchAppsWithCompetitors()
            .then((data) => {
                commit(types.UPDATE_APPS_COMPETITORS, { data, loading: false });
            })
            .catch((e) => {
                commit(types.UPDATE_APPS_COMPETITORS, { data: [], loading: false });
                if (isNoData(e)) {
                    return;
                }
                app.config.globalProperties.$log.error('Failed to fetch app competitors', e);
            });
    },
    getAttributions({ commit }) {
        fetchAttributionEvents(commit, true);
    },
    getGoals({ commit }) {
        conversions()
            .then((data) => {
                commit(types.UPDATE_GOALS, data);
            })
            .catch((e) => {
                commit(types.UPDATE_GOALS, []);
                if (isNoData(e)) {
                    return;
                }
                app.config.globalProperties.$log.error('Failed to fetch conversions', e);
            });
    },
    toggleCurrencyView({ state, commit }) {
        const { currencyView } = state;
        const view = currencyView === 'symbol' ? 'code' : 'symbol';
        app.config.globalProperties.$maLocalStorage.currencyView = view;
        commit('TOGGLE_CURRENCY_VIEW', view);
    },
});

const mutations = app => ({
    [types.UPDATE_INTEGRATIONS](s, integrations) {
        app.config.globalProperties.$maLocalStorage.integrations = integrations;
        s.integrations = integrations;
    },
    [types.CLEAR_INTEGRATIONS](s) {
        app.config.globalProperties.$maLocalStorage.integrations = null;
        s.integrations = null;
    },
    [types.UPDATE_GOALS](s, goals) {
        s.goals = goals;
        s.allGoals = Array.from(goals);
        s.fetched.goals = true;
    },
    [types.UPDATE_USER_ORGS](s, orgs) {
        s.userOrgs = orgs;
        s.fetched.orgs = true;
    },
    [types.UPDATE_USER_ACCESS_TYPE](s, accessType) {
        s.userAccessType = accessType;
    },
    [types.UPDATE_RULE_TEMPLATES](s, templates) {
        s.ruleTemplates = templates;
    },
    [types.UPDATE_APPS_COMPETITORS](s, { data, loading }) {
        s.appCompetitors = data || s.appCompetitors;
        if (loading !== undefined) {
            s.appCompetitorsLoading = loading;
        }
    },
    [types.UPDATE_ATTRIBUTIONS](s, attrs) {
        s.attributions = attrs;
    },
    [types.UPDATE_CANDIDATE_ATTR](s, candidates) {
        s.attrCandidates = candidates;
    },
    [types.CHANGE_ORG](s, org) {
        app.config.globalProperties.$maLocalStorage.activeOrg = org;
        s.activeOrg = org;
    },
    [types.CHANGE_ACTIVE_CURRENCY](s, currency) {
        app.config.globalProperties.$maLocalStorage.activeCurrency = currency;
        s.activeCurrency = currency;
    },
    [types.SET_TOTAL_SPEND](state, spend) {
        state.accountTotalSpend = spend;
    },
    [types.TOGGLE_CURRENCY_VIEW](s, val) {
        s.currencyView = val;
    },
    [types.UPDATE_MENU_COLLAPSE](s, isCollapse) {
        app.config.globalProperties.$maSessionStorage.menuCollapsed = isCollapse;
        s.menuCollapsed = isCollapse;
    },
});

export default app => ({
    namespaced: true,
    state: state(app),
    getters,
    actions: actions(app),
    mutations: mutations(app),
});
