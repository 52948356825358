import * as Sentry from '@sentry/vue';
import {
    isStaging, isDevelopment, SENTRY_SAMPLE_RATE, environment, SENTRY_DSN,
} from '@/common/Config';
import { version } from '../../package.json';


export default {
    install(app, { router }) {
        // enable on the client browser not on builds
        if (typeof window === 'undefined' || isDevelopment) {
            return;
        }
        const release = `search-ads-ui@${ version }`;
        Sentry.init({
            app,
            dsn: SENTRY_DSN,
            environment, // development, staging, production
            release,
            integrations: [
                new Sentry.browserTracingIntegration({ router }),
            ],
            logErrors: true,
            // Specifies the request URLs to which Sentry tracing headers should be added; only requests with URLs that match the defined patterns will carry tracing data.
            tracePropagationTargets: [
                /^http:\/\/localhost:4001/,
                /^https:\/\/gateway.mobileaction\.co/,
                /^https:\/\/gateway.searchads\.com/,
                /^https:\/\/gateway-testing.mobileaction\.co/,
                /^https:\/\/gateway-testing.searchads\.com/,
                /^https:\/\/ma-searchads-ui-pr-.*\.herokuapp\.com/,
            ],
            tracesSampler(samplingContext) {
                const { location } = samplingContext;
                const blackList = [
                    '/auth/callback', // stop tracking auth/callback page
                ];
                const isPathBlocked = path => blackList.some(p => path?.includes(p));

                if (isStaging || isDevelopment || isPathBlocked(location?.pathname)) {
                    return 0; // ignore development and callback page events
                }

                return Number(SENTRY_SAMPLE_RATE); // production
            },
        });

        app.config.globalProperties.$sentry = {
            setUser(user) {
                const { maImp, maImpPrefix, jwtData } = app.config.globalProperties.$store.getters;
                const username = user.username || jwtData.username;
                const userId = maImpPrefix + username;

                Sentry.setUser({
                    id: userId,
                    email: username,
                    imp: maImp,
                    accountId: user.accountId,
                    platform: jwtData?.platform,
                    membershipType: jwtData?.membershipType,
                    registrationDate: user.createdAt,
                    lastLogin: new Date(jwtData.iat * 1000).toISOString(),
                });
            },
            clearUser() {
                const scope = Sentry.getCurrentScope();
                if (!scope) {
                    return;
                }
                scope.setUser(null);
            },
        };
        app.$log.debug('Sentry | initialized:', environment);
    },
};
