export const KEYWORD_TYPE = {
    NEGATIVE: 'negative-keyword',
    KEYWORD: 'keyword',
    SEARCH_TERM: 'search-term',
};

export const KEYWORD_TYPE_LIST = Object.keys(KEYWORD_TYPE);

export const NEGATIVE_KEYWORD_TYPE = {
    NEGATIVE_AD_GROUP: 'negative-ad-group',
    NEGATIVE_CAMPAIGN: 'negative-campaign',
};

export const KEYWORD_STATUS = {
    ACTIVE: 'ACTIVE',
    PAUSED: 'PAUSED',
};
export const AD_STATUS = {
    ACTIVE: 'ACTIVATE',
    PAUSE: 'PAUSE',
};
