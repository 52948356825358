<template>
    <div :class="containerClass">
        <ma-badge
            type="secondary"
            variant="green"
            size="large"
            class="w-fit"
        >
            <fa icon="lock" class="mr-2"/>
            {{ badgeText }}
        </ma-badge>
        <h1 class="text-black text-2xl font-semibold mt-4 text-center">
            {{ $t(headingText) }}
        </h1>
        <ma-apple-integration-button
            v-if="wallType === WALL_TYPE.INTEGRATE_ASA"
            :redirect-path="redirectPath"
            :button-text="$t('signInWithAppleSearchAds')"
            :is-from-wall="true"
            class="mt-4 !h-9 flex-shrink-0"
        />
        <router-link
            v-if="wallType === WALL_TYPE.INTEGRATE_MMP"
            :to="{ name: 'attributions' }"
        >
            <ma-button class="mt-4">
                <fa icon="plug"/>
                {{ $t('buttons.integrateMMP') }}
            </ma-button>
        </router-link>
        <a
            v-if="wallType === WALL_TYPE.CONTACT_SALES"
            :href="REQUEST_DEMO_URL"
            target="_blank"
        >
            <ma-contact-sales-button
                class="mt-4"
                icon="calendar"
            />
        </a>
        <template v-if="wallType === WALL_TYPE.JOIN_BETA">
            <ma-button
                v-if="!sentBetaRequest"
                class="mt-4"
                icon="send"
                @click="sendBetaRequest"
            >
                {{ $t('buttons.joinBeta') }}
            </ma-button>
            <ma-badge v-else type="secondary" variant="purple">
                <ma-icon name="like-bold"/>
                {{ $t('requestedBeta') }}
            </ma-badge>
        </template>
        <div class="flex mt-8 w-full flex-shrink max-w-[1200px] gap-12">
            <div class="w-2/5 flex justify-end">
                <a-timeline class="!mt-16">
                    <template
                        v-for="(item, index) in listItems"
                        :key="index"
                    >
                        <a-timeline-item v-if="$te(item)">
                            <span class="ml-4 text-lg text-gray-800 inline-block">{{ this.$t(item) }}</span>
                            <template #dot>
                                <span class="ma-timeline-dot">{{ index + 1 }}</span>
                            </template>
                        </a-timeline-item>
                    </template>
                </a-timeline>
            </div>
            <div class="w-3/5 flex items-center justify-start">
                <img
                    class="object-cover w-[600px] desktop-lg:w-[700px]"
                    :src="imgSource"
                >
            </div>
        </div>
    </div>
</template>

<script>
    import {
        MaBadge,
        MaButton,
        MaIcon,
    } from '@mobileaction/action-kit';
    import { WALL_TYPE } from '@/common/WallUtils';
    import MaAppleIntegrationButton from '@/components/social-login/MaAppleIntegrationButton.vue';
    import { joinBetaProgram } from '@/common/MaRequests/JoinBeta';
    import { BETA_TYPE } from '@/common/BetaUtils';
    import MaContactSalesButton from '@/components/MaContactSalesButton.vue';
    import { REQUEST_DEMO_URL } from '@/common/Config/index';
    import messages from '@/components/Walls/Walls.i18n';
    import sharedMessages from '@/components/Walls/ListItems.i18n';

    export default {
        name: 'ma-general-wall-template',
        components: {
            MaContactSalesButton,
            MaBadge,
            MaButton,
            MaIcon,
            MaAppleIntegrationButton,
        },
        props: {
            headingText: { type: String, required: true },
            wallType: { type: String, default: WALL_TYPE.INTEGRATE_ASA },
            listItems: { type: Array, required: true },
            imgFile: { type: String, required: true },
            displayUnderTab: { type: Boolean },
            betaMessage: { type: String, default: null, required: false },
            betaType: {
                type: String,
                default: null,
                validator(val) {
                    return Object.values(BETA_TYPE).includes(val) || val === null;
                },
            },
        },
        emits: ['after-beta-request'],
        data() {
            return {
                WALL_TYPE,
                REQUEST_DEMO_URL,
                sentBetaRequest: false,
            };
        },
        computed: {
            badgeText() {
                switch (this.wallType) {
                    case WALL_TYPE.INTEGRATE_ASA:
                        return this.$t('youNeedToIntegrateASA');
                    case WALL_TYPE.INTEGRATE_MMP:
                        return this.$t('youNeedToIntegrateMMP');
                    case WALL_TYPE.JOIN_BETA:
                        return this.$t('sendBetaRequest');
                    case WALL_TYPE.CONTACT_SALES:
                        return this.$t('upgradePlan');
                    default: return this.$t('youNeedToIntegrateASA');
                }
            },
            imgSource() {
                try {
                    return `https://files.mobileaction.co/public/sa/pageImages/${this.imgFile}`;
                } catch (e) {
                    console.error('Failed to find image', e);
                    return 'https://files.mobileaction.co/public/sa/pageImages/dashboard.svg';
                }
            },
            containerClass() {
                return `ma-general-wall-template
                ${
                    this.displayUnderTab
                        ? 'relative pt-5'
                        : 'absolute pt-12'
                }`;
            },
            redirectPath() {
                //current page
                return this.$route.path;
            },
        },
        methods: {
            async sendBetaRequest() {
                try {
                    await joinBetaProgram(this.betaType, this.betaMessage);
                    this.$emit('after-beta-request');
                    this.sentBetaRequest = true;
                } catch (e) {
                    this.$message.error('Failed to send request: ' + JSON.stringify(e));
                }
            },
        },
        i18n: {
            messages,
            sharedMessages,
        },
    };
</script>

<style lang="less">
    .ma-general-wall-template {
        @apply inset-0 overflow-hidden px-12 flex flex-col items-center;
        .ma-timeline-dot {
            @apply h-8 w-8 rounded-full text-white bg-jordy-blue
            flex items-center justify-center font-bold;
        }
        .ant-timeline-item:not(:last-child) {
            @apply pb-8;
        }
    }
</style>
