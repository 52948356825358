{
    "duplicateKeywordSuccess": "没有任何冲突问题，您可以放心保存关键字了！",
    "explanation": {
        "case_1_1": "您不会从准确的搜索词中获得任何印象，但您可能会从近似的变体中获得印象<br><br> 问题代码：1.1",
        "case_1_10": "您已经在不同的广告组中使用了相同的关键词<br><br> 问题代码：1.10",
        "case_1_12": "您已经在不同的广告组中使用了不同匹配类型的相同关键词<br><br> 问题代码：1.12",
        "case_1_16": "您已经在不同的营销活动中使用了相同的关键字<br><br> 问题代码：1.16",
        "case_1_18": "您已经在不同的营销活动中使用了不同匹配类型的相同关键字<br><br> 问题代码：1.18",
        "case_1_19": "您不会从准确的搜索词中获得任何印象，但您可能会从接近的变体中获得印象<br><br> 问题代码：1.19",
        "case_1_2": "您不会从准确的搜索词中获得任何印象，但您可能会从接近的变体中获得印象<br><br> 问题代码：1.2",
        "case_1_20": "您不会从准确的搜索词中获得任何印象，但您可能会从接近的变体中获得印象<br><br> 问题代码：1.20",
        "case_1_21": "您不会从确切的搜索词中获得任何印象，但您可能会从其他变体中获得印象<br><br> 问题代码：1.21",
        "case_1_22": "您不会从确切的搜索词中获得任何印象，但您可能会从其他变体中获得印象<br><br> 问题代码：1.22",
        "case_1_23": "您已经拥有与精确关键字相同的关键字，且出价相同<br><br> 问题代码：1.23",
        "case_1_24": "您已经拥有相同的关键词，但出价不同，匹配类型也不同<br><br> 问题代码：1.24",
        "case_1_25": "您已经拥有相同出价和相同匹配类型的相同关键词<br><br> 问题代码：1.25",
        "case_1_26": "<br><br> 问题代码：1.26",
        "case_1_3": "您已经拥有具有相同特征的相同关键词 (Bid - Exact)<br><br> 问题代码：1.3",
        "case_1_30": "您已经在不同的广告组中使用了不同匹配类型的相同关键词<br><br> 问题代码：1.30",
        "case_1_32": "您已经在不同的广告组中使用了相同的关键词<br><br> 问题代码：1.32",
        "case_1_36": "您已经在不同的营销活动中使用了不同匹配类型的相同关键词<br><br> 问题代码：1.36",
        "case_1_38": "<br><br> 问题代码：1.38",
        "case_1_39": "您不会从准确的搜索词中获得任何印象，但您可能会从接近的变体中获得印象<br><br> 问题代码：1.39",
        "case_1_4": "您已经拥有具有相同特征的相同关键字 (Bid - Exact)<br><br> 问题代码：1.4",
        "case_1_40": "您不会从准确的搜索词中获得任何印象，但您可能会从近似的变体中获得印象<br><br> 问题代码：1.40",
        "case_1_5": "您已经拥有与广泛关键字相同的关键字，且出价相同<br><br> 问题代码：1.5",
        "case_1_6": "您已经拥有相同的关键词，但出价和匹配类型不同<br><br> 问题代码：1.6",
        "case_2_1": "删除重复的否定关键字，否则如果您尝试继续添加<br><br> 问题代码，请注意它将被忽略：2.1",
        "case_2_10": "您正试图添加与该广告组中广泛否定关键字相同的否定关键字<br><br> 问题代码：2.10",
        "case_2_11": "您正在尝试添加否定关键字，该关键字在此广告组中已作为精确关键字存在<br><br> 问题代码：2.11",
        "case_2_12": "您正在尝试添加否定关键字，该关键字在此广告组中已作为广泛关键字存在<br><br> 问题代码：2.12",
        "case_2_2": "您正在尝试以不同的匹配类型添加相同的否定关键字！<br><br>问题代码：2.2",
        "case_2_21": "您正试图在此广告系列中添加与精确否定关键字相同的否定关键字，如果您继续添加，您将不会从该否定关键字中获得任何印象<br><br> 问题代码：2.21",
        "case_2_22": "您正试图添加与广泛否定关键字相同的否定关键字，如果您继续添加，您将不会从该否定关键字中获得任何印象<br><br> 问题代码：2.22",
        "case_2_23": "您正试图添加与精确关键字相同的否定关键字，如果您继续添加，您将无法从该关键字中获得任何印象<br><br> 问题代码：2.23",
        "case_2_24": "您试图在此广告系列中添加与广泛关键字相同的否定关键字，如果您继续添加，您将无法从该关键字中获得任何印象<br><br> 问题代码：2.24",
        "case_2_3": "您正试图添加与精确关键字相同的否定关键字，如果您继续添加，您将无法从该否定关键字中获得任何印象<br><br> 问题代码：2.3",
        "case_2_33": "删除重复的否定关键字，否则如果您尝试继续添加<br><br> 问题代码，请注意它将被忽略：2.33",
        "case_2_34": "您正试图添加与该广告组中广泛否定关键字相同的否定关键字<br><br> 问题代码：2.34",
        "case_2_35": "您正在尝试添加否定关键字，该关键字在此广告组中作为精确关键字存在<br><br> 问题代码：2.35",
        "case_2_36": "您正在尝试添加否定关键字，而该关键字在此广告组中已作为广泛关键字存在<br><br> 问题代码：2.36",
        "case_2_4": "您试图在此广告系列中添加与广泛关键字相同的否定关键字，如果您继续添加，您将无法从该否定关键字中获得任何印象<br><br> 问题代码：2.4",
        "case_2_9": "删除重复的否定关键字，否则，如果您尝试继续添加<br><br> 问题代码，请注意它将被忽略：2.9"
    },
    "issue": "问题",
    "issueAdGroup": "没有关键字 | 您正在向广告组添加关键字，请查看下表是否存在冲突问题！| 您正在向{count} 不同的广告组添加关键字，请检查下表是否存在冲突问题！",
    "issueCampaign": "没有关键字 | 您正在为广告系列添加否定关键字，请检查下表是否存在冲突问题！| 您在{count} 不同的广告系列中添加了否定关键字，请查看下表是否存在冲突问题！",
    "issueWarning": "{conflictingKeywordCount} {totalKeywordCount} 添加的关键词中，有以下冲突问题。",
    "keywordIssues": "关键词问题",
    "matchTypeConfirmTitle": "请确认该关键词的匹配类型将设置为{type}?",
    "nIssues": "无问题 | 1 个问题 |{n} 个问题",
    "noConflictDetected": "没有检测到冲突，保存您的关键字...",
    "noConflictingIssue": "未发现冲突问题",
    "noKeywordIssue": "无关键词问题",
    "resolved": "已解决",
    "solveConflictsBeforeContinue": "请先解决该关键词的冲突问题，然后再继续开展更健康的活动结构！",
    "statusConfirmTitle": "请确认该关键字的状态将设置为{status}?",
    "sureCancelAdding": "您确定要取消添加这个关键词吗？",
    "sureDeleteNegativeKeyword": "您确定要删除这个否定关键字吗？",
    "targetedAdGroup": "目标广告组",
    "targetedCampaign": "有针对性的活动",
    "toggleStatus": "切换关键字状态",
    "updateMatchType": "更新匹配类型"
}
