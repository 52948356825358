<template>
    <ma-loading-screen v-if="loading"/>
    <div v-else class="ma-topbar-layout">
        <div class="w-full bg-[#161a2d] py-3 px-16">
            <img
                src="@/assets/sa-logo-white.svg"
                alt="Search Ads Logo"
                class="h-12"
            >
        </div>
        <div
            class="h-full overflow-auto pb-4"
            :style="{ backgroundColor }"
        >
            <div
                class="mx-auto"
                :class="{ 'desktop:w-[1220px]': !removeFixedWidth }"
            >
                <router-view/>
            </div>
        </div>

        <ma-update-checker/>
    </div>
</template>

<script setup>
    import { computed, onMounted } from 'vue';
    import { storeToRefs } from 'pinia';
    import { useRoute } from 'vue-router';
    import colors from 'tailwindcss/colors';
    import { useAccountStore } from '@/pinia/Account';
    import MaLoadingScreen from '@/components/MaLoadingScreen.vue';
    import MaUpdateChecker from '@/components/MaUpdateChecker/index.vue';

    defineOptions({
        name: 'ma-topbar-layout',
    });

    const store = useAccountStore();
    const { loading } = storeToRefs(store);
    const currentRoute = useRoute();
    const backgroundColor = computed(() => {
        return currentRoute.meta?.layoutBgColor || colors.gray[200];
    });
    const removeFixedWidth = computed(() => {
        return currentRoute.meta?.layoutRemoveFixedWidth;
    });

    onMounted(() => {
        store.fetchApps();
    });
</script>

<style lang="less" scoped>
    .ma-topbar-layout {
        @apply flex flex-col basis-auto box-border flex-grow min-w-0 h-full;
    }
</style>
